import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import ORM, { BaseModel } from "./orm";

export interface OAuth2Code extends BaseModel {
    client_id: string;
    user_id: string;
    code: string;
    redirect_uri: string;
    expires: string;
    scope: string;
}

export interface OAuth2Redirect extends BaseModel {
    uri: string;
}


export class _NativeAppORM extends ORM<OAuth2Code>{
    public async authorize(client_id: string, redirect_uri: string, response_type: string, scope: string, state: string): Promise<OAuth2Redirect> {
        const result = await this.apiService.request('POST', `/${this.endpoint}/authorize`, {
            accept: true,
            client_id,
            redirect_uri,
            response_type,
            scope,
            state
        }) as SingleRecordResponse<OAuth2Redirect>;
        return result.record;
    }
};

export const NativeAppORM = new _NativeAppORM('snowflake');

