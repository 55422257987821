import AsyncButton from "@components/button/AsyncButton.component";
import S3Uploader from "@components/s3uploader/S3Uploader.component";
import SourceORM, { Source, SourceRecordTypeORM } from "@models/source";
import { getErrorMessage } from "@services/errors.service";
import { invalidateDataLoadsForSRT, invalidateMissionControlDataFlowData, invalidateMissionControlDataSourcesData, invalidatePipelineNodes, invalidateSourceRecordTypes, invalidateSources, invalidateTableData, saveSource, useAvailableSnowflakeTables, usePipelineNode, useProjectConfig, useSourceRecordTypes } from "@stores/data.store";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form} from "react-bootstrap";
import FileORM, { File, FilePart } from '@models/file';
import styled from 'styled-components';
import BackgroundService from "@services/bg.service";
import ApiService, { JobEnqueueResponse, ListRecordsResponse } from "@services/api/api.service";
import { SimpleDataTable, tableDataFromRows } from "@components/datatable/DataTable.component";
import { useLocation, useNavigate } from "react-router-dom";
import Danger from "@components/statusIndicators/Danger.component";
import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import { summarizeNumber, removeFileExtension } from "@services/formatting.service";
import BuildOrchestrationORM from "@models/buildOrchestration";
import Warning from "@components/statusIndicators/Warning.component";
import { PipelineNode, PipelineNodeORM } from "@models/pipelineNode";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import LoadingCard from "@components/card/LoadingCard.component";

const Styles = styled.div`
    .modal-content {
        color: #000000;
        font-family: Poppins;
        text-align: left;
    }

    .modal-header {
        padding: 12px 24px 0px 24px;

        border-bottom: none;

        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }

    .modal-body {
        padding: 12px 24px;
        .form-label {
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: #000000;
        }

        .form-control {
            border-radius: 5px;
            border: 1px solid #878787;
        }
    }

    .modal-footer {
        border-top: none;
    }

    .file-upload-info {
        margin: 20px 40px;
        text-align: center;
    }

    .uppy-DragDrop-browse {
        color: #FF9F00;
    }

    .separator {
        color: #D9D9D9;
        display: flex;
        align-items: center;
        text-align: center;
      }
      
      .separator::before,
      .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #D9D9D9;
        ;
      }
      
      .separator:not(:empty)::before {
        margin-right: .25em;
      }
      
      .separator:not(:empty)::after {
        margin-left: .25em;
      }
`

function getFileNameFromUrl(url: string): string {
    // Split the URL by '/'
    const segments = url.split('/');
    // Get the last segment
    const lastSegment = segments[segments.length - 1];
    // Split the last segment by '?' (to handle query parameters)
    const parts = lastSegment.split('?');
    // Return the first part which is the filename
    return parts[0];
  }
  
  export interface UploadRequestResponse {
    record: File
    signed_upload_url: string;
}

  export async function downloadAndUploadFile(downloadUrl: string) {
    try {
        const initResp = await ApiService.getInstance().request('POST', '/files/init-upload-from-url', {
          url: downloadUrl
      }) as UploadRequestResponse;
  
  
        console.log(initResp);
  
        try {
  
              const results = await ApiService.getInstance().request(
                  'POST',
                  `/files/${initResp.record.id}/process`
              ) as JobEnqueueResponse;
  
              // Split it into file parts
              const partResults = await BackgroundService.getInstance().waitForJob(results.job_id);
  
              if (partResults === null) {
                  //setError(`There was an error processing your file`);
                  return;
              }
  
              // setFileParts(partResults);
              // setActiveFilePart(partResults[0]);
              // setRecordTypeName(file.name)
              // if(onFileProcessed) {
              //     onFileProcessed(file.id);
              // }
          } catch (err) {
              //setError(getErrorMessage(err));
  
          } finally {
              //setProcessing(false);
          }
        
          return initResp.record.id;
    } catch (error) {
        console.error('Error:', error);
    }
  }




interface SnowflakeColumnOption {
    name: string;
    type: string;
    primary_key: string;
}

export interface DataSourceImporterProps {
    onSourceAdded: (fileId: string, sourceRecordTypeId: string) => void;
    pipelineNodeId?: string;
    sourceRecordTypeId?: string;
    allowSelectExisting?: boolean;
    selectExistingBlacklist?: string[];
    allowSystemConnectors?: boolean;
    loadFromUrl?: string;
}

function DataSourceImporter({onSourceAdded, pipelineNodeId, sourceRecordTypeId, allowSelectExisting, selectExistingBlacklist, allowSystemConnectors, loadFromUrl}: DataSourceImporterProps) {
    const [error, setError] = useState('');
    const [sourceName, setSourceName] = useState('');
    const [recordTypeName, setRecordTypeName] = useState('');
    const [recordTypeDescription, setRecordTypeDescription] = useState('');
    const [sourceDescription, setSourceDescription] = useState('');
    const [selectedSource, setSelectedSource] = useState<Source>();
    const [saving, setSaving] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [newFile, setNewFile] = useState<File>();


    const pipelineNode = usePipelineNode(pipelineNodeId);
    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(false);
    const [importing, setImporting] = useState<boolean>(false);
    const [processing, setProcessing] = useState(false);
    const [fileParts, setFileParts] = useState<FilePart[]>([]);
    const [activeFilePart, setActiveFilePart] = useState<FilePart|undefined>(undefined);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

    const tenantConfig = useProjectConfig();

    const database = useMemo(() => {
        if (tenantConfig.data) {
            return tenantConfig.data.database;
        }
        return '';
    }, [tenantConfig.dataUpdatedAt]);

    const tenantConfigId = useMemo(() => {
        if (tenantConfig.data) {
            return tenantConfig.data.id;
        }
        return '';
    }, [tenantConfig.dataUpdatedAt]);

    const navigate = useNavigate();

    const [step, setStep] = useState<'UPLOAD_DATA'|'LOAD_FROM_URL'|'CONFIRM_IMPORT'|'SELECT_CONNECTOR_TYPE'|'SELECT_TABLE'|'SET_COMPOSITE_KEY'|'SELECT_COLUMNS'>('UPLOAD_DATA');

    const { isLoading: loadingRecordTypes, data: recordTypes, refetch: refreshRecordTypes} = useSourceRecordTypes((!!selectedSource) ? selectedSource!.id : null, !!selectedSource);

    const sourceRecordTypes = useSourceRecordTypes();
    const [selectedTable, setSelectedTable] = useState('');
    const [compositeKeySelection, setCompositeKeySelection] = useState<string[]>([]);
    const [lastModifiedColumnSelection, setLastModifiedColumnSelection] = useState<string>('');
    

    // currently only supporting csv/excel
    const selectedSourceType = "flat_file";

    useEffect(() => {
        if (loadFromUrl) {
            const resume =  async () => {
                setStep('LOAD_FROM_URL');
                const file_id = await downloadAndUploadFile(loadFromUrl);
                const file = await FileORM.findById(file_id as string);
                setNewFile(file);
                const parts = await ApiService.getInstance().request(
                    'GET', 
                    `/files/${file_id}/parts`
                ) as ListRecordsResponse<FilePart>;
                setFileParts(parts.records);
                setActiveFilePart(parts.records[0]);
                setRecordTypeName(file.name)
                setStep('CONFIRM_IMPORT');
            }
            resume();
        }
    }, []);


    const fileUploadCompleted = useCallback(async (file: File) => {
        setNewFile(file);
        setProcessing(true);
        try {
            await processFile(file);
            setStep('CONFIRM_IMPORT');

        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setProcessing(false);
        }
        

    }, []);


    const close = useCallback((fileId: string, sourceRecordTypeId: string) => {
        setShow(false);

        // Do this on a delay so it doesn't change as the modal is fading out.
        setTimeout(() => {
            setStep('UPLOAD_DATA');
        }, 1000);
        setSourceName('');
        setSourceDescription('');
        setFileParts([]);
        setRecordTypeDescription('');
        setRecordTypeName('');
        
        if (sourceRecordTypeId) {
            onSourceAdded(fileId, sourceRecordTypeId);

        }
    }, [onSourceAdded]);

    

    const processFile = useCallback(async (file: File) => {

        const results = await ApiService.getInstance().request(
            'POST',
            `/files/${file.id}/process`
        ) as JobEnqueueResponse;

        // Split it into file parts
        const partResults = await BackgroundService.getInstance().waitForJob(results.job_id);
        if (partResults === null) {
            throw new Error('There was an unknown error while processing your file.');

        }
        
        setFileParts(partResults);
        setActiveFilePart(partResults[0]);
        setRecordTypeName(removeFileExtension(file.name))

       
    }, [newFile]);


    const runImport = useCallback(async () => {
        if (!activeFilePart) {
            return;
        }
        setImporting(true);
        setError('');
        try {
            
            let srtIdImportTarget: string;
            // Create the source record type
            if (!sourceRecordTypeId) {
                const srtResult = await SourceRecordTypeORM.save({
                    id: null,
                    // this lazy-creates the singleton flat file data source. See blueprints/source_record_types.py
                    source_id: 'FLAT_FILE',
                    name: recordTypeName,
                    description: recordTypeDescription,
                    resource_name: '',
                    column_preferences: [],
                    columns: selectedColumns.map(s => {
                        return {
                            label: s,
                            key: s,
                            is_synthetic_field: false,
                            is_system_field: false,
                        }
                    }),
                    composite_key: compositeKeySelection,
                });
                srtIdImportTarget = srtResult.id as string;
            } else {
                srtIdImportTarget = sourceRecordTypeId;
            }


            
            const result = await ApiService.getInstance().request('POST', `/files/${activeFilePart!.file_id}/parts/${activeFilePart!.id}/load`, {
                'source_record_type_id': srtIdImportTarget,
                'columns': selectedColumns,
            }) as JobEnqueueResponse;

            const loadResults = await BackgroundService.getInstance().waitForJob(result.job_id);
           
            invalidateDataLoadsForSRT(srtIdImportTarget);
            close(activeFilePart!.file_id, srtIdImportTarget as string);
            
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setImporting(false);
        }
    }, [activeFilePart, sourceRecordTypeId, recordTypeName, recordTypeDescription, selectedColumns, compositeKeySelection]);

    const [selectedConnectorType, setSelectedConnectorType] = useState('FLAT_FILE');

    const addButtonIsDisabled = useMemo(() => {
        // if (selectedConnectorType == 'FLAT_FILE' && selectedColumns.length > 50) {
        //     return true;
        // }
        if (sourceRecordTypeId) {
            return false;
        }
        if (recordTypeName) {
            return false;
        }
        

        return true;
    }, [sourceRecordTypeId, recordTypeName, selectedConnectorType, selectedColumns]);

    const recordTypeOptions = useMemo(() => {
        if (!sourceRecordTypes.data) {
            return [];
        }

        return sourceRecordTypes.data.filter(s => {
            if (!selectExistingBlacklist) {
                return true;
            }
            if (!selectExistingBlacklist.includes(s.id as string)) {
                return true;
            }
            return false;
        }).sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            return 1;
        }).map(f => {
            return {
                label: f.name,
                value: f.id,
            }
        })
    }, [sourceRecordTypes.dataUpdatedAt, selectExistingBlacklist]);

    const chooseConnectorType = useCallback((value: string) => {
        setSelectedConnectorType(value);
        if (value == 'SNOWFLAKE_TABLE') {
            setStep('SELECT_TABLE');

        } else {
            setStep('UPLOAD_DATA');
        }
    }, []);

    const [connectorSourceLoading, setConnectorSourceLoading] = useState(false);

    const availableUpstreamNodeFilterFunc = useCallback((pn: PipelineNode) => {
        console.log('checking node', pn);
        if (!pipelineNode.data) {
            console.log('No pn data', pipelineNode.data);
            return true;
        }

        if (pn.node_type == 'BUSINESS_OBJECT') {
            return false;
        }
        return !pipelineNode.data.upstream_node_ids.includes(pn.id as string);
    }, [pipelineNode.dataUpdatedAt])
    

    const saveConnectorSource = useCallback(async () => {
        setConnectorSourceLoading(true);
        const newSource = {
            id: null,
            name: sourceName,
            type: selectedConnectorType,
        };

        const result = await SourceORM.save(newSource);
        navigate('/source/' + result.id as string);
        setConnectorSourceLoading(false);
    }, [sourceName, selectedConnectorType, navigate]);


    const availableSnowflakeTables = useAvailableSnowflakeTables();
    const availableTableOptions = useMemo(() => {
        if (!availableSnowflakeTables.data) {
            return [];
        }

        return availableSnowflakeTables.data.map(r => {
            return {
                value: `"${r.database_name}"."${r.schema_name}"."${r.name}"`,
                label: r.name,
                description: `Schema: ${r.database_name}.${r.schema_name}`,
                badgeText: r.rows == undefined ? 'VIEW' : (summarizeNumber(r.rows) + ' rows'),
            }
        });
    }, [availableSnowflakeTables.dataUpdatedAt]);



    useEffect(() => {
        if (activeFilePart) {
            setSelectedColumns(activeFilePart.headers);
            
        } else {
            setSelectedColumns([]);
        }
    }, [activeFilePart]);

    const columnOptions = useMemo(() => {
        if (activeFilePart) {
            return activeFilePart.headers.map(h => {
                return {value: h, label: h}
            });
        }
        return [];
    }, [activeFilePart]);

    const loadingAvailableTables = useMemo(() => {
        return availableSnowflakeTables.isFetching || availableSnowflakeTables.isRefetching || availableSnowflakeTables.isLoading;
    }, [availableSnowflakeTables.isFetching, availableSnowflakeTables.isRefetching, availableSnowflakeTables.isLoading])
    
    const getContent = () => {
        if (step == 'SELECT_CONNECTOR_TYPE') {
            return (
                <>
                    <p>Where does your data live?</p>
                    
                    

                    <div className="row">
                        <div className="col-6 pe-1">
                            <button className="btn btn-pliable w-100" onClick={() => chooseConnectorType('FLAT_FILE')}>Flat File</button>

                        </div>
                        <div className="col-6 ps-1">
                            <button className="btn btn-primary w-100" onClick={() => chooseConnectorType('SNOWFLAKE_TABLE')}>Snowflake Table</button>
                            
                        </div>
                    </div>

                        
                </>
            )
        } else if (step == 'UPLOAD_DATA') {
            return (
                <>

                    <p>Upload any file readable by Excel. Please ensure that the column headers are in the first row of each sheet.</p>

                    <div className="flex-1">
                        <div className="mb-2">
                            <S3Uploader
                                label="Drag and drop or %{browse} to upload" 
                                maxUploads={1} 
                                parallelUploads={1} 
                                onUploadComplete={(file: File) => fileUploadCompleted(file)}
                            />
                            {processing && <>
                                <div className="text-muted">
                                    <i className="mdi mdi-loading mdi-spin"></i> Upload complete! Processing file...
                                </div>
                            </>}
                        </div>
                        <p style={{ textAlign: 'center' }}>Max file size is 250MB</p>
                        {/* <div>
                            <p className="file-upload-info">Supported formats: XLSX, CSV, TSV, and most formats Excel supports</p>
                        </div> */}
                        {error && <Danger>{error}</Danger>}
                        
                    </div>
                </>
            );        
        } else if (step == 'LOAD_FROM_URL') {
            return (
                <>
                    <div className="flex-1">
                        <LoadingCard action="loading file info" />
                    </div>
                </>
            );        
        } else if (step == 'CONFIRM_IMPORT') {

            return (
                <>

                    <div className="flex-1">
                        <div className="mb-2">
                            {fileParts.length > 1 && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Tab</Form.Label>
                                    <Form.Select value={activeFilePart!.part_identifier} onChange={(e) => setActiveFilePart(fileParts.find(p => p.part_identifier === e.target.value))}>
                                        {fileParts.map(p => {
                                            return <option value={p.part_identifier}>{p.part_identifier}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            )}
                            
                            <h4>Sample Data</h4>
                            <InfoAlert>
                                <div>Review the data in your uploaded file to make sure it's what you expect. If you're satisfied, click on <strong>"Looks Good"</strong> to load it into Snowflake.</div>
                            </InfoAlert>
                            
                            <div className="border mb-2 mt-4">
                                <SimpleDataTable data={tableDataFromRows([activeFilePart!.headers].concat(activeFilePart!.sample_rows))}/>
                            </div>
                            
                            
                        </div>
                        

                    </div>
                    {error && <Danger>{error}</Danger>}
                    {/* {!sourceRecordTypeId && (
                        <>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="recordTypeName">Name</Form.Label>
                                <Form.Control id="recordTypeName" autoFocus placeholder="" value={recordTypeName} onChange={(e: ChangeEvent) => setRecordTypeName((e.target as HTMLInputElement).value)} type="text" size="lg"/>
                                <Form.Text>For example, "Salesforce Contacts Export" or "Bank Transactions"</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="recordTypeDescription">Description</Form.Label>
                                <Form.Control id="recordTypeDescription" placeholder="" value={recordTypeDescription} onChange={(e: ChangeEvent) => setRecordTypeDescription((e.target as HTMLInputElement).value)} as="textarea" rows={3} />
                            </Form.Group>
                        </>
                        
                    )} */}
                        
                    <footer className="text-end">
                        
                        <AsyncButton 
                            loading={importing} 
                            variant="success" 
                            onClick={() => runImport()} 
                            className="flex-grow-1" 
                            text="Looks good." 
                            disabled={addButtonIsDisabled}
                            icon="mdi mdi-check-bold"
                        />
                        <Button onClick={() => setStep('UPLOAD_DATA')} variant="outline-danger" className="flex-grow-1 ms-1">
                            <i className="mdi mdi-close-thick"></i> Upload something else instead
                        </Button>
                        {importing && (
                            <div>
                                <Form.Text>
                                    We're loading this file into Snowflake. This may take up to 30 seconds.
                                </Form.Text>
                            </div>
                        )}
                        
                        
                        
                    </footer>
                    
                </>
            );
        }
    }

    return (
        <Styles>
            {getContent()}
            
        </Styles>
    );
    
}

export default DataSourceImporter;