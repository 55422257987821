import AsyncButton from "@components/button/AsyncButton.component";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { saveUserConfig, useUserConfig } from "@stores/data.store";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";


const TERMS_UPDATED_AT = Date.UTC(2025, 0, 1, 0, 0, 0, 0);


const TOSModal = () => {
    const config = useUserConfig();

    const [requireAgreeToTerms, setRequireAgreeToTerms] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);
    useEffect(() => {
        if (!config.data) {
            return;
        }

        if (!config.data.agreed_to_terms_date || config.data.agreed_to_terms_date < TERMS_UPDATED_AT) {
            setRequireAgreeToTerms(true);
            if (!config.data.agreed_to_terms_date) {
                setIsNewUser(true);
            }
        }
    }, [config.dataUpdatedAt]);

    const [agreeing, setAgreeing] = useState(false);
    const agreeToTerms = useCallback(async () => {
        if (!config.data) {
            return;
        }

        setAgreeing(true);
        try {
            const newConfig = {
                ...config.data,
                agreed_to_terms_date: new Date().getTime()
            };
            await saveUserConfig(newConfig);
            setRequireAgreeToTerms(false);
            setIsNewUser(false);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setAgreeing(false);
        }
        


    }, [config.dataUpdatedAt]);

    return <>
        <Modal backdrop="static" show={requireAgreeToTerms} onHide={() => {}} keyboard={false}>
            <Modal.Header>
                <Modal.Title>
                    {isNewUser ? 'Welcome to Pliable!' : 'Terms of Service Update'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>Terms of Service</h3>
                {isNewUser && <p>
                    We're excited to have you on board! Please review our Terms of Service and Privacy Policy to ensure we're all on the same page.
                </p>}
                {!isNewUser && <p>
                    We've updated our Terms of Service! To continue using Pliable, please review the updated terms.
                </p>}
                
                <p>
                    <a href="https://pliable.co/terms" target="_blank">View Terms of Service</a>
                    <span className="ms-3 me-3">&bull;</span>
                    <a href="https://pliable.co/privacy-policy" target="_blank">View Privacy Policy</a>
                </p>
                
            </Modal.Body>
            <Modal.Footer>
                <AsyncButton
                    text="I Agree"
                    onClick={agreeToTerms}
                    loading={agreeing}
                    icon="mdi mdi-check-bold"
                />
            </Modal.Footer>
        </Modal>
    </>
}

export default TOSModal;