export interface SampleDataNode {
    name: string;
    description: string;
    icon: string;
    url: string;
}

export const sampleData: SampleDataNode[] = [
    {
        name: 'Salesforce Contacts',
        description: 'A csv file of contacts with names, emails, and phone numbers from a fake Salesforce account',
        icon: 'mdi mdi-account',
        url: 'https://cdn.dev-beta.pliable.co/sample_data/salesforce_contacts.csv',
    },
    {
        name: 'Salesforce Accounts',
        description: 'A csv file of accounts from a fake Salesforce account',
        icon: 'mdi mdi-domain',
        url: 'https://cdn.dev-beta.pliable.co/sample_data/salesforce_accounts.csv',
    },
    {
        name: 'ATP Tennis Matches (2024)',
        description: 'Data from all ATP tennis matches from 2024',
        icon: 'mdi mdi-tennis',
        url: 'https://raw.githubusercontent.com/JeffSackmann/tennis_atp/refs/heads/master/atp_matches_2024.csv'
    }
];
