import DataModelSubnav from "@components/datamodel/DataModelSubnav.component";
import DimensionForm from "@components/datamodel/DimensionForm.component";
import MeasureForm from "@components/datamodel/MeasureForm.component";
import { MissionControlDataFlowDiagramManager } from "@components/missionControl/dataflow/MissionControlDataFlow";
import PipelineNodeName from "@components/pipelineNodes/PipelineNodeName.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { ReportBuilderDimension, ReportBuilderDimensionORM, ReportBuilderMeasure, ReportBuilderMeasureORM, useDimensions, useMeasures } from "@models/reportBuilder";
import { recursiveGetNodesAndEdges } from "@pages/Dag.page";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { requireConfirmation } from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import { getGroupValueForNodeType } from "@services/modeling.service";
import toast from "@services/toast.service";
import { useQueryParams } from "@services/url.service";
import { useMissionControlDataFlowData } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DataModelSemanticLayer = () => {
    
    const dimensions = useDimensions();
    const measures = useMeasures();

    const [activeDimensionId, setActiveDimensionId] = useState('');
    const [activeMeasureId, setActiveMeasureId] = useState('');

    const activeDimension = useMemo(() => {
        return dimensions.data?.find(d => d.id === activeDimensionId);
    }, [dimensions.data, activeDimensionId]);

    const activeMeasure = useMemo(() => {
        return measures.data?.find(m => m.id === activeMeasureId);
    }, [measures.data, activeMeasureId]);

    const onSaveDimension = useCallback(async (newDim: ReportBuilderDimension) => {
        setActiveDimensionId('');
        try {
            await ReportBuilderDimensionORM.save(newDim);
            dimensions.refetch();
            toast('success', 'Success', 'Dimension saved');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
        
    }, []);

    const onSaveMeasure = useCallback(async (newMeasure: ReportBuilderMeasure) => {
        setActiveMeasureId('');
        try {
            await ReportBuilderMeasureORM.save(newMeasure);
            measures.refetch();
            toast('success', 'Success', 'Measure saved');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, []);

    const onDeleteDimension = useCallback(async (dimId: string) => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this dimension? It may be used in your reports.');
        if (!confirmed) {
            return;
        }
        try {
            await ReportBuilderDimensionORM.deleteById(dimId);
            dimensions.refetch();
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, []);

    const onDeleteMeasure = useCallback(async (measureId: string) => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this measure? It may be used in your reports.');
        if (!confirmed) {
            return;
        }
        try {
            await ReportBuilderMeasureORM.deleteById(measureId);
            measures.refetch();
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, []);

    const addNewDimension = useCallback(async () => {
        const newDim = await ReportBuilderDimensionORM.save({
            id: null,
            pipeline_node_id: '',
            field_id: '',
            name: 'New Dimension',
            description: '',
            column_name: '',
        });
        await dimensions.refetch();
        setActiveDimensionId(newDim.id as string);
    }, []);

    const addNewMeasure = useCallback(async () => {
        const newMeasure = await ReportBuilderMeasureORM.save({
            id: null,
            pipeline_node_id: '',
            field_id: '',
            name: 'New Measure',
            description: '',
            column_name: '',
            dimension_join_trees: [],
        });
        measures.refetch();
        setActiveMeasureId(newMeasure.id as string);
    }, []);

    const cloneMeasure = useCallback(async (measure: ReportBuilderMeasure) => {
        const newMeasure = await ReportBuilderMeasureORM.save({
            ...measure,
            id: null,
            name: `${measure.name} (Copy)`,
        });
        measures.refetch();
        setActiveMeasureId(newMeasure.id as string);
    }, []);

    return <PageStructure>
        <Offcanvas show={activeDimensionId || activeMeasureId} onHide={() => {
            setActiveDimensionId('');
            setActiveMeasureId('');
        }} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Details</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <div className="p-3">
                            {activeDimension && <DimensionForm dimension={activeDimension} onSubmit={onSaveDimension} onCancel={() => {
                                setActiveDimensionId('');
                            }}/>}
                            {activeMeasure && <MeasureForm measure={activeMeasure} onSubmit={onSaveMeasure} onCancel={() => {
                                setActiveMeasureId('');
                            }}/>}
                        </div>
                    </PaneContent>
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
        <PageContent hasSidebar>
            <PageContentInner hasHeader>
                <div className="d-flex mb-2 center-vertically">
                    <h2 className="mb-0 flex-1">
                        Dimensions
                    </h2>
                    <DraftOnly>
                        <button className="btn btn-outline-primary" onClick={addNewDimension}>Add New</button>
                    </DraftOnly>
                </div>
                <table className="table table-action table-centered">
                    <thead className="bg-light">
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Source</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dimensions.data?.map((dimension) => {
                            return <tr className="hover-control" onClick={() => {
                                setActiveDimensionId(dimension.id as string);
                            }}>
                                <td><strong>{dimension.name}</strong></td>
                                <td>{dimension.description}</td>
                                <td><PipelineNodeName pipelineNodeId={dimension.pipeline_node_id}/></td>
                                <td className="text-end">
                                    <DraftOnly>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            onDeleteDimension(dimension.id as string)
                                        }} className="btn btn-sm btn-outline-danger hover-only">Delete
                                        </button>
                                    </DraftOnly>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <hr />
                <div className="d-flex mb-2 center-vertically">
                    <h2 className="mb-0 flex-1">
                        Measures
                    </h2>
                    <DraftOnly>
                        <button className="btn btn-outline-primary" onClick={addNewMeasure}>Add New</button>
                    </DraftOnly>
                </div>
                <table className="table table-action table-centered">
                    <thead className="bg-light">
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Source</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {measures.data?.map((measure) => {
                            return <tr className="hover-control" onClick={() => {
                                setActiveMeasureId(measure.id as string);
                            }}>
                                <td><strong>{measure.name}</strong></td>
                                <td>{measure.description}</td>
                                <td><PipelineNodeName pipelineNodeId={measure.pipeline_node_id}/></td>
                                
                                <td className="text-end">
                                    <DraftOnly>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            cloneMeasure(measure);
                                        }} className="btn btn-sm btn-outline-secondary hover-only me-1">
                                            Clone
                                        </button>
                                    </DraftOnly>
                                    <DraftOnly>
                                        <button onClick={(e) => {
                                            e.stopPropagation();
                                            onDeleteMeasure(measure.id as string)
                                        }} className="btn btn-sm btn-outline-danger hover-only">Delete
                                        </button>
                                    </DraftOnly>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            
            </PageContentInner>
        </PageContent>
        <PageSidebar right>
            <div className="p-2">
                <h2>Semantic Layer</h2>
                <p>Your Semantic Layer allows you to define measures (stuff you're trying to track, like "Total Revenue" or "Active Users"), and dimensions (ways to break down those measures, like per campaign, or by day).</p>
            </div>
        </PageSidebar>
    </PageStructure>
}

export default DataModelSemanticLayer;