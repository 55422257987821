import Dropdown, { Option } from "@components/form/Dropdown.component";
import { BaseModel } from "@models/orm";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageContentNoSidebar, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import { shortid } from "@services/id.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Form, Offcanvas } from "react-bootstrap";
import GridLayout, { Layout } from "react-grid-layout";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styled from 'styled-components';
import { useImmer } from "use-immer";
import Markdown from 'react-markdown'
import { requireConfirmation } from "@services/alert/alert.service";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";
import VisualizationSelector from "@components/pipelineNodes/VisualizationSelector.component";
import VisualizationChart from "@components/pipelineNodes/VisualizationChart.component";
import { DashboardComponent, Dashboard, DashboardFilterOption, DashboardObjectType, useDashboard, DashboardORM } from "@models/dashboard";
import DashboardFilterOptionForm, { FilterForm, FiltersByNodeId } from "./DashboardFilterOptionForm.component";
import { useIsInDraftMode, usePipelineNodes } from "@stores/data.store";
import { useVisualizations } from "@models/visualization";
import { FilterConfig, PipelineNode } from "@models/pipelineNode";
import { RecordInfoStyles } from "@components/pipelineNodes/PipelineNodeInfo.component";
import RecordTitleAndDescription from "@components/card/RecordTitleAndDescription.component";
import { useNavigate, useParams } from "react-router-dom";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import SaveButton from "@components/button/SaveButton.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import PipelineNodeColumnSelector, { PipelineNodeMultiColumnSelector } from "@components/pipelineNodes/PipelineNodeColumnSelector.component";




interface ComponentFormProps {
    component: DashboardComponent;
    onSave: (c: DashboardComponent) => any;
    onDelete: () => any;
    filterColumnOptions: Option[];
}

const ComponentForm = (props: ComponentFormProps) => {
    const addNewFilterOption = useCallback(() => {

    }, []);
    const [editingComponent, setEditingComponent] = useImmer<DashboardComponent>({
        id: '',
        title: '',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        object_id: '',
    });

    useEffect(() => {
        setEditingComponent({...props.component})
    }, [props.component]);


    


    return <>
        <Form.Group className="mb-2">
            <Form.Label>Title</Form.Label>
            <Form.Control onChange={(e) => {
                setEditingComponent(draft => {
                    draft.title = e.target.value as string;
                })
            }} value={editingComponent.title}/>
        </Form.Group>
        <Form.Group className="mb-2">
            <Form.Label>Component Type</Form.Label>
            <Dropdown
                options={[{
                    value: 'NODE',
                    label: 'Node',
                    description: "Show data from a specific node",
                }, {
                    value: 'VISUALIZATION',
                    label: 'Visualization',
                    description: 'Show a specific visualization',
                }, {
                    value: 'MARKDOWN',
                    label: 'Markdown',
                    description: 'Write arbitrary markdown content',
                }, {
                    value: 'FILTER',
                    label: 'Filter',
                    description: 'Show a filter to limit the data shown in all components in the dashboard'
                }]}
                selected={editingComponent.object_type}
                onChange={(newVal: string) => {
                    setEditingComponent(draft => {
                        draft.object_type = newVal as DashboardObjectType;
                    })
                }}
            />
        </Form.Group>
        {editingComponent.object_type === 'MARKDOWN' && <>
            <Form.Group className="mb-2">
                <Form.Label>Markdown Content</Form.Label>
                <Form.Control 
                    as="textarea" 
                    onChange={(e) => {
                        setEditingComponent(draft => {
                            draft.markdown_content = e.target.value as string;
                        })
                    }} 
                    value={editingComponent.markdown_content}
                />
            </Form.Group>
        </>}
        {editingComponent.object_type === 'NODE' && <>
            <Form.Group className="mb-2">
                <Form.Label>Select Node</Form.Label>
                <PipelineNodeSelector
                    selectedId={editingComponent.object_id}
                    onSelect={(pn) => {
                        setEditingComponent(draft => {
                            if (pn) {
                                draft.object_id = pn.id as string;
                            } else {
                                draft.object_id = '';
                            }
                        })
                    }}
                />
               
            </Form.Group>
            {!!editingComponent.object_id && <>
                <Form.Group className="mb-2">
                    <Form.Label className="small">Select column(s) to show</Form.Label>
                    <PipelineNodeMultiColumnSelector
                        pipelineNodeId={editingComponent.object_id}
                        selectedIds={editingComponent.column_whitelist || []}
                        onSelect={(columnIds) => {
                            setEditingComponent(draft => {
                                draft.column_whitelist = columnIds || [];
                            })
                        }}
                    />
                </Form.Group>
                    

            </>}
            
        </>}
        {editingComponent.object_type === 'VISUALIZATION' && <>
            <Form.Group className="mb-2">
                <Form.Label>Select Visualization</Form.Label>
                <VisualizationSelector
                    selectedId={editingComponent.object_id}
                    onSelect={(pn) => {
                        setEditingComponent(draft => {
                            if (pn) {
                                draft.object_id = pn.id as string;
                            } else {
                                draft.object_id = '';
                            }
                        })
                    }}
                />
            </Form.Group>
            
        </>}
        {editingComponent.object_type === 'FILTER' && <>
            <DashboardFilterOptionForm
                option={editingComponent.filter_config || {
                    column_references: [],
                    filter_type: 'FREE_TEXT',
                    comparator: '=',
                }}
                onChange={(newData) => {
                    setEditingComponent(draft => {
                        draft.filter_config = newData;
                    })
                }}
                columnOptions={props.filterColumnOptions}
            />
        </>}
        <hr />
        <div>
            <button className="btn btn-success me-1" onClick={() => {
                props.onSave(editingComponent)
            }}>Save</button>
            <button className="btn btn-outline-danger" onClick={() => {
                props.onDelete()
            }}>Delete</button>
        </div>
        
    </>
}

const GridItemContainerStyles = styled.div`
width: 100%;
height: 100%;
background: white;
border: solid 1px var(--ct-border-color);
border-radius: 5px;
position: relative;

div.buttons {
    visibility: hidden;
    position: relative;
    z-index: 1;
}

.drag {
    cursor: move;
}

&:hover {

    div.buttons {
        visibility: visible;
    }
}

button.drag {
    position: absolute;

}

.component-content, .header {
    padding: .5rem;
}

.component-content {
    height: calc(100% - 41px);
}

.header {
    background: var(--ct-light);
}


`;



interface GridItemContainerProps {
    component: DashboardComponent;
    onEdit: () => any;
    onDelete: () => any;
    filters?: FilterConfig;
    isReady: boolean;
    onChangeFilters?: (newData: FiltersByNodeId) => any;
}

const GridItemContainer = (props: GridItemContainerProps) => {
    const initEdit = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
        props.onEdit();
    }, [props.onEdit]);

    const runDelete = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
        props.onDelete();
    }, [props.onDelete]);

    const renderComponent = () => {
        if(props.component.object_type == 'MARKDOWN') {
            return <Markdown>{props.component.markdown_content}</Markdown>
        }else if(props.component.object_type == 'FILTER') {
            return <FilterForm 
                filter_option={props.component.filter_config!}
                onChange={(newFilters) => {
                    props.onChangeFilters && props.onChangeFilters(newFilters);
                }} 
                />
        }else if(!props.isReady){
            return '';
        }else if(props.component.object_type == 'NODE') {
            return <PipelineNodeDataTable
                pipelineNodeId={props.component.object_id}
                compact
                filters={props.filters}
                columnWhitelist={props.component.column_whitelist}
                excludeRelationships
            />
        }else if(props.component.object_type == 'VISUALIZATION') {
            return <VisualizationChart
                visualizationId={props.component.object_id}
                compact
                filter={props.filters}
                vizHeight={`${30*props.component.height}px`}
            />
        }

    };

    return <GridItemContainerStyles>
        <div className="d-flex center-vertically header drag">
            
            <h4 className="mb-0 flex-1">
                {props.component.title}
            </h4>
            <div className="buttons nodrag">
                
                <button className="icon-button" onClick={initEdit}>
                    <i className="mdi mdi-cog"></i>
                </button>
                <button className="icon-button" onClick={runDelete}>
                    <i className="mdi mdi-close-thick"></i>
                </button>
            </div>
        </div>
        <div className="component-content nodrag">
            {renderComponent()}
        </div>
    </GridItemContainerStyles>
}

const DashboardEditorPage = () => {
    const { dashboardId } = useParams();
    const inDraftMode = useIsInDraftMode();
    // isReady to Load Data/Visualizations (aka all filters have been set)
    const [isReady, setIsReady] = useState(false);

    const ogDashboard = useDashboard(dashboardId as string);

    const [dashboard, setDashboard] = useImmer<Dashboard>({
        id: null,
        components: [],
        name: '',
        description: '',
    });

    useEffect(() => {
        if (ogDashboard.data) {
            setDashboard(ogDashboard.data);
        }
    }, [ogDashboard.dataUpdatedAt]);
    

    const addItem = useCallback(() => {
        setDashboard(draft => {
            draft.components.push({
                id: shortid(),
                x: 0,
                y: 0,
                width: 4,
                height: 4,
                title: 'New Component',
                object_id: '',
            })
        })
    }, []);

    const [filtersByComponentId, setFiltersByComponentId] = useImmer<{
        [componentId: string]: FilterConfig
    }>({});

    const [activeComponent, setActiveComponent] = useState<DashboardComponent|undefined>(undefined);

    const [showDrawer, setShowDrawer] = useState(false);

    const editComponent = useCallback((c: DashboardComponent) => {
        setActiveComponent(c);
        setShowDrawer(true);
    }, []);

    const saveActiveComponent = useCallback((newData: DashboardComponent) => {
        if (!activeComponent) {
            return;
        }
        setDashboard(draft => {
            const idx = draft.components.findIndex(c => c.id === activeComponent.id);
            if (idx >= 0) {
                draft.components[idx] = newData;
            }
        });
        setActiveComponent(undefined);
        setShowDrawer(false);
    }, [activeComponent]);

    const deleteComponent = useCallback(async (componentId: string) => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this component?', 'Delete Component', 'Delete');
        if (!confirmed) {
            return;
        }
        setDashboard(draft => {
            const idx = draft.components.findIndex(c => c.id === componentId);
            if (idx >= 0) {
                draft.components.splice(idx, 1);
                setShowDrawer(false);
                setActiveComponent(undefined);
            }
        })
    }, []);

    const handleLayoutChange = useCallback((layout: Layout[]) => {
        setDashboard(draft => {
            if (layout.length != draft.components.length) {
                throw new Error('Unexpected layout change')
            }

            draft.components.forEach((component, idx) => {
                component.height = layout[idx].h;
                component.width = layout[idx].w;
                component.x = layout[idx].x;
                component.y = layout[idx].y;
            })
        });
    }, []);

    const pipelineNodes = usePipelineNodes();
    const visualizations = useVisualizations();
    const filterColumnOptions = useMemo(() => {
        if (!pipelineNodes.data || !visualizations.data) {
            return [];
        }
        const pipelineNodeIdsToInclude = new Set<string>([]);

        const visualizationPipelineNodes: {
            [id: string]: string;
        } = {};
        visualizations.data.forEach(v => {
            visualizationPipelineNodes[v.id as string] = v.pipeline_node_id;
        })

        dashboard.components.forEach(c => {
            switch (c.object_type) {
                case 'NODE':
                    pipelineNodeIdsToInclude.add(c.object_id);
                    break;
                case 'VISUALIZATION':
                    const pnId = visualizationPipelineNodes[c.object_id];
                    if (pnId) {
                        pipelineNodeIdsToInclude.add(pnId);
                    }
            }
        });

        const options: Option[] = [];
        pipelineNodes.data.forEach(pn => {
            if (pipelineNodeIdsToInclude.has(pn.id as string)) {
                pn.fields.forEach(f => {
                    options.push({
                        label: `${pn.name}.${f.name}`,
                        value: `${pn.id}.${f.id}`,
                        description: f.description,
                    })
                })
            }
        });

        return options;
    }, [pipelineNodes.dataUpdatedAt, visualizations.dataUpdatedAt, dashboard.components.map(c => `${c.object_type}:${c.object_id}`)]);

    const [filtersByFilterComponentId, setFiltersByFilterComponentId] = useImmer<{
        [key: string]: FiltersByNodeId
    }>({});

    const [dirtyFilters, setDirtyFilters] = useState(false);

    useEffect(() => {
        if (!visualizations.data) {
            return;
        }
        if (dirtyFilters) {
            const tmpFiltersByNodeId: FiltersByNodeId = {}
            Object.keys(filtersByFilterComponentId).forEach(k => {
                Object.keys(filtersByFilterComponentId[k]).forEach(k1 => {
                    if (!(k1 in tmpFiltersByNodeId)) {
                        tmpFiltersByNodeId[k1] = [];
                    }
                    filtersByFilterComponentId[k][k1].forEach(f => {
                        tmpFiltersByNodeId[k1].push(f);
                    })
                })
            });

            // Now loop through each component so we can grab the filters
            // for that node even if it's a visualization
            const visualizationPipelineNodes: {
                [id: string]: string;
            } = {};
            visualizations.data.forEach(v => {
                visualizationPipelineNodes[v.id as string] = v.pipeline_node_id;
            });

            setFiltersByComponentId(draft => {
                dashboard.components.forEach(c => {
                    if (c.object_type == 'NODE') {
                        if (c.object_id in tmpFiltersByNodeId) {
                            draft[c.id] = {
                                filters: tmpFiltersByNodeId[c.object_id],
                                logic_gate: 'AND',
                            };
                        }
                    } else if (c.object_type == 'VISUALIZATION') {
                        const pnId = visualizationPipelineNodes[c.object_id];
                        if (pnId in tmpFiltersByNodeId) {
                            draft[c.id] = {
                                filters: tmpFiltersByNodeId[pnId],
                                logic_gate: 'AND',
                            };
                        }
                    }
                });
            })

            

            setDirtyFilters(false);
        }
    }, [dirtyFilters]);

    useEffect(() => {
        if (!dashboard.components || dashboard.components.length === 0) {
            return;
        }
        const filtersOnly = dashboard.components.filter(c => c.object_type === 'FILTER');
        if(filtersOnly.length > 0 && Object.keys(filtersByComponentId).length === 0) {
            // if we have filters and they havent been set, lets wait before we load data/visualizations
            setIsReady(false);
        }else{
            setIsReady(true);
        }
    }, [dashboard.components, filtersByComponentId]);

    const handleFilterChange = useCallback((componentId: string, newFilters: FiltersByNodeId) => {
        setFiltersByFilterComponentId(draft => {
            draft[componentId] = newFilters;
        });
        setDirtyFilters(true);
    }, []);

    const changeName = useCallback(async (newName: string) => {
        setDashboard(draft => {
            draft.name = newName;
        });
        await DashboardORM.patch(dashboardId as string, {
            name: newName,
        });

        
    }, [dashboardId]);

    const changeDescription = useCallback(async (newDescription: string) => {
        console.log('Changing description to:', newDescription);
        setDashboard(draft => {
            draft.description = newDescription;
        });
        await DashboardORM.patch(dashboardId as string, {
            description: newDescription,
        });
    }, [dashboardId]);

    const [saving, setSaving] = useState(false);
    const save = useCallback(async () => {
        setSaving(true);
        try {
            await DashboardORM.save(dashboard);
            ogDashboard.refetch();
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [dashboard, dashboardId]);

    const navigate = useNavigate();

    const deleteDashboard = useCallback(async () => {
        const confirm = await requireConfirmation(`Are you sure you want to delete this dashboard? Please type the name of the dashboard ("${dashboard.name}") to continue`, 'Confirm Deletion', 'Delete', 'Cancel', dashboard.name);
        if (confirm) {
            await DashboardORM.deleteById(dashboard.id as string);
            navigate('/');
            toast('success', 'Success', 'Dashboard deleted');
        }
    }, [dashboard.name]);



    return <PageStructure
        pageTitle="Edit Dashboard"
    >
        <Offcanvas placement="end" show={showDrawer} onHide={() => setShowDrawer(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Dashboard Component</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <div className="p-2">
                            {!!activeComponent &&
                                <ComponentForm 
                                    filterColumnOptions={filterColumnOptions}
                                    component={activeComponent}
                                    onSave={saveActiveComponent}
                                    onDelete={() => {
                                        deleteComponent(activeComponent.id)
                                    }}
                                />
                            }
                        </div>
                    </PaneContent>
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
        
        {/* <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <RecordTitleAndDescription
                            title={dashboard.name}
                            description={dashboard.description}
                            onChangeTitle={changeName}
                            onChangeDescription={changeDescription}
                        >
                            <Badge pill bg="primary">
                                <i className="mdi mdi-view-dashboard"></i> Dashboard
                            </Badge>
                        </RecordTitleAndDescription>                        
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar> */}
        <PageContent>
            <PageContentHeader>
                <div className="d-flex center-vertically">
                    <div className="flex-1">
                        <h1>{dashboard.name}</h1>
                        <p className="mb-0">{dashboard.description}</p>
                    </div>
                    <div>
                        <button className="btn btn-outline-danger btn-lg me-1" onClick={deleteDashboard}>Delete</button>
                        <SaveButton className="btn-lg me-1" onClick={save}/>
                        <button className="btn btn-primary btn-lg" onClick={addItem}>
                            Add Component
                        </button>
                    </div>
                </div>
            </PageContentHeader>
            <PageContentInner hasHeader>
                <div className="">
                    <GridLayout 
                        className="layout" 
                        cols={12} 
                        rowHeight={30} 
                        width={1500} 
                        draggableCancel=".nodrag" 
                        draggableHandle=".drag" 
                        onLayoutChange={handleLayoutChange}
                        isDraggable={inDraftMode}
                        isResizable={inDraftMode}
                    >
                        {dashboard.components.map(gd => {
                            return <div key={gd.id} data-grid={{
                                x: gd.x,
                                y: gd.y,
                                w: gd.width,
                                h: gd.height,
                                minW: 2,
                                minH: 2,
                            }} style={{zIndex: (gd.object_type == 'FILTER') ? '1': '0'}}>
                                <GridItemContainer 
                                    isReady={isReady}
                                    component={gd}
                                    onEdit={() => {
                                        editComponent(gd)
                                    }}
                                    onChangeFilters={(newFilters) => {
                                        handleFilterChange(gd.id, newFilters);
                                    }}
                                    filters={filtersByComponentId[gd.id]}
                                    onDelete={() => {
                                        deleteComponent(gd.id);
                                    }}
                                />
                            </div>
                        })}
                        
                    </GridLayout>
                </div>
        
            </PageContentInner>
            
        </PageContent>
    </PageStructure>
}

export default DashboardEditorPage;