import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import VisualizationChart from "@components/pipelineNodes/VisualizationChart.component";
import PipelineNodeFieldMappingDataSourceList, { DataSourceDraggableColumnPicker } from "@components/pipelineNodes/mapping/PipelineNodeFieldMappingDataSourceList.component";
import { FilterConfig, PipelineNode, PipelineNodeField } from "@models/pipelineNode";
import { ChartType, Visualization, deleteVisualization, saveVisualization, useChartData, useVisualization, Measure as VisualizationMeasure } from "@models/visualization";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import { useIsInDraftMode, usePipelineNode } from "@stores/data.store";
import { Allotment } from "allotment";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useImmer } from "use-immer";
import styled from 'styled-components';
import { Form, Offcanvas } from "react-bootstrap";
import Dropdown from "@components/form/Dropdown.component";
import SaveButton from "@components/button/SaveButton.component";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { requireConfirmation } from "@services/alert/alert.service";
import AsyncButton from "@components/button/AsyncButton.component";
import { DraftModeRequired, DraftOnly } from "@components/project/DraftModeRequired.component";
import FilterConfigForm, { FilterFormDropdown } from "@pages/SourceRecordType/FilterConfigForm.component";
import PipelineNodeColumnSelector, { PipelineNodeMultiColumnSelector } from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import { getAggregationOptionsForFieldType } from "@services/metrics.service";
import SourceIcon from "@components/sources/SourceIcon.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";


interface MeasureProps {
    measure: VisualizationMeasure;
    pipelineNodeId: string;
    onChange: (newMeasure: VisualizationMeasure) => void;
}

const MeasureComponent = (props: MeasureProps) => {
    const pn = usePipelineNode(props.pipelineNodeId);
    const aggOptions = useMemo(() => {
        if (!pn.data) {
            return [];
        }

        if (props.measure.field_id == '_PLB_UUID') {
            return getAggregationOptionsForFieldType('ID', true);
        }

        const theField = pn.data.fields.find(f => f.id == props.measure.field_id);
        if (!theField) {
            return [];
        }
        return getAggregationOptionsForFieldType(theField.type, true);
    }, [pn.dataUpdatedAt, props.measure.field_id]);
    return <div>
        <Form.Group className="mb-2">
            <Form.Label className="small">Select Column</Form.Label>
            <PipelineNodeColumnSelector
                pipelineNodeId={props.pipelineNodeId}
                selectedId={props.measure.field_id}
                includeRecordId
                onSelect={(fieldId) => {
                    props.onChange({
                        ...props.measure,
                        field_id: fieldId,
                    });
                }}
            />
        </Form.Group>
        <Form.Group>
            <Form.Label className="small">Aggregator</Form.Label>
            <Dropdown
                options={aggOptions}
                selected={props.measure.aggregate_by}
                onChange={(newVal) => {
                    props.onChange({
                        ...props.measure,
                        aggregate_by: newVal as string,
                    });
                }}
            />
        </Form.Group>
    </div>
}

const RemoveMeasureButton = styled.button`
position: absolute;
top: 5px;
right: 5px;
`

const CHART_TYPES = [
    {
        label: 'Bar',
        value: 'bar',
    }, {
        label: 'Line',
        value: 'line',
    }, {
    //     label: 'Pie',
    //     value: 'pie',
    // }, {
        label: 'Area',
        value: 'area',
    }, {
        label: 'Single Metric',
        value: 'single_metric',
    }
]

const VisualizationDetailsPage = () => {
    const inDraftMode = useIsInDraftMode();
    const { visualizationId } = useParams();

    const [viz, setViz] = useImmer<Visualization>({
        id: null,
        name: '',
        description: '',
        pipeline_node_id: '',
        dimension_field_id: '',
        dimension2_field_id: '',
        measures: [],
        chart_type: undefined,
    });


    const actualVizId = useMemo(() => {
        if (visualizationId == 'new') {
            return '';
        }
        return visualizationId as string;
    }, [visualizationId]);

    const theViz = useVisualization(actualVizId);

    useEffect(() => {
        if (theViz.data) {
            setViz(theViz.data);
        }
    }, [theViz.dataUpdatedAt]);

    const handleDrop = useCallback((sourceColumn: PipelineNodeField, targetId: string) => {
        setViz(draft => {
            if (targetId === 'dim1') {
                draft.dimension_field_id = sourceColumn.id;
            } else if (targetId === 'dim2') {
                draft.dimension2_field_id = sourceColumn.id;
            } else if (targetId === 'measures') {
                draft.measures.push({
                    field_id: sourceColumn.id,
                    aggregate_by: 'COUNT_DISTINCT',
                })
            }
        });
        
    }, []);

    const [deleting, setDeleting] = useState(false);
    const deleteViz = useCallback(async () => {
        const confirm = await requireConfirmation('Are you sure you want to delete this chart?');
        if (confirm) {
            await deleteVisualization(visualizationId as string);
            navigate(`/viz`);
        }
    }, [visualizationId]);

    const navigate = useNavigate();
    const saveViz = useCallback(async () => {
        try {
            const result = await saveVisualization(viz);
            if (visualizationId == 'new') {
                navigate(`/viz/chart/${result.id}`);
            }

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, [viz, visualizationId]);

    useEffect(() => {
        if (viz.chart_type == 'pie') {
            setViz(draft => {
                draft.dimension2_field_id = undefined;
            })
        }
    }, [viz.chart_type]);


    const [filters, setFilters] = useState<FilterConfig>({
        filters: [],
        logic_gate: 'AND',
    });

    const [filtersForViz, setFiltersForViz] = useState<FilterConfig>({
        filters: [],
        logic_gate: 'AND',
    });

    const applyFilters = useCallback(() => {
        setFiltersForViz(filters);
    }, [filters]);


    return <PageStructure>
        <PageContent>
            <PageContentHeader>
                <div>
                    <DashboardIcon bgColor="success" icon="mdi mdi-chart-bar"/>
                    <div className="flex-1 ms-2">
                        <h1 className="mb-0"><span className="fw-light">Visualizations</span> &rarr; Charts</h1>
                        <div className="text-muted font-13">Configure how your chart is generated.</div>

                    </div>
                    <SaveButton
                        onClick={saveViz}
                        text="Save & Update"
                        className="me-1"
                    />
                    {visualizationId !== 'new' && <>
                        <AsyncButton
                            variant="danger"
                            icon="mdi mdi-delete"
                            className="me-1"
                            text="Delete"
                            onClick={deleteViz}
                            loading={deleting}
                        />
                    </>}
                </div>
            </PageContentHeader>
            <PageContentInner hasHeader>
            <div className="p-4">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-3 border-right">
                                <h2>Configure Chart</h2>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Source</Form.Label>
                                    <PipelineNodeSelector
                                        optionFilter={pn => ['DIMENSION', 'REPORT'].includes(pn.node_type)}
                                        selectedId={viz.pipeline_node_id}
                                        onSelect={(node) => {
                                            setViz(draft => {
                                                draft.pipeline_node_id = node?.id || '';
                                            })
                                        }}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>
                                        Visualization Name
                                    </Form.Label>
                                    <Form.Control disabled={!inDraftMode} type="text" value={viz.name} onChange={(e) => {
                                        setViz(draft => {
                                            draft.name = e.target.value;
                                        })
                                    }}/>
                                </Form.Group>
                                <Form.Group className="mb-2">
                                    <Form.Label>
                                        Visualization Type
                                    </Form.Label>
                                    <Dropdown
                                        disabled={!inDraftMode}
                                        options={CHART_TYPES}
                                        selected={viz.chart_type}
                                        onChange={(newVal) => {
                                            setViz(draft => {
                                                draft.chart_type = newVal as ChartType;
                                            });
                                        }}
                                    />
                                    
                                </Form.Group>
                                {['bar', 'area'].includes(viz.chart_type as string) && <>
                                    <Form.Group className="mb-2">
                                        <Form.Check
                                            disabled={!inDraftMode}
                                            type="switch"
                                            checked={!!viz.stacked}
                                            onChange={(e) => setViz(draft => {
                                                draft.stacked = !draft.stacked;
                                            })}
                                            label="Stack"
                                        />
                                    </Form.Group>
                                </>}
                                {!!viz.chart_type && !!viz.pipeline_node_id && <>
                                    <hr />
                                    <Form.Group className="mb-3">
                                        <Form.Label>X-Axis</Form.Label>
                                        <PipelineNodeColumnSelector
                                            pipelineNodeId={viz.pipeline_node_id as string}
                                            selectedId={viz.dimension_field_id}
                                            onSelect={(fieldId) => {
                                                setViz(draft => {
                                                    draft.dimension_field_id = fieldId;
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                    <div className="mb-3">
                                        <div className="d-flex center-vertically mb-1">
                                            <div className="flex-1">
                                                <Form.Label className="m-0">Y-Axis</Form.Label>

                                            </div>
                                            <button onClick={() => {
                                                setViz(draft => {
                                                    draft.measures.push({
                                                        field_id: '',
                                                        aggregate_by: 'COUNT',
                                                    })
                                                })
                                            }} className="icon-button">
                                                <i className="mdi mdi-plus-thick"></i> Add
                                            </button>
                                        </div>
                                        
                                        <div className="list-group">
                                            {viz.measures.map((m, idx) => {
                                                return <div className="list-group-item">
                                                    <RemoveMeasureButton className="icon-button" onClick={() => {
                                                        setViz(draft => {
                                                            draft.measures.splice(idx, 1);
                                                        })
                                                    }}>
                                                        <i className="mdi mdi-close-thick"></i>
                                                    </RemoveMeasureButton>
                                                    <MeasureComponent
                                                        key={idx}
                                                        pipelineNodeId={viz.pipeline_node_id as string}
                                                        measure={m}
                                                        onChange={(newMeasure) => {
                                                            setViz(draft => {
                                                                draft.measures[idx] = newMeasure;
                                                            })
                                                        }}
                                                    />
                                                </div>
                                            })}
                                        </div>
                                        
                                    </div>
                                </>}
                                

                            </div>
                            <div className="col-9">
                                {viz.dimension_field_id && viz.measures.length > 0 && !!actualVizId && <>
                                    <VisualizationChart
                                        visualizationId={actualVizId}
                                        filter={filtersForViz}
                                    />
                                </>}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </PageContentInner>
        </PageContent>

    </PageStructure>
    

}

export default VisualizationDetailsPage;