import LoadingCard from "@components/card/LoadingCard.component";
import { PipelineNode } from "@models/pipelineNode";
import { summarizeNumber } from "@services/formatting.service";
import { timeAgo } from "@services/time.service";
import { usePipelineNodes } from "@stores/data.store";
import { ReactNode, useMemo } from "react";
import { Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import PipelineNodeIcon, { DashboardIcon } from "./PipelineNodeIcon.component";
import { ReportBuilderMeasure } from "@models/reportBuilder";

export const LibraryStyles = styled.div`
    background-color: white;

    h2 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: .7em;
    }


    
`

export const LibraryItemStyles = styled.div<{rounded?: boolean, active?: boolean}>`
    cursor: pointer;
    
    a {
        color: #0F0E31;
    }

    a:hover{
        text-decoration: none;
    }

    h3 {
        color: #797979;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0px;
    }

    h4 {
        margin-bottom: 0px;
        color: black;
        font-weight: 600;
    }

    &.new-item {
        border: 1px dashed #D9D9D9
    }
        
    

    &:hover {
        background-color: #f4f4f4;
    }

${props => props.rounded && css`
    border-radius: 4px;    
`}

${props => props.active && css`
    background-color: var(--ct-primary);
    h3, h4, .text-muted {
        color: white !important;
    } 

    &:hover {
        background-color: var(--ct-primary);
    }

    div.fake-img {
        background-color: white !important;;
        color: var(--ct-primary) !important;
    }
`}
`

interface SingleMeasureItemProps {
    measure: ReportBuilderMeasure;
    onClick?: () => void;
    compact?: boolean;
    rounded?: boolean;
}
export const SingleMeasureItem = (props: SingleMeasureItemProps) => {
    return <LibraryItem rounded={props.rounded} compact={props.compact} itemType="measure" name={props.measure.name}  onClick={() => {
        if (props.onClick) {
            props.onClick();
        }
    }}>
        <DashboardIcon icon="mdi mdi-pound-box" bgColor="success" compact={props.compact}/>
    </LibraryItem>
}

interface SingleNodeItemProps {
    node: PipelineNode;
    onClick?: () => void;
    compact?: boolean;
    rounded?: boolean;
    active?: boolean;
}



export const SingleNodeItem = (props: SingleNodeItemProps) => {
    return <LibraryItem active={props.active} rounded={props.rounded} compact={props.compact} itemType={props.node.node_type} name={props.node.label} totalRecords={props.node.total_records} lastBuild={props.node.last_build_completed} onClick={() => {
        if (props.onClick) {
            props.onClick();
        }
    }}>
        <PipelineNodeIcon node={props.node} compact={props.compact}/>
    </LibraryItem>
}


export interface LibraryItemProps {
    itemType: string;
    children?: ReactNode;
    totalRecords?: number;
    lastBuild?: Date;
    name: string;
    onClick: () => void;
    compact?: boolean;
    rounded?: boolean;
    active?: boolean;
}

export const LibraryItem = (props: LibraryItemProps) => {
    return (
        <LibraryItemStyles rounded={props.rounded}  active={props.active} className="list-group-item library-item d-flex" onClick={props.onClick}>
            <div className="d-flex flex-row align-items-center">
                {props.children}
                
            </div> 
            <div className="d-flex flex-column px-2 justify-content-center">
                {/* <h3>{props.itemType}</h3> */}
                {props.compact && <>
                    <h4 className="mb-0 font-13">{props.name}</h4>
                    {props.totalRecords !== undefined && <span className="text-muted font-13">
                        <i className="mdi mdi-file-multiple"></i> {summarizeNumber(props.totalRecords || 0)} records
                    </span>}
                </>}
                {!props.compact && <>
                    <h4>{props.name}</h4>
                    <div className="text-muted font-13">
                        {props.totalRecords !== undefined && <span className="me-3">
                        
                                <i className="mdi mdi-file-multiple"></i> {summarizeNumber(props.totalRecords || 0)} records
                            </span>
                        }
                        {props.lastBuild !== undefined && <span>
                            <i className="mdi mdi-clock"></i> {props.lastBuild ? timeAgo(props.lastBuild) : 'Never Built'}

                        </span>}
                        
                    </div>
                </>}
            </div>
            
        </LibraryItemStyles>
        
    )
} 
interface NodeListProps {
    nodes: PipelineNode[];
    compact?: boolean;
    onClick?: (node: PipelineNode) => void;
    selectedNodeId?: string;
}

export const NodeList = (props: NodeListProps) => {
    const navigate = useNavigate();
    return (
        <LibraryStyles>
            <div className="list-group">
                {props.nodes.map((n) => {
                    if (!n) {
                        return null;
                    }
                    return (
                        <SingleNodeItem
                            key={n.id}
                            compact={props.compact}
                            node={n}
                            active={props.selectedNodeId == n.id}
                            onClick={() => {
                                props.onClick && props.onClick(n);
                                if (!props.onClick) {
                                    navigate(`/node/${n.id}`);
                                }
                            }}
                        />
                    );
                })}
            </div>
        </LibraryStyles>
    );
};

const PipelineNodeList = () => {
    const nodes = usePipelineNodes();

    const nodesById: {[key: string]: PipelineNode} = useMemo(() => {
        if (!nodes.data) {
            return {}
        }

        const theMap: {[key: string]: PipelineNode} = {};
        nodes.data.forEach((n) => {
            if (!n) {
                return;
            }
            theMap[n.id as string] = n;
        });
        return theMap;
    }, [nodes.dataUpdatedAt]);

    if (nodes.isLoading) {
        return <LoadingCard/>
    }

    return <div className="table-responsive">
        <table className="table table-centered table-nowrap table-bordered">
            <thead className="table-light">
                <tr>
                    <th>
                        Type
                    </th>
                    <th>
                        Name
                    </th>
                    <th>Sources</th>
                    <th>
                        Description
                    </th>
                    <th>Records</th>
                    <th>Last Build</th>
                </tr>
            </thead>
            <tbody>
                {nodes.data && nodes.data.map(n => {
                    return <tr>
                        <td>
                            {n.node_type == 'SOURCE' && <Badge bg="pliable">SOURCE</Badge>}
                            {n.node_type == 'STAGING' && <Badge bg="primary">STAGING</Badge>}
                            {n.node_type == 'BUSINESS_OBJECT' && <Badge bg="purple">ENTITY</Badge>}
                        </td>
                        <td>
                            <Link to={`/node/${n.id}`}>{n.name}</Link></td>
                        <td>
                            {n.upstream_node_ids.length > 0 && <ul className="mb-0">
                                {n.upstream_node_ids.map(u => {
                                    return <li>{nodesById[u].name}</li>
                                })}    
                            </ul>}
                        </td>
                        <td>{n.description}</td>
                        <td>{summarizeNumber(n.total_records ? n.total_records : 0)}</td>
                        <td>{n.last_build_completed ? timeAgo(n.last_build_completed) : 'Never'}</td>
                    </tr>
                })}
            </tbody>
        </table>
    </div>
}

export default PipelineNodeList;