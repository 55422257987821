import { PipelineNodeMeasureJoinTree } from "@models/pipelineNode";
import { ReactNode } from "react";
import PipelineNodeName, { PipelineNodeRelationshipName } from "../PipelineNodeName.component";
import styled from "styled-components";

const Styles = styled.ul`
margin-bottom: 0px;
strong {
    color: #999;
}

span.text-primary strong {
    color: var(--ct-primary);
}
`;

const SingleComponentLabel = (props: {includeArrow: boolean, joinTree: PipelineNodeMeasureJoinTree, highlightNodeIds?: string[]}) => {
    let nodeName = <strong>
        <i className="mdi mdi-table"></i><PipelineNodeName  pipelineNodeId={props.joinTree.node_id}/>
    </strong>;
    if (props.highlightNodeIds && props.highlightNodeIds.includes(props.joinTree.node_id)) {
        nodeName = <span className="text-primary">{nodeName}</span>;
    }

    return <>
        {props.includeArrow && <>&nbsp;&rarr;&nbsp;</>}
        {props.joinTree.relationship_id && <>
            via <strong>
                <i className="mdi mdi-relation-many-to-one"></i><PipelineNodeRelationshipName relationshipId={props.joinTree.relationship_id}/></strong> to {nodeName}
        </>}
        {!props.joinTree.relationship_id && <>
        
            {nodeName}
        </>}
    </>;
}

interface Props {
    joinTree: PipelineNodeMeasureJoinTree;
    singlePath?: boolean;
    highlightNodeIds?: string[];
}

const PipelineNodeJoinTreeLabel = (props: Props) => {
    if (props.singlePath) {
        const flattened = [];
        let current = props.joinTree;
        while (current) {
            flattened.push(current);
            current = current.downstream[0];
        }
        return <>
            {flattened.map((dt, idx) => {
            return <SingleComponentLabel highlightNodeIds={props.highlightNodeIds} includeArrow={idx > 0} key={idx} joinTree={dt}/>
            })}
        </>
    }
    return <Styles>
        <li>
            <SingleComponentLabel highlightNodeIds={props.highlightNodeIds} includeArrow={false} joinTree={props.joinTree}/>

            {props.joinTree.downstream.map((dt, idx) => {
                return <PipelineNodeJoinTreeLabel highlightNodeIds={props.highlightNodeIds} key={idx} joinTree={dt}/>
            })}
        </li>
    </Styles>
}

export default PipelineNodeJoinTreeLabel;