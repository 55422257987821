import Dropdown from "@components/form/Dropdown.component";

interface Props {
    transformer: string;
    onChangeTransformer: (transformer: string) => void;
}

export interface Transformer {
    label: string;
    description: string;
    value: string;
    takesArguments?: boolean;
    snowflakeOnly?: boolean;
}

export const TransformerOptions: Transformer[] = [
{
    label: 'Translate',
    description: 'Manually translate values using a Pliable-managed mapping table',
    value: 'TRANSLATE',
},
{
    label: 'Clean Zip Code',
    description: 'Cleans up zip codes by normalizing to 5 digits and ensuring there are leading zeroes',
    value: 'CLEAN_ZIP_CODE',
}, {
    label: 'Clean Phone Numbers',
    description: 'Normalizes phone numbers to (XXX) XXX-XXXX format',
    value: 'CLEAN_PHONE',
}, {
    label: 'Convert to Lowercase',
    description: 'Converts text values to all lowercase',
    value: 'LOWERCASE',
}, {
    label: 'Convert to Uppercase',
    description: 'Converts text values to all uppercase',
    value: 'UPPERCASE',
}, {
    label: 'Remove Non-Numeric Characters',
    description: 'Strips all characters that are not digits',
    value: 'REMOVE_NON_NUMERIC',
}, {
    label: 'Remove Special Characters',
    description: 'Strips all punctuation and special characters',
    value: 'REMOVE_SPECIAL_CHARS',
}, {
    label: 'Normalize Dates',
    description: 'Parses and cleans dates by trying a variety of common formats',
    value: 'PARSE_DATE',
}, {
    label: 'Convert to "Slug" Format',
    description: 'Converts text to a web-safe "slug" format, like "New York" -> "new-york"',
    value: 'SLUGIFY',
}, {
    label: 'Get URL Path',
    description: 'Extracts the path component from a URL (https://pliable.co/landing-page will return "/landing-page")',
    value: 'URL_PATH',
}, {
    label: 'Get URL Domain',
    description: 'Extracts the domain component from a URL (https://pliable.co/landing-page will return "pliable.co")',
    value: 'URL_DOMAIN',
}, {
    label: 'Custom',
    description: 'Write a custom transformation function using Snowflake SQL',
    value: 'CUSTOM',
}]

const PipelineNodeFieldTransformer = (props: Props) => {
    console.log('Dropdown value:', props.transformer)
    return <div>

        <Dropdown
            // disabled={!inDraftMode}
            placeholder="None"
            className="input-sm"
            selected={props.transformer}
            onChange={props.onChangeTransformer}
            options={TransformerOptions}
        />
        
    </div>
}

export default PipelineNodeFieldTransformer;