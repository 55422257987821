import { usePipelineNode, usePipelineNodes } from "@stores/data.store";
import { pipe } from "handsontable/helpers";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataSourceImporter from "./DataSourceImporter.component";
import toast from "@services/toast.service";
import { PageContent, PageContentHeader, PageContentInner } from "@pages/PageStructure.component";
import SourceIcon from "@components/sources/SourceIcon.component";
import { NodeList } from "@components/pipelineNodes/PipelineNodeList.component";
import flatFileIcon from '@assets/images/connectors/flatfile.png';
import { DraftOnly, ProdOnly, ReadOnly } from "@components/project/DraftModeRequired.component";

const FlatFilesDataSource = () => {
    const pipelineNodes = usePipelineNodes();

    const fileNodes = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }

        return pipelineNodes.data.filter((node) => node.flat_file).sort((a, b) => a.name.localeCompare(b.name));
    }, [pipelineNodes.dataUpdatedAt]);

    return <PageContent>
        <PageContentHeader>
            <div>
                <SourceIcon image_url={flatFileIcon} />
                <div className="flex-1 ms-2">
                    <h1 className="mb-0">Files</h1>
                    <div className="text-muted font-13">Upload your source files directly into Pliable.</div>

                </div>
                <DraftOnly>
                    <Link className="btn btn-primary" to={`/wizard/data-source?sourceType=file`}>
                        <i className="mdi mdi-upload"></i> Upload New
                    </Link>
                </DraftOnly>
            </div>
        </PageContentHeader>
        <PageContentInner hasHeader>
            <div className="p-4">
                <div className="card">
                    <div className="card-body">
                        
                        {fileNodes.length == 0 && <>
                            <DraftOnly>
                                <div className="empty-state">
                                    <div className="text-center">
                                        <h3>You haven't uploaded any files yet.</h3>
                                        <p>Pliable can easily ingest any files that are readable by Excel (.xslx, .csv, etc).</p>
                                        <Link className="btn btn-primary" to={`/wizard/data-source?sourceType=file`}>Upload one now.</Link>
                                    </div>
                                </div>
                            </DraftOnly>
                            <ProdOnly>
                                <p>You are currently in <strong>Production Mode.</strong> To upload files, please enter Develop Mode.</p>
                            </ProdOnly>
                            
                        </>}   
                        
                        <NodeList nodes={fileNodes}/>
                    </div>
                </div>
            </div>
        </PageContentInner>
 
    </PageContent>
}

export default FlatFilesDataSource;