import { LibraryItemStyles } from "@components/pipelineNodes/PipelineNodeList.component";
import { PageContent, PageContentHeader, PageContentInner, Pane, PaneContent } from "@pages/PageStructure.component";
import { useNavigate } from "react-router-dom";
import flatFileIcon from '@assets/images/connectors/flatfile.png';
import snowflakeIcon from '@assets/images/snowflake_logo.png';
import SourceIcon from "@components/sources/SourceIcon.component";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import { sampleData, SampleDataNode  } from "../../constants/samples";
import { useCallback } from "react";
import { downloadAndUploadFile } from "./DataSourceImporter.component";
import { Grid } from "@pages/Wizard/shared";
import { DraftOnly } from "@components/project/DraftModeRequired.component";

const AllSourcesPage = () => {
    const navigate = useNavigate();

    const loadSampleData = useCallback( async (sample: SampleDataNode) => {
        navigate(`/wizard/data-source?url=${sample.url}`);
    }, []);

    return <PageContent>
        <PageContentHeader>
            <div className="d-flex center-vertically" style={{height: '100%'}}>
                <DashboardIcon icon="mdi mdi-database" bgColor="pliable"/>
                <h1 className="mb-0">All Data Sources</h1>
            </div>
        </PageContentHeader>
        <PageContentInner hasHeader>
            <div className="p-4">
                <div className="card">
                    <div className="card-body">
                    
                        <h2>Enabled Sources</h2>
                        <div className="list-group">
                            <LibraryItemStyles className="list-group-item library-item d-flex" onClick={() => {
                                navigate('/sources/files');
                            }}>
                                <div className="d-flex flex-row align-items-center">
                                    <SourceIcon image_url={flatFileIcon}/>
                                </div> 
                                <div className="d-flex flex-column px-2 justify-content-center">
                                    <h4>Flat Files</h4>
                                    <div className="text-muted font-13">Upload any file readable by Excel</div>
                                </div>
                            </LibraryItemStyles>
                            <LibraryItemStyles className="list-group-item library-item d-flex" onClick={() => {
                                navigate('/sources/snowflake');
                            }}>
                                <div className="d-flex flex-row align-items-center">
                                    <SourceIcon image_url={snowflakeIcon}/>
                                </div> 
                                <div className="d-flex flex-column px-2 justify-content-center">
                                    <h4>Snowflake Tables</h4>
                                    <div className="text-muted font-13">Connect any data in your Snowflake warehouse</div>
                                </div>
                            </LibraryItemStyles>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="card">
                    <div className="card-body">
                        <h2>Want to connect additional sources?</h2>
                        <p className="">Pliable has partnerships with ETL providers that can easily get your data into Snowflake. Contact support@pliable.co to learn more.</p>
                        {/* <button className="btn btn-light">Learn More</button> */}
                    </div>
                </div>
                <DraftOnly>
                    <hr />
                    <div className="card">
                        <div className="card-body">
                            <h2>Sample Sources</h2>
                            <Grid>
                                {sampleData.map((s, index) => {
                                    return <div className="shadow-box p-3"><h3><i className={s.icon}></i> {s.name}</h3>
                                    <p>{s.description}</p>
                                    
                                        <button key={index} className="btn btn-light" onClick={() => loadSampleData(s)}>Use this Sample</button>
                                    </div>
                                })}
                            </Grid>
                        </div>
                    </div>
                </DraftOnly>
                
                
                
            </div>
        </PageContentInner>
        
    </PageContent>
}

export default AllSourcesPage;