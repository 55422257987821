import { ReactNode } from "react";
import styled from 'styled-components';
import SuccessStatusIndicator from "./SuccessStatusIndicator.component";

const Styles = styled.div`
background-color: #6BCD1D29;
color: black;
border-radius: 5px;
padding: 1rem;
margin-bottom: 0.5rem;
font-family: "Poppins";
border: 1px solid #6BCD1D;


display: flex;
line-height: 26px;
align-items: center;

.icon-container {
    display: inline-block;
    margin-right: 1rem;
    width: 40px;
}

`
interface Props {
    children: ReactNode;
}

const SuccessAlert = (props: Props) => {
    return <Styles>
        <div className="icon-container">
            <SuccessStatusIndicator/>

        </div>
        {props.children}
    </Styles>
}

export default SuccessAlert