import AsyncButton from "@components/button/AsyncButton.component";
import SaveButton from "@components/button/SaveButton.component";
import { TableExplorer } from "@components/datatable/TableExplorer.component";
import Dropdown from "@components/form/Dropdown.component";
import ComponentDescription from "@components/general/ComponentDescription.component";
import { getImmediateUpstreamNodes } from "@components/missionControl/dataflow/utils";
import OverlapAnalysis from "@components/overlapAnalysis/OverlapAnalysis.component";
import PipelineNodeColumnSelector from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import { NodeList, SingleNodeItem } from "@components/pipelineNodes/PipelineNodeList.component";
import PipelineNodeRelationshipParentLookupEditor from "@components/pipelineNodes/PipelineNodeRelationshipParentLookupEditor.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import { DraftOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import Warning from "@components/statusIndicators/Warning.component";
import { ColumnStats, PipelineNode, PipelineNodeField, PipelineNodeRelationship, PipelineNodeRelationshipDenormalizedField, PipelineNodeRelationshipORM, PipelineNodeRelationshipParentLookup, PipelineNodeShape } from "@models/pipelineNode";
import { recursiveGetNodesAndEdges } from "@pages/Dag.page";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { requireConfirmation } from "@services/alert/alert.service";
import ApiService from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import { convertToSnakeCase, formatPercentage, summarizeNumber } from "@services/formatting.service";
import { getGroupValueForNodeType, SYSTEM_FIELD_TYPES } from "@services/modeling.service";
import { useRouteBlocker } from "@services/routing.service";
import toast from "@services/toast.service";
import { invalidatePipelineNodeRelationships, MissionControlDataFlowNode, useMissionControlDataFlowData, usePipelineNode, usePipelineNodeRelationship, usePipelineNodes } from "@stores/data.store";
import { set } from "immer/dist/internal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Form, Modal, ModalBody, Offcanvas } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReactFlow, { Background, Edge, Handle, Node, NodeProps, Position } from "reactflow";
import styled, { css } from "styled-components";
import { useImmer } from "use-immer";

const ConnectorHandle = styled(Handle)`
width: 16px;
height: 16px;
background-color: var(--pliable-blue);

&:hover {
    cursor:move;
    background-color: var(--pliable-yellow)
}


`

const ColumnHeaderStyles = styled.div<{height: number}>`
border: solid 1px var(--ct-border-color);
border-radius: 4px;
padding: 1rem;
height: ${props => props.height}px;
width: 320px;
background: white;
font-family: "Poppins";
font-weight: bold;
`

const ColumnNodeStyles = styled.div<{
    type: 'source' | 'target';
}>`
width: 100%;
height: 40px;

.inner {
    border: solid 1px var(--ct-border-color);
    line-height: 40px;
    padding: 0px 1rem;
    font-family: "Fira Code", monospace;
    font-weight: 400;
    width: 280px;
    background-color: white;
    border-radius: 4px;
}


${props => props.type == 'source' && css`
    margin-right: 20px;
`}

${props => props.type == 'target' && css`
    margin-left: 20px;
`}
`

interface HeaderNodeProps {
    height: number;
    label: string;
}
const ColumnHeaderNode = (props: NodeProps<HeaderNodeProps>) => {
    return <ColumnHeaderStyles height={props.data.height}>
        {props.data?.label}
    </ColumnHeaderStyles>
}

const SourceColumnNode = (props: NodeProps) => {
    return <ColumnNodeStyles type="source">
        <div className="inner">
            {props.data?.label}
        </div>
        <ConnectorHandle type="source" position={Position.Right}/>
    </ColumnNodeStyles>
}

const TargetColumnNode = (props: NodeProps) => {
    return <ColumnNodeStyles type="target">
        <div className="inner">
            {props.data?.label}
        </div>
        <ConnectorHandle type="target" position={Position.Left}/>
    </ColumnNodeStyles>
}

const nodeTypes = {
    header: ColumnHeaderNode,
    source: SourceColumnNode,
    target: TargetColumnNode,
}

interface DiagramConnection {
    sourceFieldId: string;
    targetFieldId: string;
    overlapPercentage?: number;
    type: 'SUGGESTED' | 'USER';
}
interface DiagramProps {
    sourcePipelineNodeId: string;
    targetPipelineNodeId: string;
    connections: DiagramConnection[];
    onConnect: (sourceFieldId: string, targetFieldId: string) => any;
    onSelectEdge: (edge: DiagramConnection) => any;
}

const Diagram = (props: DiagramProps) => {
    // Probably more efficient to just usePipelineNodes here since we're not likely to have that many
    const nodes = usePipelineNodes();
    const source = useMemo(() => {
        return nodes.data?.find(n => n.id == props.sourcePipelineNodeId);
    }, [nodes.dataUpdatedAt, props.sourcePipelineNodeId]);

    const target = useMemo(() => {
        return nodes.data?.find(n => n.id == props.targetPipelineNodeId);
    }, [nodes.dataUpdatedAt, props.targetPipelineNodeId]);

    const onConnect = useCallback((params: any) => {
        props.onConnect(params.source.split(':')[1], params.target.split(':')[1]);
    }, [props.onConnect]);

    const diagramEdges: Edge[] = useMemo(() => {
        return props.connections.map(c => {
            return {
                id: 'edge:' + c.sourceFieldId + ':' + c.targetFieldId,
                source: 'source:' + c.sourceFieldId,
                target: 'target:' + c.targetFieldId,
                style: {
                    stroke: c.type == 'SUGGESTED' ? 'green' : '#00A1E0',
                    strokeWidth: 2,
                }
            }
        });
    }, [props.connections]);
    const diagramNodes = useMemo(() => {
        if (!source || !target) {
            return [];
        }
        
        const nodeWidth = 300;
        const nodeHeight = 40;
        const xGap = 100;
        const yGap = 10;
        const xStart = 10;

        const nodes: Node[] = [];
        const sourceFields = source.fields.filter(f => !SYSTEM_FIELD_TYPES.includes(f.type)).sort((a, b) => a.label.localeCompare(b.label));
        const targetFields = target.fields.filter(f => !SYSTEM_FIELD_TYPES.includes(f.type)).sort((a, b) => a.label.localeCompare(b.label));

        nodes.push({
            id: 'source-header',
            type: 'header',
            position: {
                x: 0,
                y: 0,
            },
            width: 300,
            data: {
                label: source?.label + ' (Parent)',
                height: sourceFields.length * (nodeHeight + yGap) + 50
            }
        });

        nodes.push({
            id: 'target-header',
            type: 'header',
            position: {
                x: nodeWidth + xGap,
                y: 0,
            },
            width: 300,
            data: {
                label: target?.label + ' (Child)',
                height: targetFields.length * (nodeHeight + yGap) + 50,
            }
        });

        sourceFields.forEach((f, idx) => {
            nodes.push({
                id: 'source:' + f.id,
                type: 'source',
                position: {
                    x: xStart,
                    y: idx * (nodeHeight + yGap) + 50,
                },
                width: 300,
                data: {
                    label: f.label,
                }
            });
        });
        targetFields.forEach((f, idx) => {
            nodes.push({
                id: 'target:' + f.id,
                type: 'target',
                position: {
                    x: xStart + nodeWidth + xGap,
                    y: idx * (nodeHeight + yGap) + 50,
                },
                data: {
                    label: f.label,
                }
            });
        });

        return nodes;
    }, [source, target]);

    const onEdgeClick = useCallback((e: any, edge: Edge) => {
        // Find the connection
        const connection = props.connections.find(c => {
            return c.sourceFieldId == edge.source.split(':')[1] && c.targetFieldId == edge.target.split(':')[1];
        });

        if (!connection) {
            return;
        }

        props.onSelectEdge(connection);
    }, [props.onSelectEdge, props.connections]);

    return <ReactFlow
        nodes={diagramNodes}
        edges={diagramEdges}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
        onEdgeClick={onEdgeClick}
        style={{background: "#F9F7F5"}}
        proOptions={{hideAttribution: true}}
    >
    </ReactFlow>


}


interface UpstreamColumnsMapperProps {
    show: boolean;
    hide: () => void;
    parentColumns: ColumnOptions;
    childColumns: ColumnOptions;
}

const UpstreamColumnsMapper = (props: UpstreamColumnsMapperProps) => {
    const [checkedColumns, setCheckedColumns] = useState<{ key: string; checked: boolean; type: 'parent' | 'child' }[]>([]);

    // const mapColumns = useCallback(async () => {
    //     // Ensure you're using the latest state of checkedColumns
    //     console.log(checkedColumns, "checkedColumns");
    //     const selectedColumns = checkedColumns.filter(column => column.checked).map(column => column.key);
    //     console.log(selectedColumns, "map");
    //     if (selectedColumns.length > 0) {
    //         // Loop over selected columns only
    //         for (const columnId of selectedColumns) {
    //             const colName = columnId;
    //             let sourceNodeId = '';
    //             let sourceFieldId = '';
    //             const column = checkedColumns.find(c => c.key === columnId);
    //             const type = column ? column.type : '';
    
    //             const findSourceField = (columns: ColumnOptions) => {
    //                 for (const nodeId in columns.upstreamNodes) {
    //                     const upstreamNode = columns.upstreamNodes[nodeId];
    //                     const matchingField = upstreamNode.suggested.find(field => field.fieldLabel === colName);
    //                     if (matchingField) {
    //                         sourceNodeId = matchingField.pipelineNodeId.replace('PipelineNode:', '');
    //                         sourceFieldId = matchingField.fieldId;
    //                         return true;
    //                     }
    //                 }
    //                 return false;
    //             };
    
    //             if (type === 'parent') {
    //                 findSourceField(parentColumns);
    //             } else {
    //                 findSourceField(childColumns);
    //             }
    
    //             const targetNode = type === 'parent' ? parent : child;
    
    //             if (targetNode) {
    //                 // Add the new column once source and target are identified
    //                 // await addNewColumn(colName, sourceNodeId, sourceFieldId, targetNode);
    //             } else {
    //                 console.error('Target Node ID is undefined');
    //             }
    //         }
    //     }
    
    //     // setShowModal(false); // Close modal after processing
    // }, []);
    const handleCheckboxChange = useCallback((key: string, checked: boolean, type: 'parent' | 'child') => {
        setCheckedColumns(prevState => {
            const existingIndex = prevState.findIndex(column => column.key === key);
            if (existingIndex !== -1) {
                return prevState.map((column, index) =>
                    index === existingIndex ? { ...column, checked } : column
                );
            } else {
                return [...prevState, { key, checked, type }];
            }
        });
    }, []);

    return <Modal show={props.show} onHide={props.hide}>
        <Modal.Header closeButton>
            <Modal.Title>Column Suggestions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="row">
            <div className="col-6">
                <h3>Parent Columns</h3>
                {Object.keys(props.parentColumns.upstreamNodes).map((nodeId) => (
                    props.parentColumns.upstreamNodes[nodeId].suggested.map((column, index) => (
                        <div key={index} className="form-check mt-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={column.fieldLabel}
                                id={`parentColumn-${column.fieldLabel}-${index}`}
                                onChange={(e) => handleCheckboxChange(column.fieldLabel, e.target.checked, 'parent')}
                            />
                            <label className="form-check-label" htmlFor={`parentColumn${index}`}>
                                {column.fieldLabel}
                            </label>
                        </div>
                    ))
                ))}
            </div>
            <div className="col-6">
                <h3>Child Columns</h3>
                {Object.keys(props.childColumns.upstreamNodes).map((nodeId) => (
                    props.childColumns.upstreamNodes[nodeId].suggested.map((column, index) => (
                        <div key={index} className="form-check mt-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={column.fieldLabel}
                                id={`childColumn-${column.fieldLabel}-${index}`}
                                onChange={(e) => handleCheckboxChange(column.fieldLabel, e.target.checked, 'child')}
                            />
                            <label className="form-check-label" htmlFor={`childColumn${index}`}>
                                {column.fieldLabel}
                            </label>
                        </div>
                    ))
                ))}
            </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
            {/* <Button variant="secondary" onClick={props.hide}>
                Close
            </Button>
            <Button variant="primary" onClick={handleMapColumns}>
                Map Columns
            </Button> */}
        </Modal.Footer>
    </Modal>
}

interface ColumnOption {
    pipelineNodeId: string;
    fieldId: string;
    fieldLabel: string;
}

interface ColumnOptionBreakdown {
    suggested: ColumnOption[];
    other: ColumnOption[];
}
interface ColumnOptions {
    thisNode: ColumnOptionBreakdown;
    upstreamNodes: {
        [nodeId: string]: ColumnOptionBreakdown
    }
}

const getLikelyForeignKeys = (fields: PipelineNodeField[], shape?: PipelineNodeShape): PipelineNodeField[] => {
    // Basic algorithm to determine if fields are likely foreign keys
    // const shapesByColumnName: {
    //     [colName: string]: ColumnStats
    // } = {};

    // shape.columns.forEach(c => {
    //     shapesByColumnName[c.key] = c;
    // });

    const indicators = ['uuid', 'id', 'guid', 'key'];

    const likelyForeignKeys: PipelineNodeField[] = [];
    fields.forEach(f => {
        const converted = convertToSnakeCase(f.name).split('_');
        const matches = converted.filter(c => indicators.includes(c));

        // const shape = shapesByColumnName[f.name];
        // if (!shape) {
        //     return;
        // } 
        // // Needs high density?
        // if (shape.density < 0.9) {
        //     return;
        // }

        if (matches.length > 0) {
            likelyForeignKeys.push(f);
        }
    });
    return likelyForeignKeys
}

interface RelationshipTestResults {
    results: {
        total: number;
        ambiguous: number;
        orphans: number;
    }
    table_name: string;
}



const PipelineNodeRelationshipPage = () => {
    const [step, setStep] = useState(0);

    const [child, setChild] = useState<PipelineNode|undefined>(undefined);
    const [parent, setParent] = useState<PipelineNode|undefined>(undefined);

    const [query, setQuery] = useSearchParams();

    const afterSave = query.get('afterSave');

    const pipelineNodes = usePipelineNodes();

    useEffect(() => {
        if (!pipelineNodes.data) {
            return;
        }
        if (query.get('pipelineNodeId')) {
            const node = pipelineNodes.data.find(n => n.id == query.get('pipelineNodeId'));
            if (!node) {
                return;
            }
            setParent(node);

            if (getGroupValueForNodeType(node.node_type) == 'DATA_MODELING') {
                setRelationshipType('DATA_MODEL');
            } else {
                setRelationshipType('DENORMALIZATION');
            }
        }
    }, [pipelineNodes.isFetchedAfterMount, query.get('pipelineNodeId')]);

    const [connections, setConnections] = useImmer<DiagramConnection[]>([]);

    const [parentLookups, setParentLookups] = useImmer<PipelineNodeRelationshipParentLookup[]>([]);
    // TODO: take this from the URL and look up the existing relationship if we need to

    const [denormalizedFields, setDenormalizedFields] = useImmer<PipelineNodeRelationshipDenormalizedField[]>([]);
    const { relationshipId } = useParams();

    const relationship = usePipelineNodeRelationship(relationshipId != 'new' ? (relationshipId || '') : '');

    const [relationshipType, setRelationshipType] = useState('');

    const [relationshipName, setRelationshipName] = useState('');
    const [relationshipDescription, setRelationshipDescription] = useState('');

    const [logicGate, setLogicGate] = useState('AND');

    useEffect(() => {
        if (relationship.data && pipelineNodes.data) {
            const rel = relationship.data;
            const parent = pipelineNodes.data.find(n => n.id == rel.parent_node_id);
            const child = pipelineNodes.data.find(n => n.id == rel.child_node_id);
            setRelationshipDescription(rel.description || '');
            setRelationshipName(rel.name || '');
            if (parent && child) {
                setParent(parent);
                setChild(child);

                setParentLookups(rel.parent_lookups);
                setLogicGate(rel.parent_lookup_logic_gate);
                setDenormalizedFields(rel.denormalized_fields || []);

                if (getGroupValueForNodeType(parent.node_type) == 'DATA_MODELING' && getGroupValueForNodeType(child.node_type) == 'DATA_MODELING') {
                    setRelationshipType('DATA_MODEL');
                } else {
                    setRelationshipType('DENORMALIZATION');
                }
            }
        }
    }, [relationship.isFetchedAfterMount, pipelineNodes.isFetchedAfterMount]);
    const mcData = useMissionControlDataFlowData();
    

    const [activeEdge, setActiveEdge] = useState<DiagramConnection|undefined>(undefined);

    const getColumnSuggestionsForNode = useCallback((node: PipelineNode) => {
        if (!node || !mcData.data || !pipelineNodes.data) {
            return {
                thisNode: {
                    suggested: [],
                    other: []
                },
                upstreamNodes: {},
            };
        }

        const thisNodeColumns: ColumnOptionBreakdown = {
            suggested: [],
            other: [],
        }

        const likelyKeysForThisNode = getLikelyForeignKeys(node.fields, node.shape);
        const otherColumnsForThisNode = node.fields.filter(f => !likelyKeysForThisNode.includes(f));

        thisNodeColumns.suggested = likelyKeysForThisNode.map(f => {
            return {
                pipelineNodeId: node.id as string,
                fieldId: f.id,
                fieldLabel: f.label,
            };
        });
        thisNodeColumns.other = otherColumnsForThisNode.map(f => {
            return {
                pipelineNodeId: node.id as string,
                fieldId: f.id,
                fieldLabel: f.label,
            };
        });
        

        const thisMcNode = mcData.data.nodes.find(n => n.id == 'PipelineNode:' + node.id);
        if (!thisMcNode) {
            return {
                thisNode: {
                    suggested: [],
                    other: []
                },
                upstreamNodes: {},
            }
        }

        const [upstreamNodes] = getImmediateUpstreamNodes(thisMcNode, mcData.data.nodes, mcData.data.edges);
        const upstreamColumns: {
            [nodeId: string]: ColumnOptionBreakdown
        } = {};
        
        (upstreamNodes as MissionControlDataFlowNode[]).forEach(n => {
            const upstreamNode = pipelineNodes.data.find(pn => pn.id == n.id.replace('PipelineNode:', ''));
            if (!upstreamNode) {
                return;
            }

            // Figure out suggestions

            const likelyKeys = getLikelyForeignKeys(upstreamNode.fields, upstreamNode.shape);
            const likelyColumns = likelyKeys.map(f => {
                return {
                    pipelineNodeId: n.id,
                    fieldId: f.id,
                    fieldLabel: f.label,
                };
            });
            const unlikelyColumns = upstreamNode.fields.filter(f => !likelyKeys.includes(f)).map(f => {
                return {
                    pipelineNodeId: n.id,
                    fieldId: f.id,
                    fieldLabel: f.label,
                };
            });
            upstreamColumns[upstreamNode.id as string] = {
                suggested: likelyColumns,
                other: unlikelyColumns,
            }
        });
        return {
            thisNode: thisNodeColumns,
            upstreamNodes: upstreamColumns,
        };
    }, [mcData.dataUpdatedAt, pipelineNodes.dataUpdatedAt]);

    const childColumns: ColumnOptions = useMemo(() => {
        if (!child) {
            return {
                thisNode: {
                    suggested: [],
                    other: []
                },
                upstreamNodes: {},
            }
        }
        return getColumnSuggestionsForNode(child);
    }, [child]); 

    const parentColumns: ColumnOptions = useMemo(() => {
        if (!parent) {
            return {
                thisNode: {
                    suggested: [],
                    other: []
                },
                upstreamNodes: {},
            }
        }
        return getColumnSuggestionsForNode(parent);
    }, [parent]); 

    const onConnectFields = useCallback((sourceFieldId: string, targetFieldId: string) => {
        setConnections(draft => {   
            draft.push({
                targetFieldId: targetFieldId,
                sourceFieldId: sourceFieldId,
                type: 'USER',

            });
        });
    }, []);

    const removeActiveEdge = useCallback(() => {
        if (!activeEdge) {
            return;
        }
        setConnections(draft => {
            const idx = draft.findIndex(c => c.sourceFieldId == activeEdge.sourceFieldId && c.targetFieldId == activeEdge.targetFieldId);
            if (idx >= 0) {
                draft.splice(idx, 1);
            }
        });
        toast('success', 'Success', 'Connection removed');
        setActiveEdge(undefined);
    }, [activeEdge]);


    const getRelationshipModel = useCallback(() => {
        const rv: PipelineNodeRelationship = {
            id: relationshipId == 'new' ? null : (relationshipId as string),
            parent_node_id: parent?.id || '',
            child_node_id: child?.id || '',
            name: relationshipName,
            description: relationshipDescription,
            parent_lookup_logic_gate: logicGate,
            denormalized_fields: denormalizedFields,
            // This gets auto-set by the backend
            child_foreign_key_name: '',
            parent_lookups: parentLookups,
        };
        return rv;
    }, [parentLookups, child, parent, relationshipId, relationshipName, logicGate, denormalizedFields, relationshipDescription]);

    const [testResults, setTestResults] = useState<RelationshipTestResults|undefined>(undefined);

    const [testing, setTesting] = useState(false);


    const testRelationship = useCallback(async () => {
        const model = getRelationshipModel();
        setTesting(true);
        try {
            const testResults = await ApiService.getInstance().request('POST', '/pipelinenoderelationships/test', model) as RelationshipTestResults;
            setTestResults(testResults);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setTesting(false);
        }
    }, [getRelationshipModel]);

    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const saveRelationship = useCallback(async () => {
        const model = getRelationshipModel();
        setSaving(true);
        try {
            const result = await PipelineNodeRelationshipORM.save(model);
            toast('success', 'Success', 'Relationship saved');
            invalidatePipelineNodeRelationships();
            setPageDirty(false);

            setTimeout(() => {
                if (afterSave ) {
                    navigate(afterSave);
    
                } else if (relationshipId == 'new') {
                    navigate(`/relationships/${result.id}`);
                    
                }
            }, 200);
            
            
            
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
            
        }
    }, [getRelationshipModel, afterSave]);

    const { pageDirty, setPageDirty } = useRouteBlocker(saveRelationship)


    const [investigationMode, setInvestigationMode] = useState<'ORPHANS' | 'AMBIGUOUS' | 'NONE'>('NONE');

    const [showExtraColumnsModal, setShowExtraColumnsModal] = useState(false);

    const changeRelationshipType = useCallback((newType: string) => {
        setRelationshipType(newType);
        setPageDirty(true);
        setParent(undefined);
        setChild(undefined);
    }, []);

    const deleteRelationship = useCallback(async () => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this relationship?', 'Delete Relationship');

        if (confirmed) {
            try {
                await PipelineNodeRelationshipORM.deleteById(relationshipId as string);
                invalidatePipelineNodeRelationships();
                toast('success', 'Success', 'Relationship deleted');

                if (afterSave) {
                    navigate(afterSave);
                } else {
                    navigate('/semantic-layer');
                }
                
            } catch (err) {
                toast('danger', 'Error', getErrorMessage(err));
            }
        }
    }, [relationshipId, afterSave]);

   
    return (
        <PageStructure>
            
            <Modal size="xl" show={investigationMode != 'NONE'} onHide={() => {
                setInvestigationMode('NONE');
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>Investigate {investigationMode == 'ORPHANS' ? 'Orphan Children' : 'Ambiguous Parents'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TableExplorer
                        tablePath={`/db/data?table=${testResults?.table_name}`}
                    />
                </Modal.Body>
            </Modal>
            
            <PageContent hasSidebar={relationshipId != 'new'}>
                <PageContentHeader>
                    <div className="d-flex center-vertically" style={{height: '100%'}}>
                        <DashboardIcon icon="mdi mdi-relation-many-to-many" bgColor="purple"/>
                        <h1 className="mb-0 flex-1">{relationshipId == 'new' ? 'New Relationship' : 'Edit Relationship'}</h1>
                        <DraftOnly>
                            {relationshipId != 'new' && <button className="btn btn-lg btn-outline-danger me-1" onClick={deleteRelationship}>
                                Delete
                            </button>}
                            <SaveButton
                                className="btn btn-lg"
                                loading={saving}
                                onClick={saveRelationship}
                                text="Save"
                                disabled={!pageDirty}
                            />
                        </DraftOnly>
                    </div>

                </PageContentHeader>
                <PageContentInner hasHeader>
                    <Pane>
                        <PaneContent>
                            <ReadOnly>
                            <div className="p-5">
                                

                                <div className="card">
                                    <div className="card-body">
                                        <h2>
                                            <i className="mdi mdi-information-outline"></i> Basic Information
                                        </h2>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Relationship Type
                                            </Form.Label>
                                            <Dropdown
                                                options={[
                                                    {label: 'Data Model Relationship', value: 'DATA_MODEL', description: 'Connects two entities so they can be used together in reporting.'},
                                                    {label: 'Denormalization', value: 'DENORMALIZATION', description: 'Used in data cleaning to bring data from a parent onto a child.'},
                                                ]}
                                                selected={relationshipType}
                                                onChange={changeRelationshipType}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Relationship Name</Form.Label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={relationshipName}
                                                onChange={(e) => {
                                                    setPageDirty(true);
                                                    setRelationshipName(e.target.value);
                                                }}
                                            />
                                        
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control as="textarea" rows={3} value={relationshipDescription} onChange={(e) => {
                                                setPageDirty(true);
                                                setRelationshipDescription(e.target.value);
                                            }}/>
                                        </Form.Group>
                                        <div className="row">
                                            <div className="col-6">
                                                <Form.Group className="">
                                                    <Form.Label>Select Parent</Form.Label>
                                                    <PipelineNodeSelector
                                                        onSelect={(node) => {
                                                            setParent(node);
                                                            setPageDirty(true);
                                                        }}
                                                        selectedId={parent?.id || ''}
                                                        optionFilter={(option) => {
                                                            if (child && child.id == option.id) {
                                                                return false;
                                                            }

                                                            if (relationshipType == 'DATA_MODEL') {
                                                                return getGroupValueForNodeType(option.node_type) == 'DATA_MODELING';
                                                            }
                                                            return getGroupValueForNodeType(option.node_type) == 'STAGING' && option.node_type != 'CUSTOM';

                                                        }}
                                                    />

                                                </Form.Group>
                                            </div>
                                            <div className="col-6">
                                                <Form.Group>
                                                    <Form.Label>Select Child</Form.Label>
                                                    <PipelineNodeSelector
                                                        onSelect={(node) => {
                                                            setChild(node);
                                                            setPageDirty(true);
                                                        }}
                                                        selectedId={child?.id || ''}
                                                        optionFilter={(option) => {
                                                            if (parent && parent.id == option.id) {
                                                                return false;
                                                            }
                                                            if (relationshipType == 'DATA_MODEL') {
                                                                return getGroupValueForNodeType(option.node_type) == 'DATA_MODELING';
                                                            }
                                                            return getGroupValueForNodeType(option.node_type) == 'STAGING' && option.node_type != 'CUSTOM';
                                                        }}
                                                    />

                                                </Form.Group>
                                            </div>
                                        </div>
                                        {parent && child && 
                                        <blockquote>A <strong>{parent?.singular}</strong> can have many <strong>{child?.plural}</strong>, and a <strong>{child?.singular}</strong> can have exactly one <strong>{parent?.singular}</strong>.</blockquote>}
                                        
                                    </div>
                                </div>
                                {parent && child && <>
                                        {/* <Warning>
                                        <div><strong>Warning:</strong> there is already a relationship between these objects. Do you want to use that one instead? You can also give each one a name to help differentiate.</div>
                                    </Warning> */}
                                    <hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <h2 className="mb-0">Data Connections</h2>
                                            <p className="text-muted font-13">Connect the columns that should match across these two datasets. Once you make a connection, you can click on it to run an overlap analysis.</p>
                                            {parent && child && <>
                                                {parentLookups.length > 1 && <>
                                                    <Form.Check
                                                        type="switch"
                                                        label={logicGate == 'AND' ? 'Match ALL' : 'Match ANY'}
                                                        checked={logicGate === 'AND'}
                                                        onChange={(e) => {
                                                            logicGate == 'AND' ? setLogicGate('OR') : setLogicGate('AND');
                                                        }}
                                                    />
                                                </>}
                                                <PipelineNodeRelationshipParentLookupEditor
                                                    parentNode={parent}
                                                    childNode={child}
                                                    parentLookups={parentLookups}
                                                    onChange={(lookups) => {
                                                        setParentLookups(lookups);
                                                        setPageDirty(true);
                                                    }}
                                                    enableOverlapAnalysis
                                                />
                                            </>}
                                        </div>
                                    </div>
                                    
                                    
                                    <hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <h2 className="mb-0">Denormalization</h2>
                                            <p className="text-muted font-13">You can clean up overly-normalized source data (e.g., a <code>STATUS_ID</code> column referencing a record in another table) by bringing data from the parent directly onto each of its children.</p>
                                            <table className="table table-fixed table-condensed table-bordered table-centered">
                                                <thead className="bg-light font-poppins">
                                                    <tr>
                                                        <th>Parent Column</th>
                                                        <th>New Column Name</th>
                                                        <th className="text-end">
                                                            <button className="icon-button font-18" onClick={() => {
                                                                setDenormalizedFields(draft => {
                                                                    draft.push({
                                                                        parent_field_id: '',
                                                                        output_field_name: '',
                                                                    });
                                                                    setPageDirty(true);
                                                                });
                                                            }}>
                                                                <i className="mdi mdi-plus-thick"></i>
                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {denormalizedFields.map((field, idx) => {
                                                        return <tr key={idx}>
                                                            <td>
                                                                <PipelineNodeColumnSelector
                                                                    onSelect={(fieldId) => {
                                                                        setDenormalizedFields(draft => {
                                                                            draft[idx].parent_field_id = fieldId || '';
                                                                        });
                                                                        setPageDirty(true);
                                                                    }}
                                                                    selectedId={field.parent_field_id}
                                                                    pipelineNodeId={parent?.id || ''}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={field.output_field_name}
                                                                    onChange={(e) => {
                                                                        setDenormalizedFields(draft => {
                                                                            draft[idx].output_field_name = e.target.value;
                                                                        });
                                                                        setPageDirty(true);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td className="text-end">
                                                                <button className="icon-button font-18" onClick={() => {
                                                                    setDenormalizedFields(draft => {
                                                                        draft.splice(idx, 1);
                                                                    });
                                                                    setPageDirty(true);
                                                                }}>
                                                                    <i className="mdi mdi-trash-can"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                    <hr />
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex center-vertically mb-2">
                                                <h2 className="mb-0 flex-1">Test</h2>
                                                <AsyncButton
                                                    loading={testing}
                                                    text="Run Test"
                                                    onClick={testRelationship}
                                                />
                                            </div>
                                            <p>Pliable will test your relationship configuration to look for <a role="button">ambiguous parents</a> and <a role="button">orphan children</a></p>
                                            {connections.length > 1 && <Warning>
                                                <div>
                                                Hey mother fucker, you have multiple connections. Do you want to connect these objects if <strong>all</strong> of them match?
                                                Or <strong>at least one of them</strong> matches? If we do an "OR" then it might be slow as fuck just heads up.
                                                <br /><br />
                                                <Form.Check
                                                    type="switch"
                                                    label="Match all"
                                                />
                                                
                                                </div>
                                            </Warning>}

                                            
                                            
                                            {testResults && !testing && <div className="shadow-box p-3 mt-3">
                                                <h3>Test Results:</h3>
                                                
                                                {testResults.results.total > 0 && <div className="row">
                                                    <div className="col-4">
                                                        <h4>Passed</h4>
                                                        <Badge className="font-18" pill bg={(testResults.results.orphans + testResults.results.ambiguous == testResults.results.total) ? 'danger' : (testResults.results.orphans > 0 || testResults.results.ambiguous > 0) ? 'warning' : 'success'}>
                                                            {summarizeNumber(testResults.results.total - testResults.results.orphans - testResults.results.ambiguous)} ({formatPercentage((testResults.results.total - testResults.results.orphans - testResults.results.ambiguous) / testResults.results.total)} of total)
                                                        </Badge>
                                                    </div>
                                                    <div className="col-4">
                                                        <h4>Ambiguous Parents</h4>
                                                        <Badge onClick={() => {
                                                            // setInvestigationMode('AMBIGUOUS')
                                                        }} className="font-18 " pill bg={testResults.results.ambiguous > 0 ? 'danger' : 'success'}>
                                                            {testResults.results.ambiguous} ({formatPercentage(testResults.results.ambiguous / testResults.results.total)} of total)
                                                        </Badge>
                                                    </div>
                                                    <div className="col-4">
                                                    <   h4>Orphan Children</h4>
                                                        <Badge onClick={() => {
                                                            // setInvestigationMode('ORPHANS');
                                                        }} className="font-18 " pill bg={testResults.results.orphans > 0 ? 'warning' : 'success'}>
                                                            {testResults.results.orphans} ({formatPercentage(testResults.results.orphans / testResults.results.total)} of total)
                                                        </Badge>
                                                    </div>
                                                </div>}
                                                {testResults.results.total == 0 && <>
                                                    No data.
                                                </>}
                                            </div>}
                                        </div>
                                    </div>
                                    
                                </>}
                                    
                                    
                                    
                                
                            </div>
                            </ReadOnly>
                        </PaneContent>
                    </Pane>
                </PageContentInner>
            
                
            </PageContent>
            <PageSidebar right>
                <Pane>
                    <PaneContent>
                        <div className="p-4">
                            <h2>Quick Links</h2>
                            {parent && <>
                                <SingleNodeItem node={parent} compact onClick={() => {
                                    navigate(`/node/${parent?.id}/config`);
                                }}/>
                            </>}
                            {child && <>
                                <SingleNodeItem node={child} compact onClick={() => {
                                    navigate(`/node/${child?.id}/config`);
                                }}/>
                            </>}
                            
                        </div>
                    </PaneContent>
                </Pane>
            </PageSidebar>
            
         </PageStructure>
    )
}

export default PipelineNodeRelationshipPage;