import { ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import ORM, { BaseModel } from "./orm";

export interface MarketingFeature {
    name: string;
}

export interface Price {
    unit_amount: number;
}

export interface BillingProduct {
    name: string;
    description: string;
    images: string[];
    default_price: string;
    marketing_features: MarketingFeature[];
    prices: Price[];
}


export interface BillingAccount extends BaseModel {
    customer_id: string;
    processor: string;
    subscription_confirmed: boolean;
    subscription_id: string;
    subscription_status: string;
    price_id: string;
    product_id: string;
    product_name: string;
    payment_intent_secret?: string;
    checkout_session_id?: string;
    checkout_session_secret?: string;
    days_left_in_trial?: number;
    default_payment_method_id?: string;
}


export class _BillingAccountORM extends ORM<BillingAccount>{
    public async getBillingAccount(forceCheck = false) {
        const resp = await this.apiService.request('GET', `/${this.endpoint}/primary`, {force_check: forceCheck}) as SingleRecordResponse<BillingAccount>;
        return resp.record;
    }

    public async getBillingOptions() {
        const resp = await this.apiService.request('GET', `/${this.endpoint}/billing-options`) as ListRecordsResponse<BillingProduct>;
        return resp.records;
    }

    public async selectBillingPlan(price_id: string) {
        const resp = await this.apiService.request('POST', `/${this.endpoint}/select`, {
            price_id
        }) as ListRecordsResponse<any>;
        return resp.records;
    }

    public async getPaymentUpdateUrl() {
        const resp = await this.apiService.request('POST', `/${this.endpoint}/get-payment-url`, {}) as SingleRecordResponse<any>;
        return resp.record;
    }

    public async getSubscriptionUpdateUrl() {
        const resp = await this.apiService.request('POST', `/${this.endpoint}/get-subscription-update-url`, {}) as SingleRecordResponse<any>;
        return resp.record;
    }

    public async getSubscriptionCancelUrl() {
        const resp = await this.apiService.request('POST', `/${this.endpoint}/get-subscription-cancel-url`, {}) as SingleRecordResponse<any>;
        return resp.record;
    }

    public async getSubscriptionChangeConfirmUrl(price_id: string) {
        const resp = await this.apiService.request('POST', `/${this.endpoint}/get-subscription-confirm-url`, {price_id}) as SingleRecordResponse<any>;
        return resp.record;
    }

    public async getPortalUrl() {
        const resp = await this.apiService.request('POST', `/${this.endpoint}/get-portal-url`, {}) as SingleRecordResponse<any>;
        return resp.record;
    }

    public async cancelCheckoutSession(session_id: string) {
        const resp = await this.apiService.request('POST', `/${this.endpoint}/cancel-session`, {
            session_id
        }) as ListRecordsResponse<any>;
        return resp.records;
    }

    public async markPlanChoosen() {
        const resp = await this.apiService.request('POST', `/${this.endpoint}/mark-plan-choosen`, {
        }) as ListRecordsResponse<any>;
        return resp.records;
    }

};

const BillingAccountORM = new _BillingAccountORM('billing');
export default BillingAccountORM;

