import { PipelineNode } from "@models/pipelineNode";
import { SingleNodeItem } from "./PipelineNodeList.component";
import { useMemo } from "react";
import { useMissionControlDataFlowData, usePipelineNodes } from "@stores/data.store";
import { getGroupValueForNodeType } from "@services/modeling.service";

interface Props {
    pipelineNode: PipelineNode;
    fieldId: string;
}

const PipelineNodeColumnTransformer = (props: Props) => {
    const nodes = usePipelineNodes();

    const cleaningSteps = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        return nodes.data.filter(n => {
            if (n.id == props.pipelineNode.id) {
                return false;
            }

            if (getGroupValueForNodeType(n.node_type) != 'STAGING') {
                return false;
            }

            if (n.upstream_node_ids.includes(props.pipelineNode.id as string)) {
                return true;
            }
            return false;
        })
    }, [nodes.dataUpdatedAt, props.pipelineNode]);

    const theField = useMemo(() => {
        return props.pipelineNode.fields.find(f => f.id == props.fieldId);
    }, [props.pipelineNode, props.fieldId])
    return <>
        <h3>Clean Column: {theField?.label}</h3>
        <h4>Source Node</h4>
        <SingleNodeItem node={props.pipelineNode} />

        {props.pipelineNode.node_type == 'SOURCE' && <>
            {cleaningSteps.length == 0 && <>
                <p>You haven't created any cleaning steps for this data source. Pliable will create a new cleaning step.</p>
            </>}
            {cleaningSteps.length == 1 && <>
                <p>Pliable will clean up this column in your existing cleaning step.</p>
                <SingleNodeItem node={cleaningSteps[0]} />
            </>}
            {cleaningSteps.length > 1 && <>
                <p>Which cleaning step do you want to do this in?</p>
            </>}
        </>}
        {props.pipelineNode.node_type != 'SOURCE' && <>
            <p>Pliable will clean this up in this node.</p>
        </>}
       
    </>
}

export default PipelineNodeColumnTransformer