import PliableLoader from '@components/loaders/PliableLoader.component';
import PageStructure, { PageContent, Pane, PaneContent } from '@pages/PageStructure.component';
import ApiService from '@services/api/api.service';
import { useAuthState } from '@services/auth/auth.service';
import { SurveyAnswers } from '@services/config/config.service';
import { getErrorMessage } from '@services/errors.service';
import TenantConfigService from '@services/tenantConfig.service';
import toast from '@services/toast.service';
import { queryClient, useProjectConfig } from '@stores/data.store';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EventScheduledEvent, InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useQuery } from 'react-query';
import { useImmer } from 'use-immer';

interface CalendlyEventInfoData {
    resource: {
        name: string;
        start_time: string;
        end_time: string;
        location: {
            join_url: string;
        }
    }
}

interface CalendlyEventInfoProps {
    eventUuid: string;
}

const useCalendlyEventInfo = (eventUuid: string) => {
    return useQuery(['calendly_onboarding_event', eventUuid], async () => {
        const info = await ApiService.getInstance().request('GET', '/calendly-event') as CalendlyEventInfoData;
        console.log('Event info:', info);
        return info;

    });
}

const CalendlyEventInfo = ({ eventUuid } : CalendlyEventInfoProps) => {
    const eventInfo = useCalendlyEventInfo(eventUuid);

    if (eventInfo.data) {
        return <>
            <h4>{moment(eventInfo.data?.resource.start_time).format('MMMM Do, YYYY')} at {moment(eventInfo.data?.resource.start_time).format('h:mm a')}</h4>
        </>
    }
    
    return <PliableLoader/>
}

const BookOnboardingCallPage = () => {
    const authState = useAuthState();

    const [answers, setAnswers] = useImmer<SurveyAnswers|undefined>(undefined);
    const [tenantConfigId, setTenantConfigId] = useState('');
    const projectConfig = useProjectConfig();

    const [saving, setSaving] = useState(false);
    const saveData = useCallback(async (onboardingEventLink: string, onboardingInviteeLink: string) => {
        if (!answers) {
            return;
        }
        setSaving(true);
        try {
            const saveData = {
                ...answers,
                onboardingEventLink,
                onboardingInviteeLink,
            };

            await TenantConfigService.getInstance().updateOnboardingInfo(tenantConfigId, saveData);
            projectConfig.refetch();
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [answers, tenantConfigId]);

    const onEventScheduled = useCallback((e: EventScheduledEvent) => {
        if (!answers) {
            return;
        }
        setAnswers(draft => {
            if (!draft) {
                return;
            }
            draft.onboardingEventLink = e.data.payload.event.uri;
            draft.onboardingInviteeLink = e.data.payload.invitee.uri;
            queryClient.invalidateQueries(['calendly_onboarding_event'])
        });
        saveData(e.data.payload.event.uri, e.data.payload.invitee.uri);
        
    }, [answers]);

    useEffect(() => {
        if (projectConfig.data) {
            setAnswers(projectConfig.data.onboarding_info);
            setTenantConfigId(projectConfig.data.id);

        }
    }, [projectConfig.dataUpdatedAt]);

    const alreadyBookedCall = useMemo(() => {
        if (!projectConfig.data) {
            return false;
        }

        return !!projectConfig.data.onboarding_info?.onboardingEventLink;
    }, [projectConfig.dataUpdatedAt]);

    useCalendlyEventListener({
       onEventScheduled: onEventScheduled,
    });

    const innerContent = useMemo(() => {
        if (projectConfig.isLoading) {
            return <PliableLoader/>
        }

        if (alreadyBookedCall) {
            return <div className="card">
                <div className="card-body">
                    <h2>Your call is booked!</h2>
                    <CalendlyEventInfo eventUuid={projectConfig.data?.onboarding_info?.onboardingEventLink as string}/>

                </div>
            </div>
        }

        return <InlineWidget
            styles={{height: '800px', width: '100%'}}
            url="https://calendly.com/d/ckc3-gd4-2wh/pliable-onboarding" 
            prefill={{
                email: authState?.user?.email,
                name: authState?.user?.name,
            }}
            
        />
    }, [projectConfig.dataUpdatedAt, projectConfig.isLoading, alreadyBookedCall, authState]);

    return <PageStructure>
        <PageContent>
            <Pane>
                <PaneContent>
                    <div className="p-onboarding pt-5">
                        {innerContent}
                        
                    </div>
                </PaneContent>
            
            </Pane>
            
        </PageContent>
    </PageStructure>
}

export default BookOnboardingCallPage