import { PipelineNode } from "@models/pipelineNode";
import { usePipelineNode, usePipelineNodeRelationship } from "@stores/data.store";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

interface Props {
    pipelineNode?: PipelineNode;
    pipelineNodeId?: string;
    link?: boolean;
    linkToNewPage?: boolean;
    singular?: boolean;
    plural?: boolean;
}

const PipelineNodeName = (props: Props) => {

    const pipelineNodeData = usePipelineNode(props.pipelineNodeId);

    const [pipelineNode, setPipelineNode] = useState<PipelineNode|undefined>(undefined);

    useEffect(() => {
        if (props.pipelineNode) {
            setPipelineNode(props.pipelineNode);
        } else if (props.pipelineNodeId && pipelineNodeData.data) {
            setPipelineNode(pipelineNodeData.data);
        }
    }, [props.pipelineNode, props.pipelineNodeId, pipelineNodeData.dataUpdatedAt])

    const nameToShow = useMemo(() => {
        if (!pipelineNode) {
            return '';
        }
        if (props.plural) {
            return pipelineNode.plural || pipelineNode.label;
        } if (props.singular) {
            return pipelineNode.singular || pipelineNode.label;
        }
        return pipelineNode.label;
    }, [pipelineNode, props.plural, props.singular]);
   
    if (props.link) {
        return <Link target={props.linkToNewPage ? '_blank' : ''} to={`/node/${props.pipelineNodeId}`}>{nameToShow}</Link>
    }
    return <>
        {nameToShow}
    </>
}

export default PipelineNodeName;

interface RelationshipProps {
    relationshipId: string;
    link?: boolean;
    linkToNewPage?: boolean;
}

export const PipelineNodeRelationshipName = (props: RelationshipProps) => {
    const relData = usePipelineNodeRelationship(props.relationshipId);

    if (!relData.data) {
        return <><i className="mdi mdi-loading mdi-spin"></i></>
    }

    const inner = <>{relData.data.name ? relData.data.name : 'Unnamed Relationship'}</>

    if (props.link) {
        return <Link target={props.linkToNewPage ? '_blank' : ''} to={`/relationship/${props.relationshipId}`}>{inner}</Link>
    }
    return inner;
}

interface FieldProps {
    pipelineNodeId: string;
    fieldId: string;
}

export const PipelineNodeFieldName = (props: FieldProps) => {
    const pipelineNodeData = usePipelineNode(props.pipelineNodeId);

    const [fieldName, setFieldName] = useState<string>('');

    useEffect(() => {
        if (pipelineNodeData.data) {
            const field = pipelineNodeData.data.fields.find(f => f.id === props.fieldId);
            if (field) {
                setFieldName(field.name);
            }
        }
    }, [pipelineNodeData.dataUpdatedAt]);

    if (props.fieldId == '_PLB_UUID') {
        return <>Pliable Record ID</>
    }

    return <>{fieldName}</>
}


export const PipelineNodeFieldDescription = (props: FieldProps) => {
    const pipelineNodeData = usePipelineNode(props.pipelineNodeId);

    const [fieldDescription, setFieldDescription] = useState<string>('');

    useEffect(() => {
        if (pipelineNodeData.data) {
            const field = pipelineNodeData.data.fields.find(f => f.id === props.fieldId);
            if (field) {
                setFieldDescription(field.description || 'No description');
            }
        }
    }, [pipelineNodeData.dataUpdatedAt]);

    if (props.fieldId == '_PLB_UUID') {
        return <>Unique identifier for each record.</>
    }

    return <>{fieldDescription}</>
}