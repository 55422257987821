import ApiService, { ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import ORM, { BaseModel } from "./orm";

export interface BuildException {
    message: string;
    error_type: string;
    column?: string;
    model_name?: string;
    model_label?: string;
    source_name?: string;
    source_label?: string;
    relationship_ref?: string;
}

export interface BuildExecution {
    pipeline_execution_id: string
    status: string;
    business_object_id?: string;
    object_id: string;
    object_type: string;
    results_info: any;
    build_exception?: BuildException;
}
export interface BuildLogEntry {
    seconds: number;
    message: string;
    name: string;
}

export interface ModelStatus {
    node_path: string;
    node_id: string;
    status: string;
    started_at: string;
    finished_at?: string;
    error?: string;
    is_legacy_datamart?: boolean;
}
export interface BuildOrchestration extends BaseModel {
    status: string;
    job_id: string;
    build_executions: { [key: string]: BuildExecution; };
    queued_at?: Date;
    executed_by?: string;
    started_at?: Date;
    completed_at?: Date;
    branch_to_build?: string;
    completed_by?: string;
    error?: string;
    node_selector?: string;

    build_logs?: BuildLogEntry[];
    model_statuses: {
        [nodeId: string]: ModelStatus;
    }
    
}


export class _BuildOrchestrationORM extends ORM<BuildOrchestration>{
    
    public async buildModel(objClassName: string, objectId: string, force?: boolean) : Promise<BuildOrchestration> {
        const result = await ApiService.getInstance().request('POST', `/${this.endpoint}/build/${objClassName}/${objectId}`, {
            force: !!force,
        }) as SingleRecordResponse<BuildOrchestration>;
        return result.record;
    }

    public async buildWithSelector(selector: string, quick?: boolean, force: boolean = false) : Promise<BuildOrchestration> {
        const result = await ApiService.getInstance().request('POST', `/${this.endpoint}/build/selector`, {
            selector: selector,
            quick: !!quick,
            force: force
        }) as SingleRecordResponse<BuildOrchestration>;
        return result.record;
    }

    public async buildAll(force?: boolean) : Promise<BuildOrchestration> {
        const result = await ApiService.getInstance().request('POST', `/${this.endpoint}/build/all`, {
            force: !!force
        }) as SingleRecordResponse<BuildOrchestration>;
        return result.record;
    }

    public async cancelOrchestration(orchestrationId: string) {
        const result = await ApiService.getInstance().request('DELETE', `/${this.endpoint}/${orchestrationId}/cancel`) as SingleRecordResponse<BuildOrchestration>;
        return result.record;
    }

    

    public async getHistory(): Promise<BuildOrchestration[]> {
        const result = await ApiService.getInstance().request('GET', `/${this.endpoint}/history`) as ListRecordsResponse<BuildOrchestration>;

        return result.records.map((r) => this.convertDates(r));
    }
}


const BuildOrchestrationORM = new _BuildOrchestrationORM('buildorchestrations', ['queued_at', 'started_at', 'completed_at']);
export default BuildOrchestrationORM;