import { requireDraftMode } from "@components/branch/help";
import { enterDraftMode, useDraftVersionId, useIsInDraftMode } from "@stores/data.store";
import { PropsWithChildren, useCallback } from 'react'
import styled from 'styled-components';

const Blocker = styled.div`
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
z-index: 5000;

background: rgba(0, 0, 0, 0.5);

.actions {
    text-align: center;
    color: white;
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    width: 300px;
    margin: 100px auto;
}
`

const Styles = styled.div`
position: relative;
height: 100%;
`
type DraftModeRequiredProps = {
    showDraftModeLink?: boolean;
    justHideChildren?: boolean;
}

export function DraftOnly({ children }: PropsWithChildren) {
    const inDraftMode = useIsInDraftMode();
    if (inDraftMode) {
        return <>{children}</>
    }
    return <></>;
}

export function ReadOnly({ children }: PropsWithChildren) {
    const inDraftMode = useIsInDraftMode();
    if (inDraftMode) {
        return <>{children}</>
    }
    return <span className="disabled">{children}</span>;
}




export function DraftModeRequired({showDraftModeLink = false, children, justHideChildren}: PropsWithChildren<DraftModeRequiredProps>) {
    const {data: draftVersionId} = useDraftVersionId();

    const beginDraftClick = useCallback(async () => {
        await enterDraftMode('draft_mode_required');
    }, [draftVersionId]);

    if (!draftVersionId && justHideChildren) {
        return <></>
    } else if (justHideChildren) {
        return <>{children}</>
    }

    return <Styles>
        {!draftVersionId && <Blocker>
            <div className="actions">
                You need to be in draft mode to make changes.
                <button className="btn btn-pliable" onClick={beginDraftClick}>Enter draft mode <i className="mdi mdi-pencil"></i></button>
            </div>
        </Blocker>}
        {children}
    </Styles>
    // if (!!draftVersionId){
    //     return <>{children}</>
    // }

    // if (showDraftModeLink) {
    //     return <button className="btn" onClick={beginDraftClick}>Edit <i className="mdi mdi-pencil"></i></button>
    // }

    // return <></>
}

interface ProdModeRequiredProps {
    
}

export function ProdOnly({ children }: PropsWithChildren) {
    const inDraftMode = useIsInDraftMode();
    if (!inDraftMode) {
        return <>{children}</>
    }
    return <></>;
}

export const ProdModeRequired = (props: PropsWithChildren<ProdModeRequiredProps>) => {
    const {data: draftVersionId} = useDraftVersionId();
    const beginDraftClick = useCallback(async () => {
        await enterDraftMode('draft_mode_required');
    }, [draftVersionId]);

    if (!draftVersionId) {
        return <>
            {props.children}
        </>
    }
    return <></>
}


export function BlockInProd({ children }: PropsWithChildren) {
    const inDraftMode = useIsInDraftMode();
    if (inDraftMode) {
        return <>{children}</>
    }
    return (
        <div className="card-body">
            <div className="text-center align-items">
                <h3>You’re currently in Production Mode...</h3>
                <p>Please enter Develop Mode to continue</p>
                <button className="btn btn-pliable" onClick={() => enterDraftMode('block_in_prod')}>Enter Develop Mode <i className="mdi mdi-pencil"></i></button>
            </div>
        </div>
    );
}



