/**
 * CHANGES:
 * 
 * Add filter to search for columns across all data sources (under the header)
 * Show active data source and collapse all otehrs when you click on a mission control edge
 * Hide delete buttons until hover
 * Add placeholder drop
 * Change "fields" header to "{bo.name} Fields"
 * Add "One row per _____" under the Fields header
 * Allow dragging data sources from data library
 * Click to edit field name
 * 
 * Add "special plb fields" to the data source (use SourceRecordType.column_preferences for special static fields)
 *  - Loaded At
 *  - Source Name
 *  - Add your own static field
 * 
 */

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import 'reactflow/dist/style.css';
import { Link, useNavigate } from "react-router-dom";
import { Updater, useImmer } from 'use-immer';
import {  usePipelineNodes, usePipelineNodeRelationships, useIsInDraftMode, usePipelineNode, useTemplateName, getTableData, } from '@stores/data.store';
import styled, { css } from 'styled-components';
import { Badge, Collapse, Form, Modal, Offcanvas } from "react-bootstrap";
import { requireConfirmation } from "@services/alert/alert.service";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { DataWhitelist, PipelineNode, PipelineNodeField, PipelineNodeMapOption, PipelineNodeRelationship } from "@models/pipelineNode";
import PipelineNodeColumnDrawer, { ColumnDistro, ColumnRecordInfo } from "@components/pipelineNodes/PipelineNodeColumnDrawer.component";
import PipelineNodeFieldTranslation from "@components/pipelineNodes/PipelineNodeFieldTranslation.component";
import { shortid } from "@services/id.service";
import Warning from "@components/statusIndicators/Warning.component";
import PipelineNodeFieldEditor from "./mapping/PipelineNodeFieldEditor.component";
import toast from "@services/toast.service";
import PipelineNodeSelector from "./PipelineNodeSelector.component";
import { isValidUpstreamNode, SYSTEM_FIELD_TYPES } from "@services/modeling.service";
import { DraftOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import PipelineNodeName from "./PipelineNodeName.component";
import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import PipelineNodeBasicConfiguration from "./configuration/PipelineNodeBasicConfiguration.component";
import PipelineNodeWhitelistConfiguration from "./configuration/PipelineNodeWhitelistConfiguration.component";
import PipelineNodeOutputConfiguration from "./configuration/PipelineNodeOutputConfiguration.component";
import { UnlockedNodeRequired, UnlockedFieldRequired } from "@components/project/UnlockedRequired.component";
import Select from 'react-select';
import { MultiValue } from "react-select";
import PipelineNodeDragAndDropMapping from "./mapping/PipelineNodeDragAndDropMapping.component";
import FullScreenTakeover from "@components/general/FullScreenTakeover.component";
import { Allotment, AllotmentHandle } from "allotment";
import PipelineNodeDataTable from "./PIpelineNodeDataTable";
import { DataPaneHeader } from "@pages/PipelineNode/PipelineNodeConfiguration.page";
import PipelineNodeColumnCleanerUpper, { PipelineNodeFieldCleaningBadges, SummaryOptionPicker } from "./configuration/PipelineNodeColumnCleanerUpper.component";
import EditableText from "@components/general/EditableText.component";
import PipelineNodeFieldMap from "./mapping/PipelineNodeFieldMap.component";
import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import DataWhitelistForm from "./PipelineNodeDataWhitelist.component";
import ApiService from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import AsyncButton from "@components/button/AsyncButton.component";
import { summarizeNumber } from "@services/formatting.service";
import { GroupingBehaviorForm } from "@pages/Wizard/CleaningWizard.page";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";



interface Transformer {
    label: string;
    description: string;
    value: string;
    takesArguments?: boolean;
    snowflakeOnly?: boolean;
}



const Grid = styled.div`
display: grid;
gap: 20px;
grid-template-columns: repeat(3, 1fr);
`

const ColumnOption = styled.div<{mapped: boolean, highlight?: boolean}>`
display: inline-block;
margin-right: .5rem;
margin-bottom: .5rem;
background: var(--ct-border-color);
padding: .5rem;
color: black;
border-radius: 5px;

${props => 
    props.mapped && css`
        background: var(--pliable-blue);
        color: white;

        &:hover {
            background: var(--pliable-blue) !important;
            color: white !important;
        }
    `

}

${props => props.highlight && css`
    background: var(--pliable-yellow);
    color: white;

    &:hover {
        background: #e58f00 !important;
        color: white !important;
    }
`}

&:hover {
    background: #ccc;
    cursor: pointer;


}

.inner {
    display: flex;
    align-items: center;

    .text {
        flex: 1;
    }

}
`

interface RelatedNode {
    node: PipelineNode;
    relationship: PipelineNodeRelationship;
}


interface Props {
    node: PipelineNode;
    onChange: Updater<PipelineNode>
    enableMerge?: boolean;
    requireMerge?: boolean;
    limitToSingleSource?: boolean;
    includeRelationships?: boolean;
    mergeTextMode?: 'MERGE' | 'GROUP_BY' | 'IDENTIFY';
    useGroupByInsteadOfMerge?: boolean;
    limitToSingleFieldMap?: boolean;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

const PipelineNodeMappingConfigForm = (props: Props) => {
    const pipelineNodes = usePipelineNodes();
    const relationships = usePipelineNodeRelationships(props.node.id as string);
    
    const navigate = useNavigate();

    const upstreamNodes = useMemo(() => {
        return pipelineNodes.data?.filter(pn => props.node.upstream_node_ids.includes(pn.id as string));
    }, [pipelineNodes.dataUpdatedAt, props.node.upstream_node_ids]);
    const [showAddSourceModal, setShowAddSourceModal] = useState(false);
    
    const addNewFieldAtTop = useCallback(() => {
        const newId = shortid();
        props.onChange(draft => {
            draft.fields.unshift({
                id: newId,
                name: '',
                description: '',
                part_of_composite_key: false,
                label: 'New Field', 
                type: 'STRING',
                map_options: [],
                taxonomic_id: '',
                cell_actions: [],
            })
        });
    }, [props.onChange]);

    const onDeleteField = useCallback(async (field: PipelineNodeField) => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this column?', 'Delete Column');
        if (!confirmed) {
            return;
        }
        props.onChange(draft => {
            const idx = draft.fields.findIndex(d => d.id === field.id);
            if (idx >= 0) {
                draft.fields.splice(idx, 1);
            }
        })
    }, [props.onChange]);

    const [selectedFieldIds, setSelectedFieldIds] = useImmer<string[]>([]);


    const [searchInput, setSearchInput] = useState('');
    const [searchTerm] = useDebounce(searchInput, 250);

    const [boSearchInput, setBoSearchInput] = useState('');
    const [boSearchTerm] = useDebounce(boSearchInput, 250);

    const [columnOptionSearch, setColumnOptionSearch] = useState('');
    const [columnOptionSearchTerm] = useDebounce(columnOptionSearch, 250);

    const usedFields = useMemo(() => {
        const used: string[] = [];
        props.node.fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (mo.source_node_id) {
                    used.push(mo.source_node_id + ':' + mo.attribute_key);
                } else if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        used.push(so.source_node_id + ':' + so.attribute_key);
                    });
                }
            });
        });
        return used;
    }, [props.node.fields])


    const filteredColumns = useMemo(() => {
        if (!pipelineNodes.data) {
            return []
        }
        const term = searchTerm.toLowerCase();


        return props.node.upstream_node_ids.map(upstreamNodeId => {
            const upstreamNode = pipelineNodes.data.find(s => s.id === upstreamNodeId);
            const theseFilteredColumns = upstreamNode?.fields?.filter(c => c.label.toLowerCase().indexOf(term) >= 0 || c.label.toLowerCase().indexOf(term) >= 0).sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                return -1;
            });


            // Now break them into used/unused
            if (!theseFilteredColumns) {
                return {
                    'used': [],
                    'unused': [],
                }
            } 
            return {
                'used': theseFilteredColumns.filter(c => usedFields.includes(upstreamNodeId + ':' + c.name)),
                'unused': theseFilteredColumns.filter(c => !usedFields.includes(upstreamNodeId + ':' + c.name)),
            }

        });
    }, [props.node.upstream_node_ids, searchTerm, pipelineNodes.dataUpdatedAt, usedFields]);


    const mergeText = useMemo(() => {
        const compositeFieldNames = props.node.fields.filter(f => f.part_of_composite_key).map(f => f.label);
        let joiner: string = ' and ';
        if (props.node.combine_logic_gate == 'OR') {
            joiner = ' or ';
        }
        if (compositeFieldNames.length) {
            let fieldText = '';
            if (compositeFieldNames.length === 1) {
                fieldText = compositeFieldNames[0];
            } else if (compositeFieldNames.length === 2) {
                fieldText = compositeFieldNames.join(joiner);
            } else {
                let last = compositeFieldNames.pop();
                fieldText = compositeFieldNames.join(', ') + ', ' + joiner + last;
            }
            return fieldText;
        }
        return '';

        


    }, [props.node.fields, props.node.combine_logic_gate]);

    const toggleFieldCompositeKey = useCallback((fieldId: string) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.part_of_composite_key = !theField.part_of_composite_key;
            }
        })
        
    }, [props]);

    
    const onUnmapColumn = useCallback((sourceNodeId: string, sourceColumnId: string, targetColumnId: string) => {
        const sourceColumn = pipelineNodes.data?.find(s => s.id === sourceNodeId)?.fields?.find(f => f.id === sourceColumnId);
        if (!sourceColumn) {
            return;
        }
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === targetColumnId);
            if (!theField) {
                return;
            }

            if (!theField.map_options) {
                return;
            }

            const idx = theField.map_options.findIndex(mo => mo.source_node_id === sourceNodeId && mo.attribute_id === sourceColumnId);
            if (idx >= 0) {
                theField.map_options.splice(idx, 1);
            }

        });
        toast('success', 'Success', `Column unmapped`, 2000);

        
    }, [props.onChange, pipelineNodes.dataUpdatedAt]);

    const onMapColumn = useCallback((sourceNodeId: string, sourceColumnId: string, targetColumnId: string) => {
        const sourceColumn = pipelineNodes.data?.find(s => s.id === sourceNodeId)?.fields?.find(f => f.id === sourceColumnId);
        if (!sourceColumn) {
            return '';
        }

        let fieldId: string;

        if (targetColumnId == 'NEW_TOP') {
            fieldId = shortid();
        } else {
            fieldId = targetColumnId;
        }

        props.onChange(draft => {
            const fieldType = (SYSTEM_FIELD_TYPES.includes(sourceColumn.type)) ? 'STRING' : sourceColumn.type;
            if (targetColumnId == 'NEW_TOP') {
                draft.fields.unshift({
                    id: fieldId,
                    label: sourceColumn.label,
                    name: sourceColumn.label,
                    part_of_composite_key: false,
                    type: fieldType,
                    taxonomic_id: '',
                    description: '',
                    map_options: [
                        {
                            id: shortid(),
                            source_node_id: sourceNodeId,
                            attribute_key: sourceColumn.name,
                            attribute_id: sourceColumn.id,
                            combination_rule: 'PICK_ONE',

                        }
                    ],
                    cell_actions: []
                });

            } else {
                const theField = draft.fields.find(f => f.id === targetColumnId);
                if (!theField) {
                    throw new Error('field not found');
                }


                if (!theField.map_options) {
                    theField.map_options = [];
                }

                if (props.limitToSingleFieldMap && theField.map_options.length >= 1) {
                    theField.map_options = [];
                }

                theField.map_options.push({
                    id: shortid(),
                    source_node_id: sourceNodeId,
                    attribute_key: sourceColumn.name,
                    attribute_id: sourceColumn.id,
                    combination_rule: 'PICK_ONE', 
                });


            }
            
        });
        setAddingColumn('');
        return fieldId;

    }, [props.onChange, pipelineNodes.dataUpdatedAt]);
        

    const mergingFields = useMemo(() => {
        return props.node.fields.filter(f => f.part_of_composite_key);
    }, [props.node.fields]);

    const allFields = useMemo(() => {
        const lower = boSearchTerm.toLowerCase();
        return props.node.fields.filter(f => !SYSTEM_FIELD_TYPES.includes(f.type) && f.label.toLowerCase().indexOf(lower) >= 0);
    }, [props.node.fields, boSearchTerm])

    const addAllFieldsFromSource = useCallback((upstreamNodeId: string) => {
        if (!pipelineNodes.data) {
            return;
        }

        const theSrt = pipelineNodes.data.find(s => s.id === upstreamNodeId);
        if (!theSrt) {
            return;
        }

        const idx = props.node.upstream_node_ids.indexOf(upstreamNodeId);

        const unusedFields = filteredColumns[idx].unused;
        props.onChange(draft => {

            unusedFields.forEach(f => {
                // Find a BO field with the same name and add it if so, otherwise create
                const mapOption = {
                    id: shortid(),
                    source_node_id: upstreamNodeId,
                    attribute_key: f.name,
                    attribute_id: f.id,
                    combination_rule: 'PICK_ONE', 
                };
                const fieldWithSameName = draft.fields.find(field => field.label.toLowerCase() == f.label.toLowerCase());
                if (fieldWithSameName) {
                    if (!fieldWithSameName.map_options) {
                        fieldWithSameName.map_options = [];
                    }

                    fieldWithSameName.map_options.push(mapOption);

                } else {
                    draft.fields.push({
                        id: shortid(),
                        name: f.label,
                        label: f.label,
                        part_of_composite_key: false,
                        type: f.type,
                        map_options: [mapOption],
                        taxonomic_id: '',
                        description: '',
                        cell_actions: [],
                    })
                }
            })
        });


    }, [props.onChange, pipelineNodes.dataUpdatedAt, props.node.upstream_node_ids]);
    
    const [activeColumnPipelineNodeId, setActiveColumnPipelineNodeId] = useState('');
    const [activeColumn, setActiveColumn] = useState<PipelineNodeField|undefined>(undefined);


    const [activeFieldForTranslations, setActiveFieldForTranslations] = useState<PipelineNodeField|undefined>(undefined);
    const onEditFieldTranslations = useCallback((fieldId: string) => {
        const theField = props.node.fields.find(f => f.id === fieldId);
        if (theField) {
            setActiveFieldForTranslations(theField);
        }
    }, [props.node.fields]);

    const [activeFieldId, setActiveFieldId] = useState('');
    const activeField = useMemo(() => {
        if (activeFieldId) {
            const af = props.node.fields.find(f => f.id === activeFieldId);
            return af;
        }
        return undefined;
    }, [activeFieldId, props.node.fields]);

    const onUpdateActiveField = useCallback((field: PipelineNodeField) => {
        console.log('Updated active field', field.custom_transform_sql);
        props.onChange(draft => {
            const idx = draft.fields.findIndex(f => f.id === field.id);
            if (idx >= 0) {
                draft.fields[idx] = field;
            }
        });
    }, [props.onChange,]);

    const fieldsForAutocomplete = useMemo(() => {
        if (!activeField) {
            return [];
        }

        // Can't use other formulas in a formula, just to be safe for order of operations
        return props.node.fields.filter(f => f.id !== activeField.id && !f.advanced_mode).map(f => f.name)
    }, [props.node.fields, activeField]);


    const [newSourceSelection, setNewSourceSelection] = useState('');

    const newSourceOptionFilter = useCallback((pn: PipelineNode) => {
        return isValidUpstreamNode(props.node, pn);
    }, [props.node.upstream_node_ids, props.node])

    const onConnectSource = useCallback(async () => {
        props.onChange(draft => {
            draft.upstream_node_ids.push(newSourceSelection);
        })
        setShowAddSourceModal(false);
        
    }, [props.onChange, newSourceSelection]);

   

    const updateUpstreamNodeIds = useCallback((newUpstreamIds: string[]) => {
        // Updates the upstreamNodeIds and removes any mapped fields for no-longer-connected upstream nodes
        props.onChange(draft => {
            draft.upstream_node_ids = newUpstreamIds;

            draft.fields.forEach(f => {
                if (f.map_options) {
                    const newMapOptions: PipelineNodeMapOption[] = [];
                    f.map_options.forEach(mo => {
                        if (mo.sub_options) {
                            const newSubOptions: PipelineNodeMapOption[] = [];
                            mo.sub_options.forEach(so => {
                                if (newUpstreamIds.includes(so.source_node_id as string)) {
                                    newSubOptions.push(so);
                                }
                            });
                            mo.sub_options = newSubOptions;
                        }
                        if (newUpstreamIds.includes(mo.source_node_id as string)) {
                            newMapOptions.push(mo);
                        }
                    });
                    f.map_options = newMapOptions;
                }
            })
        })
    }, [props.onChange]);

    const removeUpstreamNode = useCallback(async (upstreamNodeId: string) => {
        const confirmed = await requireConfirmation('Are you sure you want to remove this source? Doing so will remove all of its mapped columns on this node.');
        if (confirmed) {
            const newUpstreamIds = props.node.upstream_node_ids.filter(id => id !== upstreamNodeId);
            updateUpstreamNodeIds(newUpstreamIds);
        }
        
    }, [updateUpstreamNodeIds, props.node.upstream_node_ids]);

    const inDraftMode = useIsInDraftMode();

    const [showColumnEditor, setShowColumnEditor] = useState(false);

    const mappedFieldsBySourceNode = useMemo(() => {
        const mappedFields: {[key: string]: string[]} = {};
        props.node.fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (!mappedFields[mo.source_node_id as string]) {
                    mappedFields[mo.source_node_id as string] = [];
                }
                mappedFields[mo.source_node_id as string].push(mo.attribute_id as string);

                if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        if (!mappedFields[so.source_node_id as string]) {
                            mappedFields[so.source_node_id as string] = [];
                        }
                        mappedFields[so.source_node_id as string].push(so.attribute_id as string);
                    });
                }
            });
        });
        
        // Make sure they're unique
        const cleaned: {[key: string]: string[]} = {};
        Object.keys(mappedFields).forEach(k => {
            cleaned[k] = Array.from(new Set(mappedFields[k]));
        });
        return cleaned;
    }, [props.node.upstream_node_ids, props.node.fields]);


    const columnsThatSourceColumnsAreMappedTo = useMemo(() => {
        const mappedColumns: {
            [nodeId: string]: {
                [key: string]: string[]
            }
        } = {};

        const addMapping = (sourceNodeId: string, sourceColumnId: string, targetColumnId: string) => {
            if (!mappedColumns[sourceNodeId]) {
                mappedColumns[sourceNodeId] = {};
            }
            if (!mappedColumns[sourceNodeId][sourceColumnId]) {
                mappedColumns[sourceNodeId][sourceColumnId] = [];
            }
            mappedColumns[sourceNodeId][sourceColumnId].push(targetColumnId);
        }
        props.node.fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (mo.attribute_id) {
                    addMapping(mo.source_node_id as string, mo.attribute_id as string, f.id as string);
                }
                

                if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        if (so.attribute_id) {
                            addMapping(so.source_node_id as string, so.attribute_id as string, f.id as string);
                        }
                    });
                }
            });
        });
        return mappedColumns;
    }, [props.node.fields]);

    const sourceNodeFieldCount = useMemo(() => {
        const count: {[key: string]: number} = {};

        /**
         * Get the total number of columns in each source node
         */
        pipelineNodes.data?.forEach(pn => {
            count[pn.id as string] = pn.fields.length;
        });
        return count;
        
        
    }, [pipelineNodes.dataUpdatedAt]);


    const [expandedDataSource, setExpandedDataSource] = useState('');

    const expandedSourceNode = usePipelineNode(expandedDataSource);

    const expandedSourceColumns = useMemo(() => {
        if (!expandedDataSource) {
            return [];
        }
        if (!expandedSourceNode.data) {
            return [];
        }
        return [...expandedSourceNode.data.fields].sort((a, b) => {
            if (a.label > b.label) {
                return 1;
            }
            return -1;
        });
    }, [expandedSourceNode.dataUpdatedAt, expandedDataSource]);

    const isDraggingDisabled = useMemo(() => {
        return !inDraftMode || !!boSearchTerm;
    }, [boSearchTerm, inDraftMode]);

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
      
        // change background colour if dragging
        // background: isDragging ? "lightgreen" : "grey",

        display: isDragging ? 'table' : 'table-row',

        borderTop: isDragging ? '1px solid var(--ct-border-color)' : 'none',
        backgroundColor: isDragging ? 'white' : 'inherit',
        borderLeft: isDragging ? '1px solid var(--ct-border-color)' : 'none',
        borderRight: isDragging ? '1px solid var(--ct-border-color)' : 'none',
      
        // styles we need to apply on draggables
        ...draggableStyle
    });

    useEffect(() => {
        if (!!activeFieldId) {
            setShowColumnEditor(true);
        }
    }, [activeFieldId]);

    const onColumnReorder = useCallback((result: any) => {
        if (!result.destination) {
            return;
        }

        
        props.onChange(draft => {
            // Retain the fields that aren't managed here.
            const unmanagedFields = draft.fields.filter(f => SYSTEM_FIELD_TYPES.includes(f.type));
            const managedFields = draft.fields.filter(f => !SYSTEM_FIELD_TYPES.includes(f.type));
            const newFields = reorder(managedFields, result.source.index, result.destination.index);
            draft.fields = newFields.concat(unmanagedFields);
        });
    }, [props.onChange]);

    const onSaveEditingField = useCallback((editingField: PipelineNodeField) => {
        props.onChange(draft => {
            const idx = draft.fields.findIndex(f => f.id === editingField.id);
            if (idx >= 0) {
                draft.fields[idx] = editingField;
            }
        });
        setActiveFieldId('');
        setShowColumnEditor(false);
    }, [activeFieldId, props.onChange]);

    const [addingColumn, setAddingColumn] = useState('');
    const [addingColumnNodeId, setAddingColumnNodeId] = useState('');
    const [showSourceColumnDetails, setShowSourceColumnDetails] = useState(false);  

    const onAddColumn = useCallback((nodeId: string, columnId: string) => {
        setAddingColumnNodeId(nodeId);
        setAddingColumn(columnId);
        setShowSourceColumnDetails(false);
    }, []);

    const templateName = useTemplateName(props.node.managed_by_template_id || '');
    
    const [dedupeFields, setDedupeFields] = useState<string[]>([]);

    useEffect(() => {
        const initialDedupeFields = props.node.fields
          .filter(field => field.part_of_composite_key)
          .map(field => field.id);
        setDedupeFields(initialDedupeFields);
      }, [props.node.fields]);

    const handleDedupeChange = (newValue: MultiValue<{ key: string; label: string; value: string }>) => {
        setDedupeFields(newValue.map(option => option.value));
        props.onChange(draft => {
            draft.fields.forEach(field => {
                return field.part_of_composite_key = newValue.some(option => option.value === field.id);
            });
        });
    };
    const [mappingSourceId, setMappingSourceId] = useState('');

    const openMapColumnsModal = useCallback((nodeId: string) => {
        setMappingSourceId(nodeId);
    }, [props.node.id]);

    const allotmentRef = useRef<AllotmentHandle>(null);

    const [dataViewerIsOpen, setDataViewerIsOpen] = useState(false);
    const allotmentResized = useDebouncedCallback((newSizes: number[]) => {
        if (newSizes[1] === 30) {
            setDataViewerIsOpen(false);
        } else {
            setDataViewerIsOpen(true);
        }
    }, 250);
    const toggleDataViewer = useCallback(() => {
        if (!allotmentRef.current) {
            return;
        }

        if (dataViewerIsOpen) {
            // There's a resize issue with the editor so we need to switch back to table mode first
            window.requestAnimationFrame(() => {
                allotmentRef.current!.reset();

            })
        } else {
            allotmentRef.current!.resize([1000, 1000]);

        }
        
    }, [allotmentRef, dataViewerIsOpen]);

    const [activeColumnForFieldMapping, setActiveColumnForFieldMapping] = useState<PipelineNodeField|undefined>(undefined);

    const [activeColumnMapOptions, setActiveColumnMapOptions] = useState<PipelineNodeMapOption[]>([]);
    const [activeColumnFormula, setActiveColumnFormula] = useState('');
    const [activeColumnAdvancedMode, setActiveColumnAdvancedMode] = useState(false);

    const customFormulaRef = useRef<HTMLTextAreaElement>(null);
    const updateMapOptionsForActiveColumn = useCallback((newOptions: PipelineNodeMapOption[]) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === activeColumnForFieldMapping?.id);
            if (theField) {
                theField.map_options = newOptions;
            }
        });    
    }, [props.onChange, activeColumnForFieldMapping]);
    
    const saveMappingColumn = useCallback(() => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === activeColumnForFieldMapping?.id);
            if (theField) {
                
                theField.map_options = activeColumnMapOptions;
                theField.advanced_mode = activeColumnAdvancedMode;
                theField.custom_sql = customFormulaRef.current?.value || '';
            }
        });    
        setActiveColumnForFieldMapping(undefined);
    }, [activeColumnMapOptions, customFormulaRef, activeColumnAdvancedMode, activeColumnForFieldMapping]);

    const columnWidths = useMemo(() => {
        if (['DIMENSION', 'DATE_DIMENSION'].includes(props.node.node_type)) {
            return ['5%', '5%', '35%', '20%', '25%', '10%'];
        }
        if (props.node.node_type == 'SUMMARIZE') {
            return ['5%', '0%', '20%', '20%', '20%', '20%', '10%'];
        }
        return ['5%', '0%', '35%', '20%', '25%', '10%'];
    }, [props.node.node_type]);

    const [showMergeBehaviorModal, setShowMergeBehaviorModal] = useState(false);
    const [activeMergeBehaviorField, setActiveMergeBehaviorField] = useState<PipelineNodeField|undefined>(undefined);
    const editFieldMergeBehavior = useCallback((field: PipelineNodeField) => {
        setActiveMergeBehaviorField(field);
        setActiveMergeBehaviorFieldDatatype(field.type);
        setActiveMergeBehaviorFieldAggregator(field.merge_behavior || 'PICK_ONE');
        setShowMergeBehaviorModal(true);
    }, []);

    const [activeMergeBehaviorFieldDatatype, setActiveMergeBehaviorFieldDatatype] = useState('');
    const [activeMergeBehaviorFieldAggregator, setActiveMergeBehaviorFieldAggregator] = useState('');

    const onSaveMergeBehaviorField = useCallback(() => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === activeMergeBehaviorField?.id);
            if (theField) {
                theField.type = activeMergeBehaviorFieldDatatype;
                theField.merge_behavior = activeMergeBehaviorFieldAggregator;
            }
        });
        setShowMergeBehaviorModal(false);
    }, [activeMergeBehaviorField, activeMergeBehaviorFieldDatatype, activeMergeBehaviorFieldAggregator])

    const toggleFieldDimension = useCallback((fieldId: string) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.is_dimension = !theField.is_dimension;
            }
        });
    }, [props.onChange]);

    const [activeUpstreamNodeIdForPreFilters, setActiveUpstreamNodeIdForPreFilters] = useState('');

    const [testingWhitelist, setTestingWhitelist] = useState(false);
    const [whitelistTotalRecords, setWhitelistTotalRecords] = useState(0);

    const [ranWhitelistTest, setRanWhitelistTest] = useState(false);
    const onTestWhitelist = useCallback(async () => {
        setTestingWhitelist(true);
        const whitelist = props.node.upstream_pre_filters ? props.node.upstream_pre_filters[activeUpstreamNodeIdForPreFilters] : {entries: []};
        try {
            const response = await getTableData(`/pipelinenodes/${activeUpstreamNodeIdForPreFilters}/data`, 1, 1, '', '', '', '', JSON.stringify(whitelist));
            setWhitelistTotalRecords(response.total_records);
            setRanWhitelistTest(true);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setTestingWhitelist(false);
        }
        
    }, [activeUpstreamNodeIdForPreFilters, props.node.upstream_pre_filters]);

    const totalRecordsInActiveWhitelistSource = useMemo(() => {
        if (!pipelineNodes.data) {
            return 0;
        }

        const sourceNode = pipelineNodes.data.find(pn => pn.id === activeUpstreamNodeIdForPreFilters);
        if (!sourceNode) {
            return 0;
        }
        return sourceNode.total_records || 0;
    }, [activeUpstreamNodeIdForPreFilters, pipelineNodes.dataUpdatedAt])

    const activePreFilterWhitelist: DataWhitelist = useMemo(() => {
        if (props.node.upstream_pre_filters && props.node.upstream_pre_filters.hasOwnProperty(activeUpstreamNodeIdForPreFilters)) {
            return props.node.upstream_pre_filters[activeUpstreamNodeIdForPreFilters];
        }
        return {entries: [], logic_gate: 'AND'};
    }, [activeUpstreamNodeIdForPreFilters, props.node.upstream_pre_filters]);

    const groupingType = useMemo(() => {
        if (props.node.node_type == 'DIMENSION') {
            return 'MERGE';
        }

        if (['MERGE', 'SUMMARIZE', 'IDENTIFY'].includes(props.node.node_type)) {
            return props.node.node_type;
        }
        return '';
    }, [props.node.node_type]);

    return <div>
        <Modal size="xl" show={!!activeUpstreamNodeIdForPreFilters} onHide={() => setActiveUpstreamNodeIdForPreFilters('')}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Pre-Filters for <PipelineNodeName pipelineNodeId={activeUpstreamNodeIdForPreFilters}/>
                </Modal.Title>
                
            </Modal.Header>
            <Modal.Body>
                <p className="text-muted">
                    Pre-filters allow you to filter the data from this source before it is brought in to this node. This can be useful for reducing the amount of data that is processed, or for ensuring that only the data you need is included.
                </p>
                    <DataWhitelistForm
                        nodeIds={[activeUpstreamNodeIdForPreFilters]}
                        config={activePreFilterWhitelist}
                        onChange={(newConfig) => {
                            props.onChange(draft => {
                                draft.upstream_pre_filters![activeUpstreamNodeIdForPreFilters] = newConfig;
                            });
                        }}
                    />
                    <hr />
                    

                </Modal.Body>
            <Modal.Footer>
                {ranWhitelistTest &&
                    <span className="text-muted">This filter would return {whitelistTotalRecords} out of {summarizeNumber(totalRecordsInActiveWhitelistSource)} records.</span>}
                <AsyncButton
                        text="Test" 
                        loading={testingWhitelist}
                        disabled={activePreFilterWhitelist.entries.length === 0} 
                        className="btn btn-primary" 
                        onClick={onTestWhitelist}/>
                    
                <button className="btn btn-success" onClick={() => setActiveUpstreamNodeIdForPreFilters('')}>
                    Done
                </button>
            </Modal.Footer>

        </Modal>
        <Modal show={!!showMergeBehaviorModal} onHide={() => setShowMergeBehaviorModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Aggregation: {activeMergeBehaviorField?.label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {activeMergeBehaviorField && <>
                    <Form.Group className="mb-3">
                        <Form.Label>Datatype</Form.Label>
                        <BusinessObjectFieldTypeSelector
                            selected={activeMergeBehaviorFieldDatatype || ''}
                            onSelect={setActiveMergeBehaviorFieldDatatype}
                        />
                        <Form.Text>Your summarization options depend on the data type of this column.</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>How should we summarize the data in this column?</Form.Label>
                        <SummaryOptionPicker
                            disableGroupBySelection
                            fieldType={activeMergeBehaviorFieldDatatype}
                            aggregator={activeMergeBehaviorFieldAggregator}
                            onChangeAggregator={setActiveMergeBehaviorFieldAggregator}
                        />
                    </Form.Group>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={onSaveMergeBehaviorField}>
                    Save
                </button>
            </Modal.Footer>
        </Modal>
        <Modal show={!!activeColumnForFieldMapping} onHide={() => setActiveColumnForFieldMapping(undefined)}>
            <Modal.Header closeButton>
                <Modal.Title>Column Data: {activeColumnForFieldMapping?.label}</Modal.Title>

            </Modal.Header>
            <Modal.Body>
                {activeColumnForFieldMapping && <>
                    <div className="fw-bold">
                        <Form.Check
                            type="switch"
                            label="Calculated Column"
                            checked={activeColumnAdvancedMode}
                            onChange={(e) => setActiveColumnAdvancedMode(e.target.checked)}
                        />
                    </div>
                    
                    <Form.Text>
                        Calculated columns allow you to write formulas based on the values in other columns. This can be useful for combining columns, performing calculations, or creating new columns based on existing data.
                    </Form.Text>
                    <hr />
                    {!activeColumnAdvancedMode && <>


                        <PipelineNodeFieldMap
                            showPriority={false}
                            mapOptions={activeColumnMapOptions}
                            onChangeMapOptions={setActiveColumnMapOptions}
                            displayJoinPath={false} 
                            isUnlocked={true}
                            sourceNodes={upstreamNodes}

                        />
                    </>}
                    {activeColumnAdvancedMode && <>
                        <Form.Label>Formula</Form.Label>
                        <Form.Control className="font-code text-13" ref={customFormulaRef} defaultValue={activeColumnFormula} as="textarea"  />
                        <Form.Text>Write a custom formula in Snowflake SQL. You can use any value that is mapped to this node by wrapping the column name in double quotes.</Form.Text>
                                
                    </>}
                </>}
                
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={saveMappingColumn}>
                    Save
                </button>
            </Modal.Footer>
        </Modal>
        <FullScreenTakeover noCloseButton show={!!mappingSourceId} onHide={() => setMappingSourceId('')}>
            <Allotment ref={allotmentRef} vertical onChange={allotmentResized}>
                <Allotment.Pane minSize={200}>
                    <div className="p-4 pt-2" style={{height: '100%'}}>
                        <div className="d-flex center-vertically" style={{height: '70px'}}>
                            <h2 className="flex-1 mb-0"><span className="fw-normal">Mapping data from </span><PipelineNodeName pipelineNodeId={mappingSourceId}/></h2>
                            <button className="btn btn-secondary" onClick={() => setMappingSourceId('')}>Done</button>
                        </div>
                       
                        
                        <div style={{height: 'calc(100% - 70px)'}}>
                            <PipelineNodeDragAndDropMapping
                                sourceNodeId={mappingSourceId}
                                destinationNodeId={props.node.id as string}
                                fields={props.node.fields}
                                onChange={(newFields) => {
                                    console.log('Setting fields to', newFields);
                                    props.onChange(draft => {
                                        draft.fields = newFields;
                                    });
                                }}
                            />
                        </div>
                        
                        
                    
                    </div>
                </Allotment.Pane>
                <Allotment.Pane minSize={30} preferredSize={30}>
                    <DataPaneHeader onClick={() => toggleDataViewer()}>
                        <h2>View Data from <strong><PipelineNodeName pipelineNodeId={mappingSourceId}/></strong></h2>
                            <button>
                                {dataViewerIsOpen && <i className="mdi mdi-chevron-down"></i>}
                                {!dataViewerIsOpen && <i className="mdi mdi-chevron-up"></i>}
                            </button>
                    </DataPaneHeader>
                    <PipelineNodeDataTable pipelineNodeId={mappingSourceId}/>
                </Allotment.Pane>
            </Allotment>
            
        </FullScreenTakeover>
        <PipelineNodeColumnCleanerUpper
            field={activeField}
            isCalculated={activeField?.advanced_mode}
            show={showColumnEditor}
            onChange={onUpdateActiveField}
            onClose={() => {
                setActiveFieldId('');
                setShowColumnEditor(false);
            }}
            sourcePipelineNodeId={activeField?.map_options && activeField.map_options.length == 1 && activeField.map_options[0].source_node_id ? activeField.map_options[0].source_node_id : props.node.id as string}
            sourceFieldId={activeField?.map_options && activeField.map_options.length == 1 && activeField.map_options[0].attribute_id ? activeField.map_options[0].attribute_id : (activeField?.id || '')}
        />
        
        <Modal size="lg" show={!!activeFieldForTranslations} onHide={() => setActiveFieldForTranslations(undefined)}>
            {activeFieldForTranslations && (
                <PipelineNodeFieldTranslation
                    pipelineNodeId={props.node.id as string}
                    fieldId={activeFieldForTranslations.id}
                />
            )}
            
        </Modal>
        <PipelineNodeColumnDrawer
            pipelineNodeId={activeColumnPipelineNodeId}
            column={activeColumn}
            onHide={() => {
                setActiveColumnPipelineNodeId('');
                setActiveColumn(undefined);
            }}
            show={!!activeColumn && !!activeColumnPipelineNodeId}
        />
        <>
            
            
                    <UnlockedNodeRequired pipelineNode={props.node as PipelineNode}>
                        <div className="card mb-3">
                            <div className="card-body">
                            <PipelineNodeBasicConfiguration node={props.node} onChange={props.onChange}/>

                            </div>
                        </div>
                    </UnlockedNodeRequired>
            
                    <UnlockedNodeRequired pipelineNode={props.node as PipelineNode}>
                        <div className="card mb-3">
                            <div className="card-body">
                            <div className="mb-3">
                            <div className="d-flex center-vertically mb-1">

                                <h2 className="mb-0 flex-1">
                                    <i className="mdi mdi-transit-connection-variant"></i> Connected Sources ({props.node.upstream_node_ids.length})
                                </h2>
                                <DraftOnly>
                                    {(!props.limitToSingleSource || props.node.upstream_node_ids.length == 0) && <>
                                        <Link to={`/wizard/data-source?next=cleaning&bo_context=${props.node.id}`} className="btn btn-sm btn-outline-primary">
                                            <i className="mdi mdi-plus-circle"></i> New Connection
                                        </Link>
                                    </>}
                                    
                                </DraftOnly>
                                
                            </div>
                            <p className="mb-0">Map data from connected sources to columns in your node. This node type allows you to connect <strong>{props.limitToSingleSource ? 'one source' : 'multiple sources'}</strong>.</p>  
                        </div>
                        <Grid>
                            {props.node.upstream_node_ids.map((upstreamNodeId: string, idx) => {
                                const hasFilters = props.node.upstream_pre_filters && props.node.upstream_pre_filters.hasOwnProperty(upstreamNodeId) && props.node.upstream_pre_filters[upstreamNodeId].entries.length > 0;
                                return <div key={idx} className="shadow-box p-3">
                                    <div className="d-flex">
                                        <div className="flex-1">
                                            <h3><PipelineNodeName pipelineNodeId={upstreamNodeId} link/></h3>
                                            <p>
                                                <ReadOnly>
                                                    <a role="button" onClick={() => {
                                                        openMapColumnsModal(upstreamNodeId);
                                                    }}><strong>{mappedFieldsBySourceNode[upstreamNodeId] ? mappedFieldsBySourceNode[upstreamNodeId].length : '0'}</strong> out of <strong>{sourceNodeFieldCount[upstreamNodeId]}</strong> columns mapped</a>
                                                </ReadOnly>
                                            </p>
                                            <DraftOnly>
                                                <button className="btn btn-xs btn-outline-secondary" onClick={() => {
                                                    openMapColumnsModal(upstreamNodeId);
                                                }}>
                                                    <i className="mdi mdi-table-column-plus-after"></i> Map
                                                </button>
                                                <button className="btn btn-xs btn-outline-danger ms-1" onClick={() => {
                                                    removeUpstreamNode(upstreamNodeId);
                                                }}>
                                                    <i className="mdi mdi-connection"></i> Disconnect
                                                </button>
                                            </DraftOnly>
                                        </div>
                                        <div>
                                            <button title="Edit pre-filters" className={`icon-button font-36 ${hasFilters ? 'text-pliable' : ''}`} onClick={() => {
                                                setActiveUpstreamNodeIdForPreFilters(upstreamNodeId);
                                                setRanWhitelistTest(false);
                                                setWhitelistTotalRecords(0);
                                            }}>
                                                <i className="mdi mdi-filter"></i>
                                            </button>
                                        
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            })}
                        </Grid>
                            </div>
                        </div>
                        
                        
                    </UnlockedNodeRequired>
                    {props.node.managed_by_template_id && (
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="d-flex center-vertically mb-1">
                                    <h2 className="mb-0 flex-1">
                                        <i className="mdi mdi-image-frame"></i> Templates
                                    </h2>
                                </div>
                                <p>This node is managed by the template
                                    <strong> {templateName}</strong>
                                </p>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="switch"
                                        label="Unlock node"
                                        checked={props.node.unlocked}
                                        disabled={!inDraftMode}
                                        onChange={(e) => {
                                            props.onChange(draft => {
                                                draft.unlocked = e.target.checked;
                                            });
                                        } } />
                                    <Form.Text className="text-muted">
                                        By unlocking this node, you wil be able to make whatever changes you'd like, but you wil no longer receive updates if there are changes in the original template. <i className="mdi mdi-information-outline"></i>
                                    </Form.Text>
                                </Form.Group>
                            </div>
                        </div>)}
                    <div className="card mb-3">
                        <div className="card-body">
                        <div className="mb-3">
                            <div className="d-flex center-vertically mb-1 justify-content-between">            
                                <h2 className="mb-0"><i className="mdi mdi-cog"></i> Configure Columns</h2> 
                                <div className="pe-5" style={{width: '40%'}}>
                                    <input type="text" className="search-input" value={boSearchInput} onChange={(e) => setBoSearchInput(e.target.value)} placeholder="Search columns"/>
                                </div>
                                <DraftOnly>
                                    <button className="btn btn-outline-primary btn-sm" onClick={() => {
                                        addNewFieldAtTop();
                                    }}>
                                        <i className="mdi mdi-plus-circle"></i> Add Column
                                    </button>
                                </DraftOnly>
                                
                            </div>
                        </div>
                            
                        <DragDropContext onDragEnd={onColumnReorder}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <table
                                        ref={provided.innerRef}
                                        className="table table-centered table-fixed w-100"
                                        
                                    > 
                                        <thead className="bg-light">
                                            <tr>
                                                <th style={{width: columnWidths[0]}}></th>
                                                {['DATE_DIMENSION', 'DIMENSION'].includes(props.node.node_type) && <th style={{width: columnWidths[1]}}></th>}
                                                
                                                <th style={{width: columnWidths[2]}}>Name</th>

                                                <th style={{width: columnWidths[3]}}>Data</th>
                                                <th style={{width: columnWidths[4]}}>Formatting</th>
                                                {props.node.node_type == 'SUMMARIZE' && <>
                                                    <th style={{width: columnWidths[5]}}>Aggregation</th>
                                                    <th style={{width: columnWidths[6]}}></th>
                                                </>}
                                                {props.node.node_type != 'SUMMARIZE' && <>
                                                    <th style={{width: columnWidths[5]}}></th>
                                                </>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {allFields.map((f, idx) => {
                                            return <Draggable key={f.id} draggableId={f.id} index={idx} isDragDisabled={isDraggingDisabled}>
                                                
                                                {(draggableProvided, snapshot) => {
                                                    if (draggableProvided.draggableProps.style) {
                                                        var transform = draggableProvided.draggableProps.style?.transform
                                                        if(transform){
                                                            var t = transform.split(",")[1]
                                                            draggableProvided.draggableProps.style.transform = "translate(0px," + t
                                                        }
                                                    }
                                                    
                                                    return <tr 
                                                        ref={draggableProvided.innerRef} 
                                                        {...draggableProvided.draggableProps} 
                                                        // @ts-ignore
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            draggableProvided.draggableProps.style
                                                        )}
                                                        
                                                        className="hover-control"

                                                        
                                                    >
                                                        <td style={{width: columnWidths[0]}}>
                                                            {!isDraggingDisabled && <>
                                                                <div {...draggableProvided.dragHandleProps} className="font-24">
                                                                    <i className="mdi mdi-drag"></i>
                                                                </div>
                                                            </>}
                                                            
                                                        </td>
                                                        {['DATE_DIMENSION', 'DIMENSION'].includes(props.node.node_type) && <td style={{width: columnWidths[1]}}>
                                                            <DraftOnly>
                                                                <button className={`icon-button font-24 ${f.is_dimension ? 'text-pliable' : ''}`} title="Toggle dimension" onClick={() => {
                                                                    toggleFieldDimension(f.id);
                                                                }}>
                                                                    <i className="mdi mdi-label"></i>
                                                                </button>
                                                            </DraftOnly>
                                                            
                                                        </td>}
                                                        <td style={{width: columnWidths[2]}}>
                                                            <div className="d-flex center-vertically">
                                                                
                                                                <div>
                                                                    <strong>
                                                                        <EditableText
                                                                            value={f.label}
                                                                            onChange={(newVal) => {
                                                                                props.onChange(draft => {
                                                                                    draft.fields[idx].label = newVal;
                                                                                    
                                                                                });
                                                                            }}
                                                                        />
                                                                    </strong>
                                                                    <div className="small">
                                                                        <EditableText
                                                                            value={f.description}
                                                                            onChange={(newVal) => {
                                                                                props.onChange(draft => {
                                                                                    draft.fields[idx].description = newVal;
                                                                                    
                                                                                });
                                                                            }}
                                                                            emptyPlaceholder="No Description"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </td>
                                                        <td style={{width: columnWidths[3]}}>
                                                            <ReadOnly>
                                                                <div className="clickable" onClick={() => {
                                                                    setActiveColumnForFieldMapping(f);
                                                                    setActiveColumnMapOptions(f.map_options);
                                                                    setActiveColumnFormula(f.custom_sql || '');
                                                                    setActiveColumnAdvancedMode(f.advanced_mode || false);
                                                                }}>
                                                                    {f.part_of_composite_key && (!f.map_options || (f.map_options.length != props.node.upstream_node_ids.length && props.node.combine_logic_gate == 'AND')) && <>
                                                                        <Badge bg="danger" className="me-1">Missing mappings</Badge>
                                                                    </>}
                                                                    {!f.map_options.length && !f.advanced_mode && !f.part_of_composite_key && (
                                                                        <Badge bg="warning">No mapped columns</Badge>
                                                                    )}
                                                                    {!f.advanced_mode && f.map_options.length >= 1 && (
                                                                        <Badge bg="info">{f.map_options.length} mapped column{f.map_options.length > 1 ? 's' : ''}</Badge>
                                                                    )}
                                                                    {f.advanced_mode && (
                                                                        <Badge bg="dark"><i className="mdi mdi-code-braces"></i> Calculated</Badge>
                                                                        
                                                                    )}
                                                                </div>
                                                            </ReadOnly>
                                                            
                                                            {/* <DraftOnly>
                                                                <button className="btn btn-light btn-sm ms-1" title="Edit" onClick={() => {
                                                                    setActiveColumnForFieldMapping(f);
                                                                    setActiveColumnMapOptions(f.map_options);
                                                                    setActiveColumnFormula(f.custom_sql || '');
                                                                    setActiveColumnAdvancedMode(f.advanced_mode || false);
                                                                }}>
                                                                    <i className="mdi mdi-pencil"></i> Edit
                                                            </button>
                                                            </DraftOnly> */}
                                                        </td>
                                                        <td style={{width: columnWidths[4]}}>
                                                            <PipelineNodeFieldCleaningBadges
                                                                datatype={f.type}
                                                                transform={f.advanced_mode ? '' : f.transformer}
                                                                onEdit={() => {
                                                                    setActiveFieldId(f.id);
                                                                }}
                                                            />
                                                        </td>
                                                        
                                                        {props.node.node_type == 'SUMMARIZE' && <>
                                                            <td style={{width: columnWidths[5]}}>
                                                                {f.part_of_composite_key && (
                                                                    <Badge pill bg="pliable">Group By</Badge>
                                                                )}
                                                                {!f.part_of_composite_key && (
                                                                    <Badge className="clickable" pill bg="primary" onClick={() => {
                                                                        editFieldMergeBehavior(f);
                                                                    }}>{f.merge_behavior || 'PICK_ONE'}</Badge>
                                                                )}
                                                            </td>
                                                            <td className="text-end font-18" style={{width: columnWidths[6]}}>
                                                                <DraftOnly>
                                                                    <UnlockedFieldRequired pipelineNodeFields={[f]}>
                                                                        <button onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            onDeleteField(f)
                                                                        }} className="icon-button">
                                                                            <i className="mdi mdi-delete"></i>
                                                                        </button>
                                                                    </UnlockedFieldRequired>
                                                                </DraftOnly>
                                                            </td>
                                                        </>}
                                                        {props.node.node_type != 'SUMMARIZE' && <>
                                                            <td className="text-end font-18" style={{width: columnWidths[5]}}>
                                                                
                                                                <DraftOnly>
                                                                    <UnlockedFieldRequired pipelineNodeFields={[f]}>
                                                                        <button onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            onDeleteField(f)
                                                                        }} className="icon-button">
                                                                            <i className="mdi mdi-delete"></i>
                                                                        </button>
                                                                    </UnlockedFieldRequired>
                                                                </DraftOnly>
                                                            </td>
                                                        </>}
                                                        
                                                    </tr>
                                                    }}
                                            </Draggable>
                                        })}
                                        {provided.placeholder}
                                        </tbody>
                                        
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext>  
                        </div>
                    </div>
                              
                    {/* <UnlockedNodeRequired pipelineNode={props.node as PipelineNode}>
                        <div className="card">
                            <div className="card-body">
                                <div className="mb-3">
                                    <div className="d-flex center-vertically mb-1">
                                        <h2 className="mb-0 flex-1">
                                            <i className="mdi mdi-transit-connection-variant"></i> Data Whitelisting
                                        </h2>
                                    </div>
                                    <p className="mb-0">Whitelisting allows you to filter the data that is brought in to this node. This can be useful for reducing the amount of data that is processed, or for ensuring that only the data you need is included.</p>  
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Pre-Filters</Form.Label>
                                    <Form.Select
                                        value={activeUpstreamNodeIdForPreFilters}
                                        onChange={(e) => setActiveUpstreamNodeIdForPreFilters(e.target.value)}
                                    >
                                        <option value="">Select a source</option>
                                        {props.node.upstream_node_ids.map((upstreamNodeId: string, idx) => {
                                            return <option key={idx} value={upstreamNodeId}>
                                                {pipelineNodes.data?.find(pn => pn.id === upstreamNodeId)?.name}
                                            </option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                    </UnlockedNodeRequired> */}
                    <UnlockedNodeRequired pipelineNode={props.node as PipelineNode}>
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="d-flex center-vertically mb-1">
                                    <h2 className="mb-0 flex-1">
                                        <i className="mdi mdi-set-merge"></i> Grouping Behavior
                                    </h2>
                                </div>
                                <p>Grouping behavior determines how records are combined when they have the same values in the selected columns.</p>


                                {props.node.node_type == 'DIMENSION' && <>
                                    <InfoAlert><div>
                                        <strong>Entities</strong> always use the <strong>MERGE</strong> grouping behavior. This means that records with the same values in the selected columns will be combined across all connected data sources. If you want to use a different grouping behavior, you can do so upstream in a cleaning step.    
                                    </div></InfoAlert>
                                </>}
                                <GroupingBehaviorForm
                                    emptyOK={props.node.node_type == 'DIMENSION'}
                                    disabled={props.node.node_type == 'DIMENSION'}
                                    groupingType={groupingType}
                                    setGroupingType={(newType: string) => {
                                        props.onChange(draft => {
                                            draft.node_type = newType || 'STACK';
                                        });
                                    }}
                                    dupeLogicGate={props.node.combine_logic_gate || 'AND'}
                                    setDupeLogicGate={(newGate: string) => {
                                        props.onChange(draft => {
                                            draft.combine_logic_gate = newGate;
                                        });
                                    }}
                                    dupeColumnNames={props.node.fields.filter(f => f.part_of_composite_key).map(f => f.label)}
                                >

                                    {['DIMENSION', 'MERGE', 'IDENTIFY', 'SUMMARIZE'].includes(props.node.node_type) && <>
                                        <Form.Group className="mt-3">
                                            <Form.Label>Which column(s) do you want to use to group this data?</Form.Label>
                                            <Select
                                                isDisabled={!inDraftMode}
                                                isMulti={true}
                                                value={dedupeFields.map(id => {
                                                    const field = props.node.fields.find(f => f.id === id);
                                                    return field ? { key: field.id, label: field.label, value: field.id } : { key: id, label: id, value: id };
                                                })}
                                                onChange={handleDedupeChange}
                                                options={allFields.map((f) => ({
                                                    key: f.id,
                                                    label: f.label,
                                                    value: f.id
                                                }))}
                                            />
                                        </Form.Group>
                                    </>}
                                </GroupingBehaviorForm>
                                
                                
                                

                            </div>
                        </div>
                        
                        <div className="card mb-3">
                            <div className="card-body">
                                <PipelineNodeWhitelistConfiguration node={props.node} onChange={props.onChange}/>

                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <PipelineNodeOutputConfiguration node={props.node} onChange={props.onChange}/>

                            </div>
                        </div>
                        <div className="mb-5"></div>
                        {/* <hr />
                        <h2>Output Settings</h2>       */}
                    </UnlockedNodeRequired>
            </>
        
        
        
    </div>
    
}


export default PipelineNodeMappingConfigForm;
