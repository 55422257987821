import styled from "styled-components";

interface BigSelectorComponentProps {
    title: string;
    description: string;
    icon?: string;
    selected?: boolean;
    onClick: () => any;
}

const BigSelectorComponentStyles = styled.div`
.shadow-box:hover {
    outline: solid 2px var(--ct-info-bg);
    cursor: pointer;
}

.shadow-box {

    height: 120px;

    display: flex;
    align-items: center;
    div.text {
        flex: 1;

        p {
            margin-bottom: 0px;
        }
    }


    div.icon {
        font-size: 72px;
        color: #ccc;
        padding-right: 20px;
    }

    &.selected .icon {
        color: var(--pliable-yellow);
    }
}

.shadow-box.selected {
    outline: solid 2px var(--pliable-blue);
}
`

export const BigSelectorComponent = (props: BigSelectorComponentProps) => {
    return <BigSelectorComponentStyles onClick={props.onClick}>
        
        <div className={`shadow-box p-3 ${props.selected ? 'selected' : ''}`}>
            <div className="icon">
                <i className={props.icon}/>
            </div>
            <div className="text">
                <h4>{props.title}</h4>
                <p>{props.description}</p>
                {props.selected}
            </div>
            
            
        </div>
    </BigSelectorComponentStyles>
}

export const Grid = styled.div`
display: grid;
gap: 20px;
grid-template-columns: repeat(3, 1fr);
`