import PageStructure, { PageContent, PageContentHeader, PageContentInner } from "@pages/PageStructure.component"
import { useLocation } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRouteBlocker } from "@services/routing.service";
import styled from "styled-components";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import { getPromptAnswer } from "@services/alert/alert.service";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";


const CronWrapper = styled.div`
    border:solid 1px var(--ct-border-color);
    padding: 1rem;
    border-radius: .25rem;

    .react-js-cron-field {
        margin-bottom: 0px !important;
    }
`
const BackOfficePage = () => {
    const location = useLocation();
    const lastHash = useRef('');

    const [resetLink, setResetLink] = useState<string | null>(null);


    const { pageDirty, setPageDirty } = useRouteBlocker(() => {

    });


    const beginGetActivationLink = useCallback(async () => {
        const email = await getPromptAnswer('Enter Email', 'New Activation Link');

        if (!!email) {
            try{
                setResetLink(null);
                const resp = await ApiService.getInstance().request('POST', `/tenant-config/reset-activation-link`, {
                    email
                }) as SingleRecordResponse<any>;
                console.log(resp);
                setResetLink(resp.record.link);
            }catch(e){
                toast('danger', 'Error', `Failed to reset activation link: ${getErrorMessage(e)}`);
            }
        }



    }, []);

    return <PageStructure>
        <PageContent>
            <PageContentHeader>
                <div>
                    <DashboardIcon bgColor="dark" icon="mdi mdi-cog"/>
                    <h1 className="mb-0">Admin</h1>
                </div>
            </PageContentHeader>
            <PageContentInner hasHeader>
                <div className="p-4">
                    <div className="card">
                        <div className="card-body">
                            <button className="btn btn-primary" onClick={beginGetActivationLink}>Reset Activation Link</button>
                            {resetLink && <div className="mt-2">
                                {resetLink}
                            </div>}
                        </div>
                    </div>
                    <hr />
                </div>
            </PageContentInner>
        </PageContent>
    </PageStructure>
}

export default BackOfficePage;