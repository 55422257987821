import { Option } from "@components/form/Dropdown.component";

export const getAggregationOptionsForFieldType = (fieldType: string, vizOnly: boolean = false) => {
    const options: Option[] = [{
        value: 'COUNT_DISTINCT',
        label: 'Count Distinct',
    }];
    
    if (!vizOnly) {
        options.push({
            value: 'PICK_ONE',
            label: 'Random',
        });
    }

    switch (fieldType) {
        case 'DATE':
        case 'DATETIME':
        case 'DATETIME_TZ':
            options.push({
                value: `MIN`,
                label: 'Min'
            });
            options.push({
                value: `MAX`,
                label: 'Max',
            });
            break;
        case 'INT':
        case 'DECIMAL':
            options.push({
                value: `MIN`,
                label: 'Min',
            });
            options.push({
                value: `MAX`,
                label: 'Max',
            });
            options.push({
                value: `AVG`,
                label: 'Avg'
            });
            options.push({
                value: `SUM`,
                label: 'Sum'
            });
            break;
        case 'STRING':
            if (!vizOnly) {
                options.push({
                    value: 'CONCAT',
                    label: 'Comma-separated list of unique values',
                });
            }
            
            break;
    }
    return options;
}