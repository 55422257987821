import ApiService from "@services/api/api.service";
import { useQueryParams } from "@services/url.service";
import { invalidateSourceRecordTypes, invalidateSources } from "@stores/data.store";
import { useCallback, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const SourceFinishFivetranPage = () => {
    const { sourceId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const continueConnecting = searchParams.get('continue') == 'true';


    const query = useQueryParams();
    const navigate = useNavigate();

    const connect = useCallback(async (sourceId: string, fivetranId: string) => {
        const result = await ApiService.getInstance().request('POST', `/sources/${sourceId}/fivetran-connect/${fivetranId}`)
        invalidateSources();
        invalidateSourceRecordTypes();
        if (continueConnecting) {
            navigate('/account/authorize-sources');
        }else{
            navigate('/source/' + sourceId);
        }
        
    }, []);

    useEffect(() => {
        if (sourceId && query && query.get('id')) {
            connect(sourceId, query.get('id') as string);
        }
    }, [query, sourceId]);

    return <Spinner/>
}

export default SourceFinishFivetranPage;