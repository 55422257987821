import { recursiveGetNodesAndEdges } from "@pages/Dag.page";
import { MissionControlDataFlowEdge, MissionControlDataFlowNode } from "@stores/data.store";
import { Connection, Edge, Node } from "reactflow";

export const isValidConnection = (connection: Connection, nodes: Node[], edges: Edge[]) => {
    // Nodes must be 2 business object, or a business objet and srt
    try {
        if (connection.source == connection.target) {
            return false;
        }
    
        if (connection.source?.startsWith('SourceRecordType:')) {
            if (!connection.target?.startsWith('BusinessObject:')) {
                return false;
            }
        }

        if (connection.source?.startsWith('DataMart:') || connection.target?.startsWith('DataMart:')) {
            return false;
        }
    
        const existingEdge = edges.find(e => {
            return (e.target == connection.target && e.source == connection.source) || (e.target == connection.source && e.source == connection.target); 
        });
    
        if (existingEdge) {
            return false;
        }
    
        return true;
    } catch (err) {
        console.error(err);
    }

    return false;
    
}

export const getImmediateUpstreamNodes = (
    thisNode: MissionControlDataFlowNode, 
    allNodes: MissionControlDataFlowNode[], 
    allEdges: MissionControlDataFlowEdge[]
) => {
    let nodes: MissionControlDataFlowNode[] = [];
    let edges: MissionControlDataFlowEdge[] = [];

    allEdges.forEach(e => {
        if (e.target == thisNode.id && ['MAPPING', 'DIMENSION', 'MEASURE'].includes(e.data.type)) {
            const relatedNode = allNodes.find(n => n.id === e.source);
            if (relatedNode) {
                nodes.push(relatedNode);
                edges.push(e);
            }
        }
    });

    return [nodes, edges];
}

export const getAllRelatedNodeIds = (
    thisNode: MissionControlDataFlowNode,
    allNodes: MissionControlDataFlowNode[],
    allEdges: MissionControlDataFlowEdge[],
    typeFilter?: (nt: string) => boolean,
) => {
    const [nodesDownstream] = recursiveGetNodesAndEdges(thisNode, allNodes, allEdges, [thisNode.id], [], 'source');
    const [nodesUpstream] = recursiveGetNodesAndEdges(thisNode, allNodes, allEdges, [thisNode.id], [], 'target');
    const combined = [...nodesDownstream, ...nodesUpstream];
    return combined.filter(c => {
        if (typeFilter) {
            return typeFilter(c.data.nodeType);
        }
        return true;
    }).map(c => c.id.split(':')[1]);

}