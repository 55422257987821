import AsyncButton from "@components/button/AsyncButton.component";
import { BillingProduct } from "@models/billingAccount";
import { useBillingOptions } from "@stores/data.store";
import { string } from "prop-types";
import { useState } from "react";
import { Badge, Button, Card } from "react-bootstrap";

import styled, { css } from 'styled-components';


interface Props {
    billingOptions: BillingProduct[];
    hasPaymentDetails?: boolean;
    currentPlan?: string;
    onOptionSelected: (bp: BillingProduct) => void;
}

const Container = styled.div`

hr {
    margin: 15px 0;
}

a {
    font-size: 12px;
}

.plan-image {

}

`

const PlanCard = styled(Card)<{topColor: string}>`
${props => props.topColor && css`
    border-top: solid 10px var(--${props.topColor});
`}
`

const CorePlanPicker = (props: Props) => {
    const [planChoosen, setPlanChoosen] = useState(-1);

    const onBillingOptionClick = (idx: number, bp: BillingProduct) => {
        setPlanChoosen(idx);
        props.onOptionSelected(bp);
    }

    return (
    <Container>
        <div className="row">
            <div className="col-12 my-2">
                <h3>Choose a subscription plan.</h3>
                <p className="text-muted">You'll be asked for payment information once you select a plan.</p>
            </div>
        </div>
        <div className="row">
            {props.billingOptions?.map((option, idx) => {
                let color = 'ct-border-color';
                let thisIsThePlan = false;
                if (option.name === props.currentPlan) {
                    color = 'pliable-blue';
                    thisIsThePlan = true;
                }
                return <div className="col-4">
                    <PlanCard topColor={color}>
                        {/* <Card.Img variant="top" className="plan-image" src={(option.images.length) ? option.images[0] : ''} /> */}
                        <Card.Body>
                            <Card.Title>
                                <span className="fw-bold">{option.name}</span>
                                {thisIsThePlan && <Badge bg="success" className="ms-2">Current Plan</Badge>}
                                <div className="float-right">${option.prices[0].unit_amount/100}/month</div>
                            </Card.Title>
                            <Card.Text>
                                {option.description} - <a href="#" className="float-right"><i className="mdi mdi-information"></i> More Info</a>
                                <br /><br />
                                <ul style={{minHeight: '100px'}}>
                                    {option.marketing_features.map((feature) => {
                                        return <li>{feature.name}</li>

                                    })}
                                </ul>
                                
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            {thisIsThePlan && <AsyncButton 
                                variant="primary" 
                                loading={idx===planChoosen}
                                disabled={planChoosen !== -1}
                                text={`Change payment method`}
                                onClick={() => onBillingOptionClick(idx, option)}
                            />}
                            {!thisIsThePlan && <AsyncButton 
                                variant="pliable" 
                                loading={idx===planChoosen}
                                disabled={planChoosen !== -1}
                                text={`Choose this plan`}
                                onClick={() => onBillingOptionClick(idx, option)}
                            />}
                        </Card.Footer>
                    </PlanCard>
                </div>
            })}
        </div>
    </Container>
    );
}

export default CorePlanPicker;