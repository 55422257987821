import { PliableIcon } from "@components/icons/PliableIcons.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { SnowflakeIcon } from "@components/loaders/SnowflakeLoader.component";
import { NativeAppORM } from "@models/snowflake";
import AuthService from "@services/auth/auth.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { useQueryParams } from "@services/url.service";
import { useState } from "react";
import styled from 'styled-components';


const ApprovalStyles = styled.div`

.logos {
    .plb-logo {
        path {
            transform: scale(4);
        }
    }
}

`

export default function SnowflakeNativeAppAuthorization() {
    const queryParms = useQueryParams();
    const [authorizing, setAuthorizing] = useState(false);
    const tenantName = AuthService.getInstance().getTenantName();

    // const { data: authCode, isLoading, error } = useNativeAppAuthroizationReq();
    const isLoading = false;
    const error  = false;

    const handleGrantAccess = async () => {
        setAuthorizing(true);
        try{
            const resp = await NativeAppORM.authorize(
                queryParms.get('client_id') as string,
                queryParms.get('redirect_uri') as string,
                queryParms.get('response_type') as string,
                queryParms.get('scope') as string,
                queryParms.get('state') as string,
            );
            if(resp.uri){
                window.location.href = resp.uri;
            }
        }catch(ex){
            console.log(ex);
            toast('danger', 'Error', getErrorMessage(ex));
        }finally{
            setAuthorizing(false);
        }
    }
    
    const innerContent = () => {
        if (isLoading) {
            return (
                <div className="p-4 text-center">
                    <PliableLoader />
                </div>
            )
        }else if (error) {
            return (
                <div className="p-4 text-center">
                    Error
                </div>
            )
        }else{
            return (
                <>
                <div className="pt-4 text-center logos">
                    <PliableIcon height="110px" verticalAlign="middle" /> <div style={{display: 'inline-block', fontSize: '50px', padding: '20px'}}> <i className="mdi mdi-arrow-left-right"></i> </div>  <SnowflakeIcon />
                </div>
                <div className=" p-4">
                    <div className="text-center">
                        <h1 className="mt-4">Are you sure you would like to grant Snowflake access to your Pliable account:</h1> 
                        <h2 className="mt-3">{tenantName}</h2> 
                        <button className="btn btn-primary mt-4" onClick={handleGrantAccess}>Grant Access</button>    
                        
                    </div>
                </div>
                </>
            );
        }
    }

    return (
        <ApprovalStyles className="container">
            <div className="row justify-content-center">
            <div className="col-lg-5">
                <div className="card">
                    <div className="pt-4 pb-4 text-center bg-primary">
                        <h1 className="text-white">Grant Access to Snowflake native app</h1>
                    </div>

                    {innerContent()}

                </div>
            </div>
            </div>
        </ApprovalStyles>
    );
}