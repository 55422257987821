import PliableLoader from "@components/loaders/PliableLoader.component";
import { useColumnValueDistribution } from "@stores/data.store";
import { table } from "console";

interface Props {
    pipelineNodeId: string;
    fieldId: string;
}

/**
 * Displays a running list of common values
 * in a particular column
 * 
 */
const PipelineNodeColumnValues = (props: Props) => {
    const valueDistribution = useColumnValueDistribution(props.pipelineNodeId, props.fieldId, 250);

    if (valueDistribution.isLoading) {
        return <PliableLoader/>
    }
    return <table className="table">
        <thead className="bg-light">
            <tr>
                <th>Value</th>
                <th>Occurrences</th>
            </tr>
        </thead>
        <tbody>
            {valueDistribution.data?.map((value) => {
                return <tr key={value.value}>
                    <td>{value.value}</td>
                    <td>{value.total}</td>
                </tr>
            })}
        </tbody>
    </table>
}

export default PipelineNodeColumnValues;