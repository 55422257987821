import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const EmbeddedCheckoutForm = ({clientSecret}: any) => {
  const stripePromise = loadStripe("pk_test_51NfpGhFA852DSwuBNtu1b5RCYtT0dchOWxls1usoTN46Hgz6gR00XFfNRzIbzieQoicrWbBHij1iVkKVQIkWDw4f0089lmWgl3");

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret}} >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};

export default EmbeddedCheckoutForm;







