import { ReactNode } from "react";
import styled from 'styled-components';
import InfoStatusIndicator from "./InfoStatusIndicator.component";

const Styles = styled.div`
background-color: var(--pliable-blue-bg);
color: var(--pliable-blue);
border: solid 1px var(--pliable-blue);
border-radius: 5px;
padding: 1rem;
margin-bottom: 0.5rem;
font-family: "Poppins";


.icon-container {
    display: inline-block;
    margin-right: 1rem;
    width: 40px;
}

`
interface Props {
    children: ReactNode;
    noIcon?: boolean;
}

const InfoAlert = (props: Props) => {
    return <Styles>
        <div className="d-flex">
            {!props.noIcon && <div className="icon-container">
                <InfoStatusIndicator/>

            </div>}
            <div className="flex-1">
                {props.children}

            </div>
        </div>
        
    </Styles>
}

export default InfoAlert