import PageStructure, { PageContent, PageContentInner, PageSidebar, Pane, PaneContent,PaneContentWithSubnav } from "@pages/PageStructure.component";
import { usePipelineNode } from "@stores/data.store";
import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import FilesList from "@components/files/FilesList.component";
import { File } from '@models/file';
import DataSourceImporter from "@pages/Sources/DataSourceImporter.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { PipelineNodeORM } from "@models/pipelineNode";
import { alert, closeWaiting, confirmation, showWaiting } from "@services/alert/alert.service";
import { emit } from "@nextcloud/event-bus";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { useHotkeys } from "react-hotkeys-hook";

const PipelineNodeFilesPage = () => {
    const { pipelineNodeId } = useParams();
    const { isLoading, data: pipelineNode, refetch: refetchPipelineNode } = usePipelineNode(pipelineNodeId as string);
    const navigate = useNavigate();

    useHotkeys('ctrl+r', () => {
        confirmation({
            header: 'Reload all files?',
            message: 'Are you sure you want to reimport all files? This may take a minute or two.',
            confirmationButtonVariant: 'pliable',
            confirmationButtonText: 'Do it',
            onClose: () => {

            },
            onConfirm: async () => {
                showWaiting({message: 'Re-importing all files...'});
                await PipelineNodeORM.reimportAllSourceFiles(pipelineNodeId as string);
                closeWaiting();
            }
        })
    }, [pipelineNodeId]);

    const onSourceAdded = useCallback(async () => {
        
        alert('Success', 'File added successfully. Rebuild this source to get access to the new data.', 'success');
        // ANCIENT CODE - the files list needs to be refactored into a functional component and use react-query
        emit('ws:files:reload', {});

        // Hacky way to ensure it rebuilds
        await PipelineNodeORM.patch(pipelineNodeId as string, {
            marked_stale_at: (new Date()).toISOString(),
        });
    }, [pipelineNodeId]);

    const onFileDelete = useCallback(async (file: File) => {
        await PipelineNodeORM.deleteFile(pipelineNodeId as string, file.id as string);
        alert('Success', 'This file has been deleted and its data has been removed from Snowflake. Rebuild this source to get the updated data', 'success');
        refetchPipelineNode();
    }, [pipelineNodeId]);

    return <>
        <PipelineNodeSubnav
            pipelineNodeId={pipelineNodeId as string}
        >
        </PipelineNodeSubnav>
        <PageContentInner hasHeader>
            {!isLoading && <>
                <div className="p-4">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="mb-0">Uploaded Files</h2>
                            <p className="text-muted font-13">Manage files you've already uploaded for this source.</p>
                            <FilesList 
                                source_record_type_id={pipelineNode?.source_record_type_id}
                                pipelineNode={pipelineNode}
                                onDelete={onFileDelete}
                            />
                        </div>
                    </div>
                    <hr />
                    <DraftOnly>
                        <div className="card">
                            <div className="card-body">
                                <h2 className="mb-0">Add more data</h2>
                                <p className="text-muted font-13">
                                    Upload another file with the same headers to append its data. To replace the data, delete any previous files first, and then upload your new file.
                                </p>
                                <DataSourceImporter 
                                    onSourceAdded={onSourceAdded}
                                    sourceRecordTypeId={pipelineNode?.source_record_type_id}
                                />
                            </div>
                        </div>
                    </DraftOnly> 
                </div>
            </>}
            
            {isLoading && <PliableLoader />}
            
        </PageContentInner>
    </>
}

export default PipelineNodeFilesPage;