import { Editor } from "@monaco-editor/react";
import { useIsInDraftMode } from "@stores/data.store";
import { useCallback, useRef } from "react";

interface Props {
    code: string;
    onChange: (code: string) => void;
}

const DBTEditor = (props: Props) => {
    const editorRef = useRef<any>();

    const inDraftMode = useIsInDraftMode();

    const editorDidMount = useCallback((editor: any, monacoParam: any) => {
        editorRef.current = editor;
    }, []);
    return <>
        <Editor
            onChange={v => props.onChange(v ?? '')}
            height="100%"
            defaultLanguage="sql"
            options={{
                readOnly: !inDraftMode,
                minimap: { enabled: false },
            }}
            value={props.code}
            onMount={(editor, monaco) => editorDidMount(editor, monaco)}
        />
    </>;
}


export default DBTEditor;
