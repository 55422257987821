import { TableExplorer } from "@components/datatable/TableExplorer.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import BigMetricCard from "@components/metric/BigMetricCard.component";
import Danger from "@components/statusIndicators/Danger.component";
import { PipelineNodeCalculation, PipelineNodeMeasure } from "@models/pipelineNode";
import { FilterConfig } from "@models/pipelineNode";
import { Measure, useChartData, useVisualization } from "@models/visualization";

import { Pane, PaneContent } from "@pages/PageStructure.component";
import { getErrorMessage } from "@services/errors.service";
import { formatValue } from "@services/formatting.service";
import { usePipelineNode } from "@stores/data.store";

import { useCallback, useMemo, useState } from "react";
import Chart from 'react-apexcharts';
import { Modal } from "react-bootstrap";


interface Props {
    filter?: FilterConfig
    visualizationId: string;
    compact?: boolean;
    vizHeight?: string;
}

interface SelectionInfo {
    seriesIndex: number;
    dataPointIndex: number;
}

const VisualizationChart = (props: Props) => {
    const chartData = useChartData(props.visualizationId, props.filter);
    const visualization = useVisualization(props.visualizationId);
    const pipelineNode = usePipelineNode(visualization.data?.pipeline_node_id, !!visualization.data)

    const [drilldownSeries, setDrilldownSeries] = useState('');
    const [drilldownX, setDrilldownX] = useState('');
    const [showDrilldown, setShowDrilldown] = useState(false);

    const handleDataPointSelection = useCallback((event: any, chartContext: any, config: any) => {
        console.log(config);
        if (!chartData.data) {
            return;
        }

        const series = chartData.data.series[config.seriesIndex];
        const xAxis = chartData.data.x_axis_categories[config.dataPointIndex];
        
        setShowDrilldown(true);
        setDrilldownSeries(series.name);
        setDrilldownX(xAxis);

    }, [chartData.dataUpdatedAt]);

    const getFormatter = useCallback((measure: Measure) => {
        if (!pipelineNode.data?.measures) {
            return;
        }
        let formatter = pipelineNode.data.fields.find((f) => f.id == measure.field_id)?.formatter;
        return formatter;
    }, [pipelineNode.dataUpdatedAt]);

    const chartComponent = useMemo(() => {
        if (!visualization.data) {
            return <PliableLoader/>;
        }
        if (chartData.isLoading) {
            return <PliableLoader/>
        }
        if (!chartData.data || !visualization.data || !chartData.data.series.length) {
            return <>No data available.</>
        }
        if (chartData.isError) {
            return <Danger><div>{getErrorMessage(chartData.error)}</div></Danger>
        }
        if (visualization.data.chart_type == 'single_metric') {
            const theNumber = chartData.data.series[0].data[0];
            const formatter = getFormatter(visualization.data.measures[0]);
            
            const formattedNumber = formatValue({
                value: theNumber,
                format: formatter
            });

            return <BigMetricCard
                title=""
                metric={formattedNumber}
                loading={false}
                icon=""
            />;
        }
        
        const stroke = {
            width: visualization.data.chart_type == 'line' ? 3 : 1,
            // colors: visualization.data.chart_type == 'bar' ? ['#fff'] : undefined,
        }

        const options: ApexCharts.ApexOptions = {
            colors: [
                '#981c9c',
                '#1301ab',
                '#00a975',
                '#ab1521',
                '#ecd245',
                '#e46205',
                '#d96fe1',
                '#8991ff',
                '#e8ba9b',
                '#34f727',
                '#2573e8',

            ],
            xaxis: {
                categories: chartData.data.x_axis_categories,
                labels: {
                    show: !props.compact,
                },
                axisTicks: {
                    color: "#333"
                },
                axisBorder: {
                    color: "#333"
                }
            },
            // foreColor: "#fff",
            states: {
                active: {
                    allowMultipleDataPointsSelection: false,
                }
            },
            chart: {
                type: visualization.data.chart_type,
                stacked: !!visualization.data.stacked,
                events: {
                    dataPointSelection: handleDataPointSelection
                },
                animations: {
                    enabled: false
                }
            },
            // theme: {
            //     palette: 'palette1',
            //     // monochrome: {
            //     //     enabled: true,
            //     //     color: '#0e0252',
            //     //     shadeTo: 'light',
            //     //     shadeIntensity: 0.65,
            //     // }
            // },
            grid: {
                borderColor: "#ccc"
            },
            dataLabels: {
                enabled: false,
            },
            
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '85%',
                    // endingShape: 'rounded',
                }
            },
            legend: {
                show: !props.compact,
            },
            
            
            stroke: stroke,
            // theme: {
            //     mode: 'light',
            //     monochrome: {
            //         enabled: true,
            //         color: '#00A1E0',
            //         shadeTo: 'dark',
            //         shadeIntensity: 0.65,
            //     }
            // },
            fill: {
                // type: "gradient",
                // gradient: {
                //     gradientToColors: ["#FF9F00", "#0f0e31", "#00A1E0", "#68357A", "#1BD77D", "#EA4335"],
                //     shade: "dark",
                //     type: "vertical",
                //     shadeIntensity: 0.5,
                //     inverseColors: false,
                //     opacityFrom: 1,
                //     opacityTo: 0.8,
                //     stops: [0, 100]
                // }
                opacity: 1,
            },  
        };

        if (visualization.data.chart_type == 'pie') {
            options.labels = chartData.data.x_axis_categories;
        }

        if (chartData.isFetching) {
            return <PliableLoader/>
        }

        return <>
            <Chart
                options={options}
                series={chartData.data.series}
                type={visualization.data.chart_type}
                height={props.vizHeight}
            />
        </>

        

    }, [chartData.dataUpdatedAt, chartData.isError, chartData.error, visualization.dataUpdatedAt, pipelineNode.dataUpdatedAt, getFormatter, handleDataPointSelection]);

    return <div>
        <Modal size="xl" onHide={() => setShowDrilldown(false)} show={showDrilldown}>
            <Modal.Header closeButton>
                <Modal.Title>Drilldown Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{height: 'calc(60vh)'}}>
                <Pane>
                    <PaneContent>
                        {showDrilldown && drilldownSeries && drilldownX && <>
                            <TableExplorer
                                tablePath={`/visualizations/${props.visualizationId}/drilldown?series=${drilldownSeries}&x_axis=${drilldownX}`}
                            />
                        </>}
                    </PaneContent>
                </Pane>
                </div>
                
            </Modal.Body>
        </Modal>
        
        {chartComponent}
    </div>
}

export default VisualizationChart;
