import { usePipelineNode, usePipelineNodes } from "@stores/data.store";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "@services/toast.service";
import PageStructure, { PageContent, PageContentHeader, PageContentInner } from "@pages/PageStructure.component";
import SourceIcon from "@components/sources/SourceIcon.component";
import { NodeList } from "@components/pipelineNodes/PipelineNodeList.component";
import flatFileIcon from '@assets/images/connectors/flatfile.png';
import { useVisualizations } from "@models/visualization";
import PipelineNodeName from "@components/pipelineNodes/PipelineNodeName.component";
import { DashboardORM, useDashboards } from "@models/dashboard";
import { getErrorMessage } from "@services/errors.service";
import { ReadOnly } from "@components/project/DraftModeRequired.component";

const VisualizationsPage = () => {
    const vizes = useVisualizations();
    const dashboards = useDashboards();
    const navigate = useNavigate();

    const createNewDashboard = useCallback(async () => {
        try {
            const result = await DashboardORM.save({
                id: null,
                name: 'New Dashboard',
                description: '',
                components: [],
            });
            navigate(`/viz/dashboard/${result.id}`);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
    }, []);

    return <PageStructure>
        
        <PageContent>
        <PageContentHeader>
            <div>
                <SourceIcon image_url={flatFileIcon} />
                <div className="flex-1 ms-2">
                    <h1 className="mb-0">Visualizations</h1>
                    <div className="text-muted font-13">Create charts and dashboards using your data in Pliable</div>

                </div>
                <ReadOnly>
                    <Link className="btn btn-primary me-1" to={`/viz/chart/new`}>
                        <i className="mdi mdi-upload"></i> New Chart
                    </Link>
                    <button onClick={createNewDashboard} className="btn btn-primary">
                        <i className="mdi mdi-upload"></i> New Dashboard
                    </button>
                </ReadOnly>
            </div>
        </PageContentHeader>
        <PageContentInner hasHeader>
            <div className="p-4">
                <div className="row">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h2>Charts</h2>
                                {vizes.data && vizes.data.length == 0 && <>
                                    <div className="empty-state">
                                        <div className="text-center">
                                            <h3>You haven't created any charts yet.</h3>
                                            <p>It's easy to create simple charts using your data in Pliable. For more advanced options, you can connect any BI tool that can read from Snowflake.</p>
                                            <ReadOnly>
                                                <Link className="btn btn-primary me-1" to={`/viz/chart/new`}>Create a chart</Link>
                                                <button className="btn btn-light">Connect a BI Tool</button>
                                            </ReadOnly>
                                        </div>
                                    </div>
                                </>}
                                {vizes.data && vizes.data.length > 0 && <>
                                    <table className="table table-center table-bordered table-fixed">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name</th>
                                                <th>Type</th>
                                                <th>Source</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vizes.data.map(v => {
                                                return <tr>
                                                    <td>{v.name}</td>
                                                    <td>{v.chart_type}</td>
                                                    <td>
                                                        <PipelineNodeName pipelineNodeId={v.pipeline_node_id} />
                                                    </td>
                                                    <td className="text-end">
                                                        <Link to={`/viz/chart/${v.id}`}>View</Link>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </>}
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <h2>Dashboards</h2>
                                {dashboards.data && dashboards.data.length == 0 && <>
                                    <div className="empty-state">
                                        <div className="text-center">
                                            <h3>You haven't created any dashboards yet.</h3>
                                            <p>Dashboards allow you to combine one or more charts together with filters, markdown, and more.</p>
                                            <ReadOnly>
                                                <Link className="btn btn-primary" to={`/viz/dashboards/new`}>Create one now.</Link>
                                            </ReadOnly> 
                                        </div>
                                    </div>
                                </>}
                                {dashboards.data && dashboards.data.length > 0 && <>
                                    <table className="table table-center table-bordered table-fixed">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashboards.data.map(v => {
                                                return <tr>
                                                    <td>{v.name}</td>
                                                    <td className="text-end">
                                                        <Link to={`/viz/dashboard/${v.id}`}>View</Link>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </>}
                                
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </PageContentInner>
 
    </PageContent>
    </PageStructure>
}

export default VisualizationsPage;