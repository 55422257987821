import { PipelineNode } from "@models/pipelineNode";
import DataWhitelistForm from "../PipelineNodeDataWhitelist.component";
import { Updater } from "use-immer";

interface Props {
    node: PipelineNode;
    onChange: Updater<PipelineNode>;
}

const PipelineNodeWhitelistConfiguration = (props: Props) => {
    return <>
        <div className="mb-3">
            <div className="d-flex center-vertically mb-1">

                <h2 className="mb-0 flex-1">
                    <i className="mdi mdi-filter"></i> Post-Processing Filters (Optional)
                </h2>
                {/* <button className="btn btn-outline-primary btn-sm" onClick={() => {
                    addNewFieldAtTop();
                }}>
                    <i className="mdi mdi-plus-circle"></i> Add Filter
                </button> */}
            </div>
            <p>Add rules to filter out records from the resulting dataset.</p>
        </div>
        <DataWhitelistForm
            config={props.node.data_whitelist || {
                entries: [],
                logic_gate: 'AND',
            }}
            nodeIds={[props.node.id as string]}
            onChange={(config) => {
                props.onChange(draft => {
                    draft.data_whitelist = config;
                })
            }}
        />
    </>
}

export default PipelineNodeWhitelistConfiguration