import { useIsInDraftMode } from "@stores/data.store";
import produce from "immer";
import { useCallback, useMemo, useState } from "react";
import { DragDropContext, Droppable, Draggable, DragUpdate, DropResult } from "react-beautiful-dnd";
import styled from 'styled-components';
import { PipelineNode, PipelineNodeMapOption, useNodeColumnLabeler } from "@models/pipelineNode";
import { shortid } from "@services/id.service";
import PipelineNodeName from "../PipelineNodeName.component";
import PipelineNodeJoinPathLabel from "./PipelineNodeJoinPathLabel.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { Form } from "react-bootstrap";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import Warning from "@components/statusIndicators/Warning.component";


/**
 * DISCUSSION SUMMARY
 * 
 * If no interaction within a few seconds, create some random edges and animate them to show that you can connect
 * 
 * If they check the box to "use this field to merge records", then we have rules around combining attributes from different sources, and we can show an error
 */
const SingleMapOptionStyles  = styled.div`
    border-radius: 5px;
    padding: 12px 12px 12px 37px;
    display: block;
    user-select: none;



    &.combine-target {
        background-color: var(--ct-light-gray);
    }

`

const SingleMapOptionText = styled.div`
font-family: "Fira Code", monospace;
    span.attribute {
        font-weight: bold;
        color: white;
    }
`


interface Props {
    mapOptions: PipelineNodeMapOption[];
    showPriority: boolean;
    onChangeMapOptions: (newOptions: PipelineNodeMapOption[]) => void
    displayJoinPath?: boolean;
    isUnlocked: boolean;

    // If specified, the user can add additional columns from this list.
    sourceNodes?: PipelineNode[];
}

const mapOptionId = (mo: PipelineNodeMapOption) => {
    return `mo|srt|${mo.source_node_id}|attr|${mo.attribute_key}`;
}


interface MapOptionWrapperProps {
    mapOption: PipelineNodeMapOption;
    isCombineTarget: boolean;
    showPriority: boolean;
    isUnlocked: boolean;
    onSeparate: (separatingFrom: string, separating: PipelineNodeMapOption) => void;
    onChangeSeparator: (parentMoId: string, separator: string) => void;
    index?: number;
    onDelete: () => void;
    allowDrag?: boolean;
    displayJoinPath?: boolean;
}

interface SingleMapOptionProps {
    mapOption: PipelineNodeMapOption;
    isCombineTarget: boolean;
}

const DndContainer = styled.div`

`



const MultiMapOptionStyles = styled.div`
    border-radius: 5px;
    padding: 12px 12px 12px 37px;
    user-select: none;

    div.combine-with {
        font-family: "Poppins";
        font-size: 14px;
        text-transform: uppercase;
        margin-top: 5px;
    }

    &.combine-target {
        background-color: var(--ct-light-gray);
    }

    div.option {
        &:hover {
            background-color: black;
        }

        display: flex;
        .text-container {
            flex: 1;
        }
        button.separate-button {
            background: none;
            border: none;
            color: var(--ct-body-color);

            &:hover {
                color: white;
            }
        }
    }

    
`




const SeparatorSelector = styled.select`
    border: solid 1px var(--ct-border-color);
    margin-left: .5rem;
    color: var(--ct-body-color);
`

interface MultiMapOptionProps {
    mapOptionId: string;
    mapOptions: PipelineNodeMapOption[];
    combinationRule: string;
    concatJoiner: string;
    onSeparate: (separatingFrom: string, mo: PipelineNodeMapOption) => void;
    isCombineTarget: boolean;
    onChangeSeparator: (separator: string) => void;
}
const MultiMapOption = (props: MultiMapOptionProps) => {
    const labelColumn = useNodeColumnLabeler();
    const inDraftMode = useIsInDraftMode();
    return <MultiMapOptionStyles className={props.isCombineTarget ? 'combine-target': ''}>
        
        <Droppable 
            droppableId={props.mapOptionId}
            isDropDisabled={true}
            type="multimap"
        >
            {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    {props.mapOptions.map((mo, idx) => {
                        return <Draggable
                            draggableId={mo.id}
                            key={mo.id}
                            index={idx}
                        >
                            {draggableProvided => (
                                <div className="option"
                                    {...draggableProvided.draggableProps}
                                    {...draggableProvided.dragHandleProps}
                                    ref={draggableProvided.innerRef}
                                    key={mo.id}
                                >
                                    <SingleMapOptionText className="text-container">

                                        <span className="source">
                                            <PipelineNodeName pipelineNodeId={mo.source_node_id}/>:
                                        </span>
                                        <span className="attribute">
                                            {labelColumn(mo.source_node_id!, mo.attribute_id!)}
                                        </span>
                                        
                                    </SingleMapOptionText>
                                    <DraftOnly>
                                        <button className="separate-button" onClick={() => props.onSeparate(props.mapOptionId, mo)}>
                                            <i className="mdi mdi-export"></i>
                                        </button>
                                    </DraftOnly>
                                    
                                </div>
                            )}
                            

                        </Draggable>
                        
                        
                    })}
                    {provided.placeholder}
                </div>
        )}
        </Droppable>
        <div className="combine-with">
            Combine With: 
            <SeparatorSelector disabled={!inDraftMode} value={props.concatJoiner} onChange={(e) => props.onChangeSeparator(e.target.value)}>
                <option value=" ">Space ( )</option>
                <option value="-">Hyphen (-)</option>
                <option value="_">Underscore (_)</option>
                <option value=",">Comma (,)</option>
            </SeparatorSelector>
        </div>   
        
    </MultiMapOptionStyles>
}

const SingleMapOption = (props: SingleMapOptionProps) => {
    const labelColumn = useNodeColumnLabeler();
    return <SingleMapOptionStyles className={props.isCombineTarget ? 'combine-target': ''}>
        <SingleMapOptionText>
            <span className="attribute">
                {labelColumn(props.mapOption.source_node_id!, props.mapOption.attribute_id!)}
            </span>
        </SingleMapOptionText>
        {props.mapOption.join_path && <div className="font-13">
            <PipelineNodeJoinPathLabel joinPath={props.mapOption.join_path}/>
        </div>}
        {!props.mapOption.join_path && <div className="font-13">
            <PipelineNodeName pipelineNodeId={props.mapOption.source_node_id}/>

        </div>}
        
    </SingleMapOptionStyles>
}

const SingleMapOptionWrapper = styled.div`
margin-top: 6px;
position: relative;

background: var(--ct-dark);
    color: white;




`

const MapOptionContents = styled.div`
display: flex;
align-items: center;
position: relative;
`

const DragHandle = styled.div`
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 25px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0px;
    z-index: 1;
    margin-top: -12px;

    &:hover {
        color: white;
    }

    i {
        font-size: 24px;
        display: block;
        line-height: 24px;

    }
`


const ButtonContainer = styled.div`
align-items: center;
justify-content: center;
display: flex;
user-select: none;

border-radius: 100%;
text-align: center;
padding: 0px;
width: 30px;
height: 30px;
button {
    font-size: 18px;
    padding: 0px;
    margin: 0px;
    border-radius: 100%;
    border: none;
    background-color: transparent;
    color: var(--ct-body-color);
    line-height: 24px;
    text-align: center;

    &:hover {
        color: white;
        cursor: pointer;
    }
}
`
const MapOptionWrapper = (props: MapOptionWrapperProps) => {
    return <Draggable 
        draggableId={props.mapOption.id}
        key={props.mapOption.id}
        index={props.index!}
    >
        {provided => (
            

                <SingleMapOptionWrapper
                    {...provided.draggableProps}
                    className="shadow-box"
                    
                    ref={provided.innerRef}
                    key={props.mapOption.id}
                >
                    <DraftOnly>
                        {props.allowDrag && <DragHandle {...provided.dragHandleProps}>
                            <div>
                            <i className="mdi mdi-drag"></i>
                            </div>
                        </DragHandle>}
                    </DraftOnly>
                    
                    <div>
                    <MapOptionContents className="map-option-contents">
                        <div style={{flex: 1}}>
                            {!!props.mapOption.sub_options && (
                                <MultiMapOption
                                    concatJoiner={!!props.mapOption.concat_joiner ? props.mapOption.concat_joiner! : ' '}
                                    onChangeSeparator={(newSeparator) => props.onChangeSeparator(props.mapOption.id, newSeparator)}
                                    onSeparate={props.onSeparate}
                                    mapOptionId={props.mapOption.id}
                                    combinationRule={props.mapOption.combination_rule || 'CONCAT'}
                                    mapOptions={props.mapOption.sub_options!}
                                    isCombineTarget={props.isCombineTarget}
                                />
                            )}
                            {!props.mapOption.sub_options && (
                                <SingleMapOption 
                                    isCombineTarget={props.isCombineTarget} 
                                    mapOption={props.mapOption}
                                />
                            )}
                           
                        </div>
                    
                        
                        <DraftOnly>
                            <ButtonContainer className="button-container">
                                
                                {/* {props.showPriority && <Badge pill bg="pliable">Priority {props.index! + 1}</Badge>} */}
                                <button 
                                    onClick={() => props.onDelete()} 
                                    className={props.mapOption.managed_by_template && !props.isUnlocked ? 'hidden' : ''}
                                >
                                    <i className="mdi mdi-close-thick"></i>
                                </button>
                            </ButtonContainer>
                        </DraftOnly>
                        
                        {/* <PriorityIndicator>
                            Priority {props.index! + 1}
                        </PriorityIndicator> */}
                        
                    </MapOptionContents>
                    </div>
                    
                    
                    
                    
                </SingleMapOptionWrapper>
                
        )}
    </Draggable>
     
}



const PipelineNodeFieldMap = (props: Props) => {

    const [dragOverId, setDragOverId] = useState('');

    const onSeparate = useCallback((separatingFrom: string, separating: PipelineNodeMapOption) => {
        console.log('SEPARATING', separatingFrom, separating);

        const newOptions = produce(props.mapOptions, draft => {
            const separatingFromIndex = props.mapOptions.findIndex(mo => mo.id === separatingFrom);

            const separatingFromOption = props.mapOptions[separatingFromIndex];

            draft.push(separating);

            if (separatingFromOption.sub_options!.length === 2) {
                const notThisOne = separatingFromOption.sub_options!.find(so => so.id !== separating.id);

                if (!notThisOne) {
                    throw new Error('Could not find other member sub option');
                }
                // Flip it to a single option
                draft[separatingFromIndex] = notThisOne;
                
            } else {
                const subIndex = separatingFromOption.sub_options!.findIndex(so => so.id === separating.id);
                draft[separatingFromIndex].sub_options!.splice(subIndex, 1);
            }
        });

        props.onChangeMapOptions(newOptions);
    }, [props.mapOptions, props.onChangeMapOptions]);

    const handleMainDragEnd = useCallback((res: DropResult) => {
        if (
            res.destination && res.destination.droppableId === res.source.droppableId &&
            res.destination.index === res.source.index
        ) {
            // Didn't do anything
            return;
        };
        
        const newMapOptions = produce(props.mapOptions, draft => {
            const sourceSpl = res.draggableId.split('|');
            const movingItem = draft[res.source.index];

            if (res.combine && !movingItem.sub_options) {
                const combineTargetIndex = props.mapOptions.findIndex(d => d.id === res.combine?.draggableId);


                if (combineTargetIndex < 0) {
                    
                    throw new Error('Could not find combine target');
                }

                const combineTarget = draft[combineTargetIndex];

                
                if (!combineTarget.sub_options) {
                    draft[combineTargetIndex] = {
                        id: shortid(),
                        concat_joiner: ' ',
                        sub_options: [
                            combineTarget,
                            movingItem,
                        ]
                    }
                } else {
                    draft[combineTargetIndex].sub_options?.push(movingItem);
                }

                // Finally, remove the item being moved so we don't mess with the indexes beforehand
                draft.splice(res.source.index, 1);
            } else if (res.destination) {

                draft.splice(res.source.index, 1);
                draft.splice(res.destination.index, 0, movingItem);

            }

        });
        props.onChangeMapOptions(newMapOptions);
    }, [props.onChangeMapOptions, props.mapOptions]);

    const handleSubDragEnd = useCallback((res: DropResult) => {
        // Need to do a little dancing to find the main component in question...

        const draggingId = res.draggableId;
        if (!res.destination) {
            return;
        }
        const newOptions = produce(props.mapOptions, draft => {
            const mainOption = draft.find(d => {
                if (!d.sub_options) {
                    return false;
                }

                return d.sub_options.some(so => so.id === draggingId);
            });

            if (!mainOption) {
                throw new Error('Could not find parent option');
            }

            const movingItem = mainOption.sub_options![res.source.index];
            mainOption.sub_options!.splice(res.source.index, 1);
            mainOption.sub_options!.splice(res.destination!.index, 0, movingItem);

        });

        props.onChangeMapOptions(newOptions);
    }, [props.onChangeMapOptions, props.mapOptions]);

    const managed_by_templates = useMemo(() => {
        return props.mapOptions.filter(option => option.managed_by_template);
    }, [props.mapOptions]);
    
    const onDragEnd = useCallback((res: DropResult) => {
        const isDropAllowed = !managed_by_templates.some(option => option.id === res.draggableId || option.id === res.combine?.draggableId);
        if(props.isUnlocked) {
            if (res.type === 'multimap') {
                handleSubDragEnd(res);
            } else {
                console.log('Handling drag end');
                handleMainDragEnd(res);
            }
            setDragOverId('');
            return;
        }
        if (!isDropAllowed) {
            return;
            }
        
    }, [managed_by_templates, handleSubDragEnd, handleMainDragEnd]);

    const getRootMapOptionById = useCallback((id: string) => {
        return props.mapOptions.find(mo => mo.id === id);
    }, [props.mapOptions])

    const onDragUpdate = useCallback((res: DragUpdate) => {
        const currentlyDragging = getRootMapOptionById(res.draggableId);
        if (res.combine && !currentlyDragging?.sub_options) {
            setDragOverId(res.combine.draggableId);
        } else {
            setDragOverId('');
        }
    }, []);

    const onChangeSeparator = useCallback((parentMoId: string, separator: string) => {
        const newOptions = produce(props.mapOptions, draft => {
            const mo = draft.find(d => d.id === parentMoId);
            if (mo) {
                mo.concat_joiner = separator;
            }
        });

        props.onChangeMapOptions(newOptions);
    }, [props.mapOptions, props.onChangeMapOptions]);

    const onDeleteMapOption = useCallback((moId: string) => {
        const newOptions = produce(props.mapOptions, draft => {
            const moIdx = draft.findIndex(d => d.id === moId);
            if (moIdx >= 0) {
                draft.splice(moIdx, 1);
            }
        });

        props.onChangeMapOptions(newOptions);
    }, [props.mapOptions, props.onChangeMapOptions]);

    const otherColumnOptions = useMemo(() => {
        if (!props.sourceNodes) {
            return [];
        }
        const otherOptions: Option[] = [];

        props.sourceNodes.forEach(n => {
            n.fields.forEach(f => {
                otherOptions.push({
                    label: `${n.name}: ${f.name}`,
                    value: `${n.id}|${f.id}`
                })
            })
        });

        return otherOptions;
    }, [props.sourceNodes]);

    return <>
        
        {props.sourceNodes && <>
            <Form.Group className="mb-3">
                <Form.Label>Map another column</Form.Label>
                <Dropdown
                    options={otherColumnOptions}
                    allowEmpty={true}
                    placeholder="Select a column to map"
                    onChange={(value) => {
                        const [nodeId, fieldId] = value.split('|');
                        const newOptions = produce(props.mapOptions, draft => {
                            draft.push({
                                id: shortid(),
                                source_node_id: nodeId,
                                attribute_id: fieldId,
                                concat_joiner: ' ',
                            })
                        });

                        props.onChangeMapOptions(newOptions);
                       
                    }}
                />

            </Form.Group>
        </>}
        {!!props.mapOptions.length && props.mapOptions.length > 1 && <p>
            Drag to change the priority order of the mapping. Pliable will pick the first non-empty value.<br />You can also drop one column onto another column to combine them together.
        </p>}
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
            <Droppable droppableId="main" isCombineEnabled type="main">
                {provided => (
                    <DndContainer 
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {props.mapOptions.map((mo, idx) => {
                            return <MapOptionWrapper
                                key={mo.id}
                                allowDrag={props.mapOptions.length > 1}
                                showPriority={props.showPriority}
                                onChangeSeparator={onChangeSeparator}
                                onSeparate={onSeparate}
                                mapOption={mo}
                                index={idx}
                                isCombineTarget={dragOverId === mo.id}
                                isUnlocked={props.isUnlocked}
                                onDelete={() => {
                                    onDeleteMapOption(mo.id);
                                }}
                                displayJoinPath={props.displayJoinPath}
                            />
                            
                        })}
                        {provided.placeholder}
                    </DndContainer>
                )}
            </Droppable>
        </DragDropContext>
    </>
}

export default PipelineNodeFieldMap;
