import TenantPicker from "@components/tenant/TenantPicker.component";
import { useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom"
import styled from "styled-components";

const Quote = styled.div`
font-size: 36px;
font-style: italic;
font-family: "Poppins";
font-weight: 300;

`

const Attribution = styled.div`
    font-size: 18px;
    margin-left: 3rem;
    font-family: "Poppins";
    font-weight: 500;
`

interface QuoteOption {
    quote: string;
    person: string;
}

const quoteOptions: QuoteOption[] = [
    {
        quote: "Data modeling is not about databases; it's about understanding the business. A good model reflects the questions people ask of their data.",
        person: "Kimball and Ross"
    },
    {
        quote: "The goal of data modeling is not just to store data but to represent reality in a way that fosters meaningful insights and decisions.",
        person: 'Len Silverston',
    },
    {
        quote: "A data model is a roadmap for understanding the relationships and meaning within your data. It's the foundation of effective analysis.",
        person: 'Ralph Kimball'
    },
    {
        quote: "Data modeling bridges the gap between raw data and actionable insights—it's where data becomes knowledge.",
        person: "Unknown"
    },

]
const NoNavWrapperPage = () => {
    const location = useLocation();
    const randomQuote = useMemo(() => {
        return quoteOptions[Math.floor(Math.random() * quoteOptions.length)];
    }, [location.pathname]);
    return <div className="d-flex">
        <div style={{width: '50%', height: '100vh'}} className=" border-right bg-white">
            <Outlet/>
        </div>
        <div style={{width: '50%'}} className="col-6 bg-navy text-white">
            <div className="p-3">
                <div className="d-flex justify-content-end align-items-center">
                    <button className="btn btn-pliable me-2">
                        <i className="mdi mdi-chat"></i> Get Help
                    </button>

                    <TenantPicker/>
                    <Link to="/logout" className="btn btn-link text-white">Logout</Link>
                </div>
            </div>
            <div className="p-5">
                <Quote>
                    "{randomQuote.quote}"
                </Quote>
                <Attribution>- {randomQuote.person}</Attribution>
            </div>
            {/* <div className="p-5">
                What goes here?
            </div> */}
        </div>
    </div>
}

export default NoNavWrapperPage;