import { useCallback, useState, KeyboardEvent, useRef } from "react";
import { Form } from "react-bootstrap";
import styled from 'styled-components';
interface Props {
    value: string;
    onChange: (newValue: string) => any;
    textarea?: boolean;
    emptyPlaceholder?: string;
}

const EditableTextStyles = styled.div`
position: relative;
.editable {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.buttons {
    position: absolute;
    bottom: -35px;
    z-index: 1000;
}

.form-control {
    width: 90%;
}
`

const EditableText = (props: Props) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(props.value);
    const formControl = useRef<HTMLInputElement>(null);

    const onEdit = useCallback(() => {
        setEditing(true);
        setTimeout(() => {
            formControl.current?.focus();
            formControl.current?.setSelectionRange(0, formControl.current?.value.length);
        }, 0);
    }, [formControl]);

    const onSave = () => {
        props.onChange(value);
        setEditing(false);
    }

    const onCancel = () => {
        setValue(props.value);
        setEditing(false);
    }

    const checkForEnterKey = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            onSave();
        } else if (e.key == 'Escape') {
            onCancel();
        }
    }, [onSave, onCancel]);


    return (
        <EditableTextStyles>
            {editing ? (
                <>
                    <Form.Control ref={formControl} className="flex-1 me-1" as={props.textarea ? 'textarea' : 'input'} value={value} onKeyDown={checkForEnterKey} onChange={(e) => {
                        setValue(e.target.value)
                    }} />
                    <div className="buttons">
                    <button onClick={onSave} className="btn btn-sm btn-success me-1">
                        <i className="mdi mdi-check-circle"></i> Done
                    </button>
                    <button onClick={onCancel} className="btn btn-sm btn-danger"><i className="mdi mdi-cancel"></i></button>
                
                    </div>
                </>
            ) : (
                <div>
                    <span onClick={onEdit} className="editable">{props.value || props.emptyPlaceholder || '(None)'}</span>
                </div>
            )}
        </EditableTextStyles>
    )
}

export default EditableText;