import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import TenantConfigSubnav from "@components/tenantConfig/TenantConfigSubnav.component";
import AuthService from "@services/auth/auth.service";
import { useBillingAccount, useBillingOptions, useProjectConfig } from "@stores/data.store";
import toast from "@services/toast.service";
import LoadingCard from '@components/card/LoadingCard.component';
import CorePlanPicker from '@components/billing/PlanPicker.component';
import BillingAccountORM, { BillingProduct } from '@models/billingAccount';
import CurrentBillingPlan from '@components/billing/CurrentPlan.component';
import EmbeddedCheckoutForm from '@components/billing/EmbeddedCheckout.component';
import AsyncButton from '@components/button/AsyncButton.component';
import CheckoutForm from '@components/billing/CheckoutForm.component';
import PaymentForm from '@components/billing/CheckoutForm.component';
import { set } from 'immer/dist/internal';


const BillingAccountPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const tenant_name = AuthService.getInstance().getTenantName();
    const [loading, setLoading] = useState(false);
    const { data: tenantConfig } = useProjectConfig();
    const { isLoading: loadingBillingAccount, data: billingAccount, refetch } = useBillingAccount(searchParams.get('session_id') != null || searchParams.get('redirect_status') == 'succeeded');
    const { data: billingOptions } = useBillingOptions();
    let innerContent =  null;

    const setupIntentId = searchParams.get('setup_intent');

    // useEffect(() => {
    //     if(setupIntentId){
    //         confirmPaymentSetp(setupIntentId);
    //     }
    // }, []);

    // const confirmPaymentSetp = useCallback(async (paymentMethodId: string) => {
    //     setLoading(true);
    //     await BillingAccountORM.confirmPaymentSetup(setupIntentId as string);
    //     setSearchParams({});
    //     refetch();
    //     setTimeout(() => {setLoading(false);} , 500);
    // }, [setupIntentId]);

    const onPlanChoosen = useCallback(async (bp: BillingProduct) => {
        setLoading(true);
        const resp = await BillingAccountORM.selectBillingPlan(bp.default_price);
        refetch();
        setTimeout(() => {setLoading(false);} , 500);
    }, [billingAccount]);

    const cancelPlanCheckout = useCallback(async () => {
        setLoading(true);
        await BillingAccountORM.cancelCheckoutSession(billingAccount!.checkout_session_id as string);
        refetch();
        setTimeout(() => {setLoading(false);} , 500);
    }, [billingAccount]);

    if (loading || loadingBillingAccount) {
        innerContent = <div><LoadingCard /></div>;
    }else if(!!billingAccount &&  ['active', 'trialing'].includes(billingAccount.subscription_status)){
        // show current active plan
        innerContent = <CurrentBillingPlan billingAccount={billingAccount} allowCancel={true} />;
    }else if (!billingAccount || (!billingAccount.checkout_session_secret && !billingAccount.payment_intent_secret)) {
        // allow them to choose plan
        innerContent = <div className="d-flex justify-content-center">
            <div className="col-9">
                <CorePlanPicker billingOptions={billingOptions || []}  onOptionSelected={onPlanChoosen} />
            </div>
     </div>;
    }else if(billingAccount.payment_intent_secret){
        // allow them to pay for a plan
        innerContent = <>
            <div className="d-flex justify-content-center mt-2">
                <div className="col-8 col-xs-12">
                    <PaymentForm clientSecret={billingAccount.payment_intent_secret} />
                </div>
            </div>    
        </>;
    }else {
        // allow them to pay for a plan
        innerContent = <>
            <div className="mt-3 mx-5">
                <AsyncButton icon='mdi mdi-arrow-left' text="Choose a different plan" variant="outline" onClick={cancelPlanCheckout} loading={loading} />
            </div>
            <div className="d-flex justify-content-center mt-2">
                <div className="col-12 col-xs-12">
                    <EmbeddedCheckoutForm clientSecret={billingAccount.checkout_session_secret} />
                </div>
            </div>    
        </>;
    }

    return <PageStructure
        pageTitle="Build Schedule"
        breadcrumbs={[
            {
                title: 'Account',
            }
            
        ]}
    >
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Account Name
                            </Form.Label>
                            <Form.Control value={tenant_name as string} disabled={true} />
                            <Form.Text></Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>
                                Account ID
                            </Form.Label>
                            <Form.Control value={tenantConfig?.tenant_slug as string} disabled={true} />
                            <Form.Text>
                                <strong>cannot be changed</strong> after creation
                            </Form.Text>
                        </Form.Group>
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>
            <Pane>
                <TenantConfigSubnav>
                </TenantConfigSubnav>
                <PaneContentWithSubnav>
                    <Pane>
                        <PaneContent>
                            {innerContent}
                        </PaneContent>
                    </Pane>
                </PaneContentWithSubnav>
            </Pane>
        </PageContent>
               
    </PageStructure>
    
}

export default BillingAccountPage;