import PliableLoader from "@components/loaders/PliableLoader.component";
import ApiService from "@services/api/api.service";
import { useQueryParams } from "@services/url.service";
import { invalidateSourceRecordTypes, invalidateSources } from "@stores/data.store";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const FivetranSourceStepReturnPage = () => {
    // This is the route fivetran redirects to after completing a connector card

    const { templateGroupId, sourceId } = useParams();

    const query = useQueryParams();
    const navigate = useNavigate();

    const connect = useCallback(async (sourceId: string, fivetranId: string) => {
        const result = await ApiService.getInstance().request('POST', `/sources/${sourceId}/fivetran-connect/${fivetranId}`);
        invalidateSources();
        invalidateSourceRecordTypes();
        navigate('/template-group/' + templateGroupId);
    }, []);

    useEffect(() => {
        if (sourceId && query && query.get('id')) {
            connect(sourceId, query.get('id') as string);
        }
    }, [query, sourceId]);

    return <PliableLoader size="sm" />
}

export default FivetranSourceStepReturnPage;