import Dropdown from "@components/form/Dropdown.component";
import OverlapAnalysis from "@components/overlapAnalysis/OverlapAnalysis.component";
import Warning from "@components/statusIndicators/Warning.component";
import { PipelineNode, PipelineNodeRelationshipParentLookup } from "@models/pipelineNode";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import produce from "immer";
import { useCallback, useMemo, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Pipe } from "stream";

interface Props {
    parentNode: PipelineNode;
    childNode: PipelineNode;
    parentLookups: PipelineNodeRelationshipParentLookup[];
    onChange: (newLookups: PipelineNodeRelationshipParentLookup[]) => void;
    enableOverlapAnalysis?: boolean;

}

const PipelineNodeRelationshipParentLookupEditor = (props: Props) => {
    const addNewLookup = useCallback(() => {
        props.onChange(produce(props.parentLookups, draft => {
            draft.push({
                parent_field_id: '',
                child_field_id: '',
            });
        }));
    }, [props.onChange, props.parentLookups]);

    const changeParentField = useCallback((idx: number, newFieldId: string) => {
        props.onChange(produce(props.parentLookups, draft => {
            draft[idx].parent_field_id = newFieldId;
        }));
    }, [props.onChange, props.parentLookups]);

    const changeChildField = useCallback((idx: number, newFieldId: string) => {
        props.onChange(produce(props.parentLookups, draft => {
            draft[idx].child_field_id = newFieldId;
        }));
    }, [props.onChange, props.parentLookups]);

    const deleteLookup = useCallback((idx: number) => {
        props.onChange(produce(props.parentLookups, draft => {
            draft.splice(idx, 1);
        }));
    }, [props.onChange, props.parentLookups]);

    const columnWidths = useMemo(() => {
        if (props.enableOverlapAnalysis) {
            return ['35%', '35%', '30%'];
        } else {
            return ['45%', '45%', '10%'];
        }
    }, [props.enableOverlapAnalysis]);

    const [activeLookupIdx, setActiveLookupIdx] = useState(-1);
    return <>
        <Offcanvas placement="end" show={activeLookupIdx >= 0} onHide={() => {
                setActiveLookupIdx(-1);
            }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Connection Details</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        {activeLookupIdx >= 0 && <>
                            <div className="p-2">
                                <h3>Overlap Analysis</h3>
                            </div>
                            {!props.childNode.last_build_completed && <>
                                <Warning>
                                    <div>The <strong>{props.childNode.label}</strong> node has never been built. You need to build it first in order to run an overlap analysis.</div>
                                </Warning>
                            </>}
                            {!props.parentNode.last_build_completed && <>
                                <Warning>
                                    <div>The <strong>{props.parentNode.label}</strong> node has never been built. You need to build it first in order to run an overlap analysis.</div>
                                </Warning>
                            </>}
                            {props.parentNode.last_build_completed && props.childNode.last_build_completed && <>
                            <OverlapAnalysis
                                sourcePipelineNodeId={props.parentNode.id || ''}
                                targetPipelineNodeId={props.childNode.id || ''}
                                sourceFieldId={props.parentLookups[activeLookupIdx].parent_field_id || ''}
                                targetFieldId={props.parentLookups[activeLookupIdx].child_field_id || ''}
                            /></>}
                        </>}
                        
                    </PaneContent>
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
    <table className="table table-fixed table-condensed table-bordered table-compact table-centered">
        <thead className="bg-light font-poppins">
            <tr>
                <th style={{width: columnWidths[0]}}>{props.parentNode.label} Column</th>
                <th style={{width: columnWidths[1]}}>{props.childNode.label} Column</th>
                <th className="text-end">
                    <button className="icon-button font-18" onClick={addNewLookup}>
                        <i className="mdi mdi-plus-thick"></i>
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
            {props.parentLookups.map((l, idx) => {
                return <tr>
                        <td style={{width: columnWidths[0]}}>
                            <Dropdown
                                size="sm"
                                options={props.parentNode.fields.filter(f => !['DENORMALIZED', 'FOREIGN_KEY'].includes(f.type)).map(f => {
                                    return {
                                        label: f.name,
                                        value: f.id as string,
                                    }
                                })}
                                selected={l.parent_field_id}
                                onChange={(newVal) => {
                                    changeParentField(idx, newVal);
                                }}
                            />
                        </td>
                        <td style={{width: columnWidths[1]}}>
                        <Dropdown
                                size="sm"
                                options={props.childNode.fields.filter(f => !['DENORMALIZED', 'FOREIGN_KEY'].includes(f.type)).map(f => {
                                    return {
                                        label: f.name,
                                        value: f.id as string,
                                    }
                                })}
                                selected={l.child_field_id}
                                onChange={(newVal) => {
                                    changeChildField(idx, newVal);
                                }}
                            />
                        </td>
                    
                        <td className="text-end">
                            {props.enableOverlapAnalysis && <button className="btn btn-sm btn-light me-2" onClick={() => {
                                setActiveLookupIdx(idx);
                            }}>
                                <i className="mdi mdi-set-center"></i> Overlap Analysis
                            </button>}
                            <button className="icon-button font-18" onClick={() => {
                                deleteLookup(idx);
                            }}>
                                <i className="mdi mdi-delete"></i>
                            </button>
                        </td>
                    </tr>;
            })}
                
                    
        </tbody>
    </table></>
}

export default PipelineNodeRelationshipParentLookupEditor;