import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { useQuery } from "react-query";
import { Template } from "./MyTemplates.page";
import PageStructure, { PageContent } from "@pages/PageStructure.component";
import { useCallback } from "react";
import { queryClient } from "@stores/data.store";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";

export const useAllTemplates = () => {
    return useQuery('all_templates', async () => {
        const response = await ApiService.getInstance().request('GET', '/templates/all') as ListRecordsResponse<Template>;
        return response.records;
    }, {
        // 30 mins
        staleTime: 60 * 30 * 1000
    });
}

const InstallTemplatePage = () => {
    const allTemplates = useAllTemplates();

    const installTemplate = useCallback(async (templateId: string) => {
        try {
            const result = await ApiService.getInstance().request('POST', `/templates/install/${templateId}`, {});
            queryClient.invalidateQueries('template_installations');
            toast('success', 'Success', 'Template installed');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
        
    }, []);

    return <PageStructure>
        <PageContent>
            <div className="p-5">
                <h1>Available Templates</h1>
                <table className="table table-centered">
                    <thead className="bg-light">
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTemplates.data && allTemplates.data.map(t => {
                            return <tr>
                                <th>{t.name}</th>
                                <td>{t.type}</td>
                                <td><button className="btn btn-primary" onClick={() => {
                                    installTemplate(t.id);
                                }}>Install</button></td>
                            </tr>
                                
                        })}
                    </tbody>
                </table>
               
            </div>

        </PageContent>
    </PageStructure>
}

export default InstallTemplatePage;