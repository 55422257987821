import React, { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthorizedContent, AdminPortal } from "@frontegg/react";
import Dropdown from 'react-bootstrap/Dropdown';
import TenantPicker from "@components/tenant/TenantPicker.component";
import { Role } from "../../constants/roles";
import { useAuthState } from "../../services/auth/auth.service";
import TrackingService, { Events } from '@services/tracking.service';
import styled from 'styled-components';

const StyledDropdown = styled(Dropdown)`
  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-toggle {
    img {
      opacity: 0.8;
    }

    &:hover {
      cursor: pointer;
      img {
        opacity: 1.0;
      }
    }
  }

  .nav-item {
    color: inherit;
  }

`;

const UserSettings: React.FC = () => {
  const location = useLocation();
  const authState = useAuthState();

  const showAdminPortal = useCallback(() => {
    AdminPortal.show();
    TrackingService.track_event(Events.USER_ADMIN_CLK);
  }, []);

  return (
    <StyledDropdown className="ms-2 navbar-component">
      <Dropdown.Toggle as="div" id="dropdown-custom-components">
        <img
          src={authState?.user?.profilePictureUrl as string | undefined}
          alt="User"
          width="40"
          height="40"
          className="rounded-circle"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={showAdminPortal} className="nav-item" title="User Admin">
          <i className=" me-1 mdi mdi-account-group"></i>
          <span className="link-label">Manage Users</span>
        </Dropdown.Item>
        <AuthorizedContent requiredRoles={[Role.Admin]}>
          <Dropdown.Item className="nav-item">
            <Link
              className={`nav-item ${location.pathname === '/settings' ? 'active' : ''}`}
              to="/settings"
              title="Account Settings"
            >
              <i className=" me-1 mdi mdi-cog"></i>
              <span className="link-label">Account Settings</span>
            </Link>
          </Dropdown.Item>
        </AuthorizedContent>
        <Dropdown.Item className="nav-item">
          <TenantPicker />
        </Dropdown.Item>
        <Dropdown.Item href="/logout">
          Logout
        </Dropdown.Item>
        <Dropdown.Item>
          <div className="small">version {process.env.REACT_APP_VERSION}</div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

export default UserSettings;