import ApiService, { ApiError } from "@services/api/api.service";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQueryParams as useURLQuery } from '@services/url.service';
import ReactFlow, { ConnectionMode, Edge, Handle, MarkerType, Node, NodeProps, Position, EdgeProps, useStore, getBezierPath, XYPosition, internalsSymbol, ReactFlowInstance, Background } from "reactflow";
import { Column } from "react-table";
import { autoLayout } from "@services/diagram.service";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { useColumnShape, usePipelineNode, usePipelineNodes } from "@stores/data.store";
import PipelineNodeInfo from "@components/pipelineNodes/PipelineNodeInfo.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import styled, { css } from 'styled-components';
import { MissionControlCardinalEdge, getHandleCoordsByPosition } from "@components/missionControl/edges/MissionControlEdge.component";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import { Badge, Form } from "react-bootstrap";
import LoadingCard from "@components/card/LoadingCard.component";
import { CustomHandle } from "@components/missionControl/dataflow/MissionControlDataFlow";
import PipelineNodeColumnDrawer from "@components/pipelineNodes/PipelineNodeColumnDrawer.component";
import { formatPercentage } from "@services/formatting.service";
import DagDataLibrary from "@components/nav/DagDataLibrary.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import Danger from "@components/statusIndicators/Danger.component";
import { getErrorMessage } from "@services/errors.service";
import PipelineNodeNotFound from "@components/pipelineNodes/PipelineNodeNotFound.component";
import PipelineNodeIcon from "./PipelineNodeIcon.component";
import { getNodeTypeConfigFromNodeTypeValue } from "@services/modeling.service";

interface ColumnLineageNode {
    id: string;
    object_label: string;
    field_label: string;
    object_type: string;
    object_id: string;
    field_id: string;
    field_name: string;
    node_type: string;
}

interface ColumnLineageEdge {
    id: string;
    type: string;
    source: string;
    target: string;
}

interface ColumnLineageResponse {
    nodes: ColumnLineageNode[];
    edges: ColumnLineageEdge[];
}

export function getHandleCoordsByType(node: Node, type: 'source'|'target'): XYPosition {
    // all handles are from type source, that's why we use handleBounds.source here
    //   @ts-ignore
    let x: number;
    let y: number = node.positionAbsolute!.y + (node.height ? node.height / 2 : 0);
    if (type == 'source') {
        x = node.positionAbsolute!.x + 400;
        
    } else {
        x = node.positionAbsolute!.x;
    }
    
    return {x, y};
}

const EdgePathContainer = styled.g`
`

const ColumnLineageEdge = ({id, source, target, data}: EdgeProps<ColumnLineageEdge>) => {
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
    
    const fillColor = useMemo(() => {
        if (data && data.type == 'DENORMALIZED') {
            return '#68357A';
        } 
        return '#FF9F00';
    }, [data]);
    if (!sourceNode || !targetNode) {
        return <></>
    }
    const sourcePos = getHandleCoordsByType(sourceNode, 'source');
    const targetPos = getHandleCoordsByType(targetNode, 'target');

    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX: sourcePos.x,
        sourceY: sourcePos.y,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        targetX: targetPos.x,
        targetY: targetPos.y,
    });

    return <EdgePathContainer>
        <path fill="none" id={id} d={edgePath} strokeWidth={5} style={{'stroke': fillColor}}/>
        <circle cx={sourcePos.x} cy={sourcePos.y} r={7} fill={fillColor}/>
        <circle cx={targetPos.x} cy={targetPos.y} r={7} fill={fillColor}/>

    </EdgePathContainer>

}

const ColumnLineageNodeCardStyles = styled.div<{topColor?: string}>`
width: 400px;
padding: 16px; 

.node-box {
    border-radius: 5px;
    background-color: white;

    &:hover {
        background: #f1f1f1;
        cursor: pointer;
    }

    h4 {
        color: var(--ct-body-color);
        font-weight: 400;
    }

    &.selected {
        box-shadow: 0px 0px 10px 5px var(--ct-primary);
    }

    border-top: solid 5px var(--ct-border-color);

    

    
    
}
    
${props => props.topColor && css`
    .node-box {
        border-top: solid 5px var(--pliable-${props.topColor}) !important;
    }
    
`}



.actions {
    height: 35px;
    border-top: solid 1px var(--ct-border-color);
    display: flex;
    flex-direction: row;

    button, a {
        font-family: "Poppins";
        display: block;
        color: #888;
        height: 34px;
        border: none;
        border-right: solid 1px var(--ct-border-color);
        background-color: inherit;
        border-radius: 0px;

        &:disabled {
            color: #ccc;
        }

        &:hover:not(:disabled) {
            cursor: pointer;
            color: black;
        }

        &:first-child {
            border-bottom-left-radius: 5px;
        }

        &:last-child {
            border-bottom-right-radius: 5px;
            border-left: none;
        }

        &.active {
            color: white;
            background-color: var(--pliable-yellow);
        }
    }
}


`



const ColumnLineageNodeCard = (props: NodeProps<ColumnLineageNode>) => {
    const navigate = useNavigate();

    const pipelineNodes = usePipelineNodes();
    
    const topColor = useMemo(() => {
        
        const groupColor = getNodeTypeConfigFromNodeTypeValue(props.data.node_type).group.color
        return groupColor;
    }, [props.data.object_type, props.selected]);

    const viewMappings = useCallback(() => {
        navigate(`/node/${props.data.object_id}/fields`);
    }, [props.data.object_id]);

    const viewLineage = useCallback(() => {
        // navigate(`/node/${props.data.object_id}/lineage?fieldId=${props.data.field_id}`);
    }, [props.data.object_id, props.data.field_id]);

    const theNode = useMemo(() => {
        return pipelineNodes.data?.find(n => n.id == props.data.object_id);
    }, [pipelineNodes.dataUpdatedAt, props.data.object_id]);
    

    if (!theNode) {
        return <></>;
    }
    return <ColumnLineageNodeCardStyles topColor={topColor}>
    
        <div className={`node-box shadow-box`} onClick={viewLineage}>
            <div className="d-flex p-3 center-vertically">
                <PipelineNodeIcon node={theNode} compact={true}/>
                <div className="flex-1">
                    <h4 className="mb-0">
                        {props.data.object_label}
                    </h4>
                    <h2 className="mb-0">{props.data.field_label}</h2>
                </div>
            </div>
           
        </div>
        <CustomHandle
                    type="source"
                    position={Position.Right}
                >
                </CustomHandle>
                
                <CustomHandle  type="target" position={Position.Left}>
                </CustomHandle>
        
    </ColumnLineageNodeCardStyles>
}

const nodeTypes = {
    columnLineage: ColumnLineageNodeCard
}

const edgeTypes = {
    columnLineage: ColumnLineageEdge,
  };

const useColumnLineage = (pipelineNodeId: string, fieldId: string) => {
    return useQuery(['column_lineage', pipelineNodeId, fieldId], async () => {
        console.log('Loading lineage', pipelineNodeId, fieldId);
        if (!pipelineNodeId || !fieldId) {
            return {
                nodes: [],
                edges: [],
            };
        }
        const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/column-lineage`, {
            field_id: fieldId,
        }) as ColumnLineageResponse;
        return result;
    }, {
        // 2 mins?
        staleTime: 60 * 1000 * 2
    })
}

const fitViewOptions = { padding: 1, duration: 500 };


interface Props {
    pipelineNodeId: string;
    fieldId: string;
}

const PipelineNodeColumnLineage = (props: Props) => {
    const pipelineNode = usePipelineNode(props.pipelineNodeId);

    const columnLineage = useColumnLineage(props.pipelineNodeId as string, props.fieldId);

    const [nodes, setNodes] = useState<Node<ColumnLineageNode>[]>([]);
    const [edges, setEdges] = useState<Edge<ColumnLineageEdge>[]>([]);

    const columnOptions: Option[] = useMemo(() => {
        if (pipelineNode.data) {
            return pipelineNode.data.fields.filter(f => f.type != 'FOREIGN_KEY').map(f => {
                return {
                    value: f.id,
                    label: f.label,
                }
            })
        }
        return [];
    }, [pipelineNode.dataUpdatedAt]);

    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance|null>(null);

    const onReactFlowInit = useCallback((instance: ReactFlowInstance) => {
        setReactFlowInstance(instance);
    }, []);

    const zoomToFitNode = useCallback((nodeId: string) => {
        if (!reactFlowInstance) {
            return;
        }
        console.log('Calling fit view on node', nodeId);
        reactFlowInstance.fitView({
            padding: 50,
            minZoom: 1,
            maxZoom: 5,
            nodes: [{id: nodeId}],
            duration: 500,
        });
    }, [reactFlowInstance]);

    useEffect(() => {
        if (columnLineage.data) {
            let newNodes: Node<ColumnLineageNode>[] = columnLineage.data.nodes.map(n => {
                return {
                    id: n.id,
                    label: n.object_label + ': ' + n.field_label,
                    data: n,
                    position: {
                        x: 0, y: 0,
                    },
                    type: 'columnLineage',
                    selected: n.object_id == props.pipelineNodeId,
                }
            });

            

            const newEdges: Edge<ColumnLineageEdge>[] = columnLineage.data.edges.map(e => {
                let strokeColor;
                
                if (e.type == 'DENORMALIZED') {
                    strokeColor = '#68357A';
                } else if (e.type == 'MAPPING') {
                    strokeColor = '#FF9F00';
                } else if (e.type == 'REPORT') {
                    strokeColor = '#1BD77D';
                } else if (e.type == 'CALCULATION') {
                    strokeColor = '#1BD77D';
                }
                return {
                    id: e.id,
                    source: e.source,
                    target: e.target,
                    data: e,
                    style: {
                        stroke: strokeColor,
                        strokeWidth: 7,
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 8,
                        height: 8,
                        strokeWidth: 2,
                        strokeColor: strokeColor,
                        color: strokeColor,
                    }
                }
            });

            const positions = autoLayout(newNodes, newEdges, 'LINEAR', 450, 300);
            newNodes = newNodes.map( n => {
                if (positions.hasOwnProperty(n.id)) {
                    n.position = positions[n.id];

                }
                return n;
            });

            setNodes(newNodes);
            setEdges(newEdges);


        }
    }, [columnLineage.dataUpdatedAt, props.pipelineNodeId]);

    useEffect(() => {
        setTimeout(() => {
            zoomToFitNode(props.pipelineNodeId as string + ':' + props.fieldId);

        }, 100);
    }, [nodes, edges, props.pipelineNodeId, props.fieldId]);

    const innerContent = useMemo(() => {
        if (columnLineage.error) {
            return <div><Danger>{getErrorMessage(columnLineage.error)}</Danger></div>
        }
        if (columnLineage.isLoading) {
            return <div>
                <PliableLoader/>
            </div>
        }

        
        if (!props.fieldId) {
            return <div className="p-3">Select a field to view its lineage.</div>
        }
        return <div style={{width: '100%', height: '100%'}}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                draggable={false}
                nodesConnectable={false}
                nodeTypes={nodeTypes}
                fitView={true}
                onInit={onReactFlowInit}
                fitViewOptions={fitViewOptions}
                style={{background: "#F9F7F5"}}
                proOptions={{hideAttribution: true}}
            >
                <Background/>
            </ReactFlow>
        </div>
    }, [columnLineage.data, nodes, edges, props.fieldId]);

    const navigate = useNavigate();


    if (pipelineNode.status == 'error' && (pipelineNode.error as ApiError).code == 404) {
        return <PipelineNodeNotFound/>
    }

    return <>
        {innerContent}

    </>

    
}

export default PipelineNodeColumnLineage;