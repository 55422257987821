import { getErrorMessage } from '@services/errors.service';
import TenantService from '@services/tenant/tenant.service';
import TenantConfigService from '@services/tenantConfig.service';
import AsyncButton from '@components/button/AsyncButton.component';
import produce from 'immer';
import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Collapse, Modal, Offcanvas, ProgressBar, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CopyBlock, atomOneLight } from "react-code-blocks";
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BackgroundService from '@services/bg.service';
import useGlobalState from '@stores/global.state';
import pliablemark from '@assets/images/pliable/brand_mark_orange.png';
import snowflakemark from '@assets/images/snowflake_logo.png';
import dbtmark from '@assets/images/dbt.png';
import styled, { css } from 'styled-components';
import ApiService from '@services/api/api.service';
import toast from '@services/toast.service';
import PageStructure, { Main, PageContent, PageContentNoSidebar, PageSidebar, Pane, PaneContent, PaneContentSidebar } from '@pages/PageStructure.component';
import { useHotkeys } from 'react-hotkeys-hook';
import { enterDraftMode, invalidateEverything, queryClient, toggleThenaWidget, useBillingAccount } from '@stores/data.store';
import ConfigService, { SurveyAnswers, SurveyDataSources } from '@services/config/config.service';
import { Updater, useImmer } from 'use-immer';
import SnowflakeMenuScreen from '@assets/images/onboarding/snowflake_menu.png';
import SnowflakeMenuScreen2 from '@assets/images/onboarding/snowflake_menu2.png';
import SnowflakeMenuScreen3 from '@assets/images/onboarding/snowflake_menu3.png';

import PliableLogo from '@assets/images/pliable/logo_with_text_black.png';
import Danger from '@components/statusIndicators/Danger.component';
import SuccessAlert from '@components/statusIndicators/SuccessAlert.component';
import { EventScheduledEvent, InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useAuthState } from '@services/auth/auth.service';
import { useDebouncedCallback } from 'use-debounce';
import { useQuery } from 'react-query';
import moment from 'moment';
import PliableLoader from '@components/loaders/PliableLoader.component';
import BrandMark from '@assets/images/pliable/brand_mark_orange.svg';
import TrackingService from '@services/tracking.service';
import TenantPicker from '@components/tenant/TenantPicker.component';
import { isValidSnowflakeURL } from '@services/url.service';
import CorePlanPicker from '@components/billing/PlanPicker.component';
import BillingAccountORM from '@models/billingAccount';
import EmbeddedCheckoutForm from '@components/billing/EmbeddedCheckout.component';
import CurrentBillingPlan from '@components/billing/CurrentPlan.component';
import Dropdown, { MultiDropdown } from '@components/form/Dropdown.component';

import pliableLogo from '@assets/images/pliable/logo_with_text_black.png'
import InfoAlert from '@components/statusIndicators/InfoAlert.component';
import LoadingCard from '@components/card/LoadingCard.component';
import TOSModal from '@components/alert/TOSModal.component';

// step order
enum WizardStep {
    Survey = 'survey',
    Warehouse = 'warehouse', 
    Finalize = 'finalize',
    Plan = 'plan', 
    Billing = 'billing',
    Complete = 'complete'
}

const stepsOrder = [WizardStep.Survey, WizardStep.Warehouse, WizardStep.Finalize,];


export const ButtonGrid = styled.div`
    button {
        
        display: inline-block;
        margin: .5rem;
        background-color: white;
        font-weight: 300;
        font-family: "Poppins";
        padding: 0px 1rem;

        border: 1px solid #D9D9D9;
        border-radius: 10px;
        box-shadow: 0px 2px 3px 0px #0000001A;
        height: 50px;
        line-height: 50px;

        .inner {
            display: flex;
            align-items: center;
        }

        
        img {
            max-width: 100px;
            max-height: 90%;
        }

        &.active {
            color: white;
            background-color: var(--ct-primary);

            i {
                color: white !important;
            }

            img {
                filter: brightness(0) invert(1);
            }
            &:hover {
                background-color: var(--ct-primary) !important;
            }
        }

        &:hover {
            background-color: #eee;
        }

    }
`


interface CalendlyEventInfoData {
    resource: {
        name: string;
        start_time: string;
        end_time: string;
        location: {
            join_url: string;
        }
    }
}

interface CalendlyEventInfoProps {
    eventUuid: string;
}

const useCalendlyEventInfo = (eventUuid: string) => {
    return useQuery(['calendly_onboarding_event', eventUuid], async () => {
        const info = await ApiService.getInstance().request('GET', '/calendly-event') as CalendlyEventInfoData;
        console.log('Event info:', info);
        return info;

    });
}

const CalendlyEventInfo = ({ eventUuid } : CalendlyEventInfoProps) => {
    const eventInfo = useCalendlyEventInfo(eventUuid);

    if (eventInfo.data) {
        return <>
            <h4>{moment(eventInfo.data?.resource.start_time).format('MMMM Do, YYYY')} at {moment(eventInfo.data?.resource.start_time).format('h:mm a')}</h4>
            <a href={eventInfo.data?.resource.location.join_url}>Join Zoom Meeting</a>
        </>
    }
    
    return <PliableLoader/>
}

const HeaderImage = styled.img`
max-height: 50px;
`
interface SnowflakeDetails {
    host: string;
    username: string;
    password: string;
    database: string;
    query_warehouse: string;
    loading_warehouse: string;
    build_warehouse: string;
    role: string;
    loader_user?: string;
    loader_password?: string;
    loader_database?: string;
    loader_role?: string;
}

export const NavBar = styled.div`
    height: 50px;
    border-bottom: solid 1px var(--ct-border-color);
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;

    img {
        max-height: 30px;
    }
`

const ProgressBarWrapper = styled.div`
height: 10px;
width: 100%;

div.progress {
    border-radius: 0px;
    height: 10px;
}
`

const StepHeaderStyles = styled.div<{active?: boolean;}>`
display: flex;

margin-bottom: 20px;

h2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
}

h3 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.step-number {
    width: 25px;
    height: 25px;
    border: 2px solid #00A1E0;
    border-radius: 13px;
    color: #00A1E0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;


}

&.active {
    .step-number {
        color: #fff;
        background-color: #00A1E0;
    }
}

&.complete {
    .step-number {
        color: #fff;
        background-color: var(--ct-success);
    }
}
`

const AnswerReview = styled.div`
border: solid 1px var(--ct-border-color);
font-size: 18px;
border-radius: 5px;
padding: 1rem;
`

interface DbtIconProps {
    className?: string;
}


const DbtIcon = (props: DbtIconProps) => {
    return <img className={props.className} style={{maxWidth: '15px'}} src={dbtmark}/>
}

const DBTInfo = (props: PropsWithChildren) => {
    return <div className="mb-1 font-13">
        <span className="me-1"><DbtIcon/></span> {props.children}
    </div>
}

const HelpStyles = styled.div`
img {
    max-width: 100%;
}
`;


const toolOptions = [
    {
        label: 'Snowflake',
        value: 'snowflake'
    },
    {
        label: 'BigQuery',
        value: 'bigquery'
    },
    {
        label: 'Redshift',
        value: 'redshift'
    },
    {
        label: 'Postgres',
        value: 'postgres'
    },
    {
        label: 'MySQL',
        value: 'mysql'
    },
    {
        label: 'MongoDB',
        value: 'mongodb'
    },
    {
        label: 'DynamoDB',
        value: 'dynamodb'
    },
    {
        label: 'Databricks',
        value: 'databricks'
    },
    {
        label: 'DBT',
        value: 'dbt'
    },
    {
        label: 'Fivetran',
        value: 'fivetran'
    },
    {
        label: 'Portable',
        value: 'portable'
    },
    {
        label: 'Stitch',
        value: 'stitch'
    },
    {
        label: 'Airbyte',
        value: 'airbyte'
    },
    {
        label: 'Github',
        value: 'github'
    },
    {
        label: 'Prefect',
        value: 'prefect'
    },
    {
        label: 'Orchestra',
        value: 'orchestra'
    },
    {
        label: 'Power BI',
        value: 'powerbi'
    },
    {
        label: 'Tableau',
        value: 'tableau'
    },
    {
        label: 'Looker',
        value: 'looker'
    },
    {
        label: 'Metabase',
        value: 'metabase'
    },
    {
        label: 'Coalesce',
        value: 'coalesce'
    },
    {
        label: 'Monte Carlo',
        value: 'montecarlo'
    },
    
    {
        
        label: 'Other',
        value: 'other'
    }
].sort((a, b) => a.label.localeCompare(b.label));

interface SnowflakeFormProps {
    snowflakeDetails: SnowflakeDetails;
    credsAreValid: boolean;
    tenantConfigId: string;
    showPliableHostedSnowflakeCode: boolean;
    onChange: (attr: keyof SnowflakeDetails, value: any) => any;
    onCredentialsAreValid: (areValid: boolean) => any;
    onNextAfterValidation: () => any;
}

const SnowflakeSetupForm = ({ onNextAfterValidation, snowflakeDetails, onChange, tenantConfigId, onCredentialsAreValid, showPliableHostedSnowflakeCode, credsAreValid }: SnowflakeFormProps) => {
    const validHost = useMemo(() => {
        const isValid = isValidSnowflakeURL(snowflakeDetails.host);
        return isValid;
    }, [snowflakeDetails.host]);

    const formIsValid = useMemo(() => {
        return validHost && !!snowflakeDetails.username && !!snowflakeDetails.database && !!snowflakeDetails.role && !!snowflakeDetails.query_warehouse;
    }, [snowflakeDetails, validHost]);

    const [savingConfig, setSavingConfig] = useState(false);
    const [saveConfigError, setSaveConfigError] = useState('');
    const [generatingScript, setGeneratingScript] = useState(false);
    const [generatingScriptError, setGeneratingScriptError] = useState('');
    const [setupSql, setSetupSql] = useState('');
    const [showScriptModal, setShowScriptModal] = useState(false);
    const [activationCode, setActivationCode] = useState('');
    const [creatingHostedSnowflake, setCreatingHostedSnowflake] = useState(false);

    const [testingCredentials, setTestingCredentials] = useState(false);
    const [credentialsError, setCredentialsError] = useState('');

    const generateScript = useCallback(async () => {
        setGeneratingScript(true);

        try {
            const { snowflake_setup_sql } : any = await TenantConfigService.getInstance().showSetupScript(tenantConfigId);
            setSetupSql(snowflake_setup_sql);
            setShowScriptModal(true);
        } catch (err) {
            setGeneratingScriptError(getErrorMessage(err));
        } finally {
            setGeneratingScript(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const saveSnowflakeDetails = useCallback(async (doGenerateScript: boolean = false) => {
        setSavingConfig(true);

        try {
            await TenantConfigService.getInstance().saveSnowflakeDetails(tenantConfigId, snowflakeDetails);
            if (generateScript) {
                await generateScript();
            }
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setSavingConfig(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);

    const createPliableHostedSnowflake = useCallback(async () => {
        setCreatingHostedSnowflake(true);

        try {
            await TenantConfigService.getInstance().createHostedSnowflake(tenantConfigId, activationCode);
            ConfigService.getInstance().loadConfig(); // need to flush tenant config
            onCredentialsAreValid(true);
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setCreatingHostedSnowflake(false);
        }
    }, [activationCode, tenantConfigId]);


    const [useDBT, setUseDBT] = useState<boolean|undefined>(undefined);
    
    const testCredentials = useCallback(async () => {
        setTestingCredentials(true);
        onCredentialsAreValid(false);
        setCredentialsError('');
        try {
            const { has_db_access } : any = await TenantConfigService.getInstance().verifySnowflakeAccess(tenantConfigId);

            if (!has_db_access) {
                throw new Error('Unable to connect to Snowflake.');
            } 
            const {job_id} : any = await TenantConfigService.getInstance().initInfrustructure(tenantConfigId);
            const {infra} : any = await BackgroundService.getInstance().waitForJob(job_id);
            if (infra.is_ready) {
                onCredentialsAreValid(true);
            } else {
                throw new Error('Error setting up infrastructure.');
            }
        } catch (err) {
            setCredentialsError(getErrorMessage(err));
            onCredentialsAreValid(false);
        } finally {
            setTestingCredentials(false);
        }
    }, [snowflakeDetails, tenantConfigId]);
    const [showAll, setShowAll] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    return <div style={{textAlign: 'left'}}>
        <Modal show={showScriptModal} size="lg" onHide={() => setShowScriptModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Setup Script</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InfoAlert>This script will create all of the resources (warehouses, databases, users, roles, etc) that Pliable needs to operate in your Snowflakea account.</InfoAlert>
                <p>
                    Log into your Snowflake account and run the following script in a new worksheet. Make sure "All Queries" is checked. When you're done, close this window and click on "Validate Connection".
                </p>
                <div style={{maxHeight: 400, overflowX: 'scroll'}} className="mb-3">
                    <CopyBlock
                        text={setupSql}
                        language="sql"
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
        <Offcanvas show={showHelp} onHide={() => setShowHelp(false)} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Help</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <HelpStyles className="p-3">
                            <p>To get your Snowflake host name:</p>
                            <h4>1. Open the account menu at the bottom left-hand side of the screen</h4>
                            <img src={SnowflakeMenuScreen} className="mb-3"/>
                            <h4>2. Hover over "Account", and then hover over the currently active account in the pop-out menu.</h4>
                            <p>You may have to scroll down if you have several Snowflake accounts.</p>
                            <img src={SnowflakeMenuScreen2} className="mb-3"/>
                            <h4>3. Click on the "Copy Account URL" button and paste it into Pliable</h4>
                            <img src={SnowflakeMenuScreen3} className="mb-3"/>
                            
                        </HelpStyles>
                    </PaneContent>
                </Pane>
                
            </Offcanvas.Body>
        </Offcanvas>

        {showPliableHostedSnowflakeCode && <>
            <h4>Let Pliable create a Snowflake account for me.</h4>
            <p className="text-muted font-13">Your Pliable account will incure additional charges based off your usage of snowflake.</p>

            <Form.Group className="mb-3">
                <Form.Label className="small">Activation Code</Form.Label>
                <Form.Control
                    name="activation_code"
                    type="password"
                    placeholder=""
                    value={activationCode} 
                    onChange={(e) => setActivationCode(e.target.value)}
                />
                <Form.Text></Form.Text>
            </Form.Group>
            <hr />

            {!credsAreValid && 
                <AsyncButton
                    variant="pliable"
                    icon='mdi mdi-database-edit-outline'
                    disabled={!activationCode}
                    loading={creatingHostedSnowflake}
                    onClick={() => createPliableHostedSnowflake()}
                    text="Create Snowflake Account"
                />
            }

            {creatingHostedSnowflake && (
                <div>
                    <Form.Text>
                        Setting up new Snowflake account for you. This may take up to 5 minutes.
                    </Form.Text>
                </div>

            )}

            {!creatingHostedSnowflake && credsAreValid && <div className="mt-2">
                <SuccessAlert>
                    <div className="flex-1">Connection successful!</div>
                    <button className="btn btn-success" onClick={onNextAfterValidation}>Next</button>
                    
                </SuccessAlert>

            </div>
            }
            

        </>}

        {!showPliableHostedSnowflakeCode && <>

            <h3 className="mb-0">Snowflake Account Details</h3>
            <p className="text-muted">We'll use these details to generate a script for you to run on your Snowflake account, which will create all of the resources Pliable needs to operate. The only required field is the <strong>host</strong>.</p>

            <Form.Group className="mb-3">
                <Form.Label>Host</Form.Label>
                <Form.Control
                    name="host"
                    type="text"
                    placeholder="your-account.snowflakecomputing.com"
                    value={snowflakeDetails.host}
                    isValid={validHost} 

                    onChange={(e) => onChange('host', e.target.value)}
                />
                <Form.Text>
                    <a role="button" href="#" onClick={() => setShowHelp(true)}>Where do I find this?</a>
                </Form.Text>
            </Form.Group>
            <div className="mb-2">
                <Form.Check
                    type="switch"
                    checked={showAll}
                    label="Show Advanced Configuration"
                    onChange={(e) => setShowAll(e.target.checked)}
                />
            </div>
            
            <Collapse in={showAll}>
                <div className="shadow-box p-3">
                    <h3>Advanced Configuration</h3>
                        <Form.Group className="mb-3">
                            <Form.Label>Username</Form.Label>
                            
                            <Form.Control
                                name="username"
                                type="text"
                                placeholder=""
                                value={snowflakeDetails.username}

                                onChange={(e) => onChange('username', e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                name="password"
                                type="password"
                                placeholder=""
                                value={snowflakeDetails.password}

                                onChange={(e) => onChange('password', e.target.value)}
                            />
                            <Form.Text>Leave blank and we will automatically generate one for you.</Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Database</Form.Label>
                            <div></div>
                            <Form.Control
                                name="database"
                                type="text"
                                placeholder=""
                                value={snowflakeDetails.database}

                                onChange={(e) => onChange('database', e.target.value)}
                            />
                            <Form.Text>
                                Enter the name of the database where Pliable should store its data
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Warehouse (Building)</Form.Label>
                            
                            <Form.Control
                                name="build_warehouse"
                                type="text"
                                placeholder=""
                                value={snowflakeDetails.build_warehouse}

                                onChange={(e) => onChange('build_warehouse', e.target.value)}
                            />
                            <Form.Text>
                                Enter the name of the warehouse Pliable should use for building your models.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Warehouse (Querying)</Form.Label>
                            <Form.Control
                                name="query_warehouse"
                                type="text"
                                placeholder=""
                                value={snowflakeDetails.query_warehouse}

                                onChange={(e) => onChange('query_warehouse', e.target.value)}
                            />
                            <Form.Text>
                                Enter the name of the warehouse Pliable should use for querying your models. It's best practice to keep these separate so you don't compete for resources with the scheduled build processes.
                            </Form.Text>
                        </Form.Group>
                        
                        <Form.Group className="mb-3">
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                name="role"
                                type="text"
                                placeholder=""
                                value={snowflakeDetails.role}

                                onChange={(e) => onChange('role', e.target.value)}
                            />
                            <Form.Text>
                                Enter the name of the role Pliable should use when interacting with Snowflake. 
                            </Form.Text>
                        </Form.Group>
                        <hr />
                    <h4>Data Ingestion (Optional)</h4>
                    <Form.Group className="mb-3">
                        <Form.Label>User</Form.Label>
                        <Form.Control
                            name="loader_user"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.loader_user}

                            onChange={(e) => onChange('loader_user', e.target.value)}
                        />
                        <Form.Text>
                            Enter a username to use for loading data into your Snowflake
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            name="loader_password"
                            type="password"
                            placeholder=""
                            value={snowflakeDetails.loader_password}

                            onChange={(e) => onChange('loader_password', e.target.value)}
                        />
                        <Form.Text>Leave blank and we will automatically generate one for you.</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Database</Form.Label>
                        <Form.Control
                            name="loader_database"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.loader_database}

                            onChange={(e) => onChange('loader_database', e.target.value)}
                        />
                        <Form.Text>
                            Enter the name of the database for housing raw data ingested from your data sources.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                            name="loader_role"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.loader_role}

                            onChange={(e) => onChange('loader_role', e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Warehouse (Loading)</Form.Label>
                        <Form.Control
                            name="loading_warehouse"
                            type="text"
                            placeholder=""
                            value={snowflakeDetails.loading_warehouse}

                            onChange={(e) => onChange('loading_warehouse', e.target.value)}
                        />
                        <Form.Text>
                            Enter the name of the warehouse Pliable should use for loading in data.
                        </Form.Text>
                    </Form.Group>

                </div>
            </Collapse>
            <p className="text-muted">Once you're ready, generate and run the setup script to connect Pliable to your Snowflake.</p>     
            <AsyncButton
                variant="pliable"
                icon='mdi mdi-database-edit-outline'
                disabled={!formIsValid}
                loading={savingConfig}
                onClick={() => saveSnowflakeDetails(true)}
                text="Generate Setup Script"
            />
            <hr />
            <h3 className="mb-0">Validate Connection</h3>
            <p className="text-muted">Once you've run the script, we just need to validate the connection.</p>                       
            
            <AsyncButton
                variant="primary"
                icon='mdi mdi-database-edit-outline'
                disabled={!formIsValid}
                loading={testingCredentials}
                onClick={() => testCredentials()}
                text="Validate Connection"
            />
            {testingCredentials && (
                <div>
                    <Form.Text>
                        We're running a few different checks, which may take up to 30 seconds.
                    </Form.Text>
                </div>
                
            )}
            {credentialsError && (
                <>
                    <p>
                        <strong className="text-danger">Oops!</strong> We were unable to connect to your Snowflake. Please check your credentials and make sure the setup script ran successfully.
                    </p>
                    <Danger>
                        {credentialsError}
                    </Danger>
                </>
            )}
            {!testingCredentials && credsAreValid && <div className="mt-2">
                <SuccessAlert>
                    <div className="flex-1">Connection successful!</div>
                    <button className="btn btn-success" onClick={onNextAfterValidation}>Next</button>
                    
                </SuccessAlert>

            </div>
            }
        </>}
        
                    
    </div>
}




const INDUSTRY_OPTIONS = [
    'Construction',
    'Consulting',
    'E-Commerce',
    'Finance',
    'Government',
    'Healthcare',
    'Hospitality',
    'iGaming',
    'Insurance',
    'Lending',
    'Manufacturing',
    'Real Estate',
    'Software',
    'Other',
];

const USE_CASE_OPTIONS = [
    'Affiliate Marketing',
    'Asset Performance',
    'Customer Satisfaction',
    'Financial Health',
    'Employee Performance',
    'Marketing Funnel',
    'Product Usage',
    'Team Productivity',
    'Sales Funnel',
    'SEO',
    'Supply Chain',
    'Other',
];

interface ToolGroup {
    name: string;
    prompt: string;
    otherValue: string;
    otherKey: keyof SurveyDataSources;
    toolOptions: string[];
}

const TOOL_GROUPS: {
    [key: string]: ToolGroup;
} = {
    'AD_PLATFORMS': {
        name: 'Ad Platforms',
        prompt: 'Connect to your advertising platform sso Pliable can pull in information about your ad campaigns and their performance.',
        toolOptions: [
            'Meta Ads',
            'Google Ads',
            'LinkedIn Ads',
            'Bing Ads',
            'Other Ad Platform',
        ],
        otherKey: 'otherAdPlatform',
        otherValue: 'Other Ad Platform'
    },
    'AFFILIATE_MARKETING': {
        name: 'Affiliate Marketing Platforms',
        prompt: 'Connect to your affiliate marketing platform so Pliable can pull in information about your commissions.',
        otherValue: 'Other Affiliate Platform',
        otherKey: 'otherAffiliatePlatform',
        toolOptions: [
            'Affiliate Window',
            'Commission Junction',
            'Impact Radius',
            'Link Connector',
            'Partnerize',
            'Pepperjam',
            'Rakuten',
            'Skimlinks',
            'Other Affiliate Platform',
        ]
    },
    'ERPS': {
        name: 'ERP',
        prompt: 'Connect to your ERP so Pliable can pull in information about projects, inventory, vendors, and accounting.',
        otherValue: 'Other ERP',
        otherKey: 'otherERP',
        toolOptions: [
            'Netsuite',
            'SAP',
            'Viewpoint Spectrum',
            'Other ERP',
        ]
    },
    'HR': {
        name: 'HR Platforms',
        prompt: 'Connect to your HR platform so Pliable can pull in information about your employees, salaries, and performance.',
        toolOptions: [
            'Rippling',
            'Gusto',
            'Other HR Platform',
        ],
        otherKey: 'otherHRPlatform',
        otherValue: 'Other HR Platform',
    },
    'SELLING_PLATFORMS': {
        name: 'Selling Platforms',
        prompt: 'Connect to your selling platforms so Pliable can pull in information about your sales, performance by keyword, etc.',
        toolOptions: [
            'Amazon',
            'Etsy',
            'Shopify',
            'Other Selling Platform',
        ],
        otherKey: 'otherSellingPlatform',
        otherValue: 'Other Selling Platform',
    },
    'CRMS': {
        name: 'CRMs',
        prompt: 'Connect your CRM(s) to bring in information about your customers, sales & marketing activities, and conversions.',
        toolOptions: [
            'Hubspot',
            'Pipedrive',
            'Salesforce',
            'Other CRM',
        ],
        otherKey: 'otherCRM',
        otherValue: 'Other CRM',
    },
    'PRODUCT_DATABASES': {
        name: 'Application Databases',
        prompt: "Connect to your application database (preferably a read-replica) so Pliable can pull in information about your product usage.",
        toolOptions: [
            'DynamoDB',
            'MongoDB',
            'MySQL',
            'PostgreSQL',
            'Other Database',
        ],
        otherKey: 'otherProductDatabase',
        otherValue: 'Other Database',
    },
    'ACCOUNTING': {
        name: 'Accounting',
        prompt: 'Connect to your accounting system to Pliable can pull in information about your revenue and expenses.',
        toolOptions: [
            'Bill.com',
            'Quickbooks',
            'Stripe',
            'Xero',
            'Other Accounting System',
        ],
        otherKey: 'otherAccountingTool',
        otherValue: 'Other Accounting System',
    },
    'SPORTSBOOK': {
        name: 'Sports Book',
        prompt: 'Connect your sports book to pull in information about players, bets, and odds.',
        toolOptions: [
            'Kambi',
            'Other Sportsbook'
        ],
        otherKey: 'otherSportsbook',
        otherValue: 'Other Sportsbook',
    }, 
    'SEO': {
        name: 'SEO',
        prompt: 'Connect to your SEO tools to report on your website performance on search engines.',
        toolOptions: [
            'Google Search Console',
            'Google Analytics',
            'Segment',
            'Rudderstack',
            'A HREFS',
            'Other SEO Tool',
        ],
        otherKey: 'otherSEO',
        otherValue: 'Other SEO Tool',
    },
    'PAM': {
        name: 'iGaming PAM',
        prompt: 'Connect your PAM to pull in player and balance information.',
        toolOptions: [
            'White Hat Gaming',
            'Other PAM',
        ],
        otherKey: 'otherPAM',
        otherValue: 'Other PAM',
    },
    'LMS': {
        name: 'Loan Management System',
        prompt: 'Loans performance, payment history, default rate, etc.',
        toolOptions: [
            'Nortridge',
            'Totality',
            'Other LMS',
        ],
        otherKey: 'otherLMS',
        otherValue: 'Other LMS',
    },
    'OUTREACH': {
        name: 'Customer Outreach Tools',
        prompt: 'Opens, clicks, connections, etc for outbound (email, phone, direct mail, in-app).',
        toolOptions: [
            'Braze',
            'Iterable',
            'Klaviyo',
            'Mailchimp',
            'Sendgrid',
            'Twilio',
            'Other Outreach Tool',
        ],
        otherKey: 'otherOutreachTool',
        otherValue: 'Other Outreach Tool',

    },
    'ANALYTICS': {
        name: 'Analytics Tools',
        prompt: 'Product analytics, like logins, feature usage, etc.',
        toolOptions: [
            'AppsFlyer',
            'FullStory',
            'Google Analytics',
            'Pendo',
            'Rudderstack',
            'Segment',
            'Other Analytics Tool',
        ],
        otherKey: 'otherAnalyticsTool',
        otherValue: 'Other Analytics Tool'
    }, 
    'PRODUCTIVITY': {
        name: 'Productivity & Project Management Tools',
        prompt: 'Project efficiency, workload, resource-level performance',
        toolOptions: [
            'Airtable',
            'Asana',
            'ClickUp',
            'Gitlab',
            'GitHub',
            'JIRA',
            'Monday.com',
            'Other Productivity Tool',
        ],
        otherKey: 'otherProductivityTool',
        otherValue: 'Other Productivity Tool',
    }, 
    'SUPPORT': {
        name: 'Support Tools',
        prompt: 'Customer satisfaction, open tickets, call center volume, etc.',
        toolOptions: [
            'Aircall',
            'Open Phone',
            'Zendesk',
            'Zoom Contact Center',
            'Other Support Tool',
        ],
        otherKey: 'otherSupportTool',
        otherValue: 'Other Support Tool',
    }

}


const OnboardingStep = styled.div`
text-align: center;
width: 1000px;
margin: 100px auto;
opacity: 1.0;

h1, h1 {
    color: #0F0E31;
}
h1 {
    font-weight: 400;
    line-height: 30px;
}

h2 {
    font-weight: 200;
}

&.faded {
    opacity: 0.0;
}

input.custom-value {
    width: 200px;
    margin: 0px auto;
    border-radius: 10px;
}

textarea.custom-value {
    margin: 0px auto;
    width: 300px;
    border-radius: 10px;
}

transition: opacity 0.15s ease-in-out;
-webkit-transition: opacity 0.15s ease-in-out;
`

const AIOrbStyles = styled.div`
width: 100px;
height: 100px;
img {
    width: 100%;
    height: 100%;
}


margin: 0px auto 25px auto;
`

const AIOrb = () => {
    return <AIOrbStyles>
        <img src={BrandMark}/>
    </AIOrbStyles>
}


const NextPreviousButton = styled.button`
border: none;
background: none;
color: var(--ct-primary);
font-weight: bold;
font-family: "Poppins";
font-size: 26px;
&:hover {
    text-decoration: underline;
}

&:disabled {
    color: #ccc;
    &:hover {
        text-decoration: none;
    }
}
`

const OnboardingOption = styled.div<{selected?: boolean}>`
&:hover {
    background: var(--ct-light);
    cursor: pointer;
}

${props => props.selected && css`
    background-color: var(--ct-primary);
    color: white !important;

    h3 {
    color: white !important;}

    &:hover {
        background-color: var(--ct-primary);
    }
`}
`

export const humanReadableList = function(l: string[]): string {
    const uniqueList = l.filter((a: string, idx: number, arr: string[]) => {
        return arr.indexOf(a) === idx;
    })
    if (uniqueList.length == 0) {
        return '_______';
    }
    if (uniqueList.length == 1) {
        return l[0];
    }

    if (uniqueList.length == 2) {
        return l[0] + ' and ' + l[1];
    }

    return uniqueList.slice(0, -1).join(', ') + ', and ' + uniqueList[uniqueList.length - 1];
}

const SummaryLink = styled.a`
color: var(--pliable-yellow);
font-weight: bold;
&:hover {
    text-decoration: underline;
}
`

const OnboardingWizardPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const setDataLibraryEnabled = useGlobalState((state: any) => state.setLibraryEnabled);
    setDataLibraryEnabled(false);
    const { isLoading: loadingBillingAccount, data: billingAccount, refetch: refetchBillingAccount } = useBillingAccount(searchParams.get('session_id') != null);
    const [snowflakeDetails, setSnowflakeDetails] = useState<SnowflakeDetails>({
        host: '',
        username: 'PLIABLE_USER',
        password: '',
        database: 'PLIABLE_DATABASE',
        role: 'PLIABLE_ROLE',
        query_warehouse: 'PLIABLE_QUERY_WAREHOUSE',
        loading_warehouse: 'PLIABLE_LOADING_WAREHOUSE',
        build_warehouse: 'PLIABLE_BUILD_WAREHOUSE',
        loader_user: 'PLIABLE_LOADER_USER',
        loader_database: 'PLIABLE_LOADER_DATABASE',
        loader_role: 'PLIABLE_LOADER_ROLE',
    
    });

    const [answers, setAnswers] = useImmer<SurveyAnswers>({
        dataTeamSize: '',
        industry: {
            selection: '',
            otherValue: '',
        },
        role: '',
        otherRole: '',
    })

    const [tenantConfigId, setTenantConfigId] = useState('');

    const [tenantInfraIsReady, setTenantInfraIsReady] = useState(false);
    const [showPliableHostedSnowflakeCode, setShowPliableHostedSnowflakeCode] = useState(false);

    const setLoadSampleData = useCallback((load: boolean) => {
        setAnswers(draft => {
            draft.sampleData = load;
        })
    }, []);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useHotkeys('ctrl+h', () => {
        if(!tenantInfraIsReady){
            setShowPliableHostedSnowflakeCode(!showPliableHostedSnowflakeCode);
            console.log('Showing hosted snowflake code');
        } else {
            console.log('Infra is already ready');
        }
    }, [tenantInfraIsReady, showPliableHostedSnowflakeCode]);

    const loadData = async () => {
        setLoading(true);
        try {
            const {
                tenantRegistration,
                tenant_config,
                connection_creds,
                loader_creds
            } : any = await TenantService.getInstance().getTenantRegistration();
    
            setTenantConfigId(tenant_config.id);
            if (tenant_config.onboarding_info) {
                
                setAnswers(tenant_config.onboarding_info);

            }
            setTenantInfraIsReady(tenant_config.infra.is_ready);
            setChosenDatabase(tenant_config.database);
            setUseTemplate(tenant_config.last_used_template);
            setSnowflakeConnectionIsValid(tenant_config.infra.is_ready);

            setSnowflakeDetails(produce(snowflakeDetails, draft => {
                return {
                    host: connection_creds.host,
                    username: connection_creds.username,
                    password: connection_creds.password,
                    database: connection_creds.database,
                    role: connection_creds.role,
                    query_warehouse: connection_creds.query_warehouse,
                    loading_warehouse: loader_creds.loading_warehouse,
                    build_warehouse: connection_creds.build_warehouse,
                    loader_role: loader_creds.role,
                    loader_user: loader_creds.username,
                    loader_password: loader_creds.password,
                    loader_database: loader_creds.database,
                };
            }));
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
        
    }

    useEffect(() => {
        loadData();
    }, []);

    const [savingDatabaseChoice, setSavingDatabaseChoice] = useState(false);
    const chooseDatabase = useCallback(async (db: string) => {
        setSavingDatabaseChoice(true);
        
        try {
            const newConfig = await TenantConfigService.getInstance().saveDatabaseChoice(tenantConfigId, db);
            setChosenDatabase(db);

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSavingDatabaseChoice(false);
        }
    }, [tenantConfigId]);

    const [advanced, setAdvanced] = useState(false);

    const [onboardingMethod, setOnboardingMethod] = useState('');
    const [chosenDatabase, setChosenDatabase] = useState('');
    const [useTemplate, setUseTemplate] = useState('');
    const [savingConfig, setSavingConfig] = useState(false);
    const [configSaved, setConfigSaved] = useState(false);
    const [saveConfigError, setSaveConfigError] = useState('');
    const [setupSql, setSetupSql] = useState('');
    const [generatingScript, setGeneratingScript] = useState(false);
    const [generatingScriptError, setGeneratingScriptError] = useState('');
    const [skipReveal, setSkipReveal] = useState(false);

    const [testingCredentials, setTestingCredentials] = useState(false);
    const [finalizingOnboarding, setFinalizingOnboarding] = useState(false);
    const [credentialsError, setCredentialsError] = useState('');
    const [credentialsAreValid, setCredentialsAreValid] = useState(false);

    // grab from param or set to be first step
    const {onboardingStepName = stepsOrder[0]} = useParams();

    const onboardingStep = stepsOrder.indexOf(onboardingStepName as WizardStep);

    const setOnboardingStep = useCallback((step: number) => {
        const step_name = stepsOrder[step];
        navigate(`/onboarding/${step_name}`);
    }, []);

    // useEffect(() => {
    //     if (step) {
    //         try {
    //             const i = parseInt(step, 10);
    //             setOnboardingStep(i);
    //         } catch (err) {
    //             navigate('/onboarding');
    //         }
    //     }
    // }, [step]);

    const [saving, setSaving] = useState(false);

    const saveData = useCallback(async () => {
        setSaving(true);
        try {
            await TenantConfigService.getInstance().updateOnboardingInfo(tenantConfigId, answers);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [answers, tenantConfigId]);

    const finishSetup = useCallback(async () => {
        setFinalizingOnboarding(true);
        try {
            saveData();
            await TenantConfigService.getInstance().completeOnboarding();
            navigate('/');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setFinalizingOnboarding(false);
        }
    }, [saveData]);

    const debouncedSave = useDebouncedCallback(saveData, 1000);

    useEffect(() => {
        if(!loading) {
            debouncedSave();
        }
    }, [answers, loading])
    
    const moveToStep = useCallback((step: number) => {
        setOnboardingStep(step);
    }, [saveData]);
    const saveAndContinue = useCallback(async () => {
        navigate('/onboarding/database');
    }, []);
    const validHost = useMemo(() => {
        const isValid = isValidSnowflakeURL(snowflakeDetails.host);
        return isValid;
    }, [snowflakeDetails.host]);

    const formIsValid = useMemo(() => {
        return validHost && !!snowflakeDetails.username && !!snowflakeDetails.database && !!snowflakeDetails.role && !!snowflakeDetails.query_warehouse;
    }, [snowflakeDetails, validHost]);

    const setSnowflakeValue = (key: keyof SnowflakeDetails, value: string) => {
        setSnowflakeDetails(produce(snowflakeDetails, draft => {
            draft[key] = value;
        }));
    }

    const saveSnowflakeDetails = useCallback(async () => {
        setSavingConfig(true);

        try {
            await TenantConfigService.getInstance().saveSnowflakeDetails(tenantConfigId, snowflakeDetails);
            setConfigSaved(true);
            setOnboardingStep(2);
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setSavingConfig(false);
        }
        
    }, [snowflakeDetails, tenantConfigId]);


   

    const onSetupComplete = useCallback(() => {
        if(useTemplate) {
            navigate(`/template/${useTemplate}?autouse=true`);
        }

    }, [useTemplate]);

   

    const [creatingPliableHostedGit, setCreatingPliableHostedGit] = useState(false);

    const savePliableGitSource = useCallback(async () => {
        setCreatingPliableHostedGit(true);

        try {
            const response = await TenantConfigService.getInstance().saveRepo(tenantConfigId, 'PLIABLE', '');
            await enterDraftMode('onboarding', false);

            // Reload the config so it doesn't redirect them to onboarding
            await ConfigService.getInstance().loadConfig();
        } catch (err) {
            setSaveConfigError(getErrorMessage(err));
        } finally {
            setCreatingPliableHostedGit(false);
        }
    }, [tenantConfigId]);

    const [stepStatuses, setStepStatuses] = useImmer<{[key: string]: string}>({
        'snowflake': '',
    });

    const [snowflakeAdminUsername, setSnowflakeAdminUsername] = useState('');
    const [snowflakeAdminPassword, setSnowflakeAdminPassword] = useState('');

    const [runningAutoSetup, setRunningAutoSetup] = useState(false);
    const autoSetup = useCallback(async () => {
        await saveSnowflakeDetails();
        console.log('Saved details');
        const res = await TenantConfigService.getInstance().autoSetupSnowflake(tenantConfigId, snowflakeAdminUsername, snowflakeAdminPassword);

        setOnboardingStep(1);
    }, [snowflakeDetails, tenantConfigId, snowflakeAdminUsername, snowflakeAdminPassword, saveSnowflakeDetails]);

    const [showAllTools, setShowAllTools] = useState(false);
    
    
    const [faded, setFaded] = useState(false);

    const [snowflakeConnectionIsValid, setSnowflakeConnectionIsValid] = useState(false);

    const changeStep = useCallback((step: number) => {
        TrackingService.track_event('onboarding_step', {
            step: step,
            answers: answers,
            snowflakeConnectionIsValid,
        });
        setOnboardingStep(step);
            
    }, [answers, saveData, savePliableGitSource, snowflakeConnectionIsValid]);


    const canGoNext = useMemo(() => {
        switch (onboardingStep) {
            case 0:
                return snowflakeConnectionIsValid;
            case 1:
                return false;
            case 2:
                return false;
            default:
                return true;
        }
    }, [snowflakeConnectionIsValid, onboardingStep, answers]);

    const onEventScheduled = useCallback((e: EventScheduledEvent) => {
        // setAnswers(draft => {
        //     draft.database.onboardingEventLink = e.data.payload.event.uri;
        //     draft.database.onboardingInviteeLink = e.data.payload.invitee.uri;
        //     queryClient.invalidateQueries(['calendly_onboarding_event'])
        // });
    }, []);

    useCalendlyEventListener({
       onEventScheduled: onEventScheduled,
    });

    const [bookCall, setBookCall] = useState(false);

    


    const authState = useAuthState();
    const innerContent = useMemo(() => {
        // if (answers.database.onboardingEventLink) {
        //     return <>
        //         <h1 className="mb-2">Your onboarding call is scheduled.</h1>
        //         <div className="shadow-box p-3">
        //             <CalendlyEventInfo eventUuid={answers.database.onboardingEventLink}/>
        //         </div>
                
        //     </>;
        // }

        if (onboardingStep == 0) {
            // Survey
            return <>
                <h1>Welcome to Pliable, the data organization platform for Snowflake.</h1>
                <h2 className="fw-light">We need to ask you some questions to personalize your experience.</h2>
                <Form.Group className="mb-3">
                    <Form.Label>How big is your data team?</Form.Label>
                    <Dropdown
                        options={
                            [
                                {
                                    label: "I don't have one",
                                    value: 'no_team',
                                },
                                {
                                    label: 'Just one person',
                                    value: 'just_one'
                                },
                                {
                                    label: '2-5 people',
                                    value: '2_5'
                                },
                                {
                                    label: '6-10 people',
                                    value: '6_10'
                                },
                                {
                                    label: '11-20 people',
                                    value: '11_20'
                                },
                                {
                                    label: '21+ people',
                                    value: '21_plus'
                                }
                            ]
                        }
                        selected={answers.dataTeamSize}
                        onChange={(value) => {
                            setAnswers(draft => {
                                draft.dataTeamSize = value;
                            });
                        }}
                    />
                    
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Which other tools do you have in your data stack?</Form.Label>
                    <MultiDropdown
                        options={toolOptions}
                        selected={answers.existingTools || []}
                        onChange={(value) => {
                            setAnswers(draft => {
                                draft.existingTools = value;
                            });
                        }}
                    />
                    <Form.Text>This will help us recommend the best integrations for your workflow.</Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>What's your role at your company?</Form.Label>
                    <Dropdown
                        options={
                            [
                                {
                                    label: 'Data Analyst',
                                    value: 'data_analyst'
                                },
                                {
                                    label: 'Data Scientist',
                                    value: 'data_scientist'
                                },
                                {
                                    label: 'Data Engineer',
                                    value: 'data_engineer'
                                },
                                {
                                    label: 'Business Analyst',
                                    value: 'business_analyst'
                                },
                                {
                                    label: 'Product',
                                    value: 'product'
                                },
                                {
                                    label: 'Marketing',
                                    value: 'marketing'
                                },
                                {
                                    label: 'Sales',
                                    value: 'sales'
                                },
                                {
                                    label: 'HR',
                                    value: 'hr'
                                },
                                {
                                    label: 'Finance / Ops',
                                    value: 'finance_ops'
                                },
                                {
                                    label: 'Executive',
                                    value: 'executive'
                                },
                                {
                                    label: 'Other',
                                    value: 'other'
                                }
                            ]
                        }
                        selected={answers.role}
                        onChange={(value) => {
                            setAnswers(draft => {
                                draft.role = value;
                            });
                        }}
                    />
                    {answers.role == 'other' && <Form.Control 
                        className="mt-2"  
                        placeholder="Enter your role here" 
                        type="text" 
                        value={answers.otherRole} 
                        onChange={(e) => setAnswers(draft => {
                            draft.otherRole = e.target.value;
                        })}
                    />}
                </Form.Group>
                <div className="text-end">
                    <button 
                        className="btn btn-lg btn-light me-2"
                        onClick={async () => {
                            await saveData();
                            changeStep(onboardingStep + 1);
                        }}
                    >
                        Skip
                    </button>
                    <button 
                        className="btn btn-lg btn-pliable" 
                        onClick={async () => {
                            await saveData();
                            changeStep(onboardingStep + 1);
                        }}
                        disabled={!answers.dataTeamSize || !answers.existingTools || !answers.existingTools.length || !answers.role}>
                            Next
                        </button>
                </div>
                
            </>
        }

        if (onboardingStep == 1) {
            return <>
                
                <h1>Next: connect your Snowflake account.</h1>
                <h2 className="fw-light">Need to sign up for Snowflake? <a href="https://trial.snowflake.com/?owner=SPN-PID-724372" target="_blank">Click Here</a> for a free trial and come back once your account is created.</h2>
                <hr />
                <SnowflakeSetupForm
                    snowflakeDetails={snowflakeDetails}
                    onChange={setSnowflakeValue}
                    credsAreValid={snowflakeConnectionIsValid}
                    tenantConfigId={tenantConfigId}
                    showPliableHostedSnowflakeCode={showPliableHostedSnowflakeCode}
                    onCredentialsAreValid={(areValid) => {
                        setSnowflakeConnectionIsValid(areValid);
                    }}
                    onNextAfterValidation={() => {
                        changeStep(2);
                    }}
                />
            </>
        }

        if (onboardingStep == 2) {
            if (finalizingOnboarding) {
                return <LoadingCard action='Completing setup' />
            }
            
            return <>
                <h1 className="mb-5"><i className="mdi mdi-calendar"></i> Nice! Let's start your 14-day free trial</h1>
                <p>You'll get access to all of our features and can choose your plan later.</p>
                {/* <div className="mb-4">
                    <h4>Would you like to start with sample data?</h4>
                    <div className="row">
                        <div className="col-6">
                            <OnboardingOption onClick={() => setLoadSampleData(true)} className="shadow-box p-3 text-center" selected={answers.sampleData === true}>
                                <div><i className="mdi mdi-check-circle font-24"></i></div>
                                <h3>Yes, I want to use sample data</h3>
                                <p>Get started quickly with a pre-loaded dataset.</p>
                            </OnboardingOption>
                        </div>
                        <div className="col-6">
                            <OnboardingOption onClick={() => setLoadSampleData(false)} className="shadow-box p-3 text-center" selected={answers.sampleData === false}>
                                <div><i className="mdi mdi-close-circle font-24"></i></div>
                                <h3>No, I'll start from scratch</h3>
                                <p>Begin with a clean slate and add your own data.</p>
                            </OnboardingOption>
                        </div>
                    </div>
                </div> */}
                <div className="text-end">
                    <button className="btn btn-success btn-lg" onClick={finishSetup} >
                        Let's rock.
                    </button>
                </div>
                {/* <div className="bg-light p-3">
                    <div className="d-flex">
                        <div className="me-3">
                            <i className="mdi mdi-account-tie font-48"></i>
                        </div>
                        <div className="flex-1">
                            <h3 className="fw-normal">Book a call with a product expert</h3>
                            <p>Get personalized guidance on how to get the most out of Pliable.</p>
                            <button className="btn btn-dark" onClick={() => setBookCall(true)}>Schedule call</button>

                        </div>
                    </div>
                </div> */}
                <Modal show={bookCall} onHide={() => setBookCall(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Book a call with a product expert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InlineWidget
                            styles={{height: '800px', width: '100%'}}
                            url="https://calendly.com/d/ckc3-gd4-2wh/pliable-onboarding" 
                            prefill={{
                                email: authState?.user?.email,
                                name: authState?.user?.name,
                            }}
                            
                        />
                    </Modal.Body>
                </Modal>
            </>
        }
        return <>
            {/* {onboardingStep == 0 && <>
                <h1>Welcome to Pliable.</h1>
                <h2>We need to ask you some questions to personalize your experience.</h2>
                
            </>}
            {onboardingStep == 1 && <>
            
                <h1>Which industry best describes your business?</h1>
                <h2>This doesn't need to be exact. Just choose whatever is closest!.</h2>
                <ButtonGrid>
                    {INDUSTRY_OPTIONS.map(i => {
                        return <button onClick={() => {
                            setAnswers(draft => {
                                draft.industry.selection = i;
                            });
                        }} className={answers.industry.selection == i ? 'active' : ''}>
                            {i}
                        </button>
                    })}
                </ButtonGrid>
                {answers.industry.selection == 'Other' && <div className="mt-2">
                    <Form.Control className="custom-value"  placeholder="Enter your industry here" type="text" value={answers.industry.otherValue} onChange={(e) => setAnswers(draft => {
                        draft.industry.otherValue = e.target.value;
                    })}/>
                </div>}
                
            </>}
            {onboardingStep == 2 && <>
            
                <h1>How do you make money?</h1>
                <h2>Check all that apply.</h2>
                <ButtonGrid>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.businessType.products = !draft.businessType.products;
                        })
                    }} className={answers.businessType.products ? 'active' : ''}>
                        We sell products
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.businessType.services = !draft.businessType.services;
                        })
                    }} className={answers.businessType.services ? 'active' : ''}>
                        We sell services
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.businessType.assets = !draft.businessType.assets;
                        })
                    }} className={answers.businessType.assets ? 'active' : ''}>
                        We manage assets
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.businessType.referrals = !draft.businessType.referrals;
                        })
                    }} className={answers.businessType.referrals ? 'active' : ''}>
                        We get commission
                    </button>
                </ButtonGrid>
            </>}
            {onboardingStep == 3 && <>
                
                <h1>Who are your customers?</h1>
                <h2>Check all that apply.</h2>
                <ButtonGrid>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.customers.smbs = !draft.customers.smbs;
                        })
                    }} className={answers.customers.smbs ? 'active' : ''}>
                        SMBs
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.customers.enterprises = !draft.customers.enterprises;
                        })
                    }} className={answers.customers.enterprises ? 'active' : ''}>
                        Enterprise
                    </button>
                    <button onClick={() => {
                        setAnswers(draft => {
                            draft.customers.consumers = !draft.customers.consumers;
                        })
                    }} className={answers.customers.consumers ? 'active' : ''}>
                        Consumers
                    </button>
                </ButtonGrid>
            </>}
            {onboardingStep == 4 && <>
                <h1>What are you interested in measuring?</h1>
                <h2>Based on your answers, we've made a few guesses, but feel free to change it or add more.</h2>
                <ButtonGrid>
                    {USE_CASE_OPTIONS.map(i => {
                        return <button onClick={() => {
                            setAnswers(draft => {
                                const index = draft.useCases.selections.indexOf(i);
                                if (index >= 0) {
                                    draft.useCases.selections.splice(index, 1);
                                } else {
                                    draft.useCases.selections.push(i);
                                }
                                
                            });
                        }} className={answers.useCases.selections.includes(i) ? 'active' : ''}>
                            {i}
                        </button>
                    })}
                </ButtonGrid>
                {answers.useCases.selections.includes('Other') && <div className="mt-2">
                    <Form.Control className="custom-value"  as="textarea" placeholder="Enter your use case here" type="text" value={answers.useCases.otherValue} onChange={(e) => setAnswers(draft => {
                        draft.useCases.otherValue = e.target.value;
                    })}/>
                </div>}
            </>}
            {onboardingStep == 5 && <>
                <h1>Where's your data?</h1>
                <button onClick={() => {
                    setAnswers(draft => {
                        draft.database.hasWarehouse = true;
                    });
                    changeStep(8);
                }}>I already have data in my warehouse</button>
                <h2>Based on your previous answers, I've determined that I need data from the following types of tools.</h2>
                <div style={{width: '200px', margin: '0px auto'}}>
                    <Form.Check
                        type="switch"
                        checked={showAllTools}
                        onChange={(e) => setShowAllTools(!showAllTools)}
                        label="Show all tool categories"
                    />
                </div>
                
                
                {Object.keys(TOOL_GROUPS).filter(k => toolTypes.includes(k)).map(k => {
                    return <div className="shadow-box p-3 mt-3">
                        <h4>{TOOL_GROUPS[k].name}</h4>
                        <p>{TOOL_GROUPS[k].prompt}</p>
                        <ButtonGrid>
                        {TOOL_GROUPS[k].toolOptions.map(i => {
                            return <button onClick={() => {
                                setAnswers(draft => {
                                    const index = draft.dataSources.selections.indexOf(i);
                                    if (index >= 0) {
                                        draft.dataSources.selections.splice(index, 1);
                                    } else {
                                        draft.dataSources.selections.push(i);
                                    }
                                    
                                });
                            }} className={answers.dataSources.selections.includes(i) ? 'active' : ''}>
                                {getLogo(i)}
                            </button>
                        })}
                    </ButtonGrid>
                    {answers.dataSources.selections.includes(TOOL_GROUPS[k].otherValue) && <div className="mt-2">
                        <Form.Control className="custom-value" placeholder="Enter tool here" type="text" value={answers.dataSources[TOOL_GROUPS[k].otherKey]} onChange={(e) => setAnswers(draft => {
                            // @ts-ignore
                            draft.dataSources[TOOL_GROUPS[k].otherKey] = e.target.value;
                        })}/>
                    </div>}
                    </div>
                })}
                
                
            </>}
            {onboardingStep == 6 && <>
                <h1>Great! That's all we need to know about your use case.</h1>   
                <h2>Please review your answers below.</h2>
                <AnswerReview>
                    {summaryInfo}
                </AnswerReview>    
            </>}
            {onboardingStep == 7 && <>
                <h1>Let's move on to your data infrastructure.</h1>
                <h2>Do you already have a data warehouse? If you aren't sure, just pick "no".</h2>
                <ButtonGrid>
                    <button onClick={() => setAnswers(draft => {
                        draft.database.hasWarehouse = true;
                    })} className={answers.database.hasWarehouse === true ? 'active' : ''}>
                        <i className="mdi mdi-check-circle text-success"></i> Yes
                        
                    </button>
                    <button onClick={() => setAnswers(draft => {
                        draft.database.hasWarehouse = false;
                    })} className={answers.database.hasWarehouse === false ? 'active' : ''}>
                        <i className="mdi mdi-close-thick text-danger"></i> No
                    </button>
                </ButtonGrid>
            </>}
            {onboardingStep == 8 && <>
                {answers.database.hasWarehouse && <>
                    <h1>Great, you already have a warehouse.</h1>
                    <h2>What kind of warehouse do you have?</h2>
                    <ButtonGrid>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'snowflake';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'snowflake' ? 'active' : ''}>
                            Snowflake
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'databricks';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'databricks' ? 'active' : ''}>
                            Databricks
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'redshift';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'redshift' ? 'active' : ''}>
                            Redshift
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'bigquery';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'bigquery' ? 'active' : ''}>
                            Big Query
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'other';
                            draft.database.selfServicePreference = 'self';
                        })} className={answers.database.warehouse === 'other' ? 'active' : ''}>
                            Other
                        </button>
                        {answers.database.warehouse == 'other' && <>
                            <Form.Control className="custom-value" placeholder="Enter warehouse name" type="text" value={answers.database.otherWarehouse} onChange={(e) => setAnswers(draft => {
                                // @ts-ignore
                                draft.database.otherWarehouse = e.target.value;
                            })}/>
                        </>}
                        
                    </ButtonGrid>
                </>}
                {!answers.database.hasWarehouse && <>
                    <h1>No warehouse? No problem.</h1>
                    <h2>If you're technical, we can give you instructions for signing up for Snowflake. Otherwise, our team can set up and manage one for you.</h2>
                    <ButtonGrid>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.selfServicePreference = 'self'
                            draft.database.warehouse = 'snowflake';
                        })} className={answers.database.selfServicePreference === 'self' ? 'active' : ''}>
                            I'll set it up myself
                        </button>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.selfServicePreference = 'managed'
                            draft.database.warehouse = '';
                        })} className={answers.database.selfServicePreference === 'managed' ? 'active' : ''}>
                            Please set it up for me
                        </button>
                    </ButtonGrid>
                </>}
            </>}  */}


            {onboardingStep == 0 && <>
                {/* {answers.database.selfServicePreference == 'managed' && <>
                    <h1>Let's book some time with one of our specialists.</h1>
                    <h2>We'll get your data warehouse set up, connect your data sources, and learn more about your use case so we can get you up and running quickly.</h2>
                        <InlineWidget
                            styles={{height: '800px', width: '100%'}}
                            url="https://calendly.com/d/ckc3-gd4-2wh/pliable-onboarding" 
                            prefill={{
                                email: authState?.user?.email,
                                name: authState?.user?.name,
                            }}
                            
                        />
                </>} */}
                {/* {answers.database.selfServicePreference != 'managed' && answers.database.warehouse == 'snowflake' && <>
                    <h1>Pliable runs on Snowflake.</h1>
                    <h2>Need to sign up for Snowflake? <a href="https://trial.snowflake.com/?owner=SPN-PID-724372" target="_blank">Click Here</a> for a free trial and come back once your account is created.</h2>
                    <div className="shadow-box p-3">
                        <SnowflakeSetupForm
                            snowflakeDetails={snowflakeDetails}
                            onChange={setSnowflakeValue}
                            tenantConfigId={tenantConfigId}
                            showPliableHostedSnowflakeCode={showPliableHostedSnowflakeCode}
                            onCredentialsAreValid={() => {
                                setSnowflakeConnectionIsValid(true);
                            }}
                        />
                    </div>
                </>}
                {answers.database.selfServicePreference != 'managed' && answers.database.warehouse && answers.database.warehouse !== 'snowflake' && <>
                    <h1>Pliable currently only supports Snowflake</h1>
                    <h2>If you'd like to sign up for a Snowflake account, we can help you replicate data from your existing warehouse into Snowflake so you can use Pliable.</h2>
                    <p>Otherwise, we'll reach out once we support your data warehouse.</p>
                    <ButtonGrid>
                        <button onClick={() => setAnswers(draft => {
                            draft.database.warehouse = 'snowflake'
                        })}>
                            I'll try Snowflake
                        </button>
                        <button onClick={() => window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'}>
                            Screw you guys, I'm going home
                        </button>
                    </ButtonGrid>
                </>} */}
            </>}
            {onboardingStep == 1 && <>
                {billingAccount && billingAccount.subscription_id}
                <CurrentBillingPlan billingAccount={billingAccount} onOptionSelected={async (bp) => {
                    const resp = await BillingAccountORM.selectBillingPlan(bp.default_price);
                    refetchBillingAccount();
                    setOnboardingStep(2);
                }} />
            </>}
            {onboardingStep == 2 && <>
                <h1>Payment Info</h1>
                {billingAccount && <EmbeddedCheckoutForm clientSecret={billingAccount.checkout_session_secret} /> }
            </>}
            {onboardingStep == 10 && <>
                <h1>That's it!</h1>
                {creatingPliableHostedGit && <>
                    <h2>
                        <i className="mdi mdi-loading mdi-spin"></i>
                        We're just running some final setup...
                    </h2>
                </>}
                {saveConfigError && <Danger>
                    {saveConfigError}    
                </Danger>}
                {!creatingPliableHostedGit && !saveConfigError && <>
                    <h2>You're all set!</h2>
                    <Link to="/node/new?sourceOnly=true">Add your first data source.</Link>
                </>}
                
            </>}
            {!creatingPliableHostedGit && <>
                <div className="d-flex center-vertically mt-3" >
                    {onboardingStep > 0 && <NextPreviousButton onClick={() => changeStep(onboardingStep - 1)}>&larr; Go Back</NextPreviousButton>}
                    <div className="flex-1"></div>
                    {onboardingStep < 10 &&  <NextPreviousButton disabled={!canGoNext} onClick={() => changeStep(onboardingStep + 1)}>Next &rarr;</NextPreviousButton>}
                    
                </div>
            </>}
            
        </>
    }, [onboardingStep, answers, setAnswers, snowflakeDetails, setSnowflakeValue, tenantConfigId, setSnowflakeConnectionIsValid, showAllTools, saveConfigError, creatingPliableHostedGit]);

    const logout = () => {
        window.location.href = '/logout';
    }

    if (loading) {
        return <>
            <Spinner/>
        </>
    }
    return <div style={{height: '100vh'}}>
        <TOSModal/>
        <Pane>
            <PaneContent>
                <div className="p-5">
                    <img src={pliableLogo} style={{width: '200px'}} className="mb-5"/>
                    {innerContent}
                </div>
                
            </PaneContent>
        </Pane>
        
    </div>
    
        
        

       
}

export default OnboardingWizardPage;