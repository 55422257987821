import AsyncButton from "@components/button/AsyncButton.component";
import BillingAccountORM, { BillingAccount, BillingProduct } from "@models/billingAccount";
import { requireConfirmation } from "@services/alert/alert.service";
import { useBillingAccount, useBillingOptions } from "@stores/data.store";
import { useCallback, useEffect, useState } from "react";
import { Badge, Form, Modal } from "react-bootstrap";

import styled from 'styled-components';
import CorePlanPicker from "./PlanPicker.component";
import PaymentForm from "./CheckoutForm.component";
import { useSearchParams } from "react-router-dom";
import HeaderAlert from "@components/alert/HeaderAlert.component";


interface Props {
    billingAccount?: BillingAccount;
    allowCancel?: boolean;
    onOptionSelected?: (bp: BillingProduct) => void;
}

const Container = styled.div`

hr {
    margin: 15px 0;
}

a {
    font-size: 12px;
}

`

const CurrentBillingPlan = (props: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isLoading, data: billingOptions } = useBillingOptions();
    const [cancelling, setCancelling] = useState(false);
    const [loadingChoosePlanLink, setLoadingChoosePlanLink] = useState(false);
    const [showPaymentMethod, setShowPaymentMethod] = useState(false);

    const { refetch } = useBillingAccount(true);

    useEffect(() => {
        if (searchParams.has('confirmed')) {
            const doConfirm = async () => {
                const resp = await BillingAccountORM.markPlanChoosen();
                setSearchParams({});
                refetch()
            }
            doConfirm();
        
        }

    }, [searchParams]);

    const beginCancel = useCallback(async () => {
        const resp = await BillingAccountORM.getSubscriptionCancelUrl();
        window.location.href = resp.url;
    }, []);

    const beginPaymentMethod = useCallback(async () => {
        const resp = await BillingAccountORM.getPaymentUpdateUrl();
        window.location.href = resp.url;
    }, []);

    const beginChangePlan = useCallback(async () => {
        setLoadingChoosePlanLink(true);
        const resp = await BillingAccountORM.getSubscriptionUpdateUrl();
        window.location.href = resp.url;
    }, []);

    const beginConfirmPlanChange = useCallback(async (price_id: string) => {
        setLoadingChoosePlanLink(true);
        const resp = await BillingAccountORM.getSubscriptionChangeConfirmUrl(price_id);
        window.location.href = resp.url;
    }, []);

    const beginViewPortal = useCallback(async () => {
        setLoadingChoosePlanLink(true);
        const resp = await BillingAccountORM.getPortalUrl();
        window.location.href = resp.url;
    }, []);

    if (isLoading || !billingOptions) {
        return <div>Loading...</div>;
    }

    const onOptionSelected = (bp: BillingProduct) => {
        if(bp.default_price != props.billingAccount?.price_id) {
            // plan change
            beginConfirmPlanChange(bp.default_price);
        } else{
            // update payment method
            beginPaymentMethod();
        }
    }
    
    if(!props.billingAccount || (props.billingAccount?.subscription_confirmed == false && ['paused', 'trialing'].includes(props.billingAccount?.subscription_status as string) )) {
        return <div>
            {props.billingAccount?.subscription_status == 'trialing' &&  props.billingAccount?.days_left_in_trial != null && <HeaderAlert red className="mb-3">
                <strong>You have {props.billingAccount?.days_left_in_trial} days left in your trial!</strong> 
                <AsyncButton icon='mdi mdi-cash' className="btn-sm ms-5" text="Add a payment method" variant="primary" onClick={beginViewPortal} loading={loadingChoosePlanLink} />
            </HeaderAlert> }
            <CorePlanPicker currentPlan={props.billingAccount?.product_name} hasPaymentDetails={!!props.billingAccount?.default_payment_method_id} billingOptions={billingOptions} onOptionSelected={onOptionSelected} />
        </div>;
    }

    if(!props.billingAccount) {
        return <div>No billing account found</div>;
    }

    return (
        <Container>
            <Modal show={showPaymentMethod} onHide={() => setShowPaymentMethod(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PaymentForm clientSecret={props.billingAccount.payment_intent_secret} />
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
            <div className="col-8 col-xs-12">
                <Form.Group className="">
                    <Form.Label>Current Plan: {props.billingAccount.product_name} - <Badge pill bg="info">{props.billingAccount.subscription_status}</Badge></Form.Label>
                </Form.Group>
                <AsyncButton icon='mdi mdi-note-text-outline' text="View or change your subscription" variant="light" onClick={beginViewPortal} loading={loadingChoosePlanLink} />
                {props.allowCancel && <AsyncButton icon='mdi mdi-delete' text="Cancel Plan" variant="light" className="ms-1" onClick={beginCancel} loading={cancelling} /> }


            </div>
        </Container>
    );
}

export default CurrentBillingPlan;