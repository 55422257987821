import BuildOrchestrationORM, { BuildException, BuildOrchestration } from '@models/buildOrchestration';
import toast from '@services/toast.service';
import { MissionControlDataFlowEdge, MissionControlDataFlowNode, enterDraftMode, exitDraftMode, invalidateDataMarts, invalidateEverything, invalidateMissionControlDataFlowData, invalidatePipelineNodes, invalidateSourceRecordTypes, queryClient, saveDataMart, toggleThenaWidget, useDraftVersionId, useMissionControlDataFlowData, usePipelineNodes, useTemplates } from '@stores/data.store';
import { MouseEvent, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonGroup, Form, Dropdown as BSDropdown, Button, Offcanvas, OffcanvasBody } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getPromptAnswer, prompt, requireConfirmation } from '@services/alert/alert.service';
import PageStructure, { PageContent, PageContentHeader, PageContentInner, Pane, PaneContent} from './PageStructure.component';
import { getErrorMessage } from '@services/errors.service';
import { getGroupValueForNodeType } from '@services/modeling.service';
import { DashboardIcon } from '@components/pipelineNodes/PipelineNodeIcon.component';
import { timeAgo } from '@services/time.service';

import { savePipelineNode, useIsInDraftMode, usePipelineNode, useProjectConfig, useSources } from "@stores/data.store";
import SourceIcon from "@components/sources/SourceIcon.component";
import flatFileIcon from '@assets/images/connectors/flatfile.png';
import snowflakeIcon from '@assets/images/snowflake_logo.png';
import { LibraryItem, LibraryItemStyles, LibraryStyles, NodeList, SingleMeasureItem } from "@components/pipelineNodes/PipelineNodeList.component";
import { Badge, Modal } from "react-bootstrap";

import { useMeasures } from "@models/reportBuilder";
import { Grid } from "./Wizard/shared";
import { useAuthState } from "@services/auth/auth.service";
import { DraftOnly, ProdOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import HeaderAlert from "@components/alert/HeaderAlert.component";
import { ReportsEmptyState } from "./PipelineNode/Reports.page";
import TOSModal from "@components/alert/TOSModal.component";
import Inbox, { NotificationsList } from '@components/Inbox.component';


const HomePage = () => {
    const pipelineNodes = usePipelineNodes();
    const inDraftMode = useIsInDraftMode();
    const sources = useSources();

    const projectConfig = useProjectConfig();

    const bookedOnboardingCall = useMemo(() => {
        if (!projectConfig.data) {
            return false;
        }

        return !!projectConfig.data.onboarding_info?.onboardingEventLink;
    }, [projectConfig.dataUpdatedAt]);

    const sourcesToShow = useMemo(() => {
        if (!sources.data) {
            return [];
        }
        return sources.data.filter(s => s.type != 'flat_file');
    }, [sources.dataUpdatedAt]);

    const reportNodes = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }
        return pipelineNodes.data.filter(n => getGroupValueForNodeType(n.node_type) == 'REPORTING').sort((a, b) => a.label.localeCompare(b.label));
    }, [pipelineNodes.dataUpdatedAt]);

    const businessObjects = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }
        return pipelineNodes.data.filter(n => getGroupValueForNodeType(n.node_type) == 'DATA_MODELING').sort((a, b) => a.label.localeCompare(b.label));
    }, [pipelineNodes.dataUpdatedAt]);

    const dataSources = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }
        return pipelineNodes.data.filter(n => getGroupValueForNodeType(n.node_type) == 'SOURCE');
    }, [pipelineNodes.dataUpdatedAt]);

    const navigate = useNavigate();

    const [nodeViewMode, setNodeViewMode] = useState<'LIST' | 'COLUMNS' | 'DIAGRAM'>('COLUMNS');

    const [nodeSearch, setNodeSearch] = useState('');

    const checkForNodeSearchEnterKey = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            setNodeSearch(e.currentTarget.value);
        }
    }, []);

    const nodesToShowInList = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }
        return pipelineNodes.data.filter(n => n.label.toLowerCase().includes(nodeSearch.toLowerCase())).sort((a, b) => a.label.localeCompare(b.label));
    }, [pipelineNodes.dataUpdatedAt, nodeSearch]);

    const addNewReport = useCallback(async () => {
        const reportName = await getPromptAnswer('What do you want to name this report?', 'New Report');
        if (reportName) {
            try {
                const result = await savePipelineNode({
                    id: null,
                    node_type: 'REPORT',
                    name: reportName,
                    label: reportName,
                    description: '',
                    upstream_node_ids: [],
                    fields: [],
                    table_name: '',
                });
                navigate(`/node/${result.id}/config`);
            } catch (err) {
                toast('danger', 'Error', getErrorMessage(err));
            }
            
        }
    }, []);

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    const measures = useMeasures();

    const measuresForList = useMemo(() => {
        if (!measures.data) {
            return [];
        }

        return [...measures.data].sort((a, b) => a.name.localeCompare(b.name));
    }, [measures.dataUpdatedAt]);

    useEffect(() => {
        if (businessObjects.length == 0) {
            setShowWelcomeModal(true);
        }
    }, [businessObjects]);

    // if (pipelineNodes.data && pipelineNodes.data.length == 0 && measures.data && measures.data.length == 0) {
    //     return <PageStructure>
    //         <PageContent>
    //             <div className="p-onboarding pt-5">
    //                 <div className="empty-state text-center">
    //                     <h1>Welcome to Pliable</h1>
    //                 </div>
    //             </div>
    //         </PageContent>
    //     </PageStructure>
    // }

    const authState = useAuthState();

    const [timesOnHomeScreen, setTimesOnHomeScreen] = useState(0);

    useEffect(() => {
        const currentTimes = localStorage.getItem('timesOnHomeScreen');
        const parsed = currentTimes ? parseInt(currentTimes, 10) : 0; 
        if (currentTimes) {
            setTimesOnHomeScreen(parsed);
        } else {
            setTimesOnHomeScreen(0);
        }

        localStorage.setItem('timesOnHomeScreen', ((parsed || 0) + 1).toString());
    }, []);

    

    const hasSampleData = useMemo(() => {
        return !!projectConfig.data?.onboarding_info?.sampleData
    }, [projectConfig.dataUpdatedAt]);


    const [showOnboardingWizard, setShowOnboardingWizard] = useState(true);
    const [onboardingStepsComplete, setOnboardingStepsComplete] = useState<{[step: string]: boolean}>({});
    useEffect(() => {
        if (hasSampleData) {
            const timesOnSnowflakeScreen = parseInt(localStorage.getItem('timesOnSnowflakePage') || '0', 10);
            const timesOnSemanticLayerPage = parseInt(localStorage.getItem('timesOnSemanticLayerPage') || '0', 10);
            const timesOnReportingPage = parseInt(localStorage.getItem('timesOnReportingPage') || '0', 10);

            setOnboardingStepsComplete({
                'sources': timesOnSnowflakeScreen >= 1,
                'semantic_layer': timesOnSemanticLayerPage >= 1,
                'reporting': timesOnReportingPage >= 1,
            });

            if (timesOnSnowflakeScreen >= 1 && timesOnSemanticLayerPage >= 1 && timesOnReportingPage >= 1) {
                setShowOnboardingWizard(false);
            }
        } else if (pipelineNodes.data && measures.data) {
            let entities = 0;
            let metrics = 0;
            let sources = 0;
            let reports = 0; 

            pipelineNodes.data.forEach(n => {
                if (n.node_type == 'DIMENSION') {
                    entities++;
                }
                if (n.node_type == 'SOURCE') {
                    sources++;
                }
                if (n.node_type == 'REPORT') {
                    reports++;
                }
            });

            metrics = measures.data.length;

            setOnboardingStepsComplete({
                'sources': sources > 0,
                'entities': entities > 0,
                'reports': reports > 0,
                'metrics': metrics > 0,
            });

            if (sources > 0 && entities > 0 && reports > 0 && metrics > 0) {
                setShowOnboardingWizard(false);
            }
        }
    }, [hasSampleData, pipelineNodes.dataUpdatedAt, measures.dataUpdatedAt]);

    const inOnboarding = true;
    const isInTrial = projectConfig.data && ['paused', 'trialing'].includes(projectConfig.data.subscription_status as string);
    const daysLeftInTrial = (isInTrial) ? projectConfig.data.days_left_in_trial : 0;



    const headerAlertContent = useMemo(() => {
        // if (timesOnHomeScreen < 3 && !bookedOnboardingCall) {
        if (isInTrial && (daysLeftInTrial || 0) < 5) {
            return <HeaderAlert style={{maxWidth: '50%'}} red>
                <strong>You have {daysLeftInTrial} days left in your trial!</strong> <button className="btn btn-primary btn-xs ms-5" onClick={() => navigate('/settings#billing')}>Upgrade now</button> <button onClick={toggleThenaWidget} className="btn btn-pliable btn-xs">Chat with us</button>
            </HeaderAlert>
        }
        
        if (isInTrial && !bookedOnboardingCall) {
            return <HeaderAlert style={{maxWidth: '50%'}}>
                <p className="mb-0"><strong>Have you booked your onboarding call?</strong>&nbsp;
                    Did you know you get a free 1-on-1 call with a Pliable data modeling expert? <Link to="/onboarding-call">Schedule call now</Link>
                </p>
                
            </HeaderAlert>
        }

        if (timesOnHomeScreen >= 3 && isInTrial) {
            return <HeaderAlert style={{maxWidth: '50%'}} red>
                <strong>You have {daysLeftInTrial} days left in your trial!</strong> <button className="btn btn-primary btn-xs ms-5" onClick={() => navigate('/settings#billing')}>Upgrade now</button> <button onClick={toggleThenaWidget} className="btn btn-pliable btn-xs">Chat with us</button>
            </HeaderAlert>
        }
        return <HeaderAlert style={{maxWidth: '50%'}}>
            <strong>Need some help?</strong> Click on the chat button <i className="mdi mdi-chat"/> at the top right of your screen to talk to a real live human.
        </HeaderAlert>
    }, [inOnboarding, isInTrial, bookedOnboardingCall, timesOnHomeScreen, daysLeftInTrial]);

    const isInDraft = useIsInDraftMode();

    const [timesOnHomeScreenInThisMode, setTimesOnHomeScreenInThisMode] = useState(0);

    const [showModeModal, setShowModeModal] = useState(false);


    useEffect(() => {
        const key = isInDraft ? 'timesOnHomeScreenDraft' : 'timesOnHomeScreenProd';
        const currentTimes = localStorage.getItem(key);
        const parsed = currentTimes ? parseInt(currentTimes, 10) : 0; 
        if (currentTimes) {
            setTimesOnHomeScreenInThisMode(parsed);
        } else {
            setTimesOnHomeScreenInThisMode(0);
        }

        if (parsed == 1) {
            setShowModeModal(true);
        }

        localStorage.setItem(key, ((parsed || 0) + 1).toString());
    }, [isInDraft]);



    return <PageStructure>
        <TOSModal/>
        <Modal show={showModeModal} onHide={() => setShowModeModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isInDraft ? 'You are in Develop Mode' : 'You are in Production Mode'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {isInDraft && <>
                        This is where you can make changes to your data model and reports. Changes you make here are not visible to end users until you publish to production and run a build.
                    </>}
                    {!isInDraft && <>
                        This is where you can view your reports and data models and explore your data. Production Mode is read-only. To make changes, switch to Develop Mode.
                    </>}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={() => setShowModeModal(false)}>Got it</button>
            </Modal.Footer>
        </Modal>
        <PageContent>
            <Pane>
                <PaneContent>
                    <div className="bg-white border-bottom p-4 pt-2 pb-2">

                        <div className="d-flex center-vertically">
                            <DashboardIcon icon="mdi mdi-home" bgColor="blue"/>
                            <h1 className="mb-0 flex-1">
                                Welcome, {authState?.user?.name}
                                
                            </h1>
                            {/* <div className="me-5">
                                <div className="btn-group">
                                    <Link className="btn btn-outline-primary" to="/wizard/data-source">Add Source Data</Link>
                                    <Link className="btn btn-outline-primary" to="/semantic-layer">Manage your Data Model</Link>
                                    <button className="btn btn-outline-primary" onClick={() => {
                                        addNewReport();
                                    }}>Build a Report</button>
                                </div>
                                
                            </div> */}
                            {/* <div className="btn-group btn-light">
                                <button onClick={() => setNodeViewMode('COLUMNS')} title="Columns" className={`btn ${nodeViewMode == 'COLUMNS' ? 'btn-pliable' : 'btn-light'}`}>
                                    <i className="mdi mdi-view-column"></i>
                                </button>
                                <button onClick={() => setNodeViewMode('LIST')} title="List" className={`btn ${nodeViewMode == 'LIST' ? 'btn-pliable' : 'btn-light'}`}>
                                    <i className="mdi mdi-view-list"></i>
                                </button>
                            </div> */}
                            
                            {headerAlertContent}
                            


                        </div>
                        {showOnboardingWizard && <>
                            {hasSampleData && <section>
                                <p className="font-18">We've put some sample e-commerce data in your account.:</p>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="shadow-box p-2 h-100">
                                                <div className="d-flex">
                                                    <DashboardIcon bgColor={onboardingStepsComplete['sources'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/> 
                                                    <div>
                                                        <h5 className="mb-1">Check out data sources</h5>
                                                        <div className=" lh-compact">
                                                            Some stuff <Link to="/sources/snowflake">Go <i className="mdi mdi-arrow-right-bold"></i></Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                        <div className="shadow-box p-2 h-100">
                                                <div className="d-flex">
                                                    <DashboardIcon bgColor={onboardingStepsComplete['semantic_layer'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/> 
                                                    <div>
                                                        <h5 className="mb-1">Check out the semantic layer</h5>
                                                        <div className=" lh-compact">
                                                            Entities and metrics make it easy to asdfasdf <Link to="/semantic-layer">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="shadow-box p-2 h-100">
                                                <div className="d-flex">
                                                    <DashboardIcon bgColor={onboardingStepsComplete['reporting'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/>
                                                    <div>
                                                        <h5 className="mb-1">Reporting</h5>
                                                        <div className=" lh-compact">
                                                            Build a report using your entities and metrics. <Link to="/reports">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="shadow-box p-2 h-100">
                                                <div className="d-flex">
                                                    <DashboardIcon bgColor={onboardingStepsComplete['build'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/>
                                                    <div>
                                                        <h5 className="mb-1">Run a build</h5>
                                                        <div className=" lh-compact">
                                                            Build build build <Link to="/reports">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </section>}
                            {!hasSampleData && <section>
                                <DraftOnly>
                                    <p className="font-18">Now that you've connected to Snowflake, here are some things you should do to get started:</p>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="shadow-box p-2 h-100">
                                                <div className="d-flex">
                                                    <DashboardIcon bgColor={onboardingStepsComplete['sources'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/> 
                                                    <div className="flex-1">
                                                        <h5 className="mb-1">Connect a Data Source</h5>
                                                        <div className=" font-13 lh-compact">
                                                            Upload a file or connect directly to a Snowflake table.
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="text-end">
                                                        <Link className="btn btn-pliable btn-xs" to="/wizard/data-source">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                        <div className="shadow-box p-2 h-100">
                                                <div className="d-flex">
                                                    <DashboardIcon bgColor={onboardingStepsComplete['entities'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/> 
                                                    <div className="flex-1">
                                                        <h5 className="mb-1">Create an Entity</h5>
                                                        <div className=" font-13 lh-compact">
                                                            Entities represent core concepts in your data and are used to build reports.
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Link className="btn btn-pliable btn-xs" to="/semantic-layer">Go <i className="mdi mdi-arrow-right-bold"></i></Link>                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                        <div className="shadow-box p-2 h-100">
                                                <div className="d-flex">
                                                    <DashboardIcon bgColor={onboardingStepsComplete['metrics'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/>
                                                    <div className="flex-1">
                                                        <h5 className="mb-1">Create a Metric</h5>
                                                        <div className=" font-13 lh-compact">
                                                            Metrics are formulas calculated using your Entities. 
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="text-end">
                                                        <Link className="btn btn-pliable btn-xs" to="/semantic-layer">Go <i className="mdi mdi-arrow-right-bold"></i></Link>                                                  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                        <div className="shadow-box p-2 h-100">
                                                <div className="d-flex">
                                                    <DashboardIcon bgColor={onboardingStepsComplete['reports'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/>
                                                    <div className="flex-1">
                                                        <h5 className="mb-1">Build a Report</h5>
                                                        <div className=" font-13 lh-compact">
                                                            Build a report using your entities and metrics. No SQL required.
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="text-end">
                                                        <Link className="btn btn-pliable btn-xs" to="/wizard/report">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DraftOnly>
                            </section>}
                        </>}
                        </div>

                        <div className="p-4">
                            <div className="row">
                                {/* <div className="col-3">
                                    <h2>To-Do List</h2>
                                    <div className="card">
                                        
                                        <NotificationsList/>
                                    </div>
                                </div> */}
                                <div className="col-12">
                                    <h2><i className="mdi mdi-database"></i> Data Sources</h2>
                                    <div className="row mb-5">
                                        <div className="col-4">
                                            <div className="shadow-box h-100 d-flex flex-column">
                                                <div className="d-flex p-3 flex-1">
                                                    <div className="me-2">
                                                        <SourceIcon image_url={flatFileIcon}/>

                                                    </div>
                                                    <div className="flex-1">
                                                        <h3>Flat Files</h3>
                                                        <div className="">Upload any file readable by Excel.</div>
                                                    </div>
                                                </div>
                                                <div className="p-3 pt-2 pb-2 border-top text-end">
                                                    <Link to="/sources/files" className="btn btn-light me-1">View All</Link>
                                                    <DraftOnly>
                                                        <Link to="/wizard/data-source?sourceType=file" className="btn btn-outline-primary">Add New</Link>
                                                    </DraftOnly>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-4">
                                            <div className="shadow-box h-100 d-flex flex-column">
                                                <div className="d-flex p-3 flex-1">
                                                    <div className="me-2">
                                                        <SourceIcon image_url={snowflakeIcon}/>

                                                    </div>
                                                    <div className="flex-1">
                                                        <h3>Snowflake Tables</h3>
                                                        <div className="">Connect any table in your Snowflake warehouse.</div>
                                                    </div>
                                                </div>
                                                <div className="p-3 pt-2 pb-2 border-top text-end">
                                                    <Link to="/sources/snowflake" className="btn btn-light me-1">View All</Link>
                                                    <DraftOnly>
                                                        <Link to="/wizard/data-source?sourceType=snowflake" className="btn btn-outline-primary">Add New</Link>
                                                    </DraftOnly>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="shadow-box h-100 d-flex flex-column">
                                                <div className="d-flex p-3 flex-1">
                                                    <DashboardIcon icon="mdi mdi-connection" bgColor="dark"/>

                                                    <div className="flex-1">
                                                        <h3>Direct Integrations</h3>
                                                        <div className="">Pliable has partnerships with ETL providers that can easily get your data into Snowflake. Contact support@pliable.co to learn more.</div>
                                                    </div>
                                                </div>
                                                {/* <div className="p-3 pt-2 pb-2 border-top text-end">
                                                    <button className="btn btn-light">Learn More</button>

                                                </div> */}
                                            

                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="d-flex center-vertically mb-2">
                                        <h2 className="mb-0 flex-1">
                                            <i className="mdi mdi-star"></i> Semantic Layer
                                        </h2>
                                        <DraftOnly>
                                            <Link to="/semantic-layer" className="btn btn-primary">
                                                <i className="mdi mdi-pencil"></i> Manage Semantic Layer
                                            </Link>
                                        </DraftOnly>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">
                                            <div className="card h-100">
                                                <div className="card-body h-100">
                                                    <h3>Entities</h3>
                                                    <NodeList nodes={businessObjects}/>
                                                    
                                                    {businessObjects.length == 0 && <>
                                                        <DraftOnly>
                                                            <div className="empty-state text-center">
                                                                <p>You haven't created any entities yet.</p>
                                                                <ReadOnly>
                                                                    <Link className="btn btn-pliable" to="/semantic-layer">Get Started</Link>
                                                                </ReadOnly>
                                                            </div>
                                                        </DraftOnly>
                                                        <ProdOnly>
                                                            <p>No entities have been created. To create entities, first enter Develop mode, then go to the Semantic Layer.</p>
                                                                
                                                        </ProdOnly>
                                                    
                                                    </>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="card h-100">
                                                <div className="card-body h-100">
                                                    <h3>Metrics</h3>
                                                    {measuresForList.length == 0 && <>
                                                        <DraftOnly>
                                                            <div className="empty-state text-center">
                                                                <p>You haven't created any metrics yet.</p>
                                                                <ReadOnly>
                                                                    <Link className="btn btn-pliable" to="/semantic-layer">Get Started</Link>
                                                                </ReadOnly>
                                                            </div>
                                                        </DraftOnly>
                                                        <ProdOnly>
                                                            <p>No metrics have been created. To create metrics, first enter Develop mode, then go to the Semantic Layer.</p>
                                                        </ProdOnly>
                                                        
                                                    </>}
                                                    <div className="list-group">
                                                        {measuresForList.map(m => {
                                                            return <SingleMeasureItem
                                                                key={m.id}
                                                                measure={m}
                                                                
                                                                onClick={() => {
                                                                    navigate(`/metric/${m.id}`);
                                                                }}
                                                            />
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex center-vertically mb-2">
                                        <h2 className="mb-0 flex-1">
                                            <i className="mdi mdi-table-star"></i> Reports
                                        </h2>
                                        <DraftOnly>
                                            <Link to="/wizard/report" className="btn btn-primary"><i className="mdi mdi-plus-circle"></i> Add New Report</Link>
                                        </DraftOnly>
                                    </div>
                                    <Grid>
                                        {reportNodes.map(n => {
                                            return <div key={n.id} className="shadow-box h-100">
                                                <div className="d-flex p-3 flex-1">
                                                    <DashboardIcon icon="mdi mdi-table-star" bgColor="success"/>

                                                    <div className="flex-1">
                                                        <h3>{n.label}</h3>
                                                        <div className="text-muted font-13 mb-2">{n.description || 'No description'}</div>
                                                    </div>
                                                </div>
                                                <div className="p-3 pt-2 pb-2 border-top d-flex center-vertically">
                                                    <div className="">Last updated: {timeAgo(n.last_build_completed)}</div>
                                                    <div className="flex-1">
                                                        
                                                    </div>
                                                    <Link to={`/node/${n.id}`} className="btn btn-light">View Report</Link>
                                                    
                                                    



                                                </div>
                                            </div>
                                        })}
                                    </Grid>
                                    {reportNodes.length == 0 && <div className="card">
                                        <div className="card-body">
                                            <ReportsEmptyState/>
                                            
                                        </div>
                                    </div>}
                                    <div className="mb-5"></div>
                                    <h2><i className="mdi mdi-view-list"></i> All Nodes</h2>
                                    <input className="form-control input-rounded mb-2" placeholder="Search..." onKeyUp={checkForNodeSearchEnterKey}/>
                                    <NodeList nodes={nodesToShowInList}/>
                                </div>
                                
                            </div>
                                
                        </div>

                </PaneContent>
            </Pane>
            
            
        </PageContent>
        
        
    </PageStructure>
}

export default HomePage;
