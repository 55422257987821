import { cellActionToCommand } from "@components/datatable/DataTable.component";
import { PipelineNodeCellAction } from "@models/pipelineNode";
import { formatValue } from "@services/formatting.service";
import toast from "@services/toast.service";
import { usePipelineNode } from "@stores/data.store";
import { Menu, MenuHeader, MenuItem } from "@szhsin/react-menu";
import { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";

interface Props {
    record: Record<string, any>;
    pipelineNodeId: string;
    includeLink?: boolean;
    compact?: boolean;
}


const PipelineNodeSingleRecord = (props: Props) => {
    const navigate = useNavigate();
    const theNode = usePipelineNode(props.pipelineNodeId);
    const columns = useMemo(() => {
        if (!theNode.data) {
            return [];
        }
        return theNode.data.fields.filter(f => !['FOREIGN_KEY', 'ID'].includes(f.type));
    }, [theNode.dataUpdatedAt]);

    const handleCellActionClick = (cell_action: PipelineNodeCellAction, column: string, values: any) => {
        try{
        const command = cellActionToCommand(cell_action, column, values);
    
            if (command.method === 'navigate') {
                navigate(command.uri);
            }else if(command.method === 'referal'){
                window.open(command.uri as string, '_blank')?.focus();
            }
        }catch(e){  
            toast('danger', 'Error', e as string);
        }
    }

    return <>
        {props.includeLink && <Link className=" mb-1" to={`/node/${props.pipelineNodeId}/data/${props.record._plb_uuid}`}>View Record</Link>}
        {props.compact && 
            <dl>
                {columns.map(k => {
                    const value = props.record[k.name];
                    const format = k.formatter;
                    const formattedValue = formatValue({value, format});
                    return <>
                        <dt>{k.label}
                            {k.cell_actions && k.cell_actions.length > 0 && <Menu menuButton={<button className="icon-button">
                                                    <i className="mdi mdi-menu"></i>
                                                    </button>}>
                                <MenuHeader>Actions</MenuHeader>
                                {k.cell_actions.map((action: PipelineNodeCellAction) => {
                                    return <MenuItem onClick={() => handleCellActionClick(action, k.name, props.record)}>
                                        <i className={`mdi mdi-merge`}></i>&nbsp;{action.label}
                                    </MenuItem>
                                })}
                            </Menu>}
                        </dt>
                        <dd>{formattedValue}</dd>
                    </>
                })}
            </dl>
        }
        {!props.compact && 
        <table className="table">
            <tbody>
                {columns.map(k => {
                    return <tr>
                        <th className="bg-light border-right">{k.label}</th>
                        <td>{props.record[k.name]}</td>
                    </tr>
                })}
            </tbody>
        </table>
        }
    </>
}

export default PipelineNodeSingleRecord;