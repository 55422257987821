import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { useEntitlements } from "@frontegg/react";
import { PipelineNodeORM } from "@models/pipelineNode";
import { PageContentHeader } from "@pages/PageStructure.component";
import { getPromptAnswer } from "@services/alert/alert.service";
import { summarizeNumber } from "@services/formatting.service";
import { getNodeTypeConfig } from "@services/modeling.service";
import { timeAgo } from "@services/time.service";
import { useIsInDraftMode, usePipelineNode, useTemplateName } from "@stores/data.store";
import { ReactNode, useMemo } from "react";
import { Badge } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import dbtIcon from '@assets/images/dbt.png';
interface Props {
    pipelineNodeId: string;
    children?: ReactNode;
}

const PipelineNodeSubnav = (props: Props) => {
    const location = useLocation();
    const pipelineNode = usePipelineNode(props.pipelineNodeId);
    const inDraftMode = useIsInDraftMode();

    const { isEntitled: hostedSupersetEnabled } = useEntitlements({
        featureKey: "hosted_superset",
    });

    const activePage = useMemo(() => {
        let path = location.pathname;

        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        
        if (path.endsWith('/config')) {
            return 'config';
        } else if (path.endsWith('/files')) {
            return 'files';
        } else if (path.indexOf('/relationships') >= 0) {
            return 'relationships';
        } else if (path.endsWith('/insights')) {
            return 'insights';
        } else if (path.endsWith('/filters')) {
            return 'filters';
        } else if (path.indexOf('/visualizations') >= 0) {
            return 'visualizations';
        }

        return 'data';
    }, [location]);

    const nodeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return undefined;
        }
        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);

    const nodeTypeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return undefined;
        }

        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);
    
    const currentNodeType = pipelineNode.data?.node_type;
    
    
   
    
    const badge = useMemo(() => {
        if (!pipelineNode.data || !nodeTypeConfig) {
            return <></>
        }

        const bg = nodeTypeConfig.group.color;
        const icon = pipelineNode.data.flat_file ? 'mdi mdi-file-document' : nodeTypeConfig.nodeType.icon;
        const label = pipelineNode.data.flat_file ? 'Flat File' : nodeTypeConfig.nodeType.label;


        return (
            <Badge
                pill
                bg={bg}
            ><i className={icon}></i> {label}
            </Badge>
        );
    }, [pipelineNode.data, nodeTypeConfig]);

    const templateName = useTemplateName(pipelineNode.data?.managed_by_template_id || '') || undefined;

    if (!pipelineNode.data || !nodeConfig) {
        return <></>;   
    }

    return <PageContentHeader>
            <div className="d-flex center-vertically" style={{height: '100%'}}>
                <div className="flex-1 overflow-ellipsis">
                    
                    <h1 className="mb-0 overflow-ellipsis" style={{ maxWidth: '40vw' }} title={pipelineNode.data?.label}>
                        {pipelineNode.data?.label}
                    </h1>
                    <div className="font-poppins text-muted font-13">
                        <span>{badge}</span>
                        <span className="me-3">&nbsp;</span>
                        <span>
                            <i className="mdi mdi-file-multiple"></i> Records: {pipelineNode.data.total_records ? summarizeNumber(pipelineNode.data.total_records) : '0'}
                        </span>
                        <span className="me-3">&nbsp;</span>
                        <span>
                            <i className="mdi mdi-clock"></i> Last Updated: {pipelineNode.data.last_build_completed ? timeAgo(pipelineNode.data.last_build_completed, 'mini-minute-now') : 'never'}
                        </span>
                        <span className="me-3">&nbsp;</span>
                        <span title={templateName}>
                            {pipelineNode.data.managed_by_template_id && (
                                <i className="mdi mdi-image-frame"></i>
                            )}
                        </span>
                    </div>
                </div>
                <div className="me-1">
                    <Link to={`/node/${props.pipelineNodeId}`} className={`btn btn-light ${activePage == 'data' ? 'active' : ''}`}>
                        <i className="mdi mdi-table"></i> View Data
                    </Link>
                </div>
                {pipelineNode.data?.flat_file && <>
                    <div className="me-1">
                        <Link to={`/node/${props.pipelineNodeId}/files`} className={`btn btn-light ${activePage == 'files' ? 'active' : ''}`}>
                            <i className="mdi mdi-file-multiple"></i> Files
                        </Link>
                    </div>
                </>}
                
                <div className="me-3">
                    <Link to={`/node/${props.pipelineNodeId}/config`} className={`btn btn-light ${activePage == 'config' ? 'active' : ''}`}>
                        <i className="mdi mdi-cog"></i> Configure
                    </Link>
                </div>
                <div>
                    {props.children}
                    
                </div>
            </div>
        
        
    </PageContentHeader>;
}

export default PipelineNodeSubnav;