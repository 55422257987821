import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component"
import { ReportBuilderState } from "@components/pipelineNodes/PipelineNodeReportBuilder.component";
import { useDimensions, useMeasures } from "@models/reportBuilder";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, Pane, PaneContent, WizardContent, WizardFooter, WizardStep, WizardStepDivider, WizardSteps } from "@pages/PageStructure.component"
import { savePipelineNode, usePipelineNodes, useReportingTree } from "@stores/data.store"
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { graphlib } from "dagre";
import { useImmer } from "use-immer";
import PipelineNodeName, { PipelineNodeFieldDescription, PipelineNodeFieldName } from "@components/pipelineNodes/PipelineNodeName.component";
import { Form } from "react-bootstrap";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import AsyncButton from "@components/button/AsyncButton.component";

const ReportWizardPage = () => {
    const pipelineNodes = usePipelineNodes();

    const [reportBuilderState, setReportBuilderState] = useState<ReportBuilderState>(new ReportBuilderState(new graphlib.Graph(), [], [], [], []));

    const tree = useReportingTree();


    const measures = useMeasures();
    const dimensions = useDimensions();

    const [selectedDimensionIds, setSelectedDimensionIds] = useImmer<string[]>([]);
    const [selectedMeasureIds, setSelectedMeasureIds] = useImmer<string[]>([]);

    const [nodeName, setNodeName] = useState('');
    const [nodeDescription, setNodeDescription] = useState('');

    const graph = useMemo(() => {
        const g = new graphlib.Graph({
            directed: true,
            multigraph: true,
        });

        if (!tree.data) {
            return g;
        }

        tree.data.nodes.forEach(n => {
            g.setNode(n.id, n.title);
        });

        tree.data.edges.forEach(e => {
            g.setEdge(e.from_id, e.to_id, e.relationship_id);
        });

        return g
    }, [tree.dataUpdatedAt]);

    useEffect(() => {
        setReportBuilderState(new ReportBuilderState(graph, dimensions.data || [], measures.data || [], selectedDimensionIds, selectedMeasureIds));
    }, [graph, selectedMeasureIds, selectedDimensionIds, dimensions.dataUpdatedAt, measures.dataUpdatedAt])


    const entityNodes = useMemo(() => {
        return pipelineNodes.data?.filter(node => node.node_type == 'DIMENSION') || [];
    }, [pipelineNodes.dataUpdatedAt]);

    const [step, setStep] = useState(0);

    const numberOfSteps = 3;

    const [saving, setSaving] = useState(false);

    const navigate = useNavigate();

    const saveNode = useCallback(async () => {
        setSaving(true);
        try {
            const result = await savePipelineNode({
                id: null,
                name: nodeName,
                label: nodeName,
                fields: [],
                upstream_node_ids: [],
                table_name: '',
                description: nodeDescription,
                node_type: 'REPORT',
                measure_ids: selectedMeasureIds,
                dimension_ids: selectedDimensionIds,
            });
            toast('success', 'Success', 'Your report has been created. You can configure it further or build it by hitting the "Build" button.');
            navigate(`/node/${result.id as string}/config`);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [selectedMeasureIds, selectedDimensionIds, nodeName, nodeDescription]);

    const nextIsEnabled = useMemo(() => {
        if (step == 0 && selectedMeasureIds.length == 0) {
            return false;
        }

        if (step == 1 && selectedDimensionIds.length == 0) {
            return false;
        }

        return true;
    }, [selectedDimensionIds, selectedMeasureIds, step]);

    const [measureFilter, setMeasureFilter] = useState('');
    const filteredMeasures = useMemo(() => {
        return (measures.data?.filter(m => m.name.toLowerCase().includes(measureFilter.toLowerCase())) || []).sort((a, b) => a.name.localeCompare(b.name));
    }, [measures.data, measureFilter]);

    const [dimensionFilter, setDimensionFilter] = useState('');
    const filteredDimensions = useMemo(() => {
        return (dimensions.data?.filter(m => m.name.toLowerCase().includes(dimensionFilter.toLowerCase())) || []).sort((a, b) => a.name.localeCompare(b.name));
    }, [dimensions.data, dimensionFilter]);

    if ((measures.data && !measures.data.length) || (dimensions.data && !dimensions.data.length)) {
        return <PageStructure>
            <PageContent>
                <div className="p-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="empty-state text-center">
                                <h3>Slow down there, partner</h3>
                                <p>You need to create some metrics and dimensions in your semantic layer before you can build a report.</p>
                                <Link to="/semantic-layer" className="btn btn-pliable">Go to semantic layer</Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </PageContent>
        </PageStructure>
    }

    const innerContent = <>
        <WizardSteps>
            <WizardStep
                active={step == 0}
                complete={step > 0}
                icon="mdi mdi-broom"
                stepNumber="1"
                stepDescription="Select metrics"
            />
            <WizardStepDivider/>
            <WizardStep
                active={step == 1}
                complete={step > 1}
                icon="mdi mdi-broom"
                stepNumber="2"
                stepDescription="Select dimensions"
            />
            <WizardStepDivider/>
            <WizardStep
                active={step == 2}
                complete={step > 2}
                icon="mdi mdi-broom"
                stepNumber="3"
                stepDescription="Review"
            />

        </WizardSteps>
        <WizardContent>
            <div className="card">
                <div className="card-body">
                    {step == 0 && <div>
                        <h2 className="mb-0">Select metrics</h2>
                        <p>Select the metrics you want to include in your report. As you choose your metrics, certain other metrics may be unavailable if they are not connected.</p>
                        <input className="form-control input-rounded mb-2" placeholder="Search metrics" value={measureFilter} onChange={e => setMeasureFilter(e.target.value)} />
                        <table className="table table-centered table-fixed table-hover">
                            <thead className="bg-light">
                                <tr>
                                    <th>Use</th>
                                    <th>Metric</th>
                                    <th>Calculation</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredMeasures.map(measure => {
                                    const isValid = reportBuilderState.availableMeasureIds.includes(measure.id as string);
                                    return <tr key={measure.id} className={isValid ? 'valid clickable' : 'disabled' } onClick={() => {
                                        setSelectedMeasureIds(draft => {
                                            if (draft.includes(measure.id as string)) {
                                                draft.splice(draft.indexOf(measure.id as string), 1);
                                            } else {
                                                draft.push(measure.id as string);
                                            }
                                        });
                                    }}>
                                        <td>

                                            <td>
                                                {!isValid && <i className="mdi mdi-close-circle font-24"/> }
                                                {isValid && selectedMeasureIds.includes(measure.id as string) && <i className="mdi mdi-check-circle text-success font-24"></i>}
                                                {isValid && !selectedMeasureIds.includes(measure.id as string) && <i className="mdi mdi-check-circle-outline font-24"></i>}
                                            </td>
                                        </td>
                                        <td>
                                            <h4 className="mb-0">{measure.name}</h4>
                                            <div>{measure.description || 'No description'}</div>
                                        </td>
                                        <td>
                                            {measure.is_calculation && <div className="font-code">
                                                {measure.formula}
                                            </div>}
                                            {!measure.is_calculation && <div className="font-code">
                                                {measure.aggregator} <PipelineNodeName pipelineNodeId={measure.pipeline_node_id as string}/> - <PipelineNodeFieldName pipelineNodeId={measure.pipeline_node_id as string} fieldId={measure.field_id as string}/>
                                            </div>}
                                        </td>
                                        <td></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>}
                    {step == 1 && <div>
                        <h2 className="mb-0">Select dimensions</h2>
                        <p>Dimensions control how your report is broken down (for example, one row per campaign, per week). You can choose up to 5 dimensions, and can only select dimensions that your metrics are connected to.</p>
                        <input className="form-control input-rounded mb-2" placeholder="Search dimensions" value={dimensionFilter} onChange={e => setDimensionFilter(e.target.value)} />

                        <InfoAlert>
                            <div>
                                <strong>Hint:</strong> you can set any column on an Entity to be a dimension by clicking on the <i className="mdi mdi-label"></i> icon in the semantic layer or Entity configuration screen.
                            </div>
                        </InfoAlert>
                        <table className="table table-centered table-fixed table-hover">
                            <thead className="bg-light">
                                <tr>
                                    <th>Use</th>
                                    <th>Dimension</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredDimensions.map(dimension => {
                                    const isValid = reportBuilderState.availableDimensionIds.includes(dimension.id as string);
                                    return <tr key={dimension.id} className={isValid ? 'valid clickable' : 'disabled'} onClick={() => {
                                        setSelectedDimensionIds(draft => {
                                            if (draft.includes(dimension.id as string)) {
                                                draft.splice(draft.indexOf(dimension.id as string), 1);
                                            } else {
                                                draft.push(dimension.id as string);
                                            }
                                        });
                                    }}>
                                        <td>

                                            <td>
                                                {selectedDimensionIds.includes(dimension.id as string) && <i className="mdi mdi-check-circle text-pliable font-24"></i>}
                                                {!selectedDimensionIds.includes(dimension.id as string) && <i className="mdi mdi-check-circle-outline font-24"></i>}
                                            </td>
                                        </td>
                                        <td>
                                            <h4 className="mb-0"><PipelineNodeFieldName pipelineNodeId={dimension.pipeline_node_id as string} fieldId={dimension.field_id as string}/></h4>
                                            <div>from <strong><PipelineNodeName pipelineNodeId={dimension.pipeline_node_id}/></strong></div>
                                        </td>
                                        <td>
                                            <PipelineNodeFieldDescription pipelineNodeId={dimension.pipeline_node_id as string} fieldId={dimension.field_id as string}/>
                                        </td>
                                        
                                        <td></td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>}
                    {step == 2 && <div>
                        <h2 className="mb-0">Review</h2>
                        <p>Review the report you are about to create.</p>
                        <Form.Group className="mb-3">
                            <Form.Label>Name this report</Form.Label>
                            <input type="text" className="form-control" value={nodeName} onChange={e => setNodeName(e.target.value)} />
                            <Form.Text>E.g. "Monthly Revenue Report"</Form.Text>
                            
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Describe this report</Form.Label>
                            <Form.Control as="textarea" className="form-control" value={nodeDescription} onChange={e => setNodeDescription(e.target.value)} />
                            
                        </Form.Group>
                        <table className="table table-bordered">
                            <thead className="bg-light">
                                <tr>
                                    <th>You are measuring:</th>
                                    <th>One row per:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <ul>
                                            {selectedMeasureIds.map(id => {
                                                const measure = measures.data?.find(m => m.id == id);
                                                return <li key={id}>
                                                    <h4 className="mb-0">{measure?.name}</h4>
                                                    <p>{measure?.description || 'No description'}</p>
                                                </li>
                                            })}
                                        </ul>
                                    </td>
                                    <td>
                                        <ul>
                                            {selectedDimensionIds.map(id => {
                                                const dimension = dimensions.data?.find(d => d.id == id);
                                                return <li key={id}>
                                                    <h4 className="mb-0">
                                                        <PipelineNodeFieldName pipelineNodeId={dimension?.pipeline_node_id as string} fieldId={dimension?.field_id as string}/>
                                                        <span className="fw-light"> (from <PipelineNodeName pipelineNodeId={dimension?.pipeline_node_id}/>)</span>
                                                    </h4>
                                                    <PipelineNodeFieldDescription pipelineNodeId={dimension?.pipeline_node_id as string} fieldId={dimension?.field_id as string}/>

                                                </li>
                                            })}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                       
                    </div>}
                </div>
            </div>
        </WizardContent>
        <WizardFooter>
        <div className="row">
                <div className="col-6">
                    {step > 0 && <button onClick={() => {
                        setStep(step - 1)
                     }} className="btn btn-outline-secondary">&larr; Back</button>}
                </div>
                <div className="col-6 text-end">
                    {step < (numberOfSteps - 1) && <button onClick={() => setStep(step + 1)} disabled={!nextIsEnabled} className="btn btn-dark">Next &rarr;</button>}
                    {step == (numberOfSteps - 1) && <AsyncButton loading={saving} onClick={saveNode} disabled={!nodeName || saving} className="btn btn-success" text="Done"/>}
                </div>
            </div>
        </WizardFooter>
    </>
    return <PageStructure>
        <PageContent>
            <PageContentHeader>
                <div className="d-flex center-vertically">
                    <DashboardIcon icon="mdi mdi-wizard-hat" bgColor="success"/>

                    <div className="flex-1">
                        <h1 className="mb-0">Report Wizard</h1>
                        <div className="text-muted font-13">
                            Create a report from your entities and metrics.
                        </div>
                    </div>
                </div>
            </PageContentHeader>
            <PageContentInner hasHeader>
                <Pane>

                        {(entityNodes.length == 0 || measures.data?.length == 0) && <div className="p-5">

                            <div className="empty-state text-center">
                                <h3>Slow down there, partner</h3>
                                <p>You need to create some entities and metrics in your semantic layer before you can build a report</p>
                                <Link to="/semantic-layer" className="btn btn-pliable">Go to semantic layer</Link>
                            </div>
                        </div>}

                        {entityNodes.length > 0 && measures.data && measures.data.length > 0 && innerContent}
                </Pane>
            </PageContentInner>
        </PageContent>
    </PageStructure>
}

export default ReportWizardPage;