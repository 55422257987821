import { savePipelineNode, usePipelineNode, usePipelineNodes } from "@stores/data.store";
import { pipe } from "handsontable/helpers";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import toast from "@services/toast.service";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, Pane, PaneContent } from "@pages/PageStructure.component";
import SourceIcon from "@components/sources/SourceIcon.component";
import { NodeList } from "@components/pipelineNodes/PipelineNodeList.component";
import flatFileIcon from '@assets/images/connectors/flatfile.png';
import { getGroupValueForNodeType } from "@services/modeling.service";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import { getPromptAnswer } from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import { PipelineNode } from "@models/pipelineNode";
import { DraftOnly, ProdOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";


export const ReportsEmptyState = () => {
    return <>
        <DraftOnly>
            <div className="empty-state">
                <div className="text-center">
                    <h3>You haven't created any reports yet.</h3>
                    <p>Pliable makes it easy to build accurate reports using your Entities and Metrics, without writing any SQL.</p>
                    <Link className="btn btn-pliable" to={`/wizard/report`}>Create one now.</Link>
                </div>
            </div>
        </DraftOnly>
        <ProdOnly>
            <h3>You haven't created any reports yet.</h3>
            <p>Pliable makes it easy to build accurate reports using your Entities and Metrics, without writing any SQL.</p>
            <InfoAlert>
                You're currently in Production Mode. You must enter Develop Mode to create reports.
            </InfoAlert>
        </ProdOnly>
    </>
}
const ReportsPage = () => {
    const pipelineNodes = usePipelineNodes();

    const reportNodes = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }

        return pipelineNodes.data.filter((node) => getGroupValueForNodeType(node.node_type) == 'REPORTING').sort((a, b) => a.name.localeCompare(b.name));
    }, [pipelineNodes.dataUpdatedAt]);

    const navigate = useNavigate();
    

    return <PageStructure>
        <PageContent>
        <PageContentHeader>
            <div>
            <DashboardIcon bgColor='success' icon='mdi mdi-table-star'/>
                <div className="flex-1 ms-2">
                    <h1 className="mb-0">Reports</h1>
                    <div className="text-muted font-13">Manage all of your stakeholder-facing reports.</div>

                </div>
                <ReadOnly>
                    <Link className="btn btn-primary" to="/wizard/report">
                        <i className="mdi mdi-plus-thick"></i> Create New Report
                    </Link>
                </ReadOnly>
            </div>
        </PageContentHeader>
        <PageContentInner hasHeader>
            <div className="p-4">
                <div className="card">
                    <div className="card-body">
                        {reportNodes.length == 0 && <>
                            <ReportsEmptyState/>
                        </>}   
                        <NodeList nodes={reportNodes} onClick={(node: PipelineNode) => {
                            navigate(`/node/${node.id}/config`);
                        }}/>
                    </div>
                </div>
            </div>
        </PageContentInner>
          
       
    </PageContent>
    </PageStructure>
}

export default ReportsPage;