import { getErrorMessage } from '@services/errors.service';
import toast from '@services/toast.service';
import { Component, useCallback, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;
    className?: string;
    status?: string;
    text?: string;
    // If true, the button will have a green background (the default)
    primaryAction?: boolean;

}

const SaveButton = (props: Props) => {
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const [status, setStatus] = useState('');

    const buttonText = props.text ? props.text : 'Save';

    let saveButtonIcon = (<i className="mdi mdi-content-save"></i>);

    if (saving) {
        saveButtonIcon = <i className="mdi mdi-loading mdi-spin"></i>
    } else if (!!status && status == 'success') {
        saveButtonIcon = (<i className="mdi mdi-check-circle spin-2s"></i>)
    }
    else if (!!status && status == 'error') {
        saveButtonIcon = (<i className="mdi mdi-alert"></i>)
    }
    let theClass =  (props.primaryAction === false) ? 'btn btn-light' : 'btn btn-success';

    if (!!status && status === 'error') {
        theClass = 'btn btn-danger shake-1s';
    }
    if (props.className) {
        theClass = theClass + ' ' + props.className;
    }

    const updateStatus = useCallback((newStatus: string) => {
        setStatus(newStatus);
        setTimeout(() => {
            setStatus('');
        }, 2000)
    }, [])

    const onClick = useCallback(async () => {
        setSaving(true);
        try {
            await props.onClick();
            updateStatus('success');

        } catch (err) {
            setError(err);
            toast('danger', 'Error', getErrorMessage(err));
            updateStatus('error');
        } finally {
            setSaving(false);
        }
    }, [props.onClick])
    return (
        <button className={theClass} disabled={saving || (!!props.disabled)} onClick={(e) => onClick()}>
                                
            {saveButtonIcon}
            <span className="ms-1">{buttonText}</span>
        </button>
    )
}

export default SaveButton;

