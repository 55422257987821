import EditableText from "@components/general/EditableText.component";
import { PipelineNodeField, PipelineNodeMapOption, PipelineNodeORM } from "@models/pipelineNode";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { getPromptAnswer, requireConfirmation } from "@services/alert/alert.service";
import { shortid } from "@services/id.service";
import { usePipelineNode } from "@stores/data.store";
import produce from "immer";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { Badge, Offcanvas } from "react-bootstrap";
import { DndProvider, DragSourceMonitor, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from 'styled-components';
import PipelineNodeFieldMap from "./PipelineNodeFieldMap.component";
import { updateCommaList } from "typescript";
import AsyncButton from "@components/button/AsyncButton.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import LoadingCard from "@components/card/LoadingCard.component";
import toast from "@services/toast.service";
import PipelineNodeName from "../PipelineNodeName.component";
import { SingleNodeItem } from "../PipelineNodeList.component";
import { divide } from "lodash";

interface DraggableFieldProps {
    column: PipelineNodeField;
    onDrop: (sourceFieldId: string, droppedFieldId: string) => any;
    children: any;
}

const DraggableFieldStyles = styled.div`
cursor: move;

&:hover .shadow-box {
    background-color: var(--ct-light);
}
`;

const DraggableField = (props: DraggableFieldProps) => {
    const [{ isDragging, opacity }, drag] = useDrag({
        type: 'FIELD',
        item: {
            column: props.column,
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult() as any;
            if (item && dropResult) {
                let alertMessage = ''
                const isDropAllowed = true;
            }

            if (dropResult) {
                props.onDrop(props.column.id, dropResult.fieldId);

            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
            isDragging: monitor.isDragging(),
            column: props.column,
        }),
    });

    return <DraggableFieldStyles ref={drag}>
        {props.children}
    </DraggableFieldStyles>;
};

interface DroppableFieldProps {
    field: PipelineNodeField;
    children: ReactNode;
}

const EmptyDropTargetContainer = styled.div`
    border: dashed 2px var(--ct-border-color);
    border-radius: 5px;

    &.is-over {
        border-color: var(--ct-primary);
    }
`

const EmptyDropTarget = () => {
    const [{canDrop, isOver }, drop] = useDrop({
        accept: 'FIELD',
        drop: () => ({
            fieldId: 'NEW',
        }),
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const classes = ['p-2'];

    if (canDrop) {
        classes.push('can-drop');
    }

    if (isOver) {
        classes.push('is-over');
    }

    return <EmptyDropTargetContainer  ref={drop} className={classes.join(' ')}>
        Drop to add new column
    </EmptyDropTargetContainer>
}

const DroppableFieldContainer = styled.div`
&.is-over .shadow-box {
    border-color: var(--ct-primary);
}

.badge:hover {
    cursor: pointer;
}
`



const DroppableField = (props: DroppableFieldProps) => {
    const [{ canDrop, isOver }, drop] = useDrop({
          accept: 'FIELD',
          drop: () => ({
            fieldId: props.field.id,
          }),
          collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
          }),
    });

    const classes = [];

    if (canDrop) {
        classes.push('can-drop');
    }

    if (isOver) {
        classes.push('is-over');
    }
    return <DroppableFieldContainer ref={drop} className={classes.join(' ')}>
        {props.children}
    </DroppableFieldContainer>;
}


interface Props {
    fields: PipelineNodeField[];
    sourceNodeId: string;
    destinationNodeId: string;
    onChange: (newFields: PipelineNodeField[]) => any;
}

const PipelineNodeDragAndDropMapping = (props: Props) => {
    const sourceNode = usePipelineNode(props.sourceNodeId);
    const destNode = usePipelineNode(props.destinationNodeId);
    const [sourceSearch, setSourceSearch] = useState('');
    const [destSearch, setDestSearch] = useState('');

    const [loadingSuggestedMappings, setLoadingSuggestedMappings] = useState(false);
    const [suggestedMappingsMode, setSuggestedMappingsMode] = useState(false);
    const [suggestedMappings, setSuggestedMappings] = useState<any[]>([]);

    const sourceFields = useMemo(() => {
        if (!sourceNode.data) {
            return [];
        }

        return sourceNode.data.fields.filter(f => {
            return f.label.toLowerCase().includes(sourceSearch.toLowerCase());
        }).sort((a, b) => a.label.localeCompare(b.label));
    }, [sourceNode.dataUpdatedAt, sourceSearch]);

    const destFields = useMemo(() => {
        return props.fields.filter(f => {
            if (['FOREIGN_KEY', 'DENORMALIZED'].includes(f.type)) {
                return false;
            }
            return f.label.toLowerCase().includes(destSearch.toLowerCase());
        }).sort((a, b) => a.label.localeCompare(b.label));
    }, [props.fields, destSearch]);

    const addNewColumn = useCallback(async () => {
        const colName = await getPromptAnswer('What do you want to name this new column?', 'New Column');

        if (!colName) {
            return;
        }

        const newFields = [...props.fields, {
        
            id: shortid(),
            label: colName,
            name: colName,
            description: '',
            type: 'STRING',
            map_options: [],
            taxonomic_id: '',
            part_of_composite_key: false,
            cell_actions: [],
            
        }];
        
        setDestSearch('');
        props.onChange(newFields);
    
    }, [props.fields, props.onChange]);


    const changeColumnLabel = useCallback((id: string, newLabel: string) => {
        const newFields = produce(props.fields, draft => {
            const field = draft.find(f => f.id === id);
            if (field) {
                field.label = newLabel;
            }
        });

        props.onChange(newFields);
    }, [props.onChange, props.fields]);

    const changeColumnDescription = useCallback((id: string, newDescription: string) => {
        const newFields = produce(props.fields, draft => {
            const field = draft.find(f => f.id === id);
            if (field) {
                field.description = newDescription;
            }
        });

        props.onChange(newFields);
    }, [props.onChange, props.fields]);
    
    const addField = useCallback((sourceFieldId: string) => {
        const sourceField = sourceNode.data!.fields.find(f => f.id === sourceFieldId);
        
        const newFields = produce(props.fields, draft => {
            const destField = draft.find(f => f.name === sourceField!.name);
            if (destField) {
                destField.map_options.push({
                    id: shortid(),
                    source_node_id: props.sourceNodeId,
                    attribute_id: sourceFieldId,
                });
                return;
            }
            draft.push({
                id: shortid(),
                label: sourceField!.label,
                name: sourceField!.name,
                description: sourceField!.description,
                type: sourceField!.type,
                part_of_composite_key: false,
                taxonomic_id: '',
                cell_actions: [],
                map_options: [
                    {
                        id: shortid(),
                        source_node_id: props.sourceNodeId,
                        attribute_id: sourceFieldId,
                    }
                ]
            });
        });
        props.onChange(newFields);
    }, [props.fields, sourceNode.dataUpdatedAt, props.onChange])

    const onMapField = useCallback((sourceFieldId: string, destinationFieldId: string) => {
        if (!sourceNode.data) {
            return;
        }
        const newFields = produce(props.fields, draft => {
            const sourceField = sourceNode.data!.fields.find(f => f.id === sourceFieldId);
            if (!sourceField) {
                return;
            }
            if (destinationFieldId == 'NEW') {
                draft.push({
                    id: shortid(),
                    label: sourceField!.label,
                    name: sourceField!.name,
                    description: sourceField!.description,
                    type: sourceField!.type,
                    part_of_composite_key: false,
                    taxonomic_id: '',
                    cell_actions: [],
                    map_options: [
                        {
                            id: shortid(),
                            source_node_id: props.sourceNodeId,
                            attribute_id: sourceFieldId,
                        }
                    ]
                });
                return;
            }
            const destField = draft.find(f => f.id === destinationFieldId);
            if (destField) {
                destField.map_options.push({
                    id: shortid(),
                    source_node_id: props.sourceNodeId,
                    attribute_id: sourceFieldId,
                });
            }
            
        });

        props.onChange(newFields);
    }, [props.fields, props.fields.length, sourceNode.dataUpdatedAt, props.onChange]);


    const [showColumnId, setShowColumnId] = useState<string>('');


    const updateMapOptionsForActiveColumn = useCallback((columnId: string, newMapOptions: PipelineNodeMapOption[]) => {
        console.log('Current fields in updater:', props.fields)
        const newFields = produce(props.fields, draft => {
            const field = draft.find(f => f.id === columnId);
            if (field) {
                field.map_options = newMapOptions;

            }
        });

        props.onChange(newFields);
    }, [props.fields, props.onChange]);

    const showColumn = useMemo(() => {
        return props.fields.find(f => f.id === showColumnId);
    }, [showColumnId, props.fields]);

    const removeColumn = useCallback(async (columnId: string) => {
        const approved = await requireConfirmation('Are you sure you want to remove this column?', 'Remove Column');
        if (approved) {
            const newFields = props.fields.filter(f => f.id !== columnId);
            props.onChange(newFields);
        }
    }, [props.fields, props.onChange]);

    const suggestColumnMappings = useCallback(async () => {
        setLoadingSuggestedMappings(true);
        try {
            const resp = await PipelineNodeORM.suggestColumnMappings(props.sourceNodeId, props.destinationNodeId, props.fields);
            console.log(resp.result);

            const suggestions : any[] = [];

            resp.result.mappings.forEach((m: any) => {
                const sourceField = sourceFields.find(f => f.id === m.src_field_id);
                const destField = props.fields.find(f => f.id === m.dest_field_id);
                if (!destField || !sourceField) {
                    console.log('Could not find dest field or source field', destField, sourceField);
                    return;
                }

                for(const mo of destField.map_options) {
                    if (mo.source_node_id === props.sourceNodeId && mo.attribute_id === sourceField.id) {
                        console.log('This one is already mapped');
                        return;
                    }
                }

                suggestions.push({
                    sourceField: sourceField,
                    destField: destField,
                    explanation: m.explanation,
                });

            });

            setSuggestedMappingsMode(true);
            setSuggestedMappings(suggestions);
        } catch (e) {
            console.error(e);
            toast('danger', 'Error', 'Unable to load suggestions');
        }finally {
            setLoadingSuggestedMappings(false);
        }
    }, [props.fields, props.onChange]);

    const acceptSuggestedMapping = useCallback((suggestion: any, index: number) => {
        onMapField(suggestion.sourceField.id, suggestion.destField.id);
        const copy = [...suggestedMappings];
        copy.splice(index, 1);
        setSuggestedMappings(copy);
        if (copy.length === 0) {
            setSuggestedMappingsMode(false);
        }
    }, [suggestedMappings]);

    const mappedColumnsBySourceId: {
        [key: string]: string[];
    } = useMemo(() => {
        if (!sourceNode.data) {
            return {};
        }
        
        const mappedColumns: { [key: string]: string[] } = {};
        props.fields.forEach(f => {
            f.map_options.forEach(mo => {
                if (!mappedColumns[mo.attribute_id!]) {
                    mappedColumns[mo.attribute_id!] = [];
                }
                if (mo.source_node_id == props.sourceNodeId) {
                    mappedColumns[mo.attribute_id!].push(f.id);
                }
            });
        });
        return mappedColumns;
    }, [props.fields, sourceNode.dataUpdatedAt]);

    if (loadingSuggestedMappings) {
        return (
            <>
            <div className="row">
                <div>
                    <button className="btn btn-outline-primary btn-sm float-right mb-3" onClick={() => {setLoadingSuggestedMappings(false)}}>Close Suggestions</button>
                </div>
            </div>
            <div className="row">
                <div>
                    <LoadingCard action="Choosing the best mappings" />
                </div>
            </div>
            </>
        );
    }

    if (suggestedMappingsMode) {
        return (
            <>
            <div className="d-flex center-vertically mb-2">
                <h3 className="mb-0 flex-1">
                    {suggestedMappings.length == 1 && <>1 Suggestion</>}
                    {suggestedMappings.length != 1 && <>{suggestedMappings.length} Suggestions</>}
                </h3>
                <button className="btn btn-outline-primary btn-sm" onClick={() => setSuggestedMappingsMode(false)}>Close Suggestions</button>
            </div>
            
            <div className="row">
                {suggestedMappings.length == 0 && <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4>Oops!</h4>
                            <p>No more suggestions! You may have already mapped all the columns that the AI would have found.</p>
                            <button className="btn btn-primary" onClick={() => setSuggestedMappingsMode(false)}>Do it the ol' fashioned way</button>
                        </div>
                    </div>
                </div>}
                {suggestedMappings.map((s, i) => {
                    return <div key={i} className="col-6">
                        <div className="shadow-box p-2 mb-2">
                            <h4>{s.sourceField.label} &rarr; {s.destField.label}</h4>
                            <p><strong><i className="mdi mdi-robot"></i> AI says:</strong> <i>{s.explanation}</i></p>
                            <button className="btn btn-outline-primary btn-sm" onClick={() => {
                                acceptSuggestedMapping(s, i);
                            }}><i className="mdi mdi-arrow-right-bold"></i> Accept</button>
                        </div>
                    
                    </div>

                })}
            </div>
            </>
        );
    }



    return <div className="no-scroll" style={{height: '100%'}}>
        <Offcanvas show={!!showColumn} onHide={() => setShowColumnId('')} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <h3><span className="fw-normal">Source columns mapped to:</span> <span className="fw-bold">{showColumn?.label}</span></h3>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <div className="p-2">
                            
                            <PipelineNodeFieldMap
                                showPriority={false}
                                mapOptions={showColumn?.map_options || []}
                                onChangeMapOptions={(newOptions) => {
                                    // Update the map options
                                    updateMapOptionsForActiveColumn(showColumn!.id, newOptions);
                                } }
                                displayJoinPath={false} 
                                isUnlocked={true}
                            />
                        </div>
                    </PaneContent>
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>


        <DndProvider backend={HTML5Backend}>
            <div className="d-flex" style={{height: '100%'}}>
                <div className=" pe-0 ps-0 bg-light rounded border" style={{height: '100%', width: '45%'}}>
                    <div className="d-flex flex-column" style={{height: '100%'}}>
                        <div className="p-2">
                            <div className="mb-2">
                                {sourceNode.data && <SingleNodeItem node={sourceNode.data} />}
                            </div>
                                
                                
                            <input type="text" className="input-rounded form-control mb-2" placeholder="Search" value={sourceSearch} onChange={(e) => setSourceSearch(e.target.value)} />
                        </div>
                        
                        <div className="flex-1">
                        <Pane>
                            <PaneContent className="p-2 pt-0">
                            
                            
                            {sourceFields.map(f => {
                                return <DraggableField
                                    column={f}
                                    key={f.id}
                                    onDrop={onMapField}
                                >
                                    <div className="shadow-box mb-1 p-2">
                                        <div className="d-flex center-vertically">
                                            <div className="flex-1 me-3">
                                                <h4 className="mb-0">{f.label}</h4>
                                                <small>{f.description || 'No description'}</small>
                                            </div>
                                            <div className="me-1">
                                                <Badge bg="light" className="border">{f.type}</Badge>
                                            </div>
                                            {mappedColumnsBySourceId[f.id]?.length > 0 && <div className="me-1">
                                                <Badge bg={mappedColumnsBySourceId[f.id]?.length > 0 ? 'primary': 'secondary'}>{mappedColumnsBySourceId[f.id]?.length || 0}</Badge>

                                            </div>}
                                            {!mappedColumnsBySourceId[f.id]?.length && <div>
                                                <button onClick={() => {
                                                    addField(f.id);
                                                        
                                                }} className="icon-button font-18" title="Add Column">
                                                    <i className="mdi mdi-arrow-right-bold"></i>
                                                </button>
                                            </div>}
                                            
                                        </div>
                                        
                                    </div>
                                </DraggableField>
                            })}

                                
                        </PaneContent>
                    </Pane>
                        </div>
                    </div>
                    
                </div>

                <div style={{width: '10%'}} className="ps-2 pe-2">
                    <div className="d-flex flex-column" style={{height: '100%'}}>
                        <div className="flex-1"></div>
                        {/* <div className="text-center" style={{fontSize: '72px'}}>
                            <i className="mdi mdi-arrow-right-bold"></i>
                        </div> */}
                        <AsyncButton
                        icon={`mdi mdi-auto-fix`}
                            variant="pliable"
                            onClick={suggestColumnMappings}
                            text="Map with AI"
                            className="btn-rounded"
                            loading={loadingSuggestedMappings}
                        ></AsyncButton>
                        <div className="flex-1"></div>
                    </div>
                    
                </div>
                <div className="col-6 pe-0 ps-0 bg-light rounded border" style={{height: '100%', width: '45%'}}>
                    <div className="d-flex flex-column" style={{height: '100%'}}>
                        <div className="p-2">
                            <div className="mb-2">
                                {destNode.data && <SingleNodeItem node={destNode.data} />}
                            </div>
                            

                            <div className="d-flex center-vertically mb-2">
                                <input type="text" className="input-rounded form-control flex-1 me-2" placeholder="Search" value={destSearch} onChange={(e) => setDestSearch(e.target.value)} />
                                <button className="btn btn-outline-primary btn-sm" onClick={addNewColumn}>
                                    <i className="mdi mdi-plus-thick"></i> Add Column
                                </button>
                            </div>
                        </div>
                            
                        <div className="flex-1">
                            <Pane>
                                <PaneContent className="p-2 pt-0">
                                <div className="mb-1">
                                    <EmptyDropTarget/>

                                </div>
                                {destFields.map(f => {
                                    return <DroppableField field={f} key={f.id}>
                                        <div className="shadow-box mb-1 p-2">
                                            <div className="d-flex center-vertically">
                                                <div className="flex-1 me-3">
                                                    <h4 className="mb-0">
                                                        <EditableText value={f.label} onChange={(newVal: string) => {
                                                            changeColumnLabel(f.id, newVal);
                                                        }} />
                                                        
                                                    </h4>
                                                    <small>
                                                        <EditableText textarea value={f.description || 'No description'} onChange={(newVal: string) => {
                                                            changeColumnDescription(f.id, newVal);
                                                        }}/>
                                                    </small>
                                                </div>
                                                <div className="me-1">
                                                    <Badge bg="light" className="border">{f.type}</Badge>
                                                </div>
                                                <div className="me-1">
                                                    <Badge title={`${f.map_options.length} columns mapped`} onClick={() => {
                                                        setShowColumnId(f.id);
                                                    }} bg={f.map_options.length > 0 ? 'primary' : 'warning'}>{f.map_options.length}</Badge>
                                                </div>
                                                <div>
                                                    <button title="Delete column" className="icon-button font-18" onClick={() => {
                                                        removeColumn(f.id);
                                                    }}><i className="mdi mdi-delete"></i></button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </DroppableField>
                                })}
                            </PaneContent>
                        </Pane>
                        </div>
                    </div>

                    
                </div>
            </div>
        </DndProvider>
    </div>
};

export default PipelineNodeDragAndDropMapping;