import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Collapse } from "react-bootstrap";
import { ArrowToggleIcon } from '@components/icons/Shapes.components';
import styled from 'styled-components';
import useGlobalState from '@stores/global.state';
import { saveDataMart, useDataMarts, useMissionControlDataFlowData, usePipelineNodes, useSourceRecordTypes, useSources } from '@stores/data.store';
import LoadingCard from '@components/card/LoadingCard.component';
import { ReactComponent as GreyCircle } from '../../assets/images/pliable/shapes/grey_circle.svg';
import { ReactComponent as AddCircle } from '../../assets/images/pliable/shapes/add_circle.svg';
import { useDebounce } from 'use-debounce';
import { getPromptAnswer, prompt } from '@services/alert/alert.service';
import DataSourceSelectModal from '@pages/Sources/DataSourceSelectModal.component';
import SourceIcon from '@components/sources/SourceIcon.component';
import BusinessObjectIcon from '@components/businessObjects/BusinessObjectIcon.component';
import SourceRecordTypeIcon from '@components/sources/SourceRecordTypeIcon.component';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import { getGroupValueForNodeType, getNodeTypeConfig } from '@services/modeling.service';


const Styles = styled.div`
    background-color: white;
    position: relative;
    height: 100%;

    h2 {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: .7em;
    }

    .list-group-item {
        padding: .25rem 0px;
        border: none;
        font-family: "Poppins";
        font-size: 13px;
        

        &.empty {
            color: #ccc;
        }

        display: flex;
        align-items: center;
        div.icon {
            width: 25px;
            height: 25px;
            min-width: 25px;
            line-height: 25px;
            border-radius: 100%;
            text-align: center;
            margin-right: .5rem;
        }

        &.active {
            background-color: var(--pliable-yellow-light) !important;
            color: #212529 !important;
            border: none important!;
        }



    
    }

    
`

const LibraryItemStyles = styled.div`
    padding: .25rem 0px;
    border: none;
    margin-bottom: 12px;
    cursor: pointer;
    
    a {
        color: #0F0E31;
    }

    a:hover{
        text-decoration: none;
    }

    h3 {
        color: #797979;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 0px;
    }

    h4 {
        margin-bottom: 0px;
        color: black;
        font-weight: 600;
    }

    &.new-item {
        border: 1px dashed #D9D9D9
    }
        
    

    &:hover {
        background-color: #f4f4f4;
    }
`




export interface LibraryItemProps {
    itemType: string;
    children?: ReactNode;
    name: string;
    onClick: () => void;
}

export const LibraryItem = (props: LibraryItemProps) => {
    return (
        <LibraryItemStyles className="library-item d-flex" onClick={props.onClick}>
            <div className="d-flex flex-row align-items-center">
                {props.children}
                
            </div> 
            <div className="d-flex flex-column px-2 justify-content-center">
                <h3>{props.itemType}</h3>
                <h4>{props.name}</h4>
            </div>
            
        </LibraryItemStyles>
        
    )
} 



export interface ToggleProps {
    isCollapsed: boolean;
}

export const ToggleIcon = (props: ToggleProps) => {
    return (
        <ArrowToggleIcon height='1em' collapsed={props.isCollapsed} marginLeft='5px' />
    )
}   

interface LibraryProps {
    activeNodeId?: string;
    onToggleNodeActive?: (nodeId: string) => any;
}

const DataLibrary = (props: LibraryProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchInput, setSearchInput] = useState('');
    const [searchTerm] = useDebounce(searchInput, 250);

    const globalState = useGlobalState();

    const {refetch: refetchMissionControl} = useMissionControlDataFlowData();

    const toggleDrawer = useCallback(() => {
        globalState.toggleLibrary();
    }, []);

    const toggleLibraryBOs = useCallback(() => globalState.toggleLibraryBOs(), []);
    const toggleLibraryRecordTypes = useCallback(() => globalState.toggleLibraryRecordTypes(), []);
    const toggleLibraryDatasources = useCallback(() => globalState.toggleLibraryDatasources(), []);
    const toggleLibraryReports = useCallback(() => globalState.toggleLibraryReports(), []);



    const pipelineNodes = usePipelineNodes();
    const datamarts = useDataMarts();

    const filteredSourceNodes = useMemo(() => {
        const term = searchTerm.toLowerCase();
        if(pipelineNodes.data){
            return pipelineNodes.data.filter((pn) => {
                return getGroupValueForNodeType(pn.node_type) === 'SOURCE' && pn.name.toLowerCase().includes(term)
            }).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 1;
            });
        } else {
            return [];
        }

    }, [searchTerm, pipelineNodes.data]);

    const filteredStagingNodes = useMemo(() => {
        const term = searchTerm.toLowerCase();
        if(pipelineNodes.data){
            return pipelineNodes.data.filter((pn) => {
                return getGroupValueForNodeType(pn.node_type) === 'STAGING' && pn.name.toLowerCase().includes(term)
            }).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 1;
            });
        } else {
            return [];
        }

    }, [searchTerm, pipelineNodes.data]);

    const filteredDataModelNodes = useMemo(() => {
        const term = searchTerm.toLowerCase();
        if(pipelineNodes.data){
            return pipelineNodes.data.filter((pn) => {
                return getGroupValueForNodeType(pn.node_type) === 'DATA_MODELING' && pn.name.toLowerCase().includes(term)
            }).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 1;
            });
        } else {
            return [];
        }

    }, [searchTerm, pipelineNodes.data]);

    

    const filteredReportingNodes = useMemo(() => {
        const term = searchTerm.toLowerCase();
        if(pipelineNodes.data){
            return pipelineNodes.data.filter((pn) => {
                return getGroupValueForNodeType(pn.node_type) === 'REPORTING' && pn.name.toLowerCase().includes(term)
            }).sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                }
                return 1;
            });
        } else {
            return [];
        }

    }, [searchTerm, pipelineNodes.data]);

    const onDragStart = (event: any, nodeType: string, object_id: string) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.setData('object_id', object_id);
        event.dataTransfer.effectAllowed = 'move';
    };


    const toggleNode = useCallback((nodeId: string) => {
        if (props.onToggleNodeActive) {
            props.onToggleNodeActive(nodeId);
        }
    }, [props.onToggleNodeActive]);

   

    return (
    <Styles className="d-flex flex-column flex-shrink-0 bg-body-tertiary">
        <h2>📚 DATA LIBRARY</h2>
        <Form.Group className="mb-2">
            <Form.Control className="round-input" onChange={(e) => setSearchInput(e.target.value)} placeholder='Search'>
            </Form.Control>
        </Form.Group>
        <h2>Reporting Nodes</h2>
        <div className="list-group datamarts mb-3">
            {filteredReportingNodes.length === 0 && (
                <div className="list-group-item empty">Nothing here</div>
            )}
            {filteredReportingNodes.map(bo => {
                const isActive = props.activeNodeId == ('PipelineNode:' + bo.id);
                const nodeConfig = getNodeTypeConfig(bo);
                return <div className={`list-group-item list-group-item-action ${isActive ? 'active': ''}`} onClick={() => toggleNode('PipelineNode:' + bo.id)}>
                    <div className={`icon bg-${nodeConfig.group.color}`}>
                        <i className={nodeConfig.nodeType.icon}></i>
                    </div>{bo.name}
                    {/* <Menu menuButton={<button className="icon-button">
                        <i className="mdi mdi-menu"></i>
                    </button>}>
                        <MenuItem>
                        </MenuItem> */}

                        
                        {/* <SubMenu label="Edit">
                            <MenuItem>Cut</MenuItem>
                            <MenuItem>Copy</MenuItem>
                            <MenuItem>Paste</MenuItem>
                        </SubMenu>
                        <MenuItem>Print...</MenuItem> */}
                    {/* </Menu> */}
                </div>
            })}
        </div>
        <h2>Entities</h2>
        <div className="list-group mb-3 business-objects">
            {filteredDataModelNodes.length === 0 && (
                <div className="list-group-item empty">Nothing here</div>
            )}
            {filteredDataModelNodes.map(bo => {
                const isActive = props.activeNodeId == ('PipelineNode:' + bo.id);
                const nodeConfig = getNodeTypeConfig(bo);
                return <div className={`list-group-item list-group-item-action ${isActive ? 'active': ''}`} onClick={() => toggleNode('PipelineNode:' + bo.id)}>
                    <div className={`icon bg-${nodeConfig.group.color}`}>
                        <i className={nodeConfig.nodeType.icon}></i>
                    </div>{bo.name}
                </div>
            })}
        </div>

        <h2>Cleaning Steps</h2>
        <div className="list-group mb-3 staging-tables">
            {filteredStagingNodes.length === 0 && (
                <div className="list-group-item empty">Nothing here</div>
            )}
            {filteredStagingNodes.map(bo => {
                const isActive = props.activeNodeId == ('PipelineNode:' + bo.id);
                const nodeConfig = getNodeTypeConfig(bo);

                return <div className={`list-group-item list-group-item-action ${isActive ? 'active': ''}`} onClick={() => toggleNode('PipelineNode:' + bo.id)}>
                    <div className={`icon bg-${nodeConfig.group.color}`}>
                        <i className={nodeConfig.nodeType.icon}></i>
                    </div>{bo.name}
                </div>
            })}
        </div>

        <h2>Data Sources</h2>
        <div className="list-group sources">
            {filteredSourceNodes.length === 0 && (
                <div className="list-group-item empty">Nothing here</div>
            )}
            {filteredSourceNodes.map(pn => {
                const isActive = props.activeNodeId == ('PipelineNode:' + pn.id);
                const nodeConfig = getNodeTypeConfig(pn);

                return <div className={`list-group-item list-group-item-action ${isActive ? 'active': ''}`} onClick={() => toggleNode('PipelineNode:' + pn.id)}>
                    <div className={`icon bg-${nodeConfig.group.color}`}>
                        <i className={nodeConfig.nodeType.icon}></i>
                    </div>{pn.name}
                </div>
            })}
        </div>

        

        

        

        
    </Styles>
    );

}


export default DataLibrary;