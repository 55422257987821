import SaveButton from "@components/button/SaveButton.component";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import Danger from "@components/statusIndicators/Danger.component";
import PageStructure, { PageContentNoSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import TenantConfigService from "@services/tenantConfig.service";
import { useQueryParams } from "@services/url.service";
import { useProjectConfig, useUserConfig } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";

interface GithubVerifyResponse {
    status: string;
    error?: string;
    error_description?: string;
}

interface GithubRepo {
    id: string;
    full_name: string;
    name: string;
}
const GithubIntegrationPage = () => {
    const queryParams = useQueryParams();

    const githubConnectionStatus = useMemo(() => {
        return queryParams.get('status');
    }, [queryParams]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [repoOptions, setRepoOptions] = useState<Option[]>([]);

    const userConfig = useUserConfig();

    const [selectedRepo, setSelectedRepo] = useState('');
    const [installationId, setInstallationId] = useState('');

    const [loadingRepos, setLoadingRepos] = useState(false);

    const loadRepos = useCallback(async () => {
        setLoadingRepos(true);
        try {
            const response = await ApiService.getInstance().request('GET', '/github/repos') as ListRecordsResponse<GithubRepo>;
            setRepoOptions(response.records.map(r => {
                return {
                    value: r.full_name,
                    label: r.full_name,
                }
            }));
        } catch (err) {
            setError(getErrorMessage(err));
        } finally {
            setLoadingRepos(false);
        }
    }, []);

    const validateGithubCallback = useCallback(async (code: string, installationId: string) => {
        setLoading(true);
        try {
            const response = await ApiService.getInstance().request('POST', '/github/verify', {
                'code': code,
                'installation_id': installationId,
            }) as GithubVerifyResponse;
            await tenantConfig.refetch();
        } catch (err) {
            setError(getErrorMessage(err));
        }
        
        setLoading(false);
    }, []);

    

    useEffect(() => {
        const setupAction = queryParams.get('setup_action');
        const code = queryParams.get('code');
        const installationId = queryParams.get('installation_id');

        // if (!code && userConfig.data && !userConfig.data?.has_github_access) {
        //     window.location.href = `${ApiService.getInstance().apiUrl()}/github/authorize`
        // }

        if (!!code && !!installationId) {
            validateGithubCallback(code, installationId as string);
            queryParams.set('setup_action', '');
        }else{
            if (!!installationId) {
                localStorage.setItem('_gh_install_id', installationId as string);
            }

            if(!!code){
                validateGithubCallback(code, installationId || localStorage.getItem('_gh_install_id') as string);
                queryParams.set('code', '');
                queryParams.set('setup_action', '');
            }
        }

    }, [queryParams, userConfig.dataUpdatedAt, validateGithubCallback])

    const tenantConfig = useProjectConfig();

    const saveRepo = useCallback(async () => {
        if (!tenantConfig.data) {
            return;
        }
        const response = await TenantConfigService.getInstance().saveRepo(tenantConfig.data.id, 'GITHUB', selectedRepo);
    }, [selectedRepo, tenantConfig.dataUpdatedAt]);

    useEffect(() => {
        const setupAction = queryParams.get('setup_action');
        if (!setupAction && tenantConfig.data && tenantConfig.data.github_installation_id) {
            loadRepos();

            if (tenantConfig.data.github_repo_name) {
                setSelectedRepo(tenantConfig.data.github_repo_name);
            }
        }
    }, [tenantConfig.dataUpdatedAt])

    return <PageStructure
        pageTitle="Github Integration"
    >
        <PageContentNoSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-onboarding">
                        <h1 className="page-title">Connect to Github</h1>
                        <div className="shadow-box p-3">
                            {loading && (
                                <PliableLoader/>
                            )}
                            {!loading && (
                                <>
                                    {error && (
                                        <>
                                            <Danger>
                                                {error}
                                            </Danger>
                                            <a href={`${ApiService.getInstance().apiUrl()}/github/install`}>Authorize Github</a>
                                        </>
                                    )}
                                    {!error && tenantConfig.data && tenantConfig.data.github_installation_id && (
                                        <>
                                            <h2>Connected to Github.</h2>
                                            <Form.Group className="mb-2">
                                                <Form.Label>Select Repository</Form.Label>
                                                {loadingRepos && (
                                                    <div>
                                                        <i className="mdi mdi-loading mdi-spin"></i> Loading
                                                    </div>
                                                )}
                                                {!loadingRepos && (
                                                    <Dropdown
                                                        options={repoOptions}
                                                        onChange={setSelectedRepo}
                                                        selected={selectedRepo}
                                                    />
                                                )}
                                                

                                            </Form.Group>
                                            <SaveButton
                                                className="me-2"
                                                onClick={saveRepo}
                                            />
                                            <a className="btn btn-light" href={`${ApiService.getInstance().apiUrl()}/github/install`}><i className="mdi mdi-github"></i> Edit Github Settings</a>


                                            
                                        </>
                                    )}
                                    {!error && tenantConfig.data && !tenantConfig.data.github_installation_id && (
                                        <p>We're not connected</p>
                                    )}
                                </>
                            )}
                            
                        </div>
                    </div>
                </PaneContent>
            </Pane>
        </PageContentNoSidebar>
    </PageStructure>
}

export default GithubIntegrationPage;