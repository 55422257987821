export const reorderList = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

export const humanReadableJoin = (arr: string[], separator: string, lastSeparator: string) => {
    if (arr.length === 0) {
        return '';
    }
    if (arr.length === 1) {
        return arr[0];
    }
    if (arr.length === 2) {
        return arr.join(lastSeparator);
    }
    return arr.slice(0, arr.length - 1).join(separator) + lastSeparator + arr[arr.length - 1];
}