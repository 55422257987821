import styled, { css } from "styled-components";

const HeaderAlert = styled.div<{red?: boolean}>`
background-color: var(--pliable-yellow-light);
border: solid 1px var(--pliable-yellow);
border-radius: 4px;
padding: 1rem;
color: black;
font-family: "Poppins";

${props => props.red && css`
    background-color:  var(--ct-danger-bg);
    border: solid 1px var(--ct-danger);   
`}

`

export default HeaderAlert;