import { ReactNode } from "react";
import styled from 'styled-components';
import WarningStatusIndicator from "./WarningStatusIndicator.component";

const Styles = styled.div`
background-color: var(--ct-warning-bg);
color: black;
border-radius: 5px;
padding: 1rem;
margin-bottom: 0.5rem;
font-family: "Poppins";
border: solid 1px var(--ct-warning);

display: flex;
align-items: center;

.icon-container {
    display: inline-block;
    margin-right: 1rem;
}

`
interface Props {
    children: ReactNode;
}

const Warning = (props: Props) => {
    return <Styles>
        <div className="icon-container">
            <WarningStatusIndicator/>

        </div>
        {props.children}
    </Styles>
}

export default Warning