import { useMeasures } from "@models/reportBuilder";
import { useMemo } from "react";

interface Props {
    measureId: string;
}

const MeasureName = (props: Props) => {
    const measures = useMeasures();

    const theMeasure = useMemo(() => {
        return measures.data?.find(m => m.id === props.measureId);
    }, [props.measureId])
    return <>
        {theMeasure?.name}
    </>
}

export default MeasureName;