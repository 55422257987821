import { PipelineNode, PipelineNodeExtraReportColumn, PipelineNodeField, PipelineNodeMeasureJoinTree, useReportingPaths } from "@models/pipelineNode";
import { ReportBuilderDimension, ReportBuilderDimensionJoinTree, ReportBuilderDimensionORM, ReportBuilderMeasure, ReportBuilderMeasureORM, useDimensions, useMeasures } from "@models/reportBuilder";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { Updater, useImmer } from "use-immer";
import PipelineNodeSelector from "./PipelineNodeSelector.component";
import PipelineNodeColumnSelector from "./PipelineNodeColumnSelector.component";
import { useIsInDraftMode, usePipelineNode, usePipelineNodes, useReportingTree } from "@stores/data.store";
import PipelineNodeName, { PipelineNodeFieldName } from "./PipelineNodeName.component";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import { graphlib } from "dagre";
import Warning from "@components/statusIndicators/Warning.component";
import PipelineNodeJoinTreeLabel from "./mapping/PipelineNodeJoinTreeLabel.component";
import DataWhitelistForm from "./PipelineNodeDataWhitelist.component";
import DimensionForm from "@components/datamodel/DimensionForm.component";
import MeasureForm, { getConnectionsForAllGraphNodes } from "@components/datamodel/MeasureForm.component";
import PipelineNodeColumnOrder from "./PipelineNodeColumnOrder.component";
import PipelineNodeWhitelistConfiguration from "./configuration/PipelineNodeWhitelistConfiguration.component";
import PipelineNodeOutputConfiguration from "./configuration/PipelineNodeOutputConfiguration.component";
import { DraftOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import { Link } from "react-router-dom";

export class ReportBuilderState {

    constructor(
        graph: graphlib.Graph,
        allDimensions: ReportBuilderDimension[],
        allMeasures: ReportBuilderMeasure[],
        initialSelectedDimensions: string[],
        initialSelectedMeasures: string[],
    ) {
        this.allDimensions = allDimensions;
        this.allMeasures = allMeasures;
        this._availableDimensionIds = [];
        this._availableMeasureIds = [];
        this.selectedDimensionIds = initialSelectedDimensions;
        this.selectedMeasureIds = initialSelectedMeasures;
        this.graph = graph;

        const measuresById: {[key: string]: ReportBuilderMeasure} = {};
        this.allMeasures.forEach(m => {
            measuresById[m.id as string] = m;
        });
        this.measuresById = measuresById;

        const dimensionsById: {[key: string]: ReportBuilderDimension} = {};
        this.allDimensions.forEach(d => {
            dimensionsById[d.id as string] = d;
        });
        this.dimensionsById = dimensionsById;

        this.updateAvailableDimensionIds();
        this.updateAvailableMeasureIds();
    }

    protected measuresById: {[key: string]: ReportBuilderMeasure};
    protected dimensionsById: {[key: string]: ReportBuilderDimension};

    public allDimensions: ReportBuilderDimension[] = [];
    public allMeasures: ReportBuilderMeasure[] = [];
    public selectedDimensionIds: string[] = [];
    public selectedMeasureIds: string[] = [];
    protected graph: graphlib.Graph;
    

    protected _availableDimensionIds: string[] = [];
    protected _availableMeasureIds: string[] = [];
    public get availableDimensionIds() {
        return this._availableDimensionIds;
    }
    public get availableMeasureIds() {
        return this._availableMeasureIds;
    }

    public updateAvailableDimensionIds() {
        if (this.selectedMeasureIds.length == 0) {
            return [];
        }

        const currentlySelectedNodeIds = this.allDimensions.filter(d => this.selectedDimensionIds.includes(d.id as string)).map(d => d.pipeline_node_id) || [];
        
        
        let requiredDimensionIds: string[] = [];

        /**
         * Get a list of the pipeline node IDs that are connected to EVERY selected measure
         */
        if (this.selectedMeasureIds.length > 0) {
            let startedTree = false;
            this.selectedMeasureIds.forEach((mid, idx) => {
                const measure = this.measuresById[mid];
                if (!measure) {
                    return;
                }

                // If a measure is a calculation, it doesn't have any direct dependencies
                // on dimensions, so we can skip it (meaning we're just relying on the dimension
                // dependencies of its upstream measures)
                if (measure?.is_calculation) {
                    return;
                }
                
                // Possible edge case where the measure doesn't yet have a pipeline node ID set
                const startWith: string[] = [];
                if (measure.pipeline_node_id) {
                    startWith.push(measure.pipeline_node_id as string);
                }

                /**
                 * Here we're basically building a list of pipeline node IDs that are connected to
                 * every single selected measure. If we haven't selected a measure yet, (startedTree == false)
                 * then we just start with the selected measure's pipeline node ID and all of that measure's join
                 * trees.
                 * 
                 * If we have already started the tree, then we filter the list of required dimension IDs to only
                 * those that are also connected to every subsequent measure.
                 */
                if (!startedTree) {
                    requiredDimensionIds = startWith.concat(measure?.dimension_join_trees?.map(d => d.pipeline_node_id) || []);
                    startedTree = true;
                } else {
                    requiredDimensionIds = startWith.concat(requiredDimensionIds.filter(d => measure?.dimension_join_trees?.map(d => d.pipeline_node_id).includes(d)));
                }
            });
        }

        /**
         * Now we can select the available dimension IDs. 
         */
        const uniqueNodeIds = new Set(currentlySelectedNodeIds);
        const MAX_NODES = 5;
        
        this._availableDimensionIds = this.allDimensions.filter(d => {
            // if (this.selectedDimensionIds.includes(d.id as string)) {
            //     return true;
            // }

            if (!d.pipeline_node_id) {
                return false;
            }

            if (!requiredDimensionIds.includes(d.pipeline_node_id)) {
                return false;
            }

            // If they've already hit the max, then only allow dimensions in the already selected nodes
            if (uniqueNodeIds.size >= MAX_NODES) {
                return uniqueNodeIds.has(d.pipeline_node_id);
            }
            
            if (currentlySelectedNodeIds.length == 0) {
                return true;
            }

            return true;
        }).map(d => d.id as string);
        
    }

    protected updateAvailableMeasureIds() {
        let requiredDimensionIds: string[] = [];
        /**
         * Get a list of the pipeline node IDs that are connected to EVERY selected measure
         */
        this.selectedMeasureIds.forEach((mid, idx) => {
            const measure = this.measuresById[mid];
            if (idx == 0) {
                requiredDimensionIds = measure?.dimension_join_trees?.map(d => d.pipeline_node_id) || [];
            } else {
                requiredDimensionIds = requiredDimensionIds.filter(d => measure?.dimension_join_trees?.map(d => d.pipeline_node_id).includes(d));
            }
        });
        
        const requiredPipelineNodeIdsFromCurrentDimensions = this.selectedDimensionIds.filter(did => this.dimensionsById.hasOwnProperty(did)).map(did => this.dimensionsById[did].pipeline_node_id) || [];

        requiredDimensionIds = requiredDimensionIds?.filter(did => requiredPipelineNodeIdsFromCurrentDimensions.includes(did));

        this._availableMeasureIds = this.allMeasures.filter(m => {
            // If they've already included the measure, allow them to select it.
            // XXX - we need to check if this is still valid and flag it if it's a problem
            if (this.selectedMeasureIds.includes(m.id as string)) {
                return true;
            }
            if (m.is_calculation) {
                // Make sure we have all of the measures it depends on
                if (!m.parent_measure_ids) {
                    return true;
                }
                return m.parent_measure_ids.every(pmid => this.selectedMeasureIds.includes(pmid));
            }
            if (!m.dimension_join_trees) {
                return false;
            }

            if (requiredDimensionIds.length == 0) {
                return true;
            }
            
            const allConnectedPipelineNodeIdsForThisMeasure = m.dimension_join_trees.map(d => d.pipeline_node_id);
            return requiredDimensionIds.every(r => allConnectedPipelineNodeIdsForThisMeasure.includes(r));
        }).map(m => m.id as string);
    }
}

interface NodeConfigProps {
    node: PipelineNode;
    onChange: Updater<PipelineNode>;
}
const PipelineNodeReportBuilder = (props: NodeConfigProps) => {
    const inDraft = useIsInDraftMode();

    const [editingDimension, setEditingDimension] = useState<ReportBuilderDimension | null>(null);
    const [editingMeasure, setEditingMeasure] = useState<ReportBuilderMeasure | null>(null);

    const dimensions = useDimensions();
    const measures = useMeasures();

    const saveDim = useCallback(async (updatedDim: ReportBuilderDimension) => {
        await ReportBuilderDimensionORM.save(updatedDim);
        dimensions.refetch();
        setEditingDimension(null);
    }, []);

    const saveMeasure = useCallback(async (updatedMeasure: ReportBuilderMeasure) => {
        await ReportBuilderMeasureORM.save(updatedMeasure);
        measures.refetch();
        setEditingMeasure(null);
    }, []);

    const tree = useReportingTree();

    const [reportBuilderState, setReportBuilderState] = useState<ReportBuilderState>(new ReportBuilderState(new graphlib.Graph(), [], [], [], []));

    const graph = useMemo(() => {
        const g = new graphlib.Graph({
            directed: true,
            multigraph: true,
        });

        if (!tree.data) {
            return g;
        }

        tree.data.nodes.forEach(n => {
            g.setNode(n.id, n.title);
        });

        tree.data.edges.forEach(e => {
            g.setEdge(e.from_id, e.to_id, e.relationship_id);
        });

        return g
    }, [tree.dataUpdatedAt]);

    useEffect(() => {
        setReportBuilderState(new ReportBuilderState(graph, dimensions.data || [], measures.data || [], props.node.dimension_ids || [], props.node.measure_ids || []));
    }, [graph, props.node.measure_ids, props.node.dimension_ids, dimensions.dataUpdatedAt, measures.dataUpdatedAt])

    const allNodeConnections = useMemo(() => {
        return getConnectionsForAllGraphNodes(graph);
    }, [graph]);

    const dimensionsById = useMemo(() => {
        if (!dimensions.data) {
            return {};
        }
        return dimensions.data.reduce((acc, d) => {
            acc[d.id as string] = d;
            return acc;
        }, {} as {[key: string]: ReportBuilderDimension});
    }, [dimensions.dataUpdatedAt]);

    const measuresById = useMemo(() => {
        if (!measures.data) {
            return {};
        }
        return measures.data.reduce((acc, d) => {
            acc[d.id as string] = d;
            return acc;
        }, {} as {[key: string]: ReportBuilderMeasure});
    }, [measures.dataUpdatedAt]);

    const dimensionPipelineNodeIds = useMemo(() => {
        return dimensions.data?.map(d => d.pipeline_node_id) || [];
    }, [props.node.dimension_ids, dimensionsById]);

    

    const addExtraData = useCallback(() => {
        props.onChange(draft => {
            if (!draft.extra_report_columns) {
                draft.extra_report_columns = [];
            }
            draft.extra_report_columns.push({
                pipeline_node_id: '',
                field_id: '',
                column_label: '',
            });
        });
    }, [props.onChange]);

    const removeExtraData = useCallback((idx: number) => {
        props.onChange(draft => {
            if (!draft.extra_report_columns) {
                return;
            }
            draft.extra_report_columns.splice(idx, 1);
        });
    }, [props.onChange]);

    const editExtraDataField = useCallback((idx: number, attribute: keyof PipelineNodeExtraReportColumn, value: string) => {
        props.onChange(draft => {
            if (!draft.extra_report_columns) {
                return;
            }
            draft.extra_report_columns[idx][attribute] = value;
        });
    }, [props.onChange]);

    const [fields, setFields] = useState<PipelineNodeField[]>(props.node.fields);
    const [showColumn, setShowColumn] = useState<boolean>(false);
    const pipelineNode = usePipelineNode(props.node.id as string);
  
    useEffect(() => {
        if (pipelineNode.data && pipelineNode.data.fields) {
            setFields(pipelineNode.data.fields);
        }
    }, [pipelineNode.data, pipelineNode.dataUpdatedAt]);

    const onColumnToggle = useCallback((field: PipelineNodeField) => {
        if (!props.node) return;
        const updatedShowColumn = field.show_column ?? false;
        setShowColumn(updatedShowColumn);
    }, [props.node]);
    
    const onNodeFieldChange = useCallback((newFields: PipelineNodeField[]) => {
        if (!props.node) return;
        setFields(newFields);
        props.onChange(draft => {
            draft.fields = newFields;
        });
        
    }, [props]);

    const [measureFilter, setMeasureFilter] = useState('');
    const filteredMeasures = useMemo(() => {
        if (!measures.data) {
            return [];
        }
        return measures.data.filter(m => m.name.toLowerCase().includes(measureFilter.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }, [measureFilter, measures.dataUpdatedAt]);

    const [dimensionFilter, setDimensionFilter] = useState('');
    const filteredDimensions = useMemo(() => {
        if (!dimensions.data) {
            return [];
        }
        return dimensions.data.filter(m => m.name.toLowerCase().includes(dimensionFilter.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }, [dimensionFilter, dimensions.dataUpdatedAt]);

    const clearAll = useCallback(() => {
        props.onChange(draft => {
            draft.measure_ids = [];
            draft.dimension_ids = [];
        });
    }, [props.onChange]);


    return <div>
        <Offcanvas backdrop="static" placement="end" show={editingDimension !== null}>
            <Offcanvas.Header>
                <Offcanvas.Title>{(editingDimension && editingDimension.id) ? 'Edit Dimension' : 'Add Dimension'}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="p-3">
                    <DimensionForm 
                        dimension={editingDimension} 
                        onSubmit={saveDim}
                        onCancel={() => setEditingDimension(null)}
                    />

                </div>
            </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas backdrop="static" placement="end" show={editingMeasure !== null}>
            <Offcanvas.Header>
                <Offcanvas.Title>{(editingMeasure && editingMeasure.id) ? 'Edit Measure' : 'Add Measure'}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                    <div className="p-3">
                    {editingMeasure && 
                        <MeasureForm 
                            measure={editingMeasure} 
                            onSubmit={saveMeasure}
                            onCancel={() => setEditingMeasure(null)}
                        />
                    }

                </div>
                    </PaneContent>
                </Pane>
                
            </Offcanvas.Body>
        </Offcanvas>
        <div className="card">
            <div className="card-body">
                <h2>
                    <i className="mdi mdi-information-outline"></i> Basic Information
                </h2>
                <Form.Group className="mb-3">
                    <Form.Label>Report Name</Form.Label>
                    <Form.Control className="w-100" value={props.node.label} disabled={!inDraft} onChange={(e) => {
                        props.onChange(draft => {
                            draft.label = e.target.value;
                        });
                    }}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" className="w-100" value={props.node.description} disabled={!inDraft} onChange={(e) => {
                        props.onChange(draft => {
                            draft.description = e.target.value;
                        });
                    }}/>
                </Form.Group>
            </div>
        </div>
        <hr />
        <div className="card">
            <div className="card-body">
                <div className="d-flex center-vertically mb-2">
                    <h2 className="flex-1 mb-0">
                        <i className="mdi mdi-table-pivot"></i> Table Configuration
                    </h2>
                    <button className="btn btn-light" onClick={clearAll}>
                        Clear
                    </button>
                </div>
                
                <div className={`row ${!inDraft ? 'disabled' : ''}`}>
                    <div className="col-6 pe-3">
                        <div className="mb-2">
                            <Form.Label className="mb-0">Select Metrics (Columns)</Form.Label>
                            <p className="text-muted font-13">What do you want to measure in this report?</p>
                            <input className="input-rounded form-control" placeholder="Search..." value={measureFilter} onChange={(e) => setMeasureFilter(e.target.value)}/>
                        </div>
                        {measures.isLoading && <div>
                            <i className="mdi mdi-loading mdi-spin"></i> Loading
                        </div>}
                        {!measures.isLoading && measures.data && measures.data.length == 0 && <div>
                        <DraftOnly>
                            <Warning>
                                <div>You don't have any metrics set up yet! You can create some in your <Link to="/wizard/data-model">Semantic Layer.</Link></div>
                            </Warning>
                        </DraftOnly>
                        </div>}
                        {!measures.isLoading && measures.data && <div className="list-group user-select-none">
                            
                            {filteredMeasures.map(d => {
                                const enabled = reportBuilderState.availableMeasureIds.includes(d.id as string);
                                const selected = props.node.measure_ids && props.node.measure_ids.includes(d.id as string);
                                return <div key={d.id} className={`list-group-item list-group-item-action ${!enabled ? 'text-muted disabled': ''} ${!enabled && selected ? 'bg-danger-light': ''}`} onClick={() => {
                                    props.onChange(draft => {
                                        if (!draft.measure_ids) {
                                            draft.measure_ids = [];
                                        }
                                        if (!selected) {
                                            draft.measure_ids.push(d.id as string);
                                        } else {
                                            draft.measure_ids = draft.measure_ids.filter(id => id !== d.id);
                                        }
                                    });
                                }}>
                                    <div className="d-flex center-vertically hover-control">
                                        <div className="me-3">
                                            <div className="me-3 text-18">
                                                {!selected && enabled && <i className="mdi mdi-check-circle text-white"></i>}
                                                {!selected && !enabled && <i className="mdi mdi-cancel text-muted"></i>}
                                                {selected && <i className="mdi mdi-check-circle text-success"></i>}
                                            </div>
                                        </div>
                                        <div className="flex-1">
                                            <h4 className={`mb-0 ${!enabled ? 'text-muted' : ''}`}>{d.name}</h4>
                                            <p className="text-muted font-13">{d.description || 'No description'}</p>
                                            {!d.pipeline_node_id && !d.is_calculation && <>
                                                <div className="small text-danger">This metric is incomplete.</div>
                                            </>}
                                            <div className="font-code">
                                                {d.is_calculation && <div className="small text-muted font-code">
                                                    {d.formula}
                                                </div>}
                                                {!d.is_calculation && d.pipeline_node_id && d.field_id && <div className="small text-muted">
                                                    {d.aggregator} <PipelineNodeName pipelineNodeId={d.pipeline_node_id}/> - <PipelineNodeFieldName pipelineNodeId={d.pipeline_node_id} fieldId={d.field_id}/>
                                                </div>}
                                            </div>
                                            
                                            {/* <div className="small">
                                                <PipelineNodeName pipelineNodeId={d.pipeline_node_id}/> - <PipelineNodeFieldName pipelineNodeId={d.pipeline_node_id} fieldId={d.field_id}/>
                                            </div> */}
                                        </div>
                                        <div>
                                            <DraftOnly>
                                                <Link to={`/metric/${d.id}`} className="btn btn-sm btn-light hover-only">
                                                <i className="mdi mdi-pencil"></i> Edit
                                                </Link>
                                            </DraftOnly>
                                            
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>}
                        
                    </div>
                    <div className="col-6 ps-3">
                        <div className="mb-2">
                            <Form.Label className=" mb-0">Select Dimensions (Rows)</Form.Label>
                            <p className="font-13">You can select dimensions from up to 5 entities.</p>
                            <input className="input-rounded form-control" placeholder="Search..." value={dimensionFilter} onChange={(e) => setDimensionFilter(e.target.value)}/>


                        </div>
                        
                        {dimensions.isLoading && <div>
                            <i className="mdi mdi-loading mdi-spin"></i> Loading
                        </div>}
                        {!dimensions.isLoading && dimensions.data && <div className="list-group user-select-none">

                        
                            {filteredDimensions.map(d => {
                                const enabled = reportBuilderState.availableDimensionIds.includes(d.id as string);
                                const selected = props.node.dimension_ids && props.node.dimension_ids.includes(d.id as string);
                                return <div key={d.id} className={`list-group-item list-group-item-action ${!enabled ? 'text-muted disabled': ''} ${!enabled && selected ? 'bg-danger-light': ''}`} onClick={() => {
                                    props.onChange(draft => {
                                        if (!draft.dimension_ids) {
                                            draft.dimension_ids = [];
                                        }
                                        
                                        if (!selected) {
                                            draft.dimension_ids.push(d.id as string);
                                        } else {
                                            draft.dimension_ids = draft.dimension_ids.filter(id => id !== d.id);
                                        }
                                    });
                                }}>
                                    <div className="d-flex center-vertically hover-control">
                                        <div className="me-3 text-18">
                                            {!selected && enabled && <i className="mdi mdi-check-circle text-white"></i>}
                                            {!selected && !enabled && <i className="mdi mdi-cancel text-muted"></i>}
                                            {selected && <i className="mdi mdi-check-circle text-success"></i>}
                                            
                                        </div>
                                        <div className="flex-1">
                                            <h4 className={`mb-0 ${!enabled ? 'text-muted' : ''}`}>{d.name}</h4>
                                            <div className="small text-muted">
                                                <PipelineNodeName pipelineNodeId={d.pipeline_node_id}/> - <PipelineNodeFieldName pipelineNodeId={d.pipeline_node_id} fieldId={d.field_id}/>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            })}
                        </div>}
                        
                    </div>
                
                </div>
            </div>
        </div>
                    
        <hr />
        <div className="card">
            <div className="card-body">
                {fields.length > 0 && (
                    <PipelineNodeColumnOrder
                        fields={fields}
                        shape={props.node.shape as any}
                        onClickColumn={onColumnToggle}
                        onChangeNodeField={onNodeFieldChange}
                    />
                )}
            </div>
        </div>
        <hr />
        
        <div className="card">
            <div className="card-body">
                <PipelineNodeWhitelistConfiguration node={props.node} onChange={props.onChange}/>
            </div>
        </div>
        <hr />

        <div className="card">
            <div className="card-body">
                <PipelineNodeOutputConfiguration node={props.node} onChange={props.onChange}/>
            </div>
        </div>
                    
         <div className="mb-5"></div>
                    
    </div>;
              
}
export default PipelineNodeReportBuilder