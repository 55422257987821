import { PropsWithChildren } from "react";
import styled from "styled-components";

interface Props {
    show: boolean;
    onHide: () => any;
    noCloseButton?: boolean;
}

const Wrapper = styled.div`
position: fixed;
height: 100%;
width: 100%;
top: 0;
left: 0;
z-index: 50;
background: white;
border-radius: 5px;

.btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 1061;
}
`

const FullScreenTakeover = (props: PropsWithChildren<Props>) => {
    if (!props.show) {
        return <></>;
    }
    return <Wrapper>
        {!props.noCloseButton && <button className="btn-close" onClick={() => {
            props.onHide();
        }}>
        </button>}
        {props.children}
    </Wrapper>;
}

export default FullScreenTakeover;