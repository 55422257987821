import { SimpleDataTable } from "@components/datatable/DataTable.component";
import { BackendRequired } from "@components/project/BackendRequired.component";
import Danger from "@components/statusIndicators/Danger.component";
import SuccessAlert from "@components/statusIndicators/SuccessAlert.component";
import Warning from "@components/statusIndicators/Warning.component";
import { SourceDataConnectionORM } from "@models/source";
import ApiService from "@services/api/api.service";
import { Backends } from "@services/config/config.service";
import { getErrorMessage } from "@services/errors.service";
import { formatPercentage, summarizeNumber } from "@services/formatting.service";
import { useSourceDataConnection, useSourceRecordTypes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from 'styled-components';

interface OverlapAnalysisProps {
    sourcePipelineNodeId: string;
    targetPipelineNodeId: string;
    sourceFieldId: string;
    targetFieldId: string;
}

interface OverlapAnalysisResults {
    self: number;
    other: number;
    overlap: number;
    self_label: string;
    other_label: string;
}
interface VennDiagramProps {
    leftLabel: string;
    rightLabel: string;
    leftPct: number;
    middlePct: number;
    rightPct: number;
}

const VennContainer = styled.div`
border-radius: 5px;
padding: 10px;
width: 100%;
`

const Header = styled.h3`
font-family: "Poppins";
font-size: 14px;
font-weight: 600;
line-height: 21px;
text-transform: uppercase;
color: #000;
`

const VennDiagram = (props: VennDiagramProps) => {
    const buffer = 10;
    const circleRadius = 150;
    const overlap = 100;
    const legendRadius = 8;
    const legendHeight = 40
    const c1x = buffer + circleRadius;
    const c1y = buffer + legendHeight + circleRadius;

    const c2x = buffer + (circleRadius * 2) - overlap + circleRadius;
    const c2y = buffer + legendHeight + circleRadius;
    const width = buffer + (circleRadius * 4) - overlap + buffer;

    

    return <svg width={width} height="350">
        <text textAnchor="middle" x={c1x - (overlap/2)} y={c1y}>{formatPercentage(props.leftPct)}</text>
        <text textAnchor="middle" x={c2x + (overlap/2)} y={c2y} >{formatPercentage(props.rightPct)}</text>
        <text textAnchor="middle" x={width/2} y={c2y} >{formatPercentage(props.middlePct)}</text>
        <circle stroke="#0F0E31" strokeWidth={1} r={circleRadius} cx={c1x} cy={c1y} fill="#0F0E31" fillOpacity={0.25}/>
        <circle stroke="#FF9F00" strokeWidth={1} r={circleRadius} cx={c2x} cy={c2y} fill="#FF9F00" fillOpacity={0.25}/>
    </svg>
}

const OverlapAnalysis = (props: OverlapAnalysisProps) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const [results, setResults] = useState<OverlapAnalysisResults|undefined>(undefined);
    const runAnalysis = useCallback(async () => {
        setLoading(true);
        setError('');

        try {
            const results = await ApiService.getInstance().request('POST', `/pipelinenodes/${props.sourcePipelineNodeId}/overlap/${props.targetPipelineNodeId}`, {
                n1_field_id: props.sourceFieldId,
                n2_field_id: props.targetFieldId
            }) as OverlapAnalysisResults;
            setResults(results);
        } catch (err) {
            console.error(err);
            setError(getErrorMessage(err));
        } finally {
            setLoading(false);
        }
        

    }, [props.sourcePipelineNodeId, props.targetPipelineNodeId, props.sourceFieldId, props.targetFieldId]);



    if (loading) {
        return <div>
            <i className="mdi mdi-loading mdi-spin"></i> Running analysis...
        </div>
    } else if (error) {
        return <div className="alert alert-danger">{error}</div>
    } else if (results) {
        const total = results.overlap + results.self + results.other * 1.0;
        const leftPct = results.self / total;
        const rightPct = results.other / total;
        const middlePct = results.overlap / total;

        return <>
            <VennContainer>
                <div className="row mb-0 font-poppins">
                    <div className="col-6 text-center">{results.self_label}</div>
                    <div className="col-6 text-center">{results.other_label}</div>
                </div>
                <center>
                    <VennDiagram
                        leftLabel={results.self_label}
                        rightLabel={results.other_label}
                        leftPct={leftPct}
                        middlePct={middlePct}
                        rightPct={rightPct}
                    />
                    <div>
                        <strong>{formatPercentage(middlePct)} ({summarizeNumber(results.overlap)} records)</strong><br />
                    match across both datasets
                    </div>
                    
                </center>
            
            </VennContainer>
            <div className="mt-2 p-2">
                {middlePct > .9 && (
                    <SuccessAlert>
                        <div>
                        <strong>Most of these records are matching!</strong> That's usually a sign that your data sources have good <a>referential integrity</a>.
                        </div>
                    </SuccessAlert>
                )}

                {middlePct <= .9 && middlePct > .5 && (
                    <Warning>
                        <div>
                            <strong>A majority of these records are matching, but some are not.</strong> That might be indicative of a <a>referential integrity</a> problem in your data sources. You can investigate below.
                        </div>
                        
                    </Warning>
                )}
                {middlePct <= .5 && (
                    <Warning>
                        <div>
                        <strong>Not many of these records are matching.</strong> We might not be able to use this connection for relationship resolution. Check the data below to ensure we're not missing anything.
                        </div>
                        
                    </Warning>
                )}
            </div>
            
            
            
        </>
    } else {
        return <div className="p-2">
            <button onClick={runAnalysis} className="btn btn-primary">Run Analysis</button>
        </div>
    }
    return <></>;

    // return <>{JSON.stringify(results)}</>


}

export default OverlapAnalysis;