import { ChangeEvent, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export interface ConfirmationModalProps {
    header: string;
    message: string | JSX.Element;
    onConfirm: () => void;
    onClose: () => void;
    show?: boolean;
    confirmationButtonVariant?: string;
    confirmationButtonText: string;
    secondaryButtonVariant?: string;
    secondaryButtonText?: string;
    onSecondaryAction?: () => void; 
    typedValueExpectation?: string;
    closeButtonText?: string;
    hideCloseButton?: boolean;
}

function ConfirmationModal({header, message, onConfirm, show, typedValueExpectation, onClose, confirmationButtonText, confirmationButtonVariant='success', secondaryButtonVariant='secondary', secondaryButtonText, onSecondaryAction, closeButtonText='Cancel', hideCloseButton = false}: ConfirmationModalProps) {
    const [typedValue, setTypedValue] = useState('');
    

    const confirm = () => {
        onConfirm();
        setTypedValue('');
    }

    const close = () => {
        setTypedValue('');
        onClose();
    }

    const secondary = () => {
        if(onSecondaryAction) {
            onSecondaryAction();
        }
        setTypedValue('');
        onClose();
    }

    if (typeof message === 'string' || message instanceof String) {
        message = <p>{message}</p>
    }

    return (
        <Modal show={!!show} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message}

                {typedValueExpectation && (
                    <Form.Control value={typedValue} onChange={(e: ChangeEvent) => setTypedValue((e.target as HTMLInputElement).value)} type="text" size="lg"/>
                )}
            </Modal.Body>
            <Modal.Footer>
                {!hideCloseButton && (
                    <Button variant="light" onClick={close}>
                        {closeButtonText}
                    </Button>
                )}
                
                {!!onSecondaryAction && !!secondaryButtonText && <Button 
                    variant={secondaryButtonVariant}
                    disabled={!!typedValueExpectation && (typedValueExpectation as string !== typedValue)}
                    onClick={secondary}
                    >{secondaryButtonText}</Button>}
                <Button 
                    variant={confirmationButtonVariant}
                    disabled={!!typedValueExpectation && (typedValueExpectation as string !== typedValue)}
                    onClick={confirm}
                >{confirmationButtonText}</Button>
                
            </Modal.Footer>
        </Modal>
    );
    
}

export default ConfirmationModal;