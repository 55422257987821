import { ReactComponent as DataSourcesSVG } from '../../assets/images/pliable/data_sources_icon.svg';
import { ReactComponent as BusinessObjectsSVG } from '../../assets/images/pliable/business_objects_icon.svg';
import { ReactComponent as PliableBrandSVG } from '../../assets/images/pliable/brand_mark_orange.svg';

export interface IconProps {
    height: string;
    scale?: number;
    verticalAlign?: string;
}

export const PliableIcon = (props: IconProps) => {
    return (
        <div style={{display: 'inline-block', verticalAlign: props.verticalAlign ? props.verticalAlign : 'text-bottom'}} className="plb-logo">
            <PliableBrandSVG style={{height: props.height, maxHeight: props.height, maxWidth: props.height}} fill='currentColor' />
        </div>
    );
};

export const DataSourcesIcon = (props: IconProps) => {
    return (
        <div style={{height: props.height, display: 'inline-block', verticalAlign: 'text-bottom'}}>
            <DataSourcesSVG style={{maxHeight: props.height, maxWidth: props.height}} fill='currentColor' />
        </div>
    );
};

export const BusinessObjectsIcon = (props: IconProps) => {
    return (
        <div style={{height: props.height, display: 'inline-block', verticalAlign: 'text-bottom'}}>
            <BusinessObjectsSVG style={{maxHeight: props.height, maxWidth: props.height}} fill='currentColor' />
        </div>
    );
};