import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import GlobalDndContext from './dnd-context';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import createRoutes from './routes';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/*"
      element={
        <GlobalDndContext>
          <App />
        </GlobalDndContext>
      }
    >
      {createRoutes()}
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<RouterProvider router={router} />);


