import Dropdown from '@components/form/Dropdown.component';
import Select from 'react-select';

interface Props {
    selected: string;
    onSelect: (newValue: string) => void;
    className?: string;
    disabled?: boolean;
    normalSize?: boolean;
}

const ATTRIBUTE_OPTIONS = [
    {label: 'Text', value: 'STRING'},
    {label: 'Integer', value: 'INT'},
    {label: 'Decimal', value: 'DECIMAL'},
    {label: 'Boolean', value: 'BOOLEAN'},
    {label: 'Date', value: 'DATE'},
    {label: 'DateTime', value: 'DATETIME'},
];

const BusinessObjectFieldTypeSelector = (props: Props) => {
    return <>
        <Dropdown 
            placeholder="Select One"
            variant="button"
            disabled={!!props.disabled}
            className={props.className ? props.className: ''}
            options={ATTRIBUTE_OPTIONS} 
            selected={props.selected} 
            onChange={(e) => props.onSelect(e ? e : '')}
            btnVariant="light"
            btnSize={props.normalSize ? undefined : 'sm'}
        />    
    </>
}

export default BusinessObjectFieldTypeSelector;
