import { Modal } from "react-bootstrap";
import dbtIcon from '@assets/images/dbt.png';
import { Pane, PaneContent } from "@pages/PageStructure.component";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import BuildOrchestrationORM, { BuildExecution, BuildOrchestration } from "@models/buildOrchestration";
import { invalidateEverything } from "@stores/data.store";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import ConfettiExplosion from "react-confetti-explosion";
import PipelineOrchestration from "@components/orchestration/PipelineOrchestration.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import Danger from "@components/statusIndicators/Danger.component";
import SuccessAlert from "@components/statusIndicators/SuccessAlert.component";
import { Link } from "react-router-dom";


export interface BuildModalProps {
    orchestrationId: string;
    show: boolean;
    onClose: () => void;

    completeButtons?: ReactNode;
    errorButtons?: ReactNode;

    completeNextButtonText?: string;
    onClickNextComplete?: () => void;
    errorNextButtonText?: string;

    compactMode?: boolean;
    compactModeCompleteNextButtonText?: string;
    compactModeBuildingCopy?: string;
    compactModeOnClickViewData?: () => void;
    compactModeOnClickNext?: () => void;
    compactModeErrorNextButtonText?: string;
    compactModeOnClickNextError?: () => void;
}

const BuildModal = (props: BuildModalProps) => {
    const [activeOrchestration, setActiveOrchestration] = useState<BuildOrchestration|undefined>(undefined);
    const [showConfetti, setShowConfetti] = useState(false);
    const checkInitialOrchestration = useCallback(async () => {
        const updatedOrch = await BuildOrchestrationORM.findById(props.orchestrationId);
        setActiveOrchestration(updatedOrch);
        setShowConfetti(false);
    }, [props.orchestrationId]);

    useEffect(() => {
        checkInitialOrchestration();
    }, [props.orchestrationId, checkInitialOrchestration])
    
    const checkActiveOrchestration = useCallback(async () => {
        if (!activeOrchestration) {
            return;
        }

        const newStatuses: {
            [key: string]: BuildExecution
        } = {};
    
        Object.keys(activeOrchestration.build_executions).map(k => {
            const be = activeOrchestration.build_executions[k];
            newStatuses[`${be.object_type}:${be.object_id}`] = be;
        });

        
        if (!['ERROR', 'COMPLETE', 'IN_REVIEW', 'CANCELLED'].includes(activeOrchestration.status)) {
            
            setTimeout(async () => {
                // Keep polling, which should then re-run this effect, and so on.
                const updatedOrch = await BuildOrchestrationORM.findById(activeOrchestration.id as string);
                setActiveOrchestration(updatedOrch);
            }, 1000);
        } else if (activeOrchestration.status == 'COMPLETE') {
            setShowConfetti(true);
            setTimeout(() => {
                setShowConfetti(false);
            }, 1000);
            invalidateEverything();
        } else if (activeOrchestration.status == 'ERROR') {
            toast('danger', 'Error', getErrorMessage(activeOrchestration.error));
        }

    }, [activeOrchestration]);

    useEffect(() => {
        if (activeOrchestration) {
            checkActiveOrchestration();
        }
    }, [activeOrchestration]);


    const cancelActiveOrchestration = useCallback(async () => {
        if (!activeOrchestration) {
            return;
        }

        const updatedOrch = await BuildOrchestrationORM.cancelOrchestration(activeOrchestration.id as string);

    }, [activeOrchestration]);

    return <Modal size={props.compactMode ? undefined : 'xl'} show={props.show} onHide={props.onClose} backdrop="static">
    <Modal.Header>
        <Modal.Title>
            <img src={dbtIcon} alt="dbt" className="me-2" style={{ height: '2rem' }}/>Building
        </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0" style={{height: props.compactMode ? ' calc(40vh)': 'calc(70vh)'}}>
            <Pane>
                <PaneContent className="noscroll-h">
                    {showConfetti && <div style={{marginLeft: '50%'}}> <ConfettiExplosion zIndex={1061} colors={['#ff9f00', '#ffffff', '#000000', '#313A46', "#666666", "#343434"]} /></div>}

                    <div className="p-2">
                        {props.compactMode && <>
                            {activeOrchestration && ['RUNNING', 'QUEUED'].includes(activeOrchestration.status) && <>
                                <p>{props.compactModeBuildingCopy}</p>
                                <PliableLoader/>
                            </>}
                            {activeOrchestration && activeOrchestration.status == 'COMPLETE' && <>
                                <SuccessAlert>
                                    <div>Your data has been processed and is ready to use.</div>
                                </SuccessAlert>
                                <button className="btn btn-light me-1" onClick={() => {
                                    props.onClose();
                                    props.compactModeOnClickViewData?.();
                                }}>View Data</button>
                                {props.compactModeOnClickNext && <button className="btn btn-success" onClick={() => {
                                    props.onClose();
                                    props.compactModeOnClickNext?.();
                                }}>{props.compactModeCompleteNextButtonText}</button>}
                            </>}
                            {activeOrchestration && activeOrchestration.status == 'ERROR' && <>
                                <Danger>
                                    <div><div>There was an error processing your data.</div>
                                    <div>{activeOrchestration.error}</div></div>
                                    {props.compactModeOnClickNextError && <button className="btn btn-light" onClick={() => {
                                        props.onClose();
                                        props.compactModeOnClickNextError?.();
                                    }}>{props.compactModeErrorNextButtonText || 'Fix Error'}</button>}
                                </Danger>
                            </>}
                            {activeOrchestration && activeOrchestration.status == 'CANCELLED' && <>
                                <Danger>
                                    <div><div>This build was cancelled.</div>
                                    <div>{activeOrchestration.error}</div></div>
                                </Danger>
                            </>}
                        </>}
                        {!props.compactMode && <>
                            {activeOrchestration && <PipelineOrchestration orchestration={activeOrchestration}/>}
                        </>}
                        

                    </div>

                </PaneContent>
            </Pane>
        
        
    </Modal.Body>
    <Modal.Footer>
        {['RUNNING', 'QUEUED'].includes(activeOrchestration?.status as string) && <>
            <button onClick={() => {
                cancelActiveOrchestration();
                // props.onClose();
            }} className="btn btn-light">Cancel Build</button>
            
        </>}
        {activeOrchestration?.status == 'COMPLETE' && <>
            {props.completeButtons ? props.completeButtons : <>
                <button onClick={() => {
                    props.onClose();
                }} className="btn btn-light">Close</button>
            </>}
        </>}
        {activeOrchestration?.status == 'ERROR' && <>
            {props.errorButtons ? props.errorButtons : <>
                <button onClick={() => {
                    props.onClose();
                }} className="btn btn-light">Close</button>
            </>}
        </>}
        {activeOrchestration?.status == 'CANCELLED' && <>
            <button onClick={() => {
                    props.onClose();
                }} className="btn btn-light">Close</button>
        </>}
        
    </Modal.Footer>
</Modal>
}

export default BuildModal;