/**
 * Hardcoded functionality for managing data in Snowflake
 * 
 */
import LoadingCard from "@components/card/LoadingCard.component";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { SnowflakeTableOption, useAvailableSnowflakeTables, usePipelineNodes } from "@stores/data.store";
import { PropsWithChildren, ReactNode, useCallback, useMemo, useState, KeyboardEvent, useRef, useEffect } from "react";
import { Allotment } from "allotment";
import { Badge, Collapse, Offcanvas } from "react-bootstrap";
import styled from 'styled-components';
import { TableExplorer } from "@components/datatable/TableExplorer.component";
import { Link, useNavigate } from "react-router-dom";
import { summarizeNumber } from "@services/formatting.service";
import { PipelineNodeORM } from "@models/pipelineNode";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import AsyncButton from "@components/button/AsyncButton.component";
import Warning from "@components/statusIndicators/Warning.component";
import { useTenantRegistration } from "@services/tenant/tenant.service";
import { set } from "immer/dist/internal";
import { NodeList } from "@components/pipelineNodes/PipelineNodeList.component";
import InfoLink from "@components/general/InfoLink.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import SourceIcon from "@components/sources/SourceIcon.component";
import snowflakeIcon from '@assets/images/snowflake_logo.png';
import { DraftOnly, ProdOnly, ReadOnly } from "@components/project/DraftModeRequired.component";

interface Table {
    type: 'TABLE' | 'VIEW';
    name: string;
    totalRecords: number;
}

interface Schema {
    name: string;
    tables: Table[];
}
interface Database {
    name: string;
    schemas: Schema[];
}

interface TreeNodeProps {
    name: string;
    active?: boolean;
    onClick?: () => void;
}

const TreeNodeStyles = styled.div`
div.inner {
    padding-left: 2rem;
}

.toggle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 30px;
    &:hover {
        background-color: var(--ct-light);
        cursor:pointer;
    }

    &.active {
        font-weight: bold;
    }
}
`
const TreeNode = ({children, name, onClick, active}: PropsWithChildren<TreeNodeProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    

    const clicked = useCallback(() => {
        if (!children) {
            return onClick && onClick();
        }
        setIsOpen(!isOpen);
    }, [isOpen, children]);

    const icon = useMemo(() => {
        if (!children) {
            return '';
        }
        if (isOpen) {
            return 'mdi mdi-menu-down';
        }
        return 'mdi mdi-menu-down mdi-rotate-270';
    }, [isOpen, children])
    return <TreeNodeStyles>
        <div className={`toggle ${active ? 'active': ''}`} onClick={clicked} title={name}>
            {icon && <i className={icon}/>} {name}
        </div>
        <Collapse in={isOpen}>
            <div className="inner">{children}</div>
        </Collapse>
        
        
    </TreeNodeStyles>
}

interface SnowflakeTableSelectorProps {
    onSelect: (table: string) => void;
    selectedTable: string;
}

const getHierarchicallyOrganizedTables = (tables: SnowflakeTableOption[]) => {
    const databasesByName: { [key: string]: Database } = {};
    const schemasByName: { [key: string]: Schema } = {};

    tables.forEach((table) => {
        if (!databasesByName[table.database_name]) {
            databasesByName[table.database_name] = {
                name: table.database_name,
                schemas: []
            };
        }
        const db = databasesByName[table.database_name];
        const fullSchemaName = `${table.database_name}.${table.schema_name}`;
        if (!schemasByName[fullSchemaName]) {
            schemasByName[fullSchemaName] = {
                name: table.schema_name,
                tables: []
            };
            db.schemas.push(schemasByName[fullSchemaName]);
        }
        const schema = schemasByName[fullSchemaName];
        schema.tables.push({
            type: table.rows == undefined ? 'VIEW' : 'TABLE',
            name: table.name,
            totalRecords: table.rows || 0,
        });
    });
    
    // Now sort everything
    Object.values(databasesByName).forEach((db) => {
        db.schemas.sort((a, b) => a.name.localeCompare(b.name));
        db.schemas.forEach((schema) => {
            schema.tables.sort((a, b) => a.name.localeCompare(b.name));
        });
    });
    return Object.values(databasesByName).sort((a, b) => a.name.localeCompare(b.name));

}

export const SnowflakeTableSelector = (props: SnowflakeTableSelectorProps) => {
    const tables = useAvailableSnowflakeTables();
    const tableName = props.selectedTable;
    const selectedDb = useMemo(() => {
        if (tableName) {
            return tableName.split('.')[0];
        }
        return '';
    }, [tableName]);

    const selectedSchema = useMemo(() => {
        if (tableName) {
            return tableName.split('.')[1];
        }
        return '';
    }, [tableName]);

    const selectedTable = useMemo(() => {
        if (tableName) {
            return tableName.split('.')[2];
        }
        return '';
    }, [tableName]);

    const hierarchicallyOrganizedTables: Database[] = useMemo(() => {
        if (!tables.data) {
            return [];
        }
        return getHierarchicallyOrganizedTables(tables.data);

    }, [tables.dataUpdatedAt]);

    const [showDbTableHelp, setShowDbTableHelp] = useState(false);
    const tenantRegistration = useTenantRegistration();

    return <>
        <Offcanvas placement="end" show={showDbTableHelp} onHide={() => setShowDbTableHelp(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Database Table Access</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        <div className="p-3">
                            <p>If you can't find the table you're looking for, it's likely because Pliable's snowflake role doesn't have access. Try running these commands, replacing <code>DATABASE_NAME</code> and <code>SCHEMA_NAME</code> with the information for the specific table you want to connect.</p>
                            <InfoAlert>
                                <div>
                                    <strong>Hint: </strong> Pliable automatically has access to all tables in the <code>{tenantRegistration.data?.loader_creds.database}</code> database.
                                </div>
                            </InfoAlert>
                            <hr />
                            {tenantRegistration.data && <>
                                <p><code>set grant_db = 'DATABASE_NAME';</code></p>
                                <p><code>set grant_schema = 'DATABASE_NAME.SCHEMA_NAME';</code></p>
                                <p><code>GRANT USAGE ON DATABASE identifier($grant_db) TO ROLE {tenantRegistration.data.connection_creds.role};</code></p>
                                <p><code>GRANT USAGE ON SCHEMA identifier($grant_schema) TO ROLE {tenantRegistration.data.connection_creds.role};</code></p>
                                <p><code>GRANT SELECT ON ALL TABLES IN SCHEMA identifier($grant_schema) TO ROLE {tenantRegistration.data.connection_creds.role};</code></p>
                            </>}

                        </div>
                    </PaneContent>
                
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
        {tables.isLoading && <LoadingCard/>}
        {!tables.isLoading && <div>
            <div className="mb-3">
                <button className="btn btn-light me-1" onClick={(() => {
                    tables.refetch();
                })}>
                    <i className={`mdi mdi-refresh ${tables.isFetching ? 'mdi-spin' : ''}`}></i> Refresh Tables
                </button>
                <button className="btn btn-light" onClick={() => {
                    setShowDbTableHelp(true);
                }}>
                    <i className="mdi mdi-information"></i> Help
                </button>
            </div>
            {hierarchicallyOrganizedTables.length == 0 && <div className="text-muted">No tables found.</div>}
            {hierarchicallyOrganizedTables.map((db) => {
                return <TreeNode name={db.name} active={selectedDb == db.name}>
                    {db.schemas.map((schema) => {
                        return <TreeNode name={schema.name} active={selectedDb == db.name && selectedSchema == schema.name}>
                            {schema.tables.map((table) => {
                                return <TreeNode active={selectedDb == db.name && selectedSchema == schema.name && selectedTable == table.name} name={table.name} onClick={() => {
                                    props.onSelect(db.name + '.' + schema.name + '.' + table.name);
                                }}/>
                            })}
                        </TreeNode>
                    })}
                </TreeNode>

            })}
            </div>
            
        }
        
    </>
}

interface SnowflakeDataSourceViewerProps {
    tableName: string;
    onUseTable: () => void;
}


export const SnowflakeDataSourceViewer = (props: SnowflakeDataSourceViewerProps) => {
    const tables = useAvailableSnowflakeTables();
    const searchInput = useRef<HTMLInputElement>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const selectedDb = useMemo(() => {
        if (props.tableName) {
            return props.tableName.split('.')[0];
        }
        return '';
    }, [props.tableName]);

    const selectedSchema = useMemo(() => {
        if (props.tableName) {
            return props.tableName.split('.')[1];
        }
        return '';
    }, [props.tableName]);

    const selectedTable = useMemo(() => {
        if (props.tableName) {
            return props.tableName.split('.')[2];
        }
        return '';
    }, [props.tableName]);


    const hierarchicallyOrganizedTables: Database[] = useMemo(() => {
        if (!tables.data) {
            return [];
        }
        return getHierarchicallyOrganizedTables(tables.data);

    }, [tables.dataUpdatedAt]);

    const navigate = useNavigate();
    const showTable = useCallback((database: string, schema: string, table: string) => {
        navigate(`?table=${database}.${schema}.${table}`);
    }, []);

    const selectedTableInfo = useMemo(() => {
        if (!props.tableName) {
            return null;
        }
        const db = hierarchicallyOrganizedTables.find((db) => db.name == selectedDb);
        if (!db) {
            return null;
        }
        const schema = db.schemas.find((schema) => schema.name == selectedSchema);
        if (!schema) {
            return null;
        }
        return schema.tables.find((table) => table.name == selectedTable);
    }, [selectedDb, props.tableName, selectedSchema, selectedTable, hierarchicallyOrganizedTables]);

    const checkForSearchEnterKey = useCallback((evt: KeyboardEvent<HTMLInputElement>) => {
        if (evt.keyCode == 13) {
            setSearchQuery(searchInput.current?.value || '')
        }
    }, [searchInput, props.tableName]);

    const [loading, setLoading] = useState(false);

    return <>
        
        {props.tableName && <>
            <div style={{height: '80px'}} className="border-bottom">
                <div className="d-flex center-vertically mb-2">
                    <div className="overflow-ellipsis flex-1">
                        <>
                            <h3 title={props.tableName} className="overflow-ellipsis mb-0">{props.tableName}</h3>
                            <div className="mb-1 font-poppins text-muted font-13">
                                <span>
                                    <Badge>{selectedTableInfo?.type}</Badge>
                                </span>
                                <span className="me-3">&nbsp;</span>
                                <span>
                                    <i className="mdi mdi-file-multiple"></i> Records: {selectedTableInfo ? summarizeNumber(selectedTableInfo.totalRecords) : '0'}
                                </span>
                            </div>
                        </>
                    </div>
                    <div>
                    
                        <input type="text" className=" form-control d-inline-block input-rounded me-2" placeholder="Search (free-text or lucene)" defaultValue={searchQuery} ref={searchInput} onKeyDown={checkForSearchEnterKey} style={{width: '500px', marginLeft: '10px' }}/>
                    
                    </div>
                    {/* <AsyncButton 
                        onClick={props.onUseTable}
                        loading={loading}
                        
                        text="Use Table"
                        variant="pliable"
                    /> */}
                </div>
                
            </div>
            <div style={{height: 'calc(100% - 80px)'}}>
                <TableExplorer
                    tablePath={`/db/data?table="${selectedDb}"."${selectedSchema}"."${selectedTable}"`}
                    searchQuery={searchQuery}
                />
            </div>
        </>}
                        
                
        
    </>
}

const ensureDoubleQuotes = (str: string) => {
    if (str.indexOf('"') == -1) {
        return `"${str}"`;
    }
    return str;
}

const SnowflakeDataSourcePage = () => {
    const pipelineNodes = usePipelineNodes();

    const tableNodes = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }

        return pipelineNodes.data.filter((node) => node.node_type == 'SOURCE' && !node.flat_file).sort((a, b) => a.label.localeCompare(b.label));
    }, [pipelineNodes.dataUpdatedAt]);

    const [timesOnSnowflakePage, setTimesOnSnowflakePage] = useState(0);

    useEffect(() => {
        const currentTimes = localStorage.getItem('timesOnSnowflakePage');
        const parsed = currentTimes ? parseInt(currentTimes, 10) : 0; 
        if (currentTimes) {
            setTimesOnSnowflakePage(parsed);
        } else {
            setTimesOnSnowflakePage(0);
        }

        localStorage.setItem('timesOnSnowflakePage', ((parsed || 0) + 1).toString());
    }, []);

    return <PageContent>
         <PageContentHeader>
            <div>
                <SourceIcon image_url={snowflakeIcon} />
                <div className="flex-1 ms-2">
                    <h1 className="mb-0">Snowflake Tables</h1>
                    <div className="text-muted font-13">
                        View all Snowflake tables you have connected to Pliable.
                    </div>

                </div>
                <DraftOnly>
                    <Link className="btn btn-primary" to={`/wizard/data-source?sourceType=snowflake`}>
                        <i className="mdi mdi-connection"></i> Connect New
                    </Link>
                </DraftOnly>
            </div>
        </PageContentHeader>
        <PageContentInner hasHeader>
        <div className="p-4">
        <div className="card">
            <div className="card-body">
                {tableNodes.length == 0 && <div>
                    <DraftOnly>

                        <div className="empty-state">
                            <div className="text-center">
                                <h3>You haven't connected any tables yet.</h3>
                                <p>Pliable can pull data directly from any table in your Snowflake warehouse.</p>
                                <ReadOnly>
                                    <Link className="btn btn-primary" to={`/wizard/data-source?sourceType=snowflake`}>Connect one now.</Link>
                                </ReadOnly>
                            
                            </div>
                        </div>
                    </DraftOnly>
                    <ProdOnly>
                        <p>You are currently in <strong>Production Mode.</strong> To connect tables, please enter Develop Mode.</p>

                    </ProdOnly>
                </div>}
                <NodeList nodes={tableNodes} />
            </div>
        </div>
        
       
    </div>
        </PageContentInner>
        </PageContent>
}

export default SnowflakeDataSourcePage;

