import DraftBanner from "@components/pageTitle/DraftBanner.component";
import PageTitle, { Breadcrumb } from "@components/pageTitle/PageTitle.component";
import { toggleThenaWidget, useBillingAccount, useDraftVersionId } from "@stores/data.store";
import useGlobalState from "@stores/global.state";
import { ReactNode, useMemo } from "react";
import styled, { css } from 'styled-components';
import logoLarge from '@assets/images/pliable/logo_with_text_white.png';
import { Link, useLocation } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import { Dropdown as BSDropdown } from 'react-bootstrap';
import UserSettings from "@components/nav/UserSettings.component";
import { useEntitlements } from "@frontegg/react";
import Inbox from "@components/Inbox.component";



export const Main = styled.main`
    
    height: 100%;
    width: 100%;
    button.toggle-library-button {
        position: fixed;
        z-index: 1001;
        top: 12px;
        left: 50px;
        width: 26px;
        height: 26px;
        border-radius: 13px;
        font-size: 18px;
        padding: 0px;
        line-height: 18px;
        border: solid 1px var(--ct-border-color);
        background-color: white;
        color: #878787;
        box-shadow: 0px 1.470588207244873px 2.2058823108673096px 0px #0000001A;
        -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        &:hover {
            border-color: #555;
            color: #555;
        }

    }

    &.data-library {
        .toggle-library-button {
            left: 380px;
        }
    }

    .content-wrapper {
        height: 100vh;
        width: 100vw;
        height: -webkit-fill-available;
        max-height: 100vh;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
                flex-direction: row;
        
        .page-content {
            width: calc(100vh - 60px);
            height: 100vh;
            flex: 1;

            .page-content-inner {
                height: calc(100vh);
                display: flex;
                flex-direction: row;

                &.page-content-inner-draft {
                    height: calc(100vh - 50px);
                }
            }
        }
    }
`

const NavHeaderStyles = styled.div`
background-color: var(--pliable-navy);
color: white;
border-bottom: solid 1px var(--ct-border-color);
height: 50px;
display: flex;
align-items: center;
padding: 0px 1rem;
box-shadow: 5px 0px 10px 0px #0000000D;
a:hover {
        text-decoration: none !important;
    }

.nav-link {
    color: white;

    &:hover, &.show, &.active {
        color: var(--pliable-yellow);
    }

    
}

.logo {
margin-right: 3rem;
}
`

const TrialIndicator = styled.div`
   
    font-size: 14px;
    margin-right: 2rem;
    border-right: solid 1px var(--ct-border-color);
    padding-right: 2rem;
    margin-left: 2rem;
    padding-left: 2rem;
    border-left: solid 1px var(--ct-border-color);

`

const NavHeader = () => {
    const { isEntitled: hostedSupersetEnabled } = useEntitlements({
        featureKey: "hosted_superset",
    });

    const { isEntitled: pliableVisualizationsEnabled } = useEntitlements({
        featureKey: "pliable_visualizations",
    });

    const billing = useBillingAccount();

    const location = useLocation();
    return <NavHeaderStyles>
        <Link to="/" title="Home" className="logo">        
            <img src={logoLarge} style={{height: '27px'}}/>
        </Link>

        <Nav className="me-auto">
            <Link to="/" className={`nav-link ${location.pathname == '/' ? 'active' : ''}`}><i className="mdi mdi-home"></i> Home</Link>
            <Link to="/sources" className={`nav-link ${location.pathname.startsWith('/sources') ? 'active' : ''}`}><i className="mdi mdi-database"></i> Sources</Link>
            {/* <Link to="/cleaning" className="nav-link"><i className="mdi mdi-broom"></i> Cleaning</Link> */}
            <Link to="/semantic-layer" className={`nav-link ${location.pathname == '/semantic-layer' ? 'active' : ''}`}><i className="mdi mdi-star"></i> Semantic Layer</Link>
            <Link to="/dag" className={`nav-link ${location.pathname == '/dag' ? 'active' : ''}`}><i className="mdi mdi-sitemap mdi-rotate-270"></i> Data Flow</Link>
            <Link to="/reports" className={`nav-link ${location.pathname == '/reports' ? 'active' : ''}`}><i className="mdi mdi-table-star"></i> Reports</Link>
            {pliableVisualizationsEnabled && 
                <Link to="/viz" className={`nav-link ${location.pathname.startsWith('/viz') ? 'active' : ''}`}><i className="mdi mdi-chart-bar"></i> Visualizations</Link>
            }
            {hostedSupersetEnabled && 
                <Link to="/bi" className={`nav-link ${location.pathname == '/bi' ? 'active' : ''}`}><i className="mdi mdi-chart-bar"></i> Dashboards</Link>
            }
        </Nav>
            <button onClick={toggleThenaWidget} className="btn btn-pliable me-2">
                <i className="mdi mdi-chat"></i> Chat
            </button>
            
            <Inbox/>
            <DraftBanner />
            <UserSettings />
        
    </NavHeaderStyles>
}



interface Props {
    children: ReactNode;
    pageTitle?: string;
    breadcrumbs?: Breadcrumb[];
    pageTitleChildren?: ReactNode;
}

const PageStructure = (props: Props) => {
    const sidebarVisible = useGlobalState((state: any) => !state.libraryCollapsed);
    const toggleSidebar = useGlobalState((state: any) => state.toggleLibrary);
    const {data: draftVersionId} = useDraftVersionId();

    let className = 'd-flex flex-nowrap';

    if (sidebarVisible) {
        className = className + ' data-library';
    }

    return <Main className={className}>
        {/* <button className="toggle-library-button" onClick={() => toggleSidebar()}>
            {sidebarVisible && <i className="mdi mdi-chevron-left"></i>}
            {!sidebarVisible && <i className="mdi mdi-chevron-right"></i>}
        </button> */}

        <div className="content-wrapper">
            {/* <Nav/> */}
            <div className="page-content">
                {/* <DraftBanner /> */}
                <NavHeader/>
                {/* <PageTitle
                    title={props.pageTitle}
                    breadcrumbs={props.breadcrumbs}
                >
                    {props.pageTitleChildren}
                </PageTitle> */}
                <div className={`page-content-inner page-content-inner-draft`}>
                    {props.children}
                </div>
            </div>
            
            
        </div>
    </Main>
}

export default PageStructure;

export const PageSidebar = styled.div<{ big?: boolean; right?: boolean}>`
    width: 330px;
    height: 100%;
    overflow-y: hidden;
    background-color: var(--ct-extra-light);
    position: relative;
    border-right: solid 1px var(--ct-border-color);
    overflow-x: hidden;

    ${props =>
        props.big &&
        css`
            width: 430px;
        `
    };

    ${props => 
        props.right && 
        css`
            border-right: none;
            border-left: solid 1px var(--ct-border-color);
        `
    };
`

export const PageContent = styled.div<{ hasSidebar?: boolean, doubleSidebar?: boolean}>`
width: calc(100vw) ;
background-color: var(--ct-body-bg);
position: relative;
overflow: none;

${props => 
    props.hasSidebar && css`
        width: calc(100vw - 330px);
    `
}

${props => 
    props.doubleSidebar && css`
        width: calc(100vw - 660px);
    `
}

`

export const PageContentInner = styled.div<{noScroll?: boolean, hasHeader?: boolean, noPadding?: boolean, headerIsReallyTall?: boolean}>`
flex: 1 1 auto;
height: 0;


${props =>
    !props.noScroll && 
    css`
        overflow-y: auto;
    `
};

${props => props.hasHeader && css`
    height: calc(100% - 100px) !important;
`}

${props => props.headerIsReallyTall && css`
    height: calc(100% - 300px) !important;
`}

${props => props.noPadding && css`
    padding: 0;
`}


`;

export const PageContentHeader = styled.div<{compact?: boolean, reallyTall?: boolean}>`
height: 100px;
padding: 1rem 2rem;
background: white;
border-bottom: solid 1px var(--ct-border-color);

> div {
    display: flex;
    height: 100%;
    align-items: center;
}

${props => props.reallyTall && css`
    height: 300px;
`}
`

export const PageContentNoSidebar = styled.div`
width: calc(100vw - 60px);
`


export const PaneContentWithSubnav = styled.div`
height: calc(100% - 50px);
`

export const Pane = styled.div`
height: 100%;
display: flex;
flex-direction: column;
`;

export const PaneContent = styled.div<{noScroll?: boolean, hasHeader?: boolean}>`
flex: 1 1 auto;
height: 0;

${props =>
    !props.noScroll && 
    css`
        overflow-y: auto;
    `
};

${props => props.hasHeader && css`
    height: calc(100% - 180px) !important;
`}


`;


export const PaneContentSidebar = styled.div`
    width: 330px;
    height: 100%;
    background: white;
    border-left: solid 1px var(--ct-border-color);
`

export const PaneContentInnerWithSidebar = styled.div`
    width: calc(100% - 330px);
`

export const PaneFooter = styled.div<{big?: boolean}>`
height: 40px;
border-top: solid 1px var(--ct-border-color);
background:white;

${props =>
    props.big && 
    css`
        height: 100px;
    `
};
`;

const SwipeablePaneStyles = styled.div<{big?: boolean}>`
display: flex;
flex-direction: row;
height: 100%;
position: relative;

&> div {
    width: 330px;
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    ${props =>
        props.big &&
        css`
            width: 430px;
        `
    };
}
`


interface SwipeablePaneProps {
    children: ReactNode[];
    childIndex: number;
    big?: boolean;
}
export const SwipeablePane = (props: SwipeablePaneProps) => {
    const width = useMemo(() => {
        if (props.big) {
            return 430;
        }
        return 330;
    }, [props.big]);
    const translate = (props.childIndex -1) * -1 * width;
    return <SwipeablePaneStyles big={props.big} style={{
    }}>
        {props.children.filter(c => !!c).map((c, idx) => {
            
            return <div style={{left: `${(idx - props.childIndex) * width}px`, height: '100%'}}
            >
                {c}
            </div>
        })}
    </SwipeablePaneStyles>
}


export const WizardSteps = styled.div`
    height: 80px;
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Poppins";

    div.step {
        display: flex;
        div.icon {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background-color: var(--ct-light-gray);
            font-size: 24px;
            line-height: 40px;
            text-align: center;

        }

        &.complete {
            div.icon {
                background-color: var(--ct-dark);
                color: white;
            }
        }

        &.active {
            div.icon {
                background-color: var(--ct-primary);
                color: white;
            }
        }
        div.text {
            margin-left: 1rem;
            .step-label {
                font-size: .8rem;
            }
            .step-description {
                font-size: 0.7rem;
            }   
        }
    }

    .step-divider {
        width: 40px;
        border-bottom: solid 1px var(--ct-body-color);
    }
   
`

interface WizardStepProps {
    icon: string;
    stepNumber: string;
    active?: boolean;
    complete?: boolean;
    stepDescription: string;
}

export const WizardStep = (props: WizardStepProps) => {
    return <div className={`step ${props.active && 'active'} ${props.complete && 'complete'}`}>
        <div className="icon">
            <i className={`mdi mdi-${props.icon}`}></i>
        </div>
        <div className="text">
            <div className="step-label">Step {props.stepNumber}</div>
            <div className="step-description">{props.stepDescription}</div>
        </div>
    </div>
}

interface WizardStepDividerProps {
    active?: boolean;
}


export const WizardStepDivider = (props: WizardStepDividerProps) => {
    return <div className="step-divider">
    </div>
}

export const WizardFooter = styled.div`
    height: 80px;
    line-height: 80px;
    padding-right: 4.5rem;
    padding-left: 4.5rem;

    .btn {
        font-size: 20px;
        padding: .5rem 1rem;
    }
`

export const WizardContent = styled.div`
    flex: 1 1 auto;
    height: 0;
    padding-right: 4.5rem;
    padding-left: 4.5rem;

    .card {
        height: 100%;
    }
    .card-body {
        height: calc(100% - 4rem);
        overflow: scroll;
    }

    tr.disabled {
        cursor: not-allowed !important;
    }

`