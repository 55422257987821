import { 
    useTable,
    useSortBy, 
    useBlockLayout, 
    useResizeColumns, 
    usePagination 
} from 'react-table';
import styled from 'styled-components';
import { useMemo, useEffect, ReactNode, ReactElement, useCallback } from 'react';
import LoadingCard from '@components/card/LoadingCard.component';
import { useSticky } from "react-table-sticky";
import { formatValue, urlEncodeObject, varEx } from '@services/formatting.service';
import { Menu, MenuItem, MenuHeader } from '@szhsin/react-menu';
import { CellAction, PipelineNodeCellAction } from '@models/pipelineNode';
import { useNavigate } from 'react-router-dom';
import toast from '@services/toast.service';

export interface Column {
    header: string;
    key: string;
    formatter?: string;
    onClick?: (value: any, rowData?: any) => void;
    display?: (value: any, rowData?: any) => ReactElement;
}

export interface CellFormatOptions {
    cellColor?: string;
    textColor?: string;
}

export interface CellActionCommand {
    uri: string;
    method: string;
}

export const cellActionToCommand = (cell_action: PipelineNodeCellAction, column: string, record: any) : CellActionCommand =>{
    switch(cell_action.action){
        case CellAction.NAVIGATE_TO_NODE:
            const qry = (cell_action.config.search_tmpl) ? varEx(cell_action.config.search_tmpl, record) : '';
            const uri = `/node/${cell_action.config.node_id}?q=${encodeURIComponent(qry)}`;
            return { uri, method: 'navigate' }
            break;
        case CellAction.REFER_TO:
            const payload = Object.assign({}, cell_action.config.data);
            for (const [key, value] of Object.entries(payload)) {
                payload[key] = varEx(value as string, record);
            }
            let refer_to_uri = `${cell_action.config.url}${(cell_action.config.url.includes('?')) ? '&' : '?'}${urlEncodeObject(payload)}`;
            return { uri: refer_to_uri, method: 'referal' }
            break;
        default:
            throw new Error(`Unhandled cell action: ${cell_action.action}`);
    }
}


interface Props {
    loading?: boolean;
    columns: Column[];
    data: Record<string, any>[];
    columnFormatters: { [columnId: string]: (value: any) => CellFormatOptions; };
    columnActions: { [columnId: string]: PipelineNodeCellAction[] };
    lastRowAsFooter?: boolean;
    groupByColumn?: string;
    onCellDoubleClick?: any;
    highlightRowsByIndexes?: number[];
    totalPageCount?: number;
    onShowColumnStats?: (column: string) => void;
    onShowColumnLineage?: (column: string) => void;
    onFilterSortChange?: (params: FetchDataParams) => void;
    onCleanColumn?: (column: string) => void;
    defaultSort?: any[];
    enableColumnContextMenu?: boolean;
    enableRowContextMenu?: boolean;
    onRowContextMenuSelect?: (row: any, contextMenuOption: string) => any;
}



export interface FetchDataParams {
    pageIndex: number;
    pageSize: number;
    sortBy: any;
}


interface SimpleProps {
    loading?: boolean;
    data: Record<string, any>[];
    columns?: Column[]|string[];
    columnFormatters?: { [columnId: string]: (value: any) => CellFormatOptions; };
    columnActions?: { [columnId: string]: PipelineNodeCellAction[] };
    onColumnClick?: {
        [key: string]: (value: any) => void;
    }
    onCellDoubleClick?: any;
    highlightRowsByIndexes?: number[];
    totalPageCount?: number;
    onShowColumnStats?: (column: string) => void;
    onShowColumnLineage?: (column: string) => void;
    onCleanColumn?: (column: string) => void;
    onFilterSortChange?: (params: FetchDataParams) => void;
    initialSort?: any[];
    enableColumnContextMenu?: boolean;
    enableRowContextMenu?: boolean;
    onRowContextMenuSelect?: (row: any, contextMenuOption: string) => any;
}

const Styles = styled.div`
height: 100%;
width: 100%;
overflow: auto;
background: white;

.table {
    display: inline-block;
    border-spacing: 0;
    font-size: 12px;
    border-width: 0px 0px 1px 0px;
    height: auto;
    width: 100%;
    margin-bottom: 0px;
    border-bottom: none;

    .thead {
        position: sticky;
        z-index: 1;
        width: fit-content;
        top: 0;
    }

    .tr {
        :last-child {
            // .td {
            //     border-bottom: 0;
            // }
        }
    }

    .tr.highlighted .td {
        --ct-table-accent-bg: rgba(255, 159, 0, 0.25) !important;
    }

    .th {
        .th-content {
            a {
                color: black;
                font-weight: 700;
                flex: 1;
            }
              
            a:hover {
                text-decoration: underline;
                color: black;
            }
    
            display: flex;
        }
    }

    .td, .th {
        border-right: solid 1px var(--ct-border-color);
    }


    .td {
        border-bottom: solid 1px var(--ct-border-color);
        overflow: hidden;
        text-overflow: ellipsis;

        a {
            color: var(--ct-primary);
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

        &.cell-with-actions {

            .value-wrapper {
                overflow: hidden;
            }
            overflow: visible;
        ;}
    }

    .th {
        border-top: none;
    }

    .thead .tr {
        border-top: none;

        background-color: var(--bs-table-bg);
        z-index: 2;
    }

    .th,
    .td {
        margin: 0;
        padding: 3px 5px;
        position: relative;

        :last-child {
            border-right: 0;
        }

        .resizer {
            display: inline-block;
            background: transparent;
            width: 10px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(50%);
            z-index: 1;
            ${'' /* prevents from scrolling while dragging on touch devices */}
            touch-action: none;

            &.isResizing {
                background: transparent;
            }
        }
    }
}
`

export const DataTable = (props: Props) => {

    const navigate = useNavigate();
    const defaultColumn = useMemo(
        () => ({
          minWidth: 30,
          width: 150,
          maxWidth: 400,
        }),
        []
    );

    const columns = useMemo(
        () => props.columns.map((col, idx) => {
            return {
                Header: col.header || `column_${idx + 1}`,
                accessor: col.key || `column_${idx + 1}`,
                Cell: (data: any) => {
                    const value = data.value;
                    const format = col.formatter;
                    const formattedValue = formatValue({ value, format });

                    const innerContent: ReactNode = col.display
                        ? col.display(value, data.row.original)
                        : <span>{formattedValue}</span>;

                    if (!!col.onClick) {
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        return <a role="button" onClick={() => {
                            // @ts-ignore
                            col.onClick(value, data.row.values);
                        }}>{innerContent}</a>
                    }
                    return innerContent;
                }
            }
        }),
        [props.columns]
    );

    const data = useMemo(
        () => props.data,
        [props.data]
    );

    //const tableState = useTableState({ pageIndex: currentPage });
    const manualSortPag = useMemo(
        () => !props.totalPageCount || props.totalPageCount > 1 ||  (!!props.defaultSort && props.defaultSort!.length > 0),
        [props.totalPageCount, props.defaultSort]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize, sortBy }
    } = useTable({ 
            columns, 
            data, 
            initialState: { pageIndex: 0, pageSize: 100, sortBy: props.defaultSort || [] },

            // For some reason passing defaultColumn slows it the fuck down. unclear why
            // defaultColumn,
            manualSortBy: manualSortPag,
            // Tell the usePagination hook that we'll handle our own data fetching
            manualPagination: manualSortPag,
            // // This means we'll also have to provide our own pageCount.
            pageCount: props.totalPageCount || 1,
            style: {
                height: '100%',
                width: '100%',
                overflowX: 'scroll',
            }
        },
        useSortBy, 
        useBlockLayout, 
        useResizeColumns,
        usePagination,
        useSticky,
    );


    useEffect(() => {
        if(manualSortPag && props.onFilterSortChange){
            props.onFilterSortChange({pageIndex, pageSize, sortBy});
        }
    }, [
        manualSortPag, 
        sortBy,
        pageIndex, 
        // pageSize, 
        // sortBy.length, 
        // props.onFilterSortChange
    ])


    const renderHeaderMenu = (col: any, idx: number) => {
        // if(!props.onShowColumnStats){
        //     // there are no header cell menu items to list
        //     return <></>;
        // }
        
        // return (
        //     <span style={{display: 'inline-block', float: 'right'}} onClick={(e) => e.stopPropagation()}>
        //         <Dropdown className="header-menu">
        //             <Dropdown.Toggle variant="" id="dropdown-basic" size="sm" style={{padding: '0px 3px', lineHeight: '1px'}}>
        //             </Dropdown.Toggle>
        //             <Dropdown.Menu variant="dark">
        //                 { !!props.onShowColumnStats && <Dropdown.Item onClick={() => props.onShowColumnStats!(col.Header)}>Show Column Stats</Dropdown.Item> }
        //             </Dropdown.Menu>
        //         </Dropdown>
        //     </span>
        // );
        return <></>;
        
    
    }

    const renderColumnHeader = (col: any, idx: number)  => {
        return (
            <div {...col.getHeaderProps()} className="th fs-mask">
                <div className="th-content user-select-none">
                    <div className="d-flex center-vertically w-100">
                        <a role="button" style={{display: 'block'}} className="flex-1" {...col.getSortByToggleProps()}>{col.render('Header')}</a>
                        <div>
                            {col.isSorted && col.isSortedDesc && <i className='mdi mdi-sort-ascending'></i>}
                            {col.isSorted && !col.isSortedDesc && <i className='mdi mdi-sort-descending'></i>}
                        </div>
                        {props.enableColumnContextMenu && col.id != '_PLB_UUID' && (
                            <div>
                                <Menu menuButton={<button className="icon-button">
                                    <i className="mdi mdi-menu"></i>
                                </button>}>
                                    <MenuHeader>
                                        {col.Header}
                                    </MenuHeader>
                                    {props.onShowColumnStats && <MenuItem onClick={() => props.onShowColumnStats!(col.Header as string)}>Column Details</MenuItem>}
                                    {props.onShowColumnLineage && <MenuItem onClick={() => props.onShowColumnLineage!(col.Header as string)}>Column Lineage</MenuItem>}
                                   
                                    {/* <SubMenu label="Edit">
                                        <MenuItem>Cut</MenuItem>
                                        <MenuItem>Copy</MenuItem>
                                        <MenuItem>Paste</MenuItem>
                                    </SubMenu>
                                    <MenuItem>Print...</MenuItem> */}
                                </Menu>
                            </div>
                        )}
                        
                    </div>
                    <div>
                        
                      
                    </div>
                </div>
                
                <div
                    {...col.getResizerProps()}
                    className={`resizer ${
                    col.isResizing ? 'isResizing' : ''
                    }`}
                    // prevent resizing from tiggering sort
                    onClick={(e)=>{e.preventDefault();e.stopPropagation()}}
                />
            </div>
        );
    }

    const handleCellDoubleClick = (col: Column, row: Record<string, string>, value: any) => {
        if(props.onCellDoubleClick) {
            props.onCellDoubleClick(col, row, value)
        }
    }

    const handleCellActionClick = (cell_action: PipelineNodeCellAction, col: Column, row: Record<string, string>, cell_value: any) => {
        try{
            const command = cellActionToCommand(cell_action, col.key, row.values);

            if (command.method === 'navigate') {
                navigate(command.uri);
            }else if(command.method === 'referal'){
                window.open(command.uri as string, '_blank')?.focus();
            }
        }catch(e){  
            toast('danger', 'Error', e as string);
        }
    }

    const handleRowContextMenuClick = useCallback((row: any, contextMenuOption: string) => {
        if (props.onRowContextMenuSelect) {
            props.onRowContextMenuSelect(row.original, contextMenuOption);
        }
    }, [props.onRowContextMenuSelect]);

    if (props.loading === true) {
        return <LoadingCard />
    }

    return (
        <div {...getTableProps()} className="table table-sm table-bordered table-striped">
            <div className="table-light thead">
                
                {headerGroups.map((headerGroup: any) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="tr">
                        {props.enableRowContextMenu && (
                            <div className="th" style={{width: '50px'}}>
                                
                            </div>
                        )}
                        {headerGroup.headers.map((col: any, idx: number) => renderColumnHeader(col, idx))}
                    </div>
                ))}
            </div>
            <div {...getTableBodyProps()}>
                {rows.map((row: any, idx: number) => {
                    prepareRow(row);
                    const extraClass = (props.highlightRowsByIndexes && props.highlightRowsByIndexes.includes(idx)) ? 'highlighted' : '';
                    return (
                        <div {...row.getRowProps()} className={`tr ${extraClass}`}>
                            {props.enableRowContextMenu && (
                                <div className="td" style={{width: '50px'}}>
                                    <Menu menuButton={<button className="icon-button">
                                        <i className="mdi mdi-menu"></i>
                                    </button>}>
                                        <MenuHeader>Record Actions</MenuHeader>
                                        <MenuItem onClick={() => handleRowContextMenuClick(row, 'MERGE')}>
                                            <i className="mdi mdi-source-fork"></i>&nbsp;Merge
                                        </MenuItem>
                                        <MenuItem disabled>
                                            <i className="mdi mdi-merge"></i>&nbsp;View Lineage
                                        </MenuItem>
                                        <MenuItem disabled>
                                            <i className="mdi mdi-filter"></i>&nbsp;Filter Out
                                        </MenuItem>
                                    </Menu>
                                </div>
                            )}
                            {row.cells.map((cell: any) => {
                                const cellProps = cell.getCellProps();
                                if (props.columnFormatters.hasOwnProperty(cell.column.id)){
                                    // apply custom formatting
                                    const format = props.columnFormatters[cell.column.id](cell.value);
                                    if(format.cellColor){
                                        cellProps.style.backgroundColor = format.cellColor;
                                    }
                                    if(format.textColor){
                                        cellProps.style.color = format.textColor;
                                    }
                                }

                                return (
                                <div
                                    {...cellProps}
                                    // onDoubleClick={() => handleCellDoubleClick(cell, row, value)}
                                    title={cell.value}
                                    className={`td fs-mask text-truncate ${(props.columnActions && props.columnActions.hasOwnProperty(cell.column.id)) ? 'cell-with-actions' : ''}`}>
                                    <>
                                        {!!props.onCellDoubleClick && (
                                            <div className="d-flex center-vertically w-100">
                                                <div className='flex-1 value-wrapper'>
                                                    <span className="cell-value">
                                                        {cell.render('Cell')}
                                                    </span>
                                                </div>

                                                {props.columnActions && props.columnActions.hasOwnProperty(cell.column.id) && <Menu menuButton={<button className="icon-button">
                                                    <i className="mdi mdi-menu"></i>
                                                    </button>}>
                                                    <MenuHeader>Actions</MenuHeader>


                                                    {props.columnActions[cell.column.id].map((action: PipelineNodeCellAction) => {
                                                        return <MenuItem onClick={() => handleCellActionClick(action, cell.column, row, cell.value)}>
                                                            <i className={`mdi mdi-merge`}></i>&nbsp;{action.label}
                                                        </MenuItem>
                                                    })}

                                 
                                                </Menu>}

                                            </div>


                                        )}
                                        {!props.onCellDoubleClick && (
                                            <span>{cell.render('Cell')}</span>
                                        )}
                                    </>
                                </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>

            { !!props.totalPageCount && props.totalPageCount > 1 &&
                <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                    </button>{' '}
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                    </button>{' '}
                    <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                    </span>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                    </button>{' '}
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                    </button>{' '}
                    <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                    >
                    {[25, 100, 1000, 10000].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                        </option>
                    ))}
                    </select>
                </div> 
            }
        </div>
        
    );
}

export default DataTable;


export function SimpleDataTable({ loading, data, columns, onCellDoubleClick, onShowColumnStats, onShowColumnLineage, onFilterSortChange, totalPageCount, onColumnClick, highlightRowsByIndexes, columnFormatters, initialSort, enableColumnContextMenu, enableRowContextMenu, onRowContextMenuSelect, columnActions, onCleanColumn }: SimpleProps) {
    if (loading !== true && !data) {
        return <span></span>;
    }

    if (loading !== true && !data.length) {
        return <div className="col-12 text-center mt-4">
            <p style={{fontSize: '30px'}}><i className="mdi mdi-block-helper"></i></p>
            <p>No records match that query</p>
        </div>
            
    }

    let parsedColumns : Column[] = [];
    if (columns && columns.length > 0) {
        if (typeof(columns) == 'string') {
            // @ts-ignore
            parsedColumns = columns.map((c: string) => {
                return {
                    header: c,
                    key: c,
                }
            }).slice(0, 50)
        } else {
            // @ts-ignore
            parsedColumns = columns;
        }
       
    } else if (!loading) {
        let keys = Object.keys(data[0]);

        parsedColumns = keys.map(k => {
            const h: string = k;
            if (k.includes('.')){
                // lets strip the periods because they make accesing the data impossible in react-table v7
                k = k.replaceAll('.', '')
                if (h !== k) {
                    data.forEach((rowdata) => {
                        Object.defineProperty(rowdata, k,
                            // @ts-ignore
                            Object.getOwnPropertyDescriptor(rowdata, h));
                        delete rowdata[h];
                    })
                    
                }
            }

            return {
                header: h,
                key: k,
            };
        }).slice(0, 50);
    }

    if (!!onColumnClick) {
        const clickableKeys = Object.keys(onColumnClick);
        parsedColumns = parsedColumns.map(pk => {
            if (clickableKeys.includes(pk.key)) {
                pk.onClick = onColumnClick[pk.key];
            }
            return pk;
        })
    }

    
    return (
        <Styles>
            <DataTable 
                loading={loading} 
                columns={parsedColumns} 
                columnFormatters={columnFormatters || {}}
                columnActions={columnActions || {}}
                data={data} 
                onFilterSortChange={onFilterSortChange} 
                onCellDoubleClick={onCellDoubleClick} 
                onShowColumnStats={onShowColumnStats}
                onShowColumnLineage={onShowColumnLineage}
                onCleanColumn={onCleanColumn}
                totalPageCount={totalPageCount}
                highlightRowsByIndexes={highlightRowsByIndexes}
                defaultSort={initialSort}
                enableColumnContextMenu={enableColumnContextMenu}
                enableRowContextMenu={enableRowContextMenu}
                onRowContextMenuSelect={onRowContextMenuSelect}
            />
        </Styles>
    );       
}

interface GroupedProps extends SimpleProps {
    groupBy: string;
}
export function GroupedDataTable({data, groupBy}: GroupedProps) {

}

export function tableDataFromRows(rows: string[][]) {
    const header = rows[0];
    const body = rows.slice(1);

    return body.map(b => {
        const entries = b.map((val: string, idx: number) => {
            return [header[idx], val];
        });
        return Object.fromEntries(entries);
    });
}
