import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  ExpressCheckoutElement,
  Elements
} from "@stripe/react-stripe-js";
import AsyncButton from "@components/button/AsyncButton.component";
import { loadStripe, StripePaymentElementOptions } from "@stripe/stripe-js";

const CheckoutForm = ({clientSecret}: any) => {
  const stripe = useStripe();
  const elements = useElements();

  const [saving, setSaving] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const stripePromise = loadStripe("pk_test_51NfpGhFA852DSwuBNtu1b5RCYtT0dchOWxls1usoTN46Hgz6gR00XFfNRzIbzieQoicrWbBHij1iVkKVQIkWDw4f0089lmWgl3");

  const handleSubmit = async () => {
    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      //setErrorMessage(submitError.message || '');
      return;
    }
    setSaving(true);
    const {error} = await stripe!.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: `https://${window.location.host}/account/billing`,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      //setErrorMessage(error.message);
      setSaving(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const onConfirm = async () => {
  }

  return (
      <form>
        <PaymentElement />
        {/* <PaymentElement options={} /> */}
        <hr />
        <AsyncButton text="Save payment info" className="btn-primary" onClick={handleSubmit} loading={saving} disabled={!stripe || !elements} />
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
  );
};


const PaymentForm = ({clientSecret}: any) => {


  const stripePromise = loadStripe("pk_test_51NfpGhFA852DSwuBNtu1b5RCYtT0dchOWxls1usoTN46Hgz6gR00XFfNRzIbzieQoicrWbBHij1iVkKVQIkWDw4f0089lmWgl3");
  


  return (
    <Elements stripe={stripePromise} options={{clientSecret}} >
      <div className="mt-3">
          <h2>Set Payment Info</h2>
      </div>
      <CheckoutForm clientSecret={clientSecret} />
    </Elements>
  );
};


export default PaymentForm;