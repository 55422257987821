import SourceIcon from "@components/sources/SourceIcon.component";
import { PipelineNode } from "@models/pipelineNode";
import { getNodeTypeConfig } from "@services/modeling.service";
import { ReactNode, useMemo } from "react";
import styled, { css } from 'styled-components';

const Container = styled.div<{bgColor?: string, compact?: boolean}>`
    width: 50px;
    margin-right: .75rem;

    img, div.fake-img {
        max-width: 50px;
        max-height: 50px;
        border-radius: 100%;
    }

    div.fake-img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        ${props => css`
            background: var(--pliable-${props.bgColor});
        `}
        color: white;
        line-height: 50px;
        text-align: center;
        font-size: 30px;
    }

    ${props => props.compact && css`
        width: 30px;
        div.fake-img {
            width: 30px;
            height: 30px;
            line-height: 30px;
            font-size: 20px;
        }
    `}
`

interface DashboardIconProps {
    icon: string;
    children?: ReactNode;
    bgColor: string;
    compact?: boolean;
}
export const DashboardIcon = (props: DashboardIconProps) => {
    return <Container bgColor={props.bgColor} compact={props.compact}>
        
        <div className="fake-img">
            <i className={props.icon}/>
        </div>
    
    

    </Container>
}

interface Props {
    node: PipelineNode;
    compact?: boolean;
}

const PipelineNodeIcon = (props: Props) => {
    const nodeTypeConfig = useMemo(() => {
        try {
            return getNodeTypeConfig(props.node);
        } catch (err) {
            return undefined;
        }
    }, [props.node]);
    

    const bgColor = useMemo(() => {
        if (nodeTypeConfig && nodeTypeConfig.group) {
            return nodeTypeConfig.group.color;
        }
        return 'success';
        
    }, [nodeTypeConfig]);

    const icon = useMemo(() => {
        if (props.node.flat_file) {
            return 'mdi mdi-file-document';
        }
        if (nodeTypeConfig && nodeTypeConfig.nodeType) {
            return nodeTypeConfig.nodeType.icon;
        }
        return '';
    }, [nodeTypeConfig, props.node, props.node.source_id]);

    if (props.node.source_id) {
        return <Container compact={props.compact}>
            <SourceIcon sourceId={props.node.source_id} compact={props.compact}/>
        </Container>;
    }
    return <DashboardIcon bgColor={bgColor} icon={icon} compact={props.compact}/>
}



export default PipelineNodeIcon;