import SaveButton from "@components/button/SaveButton.component";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageContentNoSidebar, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from "@pages/PageStructure.component";
import { getNodeTypeConfig } from "@services/modeling.service";
import { usePipelineNode } from "@stores/data.store";
import { forwardRef, ReactNode, useMemo } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom"
import { ButtonGroup, Form, Dropdown as BSDropdown, Button, Offcanvas, OffcanvasBody } from "react-bootstrap";
import PipelineNodeHelper from "@components/pipelineNodes/PipelineNodeHelper.component";

interface ToggleProps {
    children: ReactNode;
    onClick: (e: React.MouseEvent) => void;
    className?: string;
}
const CustomToggle = forwardRef((props: ToggleProps, ref) => (
    <button
        className={props.className}
        // @ts-ignore
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
        }}
    >
      {props.children}
    </button>
  ));

const PipelineNodeWrapper = () => {
    const { pipelineNodeId } = useParams();

    const pipelineNode = usePipelineNode(pipelineNodeId)

    const location = useLocation();
    const activePage = useMemo(() => {
        let path = location.pathname;

        if (path.endsWith('/')) {
            path = path.substring(0, path.length - 1);
        }
        
        if (path.endsWith('/config')) {
            return 'config';
        } else if (path.endsWith('/files')) {
            return 'files';
        } else if (path.indexOf('/relationships') >= 0) {
            return 'relationships';
        } else if (path.endsWith('/insights')) {
            return 'insights';
        } else if (path.endsWith('/filters')) {
            return 'filters';
        } else if (path.indexOf('/visualizations') >= 0) {
            return 'visualizations';
        }

        return 'data';
    }, [location]);

    const nodeConfig = useMemo(() => {
        if (!pipelineNode.data) {
            return undefined;
        }
        return getNodeTypeConfig(pipelineNode.data);
    }, [pipelineNode.dataUpdatedAt]);

    return <PageStructure
        pageTitle={pipelineNode.data?.name || ''}
        breadcrumbs={[]}
    >
        <PageContent hasSidebar>
            
            <Outlet/>
            
        </PageContent>
        <PageSidebar right>
            <Pane>
                <PaneContent>
                    <PipelineNodeHelper pipelineNodeId={pipelineNodeId as string}/>

                </PaneContent>
            </Pane>

        </PageSidebar>
    </PageStructure>
}

export default PipelineNodeWrapper;